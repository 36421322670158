/* eslint-disable import/prefer-default-export */
// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
// import { registerAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Takes a multimedia object, sends it to the API, and returns the response
 * @param {number} inmuebleId
 * @returns The data from the response.
 */
export const matchPOST = async (inmuebleId) => {
  // @ts-ignore
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/match`,
    { inmueble1_id: inmuebleId },
    {
      // @ts-ignore
      headers: { Accept: "*/*", Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
