/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
import { useEffect } from "react";

// @mui/material
import { Box } from "@mui/material";

// contexts
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";

const TermsConditions = () => {
  const { languageState } = useLanguage();

  const { setRouteState } = useRoute();

  useEffect(() => {
    setRouteState({ type: "set", to: -1 });
  }, []);

  return (
    <Box
      sx={{ marginTop: "40px", padding: { md: "40px 15rem", sm: "40px 5rem", xs: "40px 20px" } }}
    >
      <h1 className="text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3">
        {languageState.texts.TermsConditions.Title}
      </h1>
      {languageState.texts.TermsConditions.Content.map((item) => (
        <Box key={item.title}>
          <h3 className="text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3">
            {item.title}
          </h3>
          {item.bodies.map((jtem, i) => (
            <p key={i}>{jtem}</p>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default TermsConditions;
