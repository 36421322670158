// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Takes a characteristic object, sends it to the API, and returns the response
 * @param {object} characteristic - The characteristic object that contains the characteristic's information.
 * @returns The data from the response.
 */
export const createCharacteristic = async (characteristic) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/caracteristica`,
    { ...characteristic },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the characteristic's ID and the ID of the characteristic to delete
 * @param {string} characteristicId - The characteristic id of the characteristic you want to delete.
 * @returns The data is being returned.
 */
export const deleteCharacteristic = async (characteristicId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/caracteristica/${characteristicId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the characteristic's ID and characteristic data to modify it
 * @param {string} characteristicId - The characteristic id of the characteristic you want to delete.
 * @param {object} characteristicData - Characteristic data: name, characteristicGroupId
 * @returns The data is being returned.
 */
export const modifyCharacteristic = async (characteristicId, characteristicData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/caracteristica/${characteristicId}`,
    { ...characteristicData },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
