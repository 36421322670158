/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";

// @emotion/css
import { css } from "@emotion/css";

// @mui icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// layouts
import Hero from "layouts/Hero/Hero";
import Section from "layouts/Section/Section";

// services
import { sendContact } from "services/email/post";

// utils
import { scrollTo } from "utils/functions";
import Loader from "components/Loader/Loader";

// sections
// import MapSection from "./Sections/Map/Map";

const Contact = () => {
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const location = useLocation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [ok, setOk] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("hola");
    scrollTo(0);
    setRouteState({
      type: "set",
      to: 5,
      page: languageState.texts.Links[4].label,
    });
  }, [location]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "name":
        return setName(value);
      case "email":
        return setEmail(value);
      default:
        return setMessage(value);
    }
  };

  const localContact = useCallback(
    async (e) => {
      setLoading(true);
      e.preventDefault();
      try {
        const response = await sendContact(email, name, message);
        console.log(response);
        if (response.status === 200) {
          setNotificationState({
            type: "set",
            ntype: "success",
            nessage: languageState.texts.Messages.EmailSent,
          });
        }
        if (response.error) {
          // console.log(response.error);
          setNotificationState({
            type: "set",
            ntype: "error",
            message:
              response.error.indexOf("Error: Network Error") > -1
                ? languageState.texts.Errors.NotConnected
                : languageState.texts.Errors.Wrong,
          });
        }
      } catch (err) {
        console.error(err);
        // console.log(err);
        setNotificationState({
          type: "set",
          ntype: "error",
          message: String(err),
        });
      }
      setLoading(false);
    },
    [email, name, message, languageState, setNotificationState]
  );

  const validate = () => {
    setOk(true);
  };

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "name":
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.NameRequired,
          });
        case "message":
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.MessageRequired,
          });
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.EmailRequired,
          });
      }
    }
  };

  return (
    <>
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[2].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul
                className={css({ "li::after": { display: "none !important" } })}
              >
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>{languageState.texts.Links[2].label}</li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        {loading ? <Loader visible /> : null}
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer
              ignoreDefault
              className="col-lg-8 sm-margin-30px-bottom"
            >
              <SitoContainer
                ignoreDefault
                className="padding-50px-right sm-no-padding-right"
              >
                <form id="contact-form" method="post" onSubmit={localContact}>
                  <SitoContainer ignoreDefault className="row">
                    <SitoContainer ignoreDefault className="col-lg-6">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="name">
                          {languageState.texts.Contact.inputs.name.label}
                        </label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          required
                          value={name}
                          onChange={handleChange}
                          onInput={validate}
                          onInvalid={invalidate}
                          placeholder={
                            languageState.texts.Contact.inputs.name.placeholder
                          }
                        />
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-lg-6">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="email">
                          {languageState.texts.Contact.inputs.email.label}
                        </label>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          required
                          value={email}
                          onChange={handleChange}
                          onInput={validate}
                          onInvalid={invalidate}
                          placeholder={
                            languageState.texts.Contact.inputs.email.placeholder
                          }
                        />
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer
                      ignoreDefault
                      className="col-lg-12 margin-20px-bottom"
                    >
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="message">
                          {languageState.texts.Contact.inputs.message.label}
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          className="form-control"
                          required
                          value={message}
                          onChange={handleChange}
                          onInput={validate}
                          onInvalid={invalidate}
                          placeholder={
                            languageState.texts.Contact.inputs.message
                              .placeholder
                          }
                        ></textarea>
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-lg-12">
                      <SitoContainer ignoreDefault className="Send">
                        <button type="submit" className="butn">
                          {languageState.texts.Buttons.SendMessage}
                        </button>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-4">
              <SitoContainer ignoreDefault>
                <h4>{languageState.texts.Contact.Details.Title}</h4>
              </SitoContainer>
              <SitoContainer
                ignoreDefault
                className="d-flex align-items-center margin-20px-bottom"
              >
                {/*
                <SitoContainer ignoreDefault>
                  <span className="ti-home font-size38 xs-font-size34 text-theme-color"></span>
                </SitoContainer>
                 <SitoContainer ignoreDefault className="margin-30px-left">
                  <p className="no-margin-bottom text-extra-dark-gray font-weight-600">
                    {languageState.texts.Contact.Details.AddressLabel}
                  </p>
                  <a
                    href={languageState.texts.Contact.Details.AddressLink}
                    className="no-margin-bottom width-90"
                  >
                    {languageState.texts.Contact.Details.Address}
                  </a>
  </SitoContainer> */}
              </SitoContainer>
              <SitoContainer
                ignoreDefault
                className="d-flex align-items-center margin-20px-bottom"
              >
                <SitoContainer ignoreDefault>
                  <span className="ti-email font-size38 xs-font-size34 text-theme-color"></span>
                </SitoContainer>
                <SitoContainer ignoreDefault className="margin-30px-left">
                  <p className="no-margin-bottom text-extra-dark-gray font-weight-600">
                    {languageState.texts.Contact.Details.EmailLabel}
                  </p>
                  <a
                    href={languageState.texts.Contact.Details.EmailLink}
                    className="no-margin-bottom width-90"
                  >
                    {languageState.texts.Contact.Details.Email}
                  </a>
                </SitoContainer>
              </SitoContainer>
              <SitoContainer
                ignoreDefault
                className="d-flex align-items-center"
              >
                <SitoContainer ignoreDefault>
                  <span className="ti-mobile font-size38 xs-font-size34 text-theme-color"></span>
                </SitoContainer>
                <SitoContainer ignoreDefault className="margin-30px-left">
                  <p className="no-margin-bottom text-extra-dark-gray font-weight-600">
                    {languageState.texts.Contact.Details.PhoneLabel}
                  </p>
                  <a
                    href={languageState.texts.Contact.Details.PhoneLink}
                    className="no-margin-bottom"
                  >
                    {languageState.texts.Contact.Details.Phone}
                  </a>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>

      {/* <MapSection /> */}
    </>
  );
};

export default Contact;
