/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import { useState } from "react";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// layouts
import UserCategories from "views/Home/Sections/UserCategories/UserCategories";

const PickPlan = (props) => {
  const { onSelectPlan } = props;
  const [loading, setLoading] = useState(false);

  const localOnSelectPlan = async (e, plan) => {
    e.preventDefault();
    setLoading(true);
    await onSelectPlan(plan);
    setLoading(false);
  };

  return (
    <SitoContainer
      id="pick-plan"
      sx={{
        left: 0,
        top: 0,
        zIndex: 9999,
        position: "fixed",
        width: "100vw",
        height: "100vh",
        background: "white",
        justifyContent: "center",
      }}
    >
      <Loader visible={loading} />
      <UserCategories selectPlan={localOnSelectPlan} />
    </SitoContainer>
  );
};

PickPlan.propTypes = {
  onSelectPlan: PropTypes.func.isRequired,
};

export default PickPlan;
