/* eslint-disable react/function-component-definition */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

// @mui/material
import { useTheme, Typography } from "@mui/material";

const Message = (props) => {
  const theme = useTheme();
  const { item, color } = props;

  return (
    <SitoContainer
      sx={{
        padding: "5px 10px",
        borderRadius: "3px",
        backgroundColor: color
          ? `${theme.palette.primary.light}9c`
          : `${theme.palette.secondary.light}9c`,
      }}
    >
      <Typography variant="subtitle2">{item.mensaje}</Typography>
    </SitoContainer>
  );
};

Message.propTypes = {
  color: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
};

export default Message;
