export const multimediaRowContainerSx = {
  marginRight: "10px",
  marginTop: "10px",
  position: "relative",
  width: "150px",
  height: "150px",
};

export const loadingMultimediaSx = {
  width: "150px",
  height: "150px",
  position: "relative",
};

export const multimediaAddButtonSx = {
  padding: "30px",
  width: "150px",
  height: "150px",
  objectFit: "cover",
  borderRadius: "3px",
  marginRight: "10px",
  marginTop: "10px",
};

export const multimediaDeleteButtonSx = {
  position: "absolute",
  right: "-5px",
  top: "-5px",
  borderRadius: "100%",
  minWidth: 0,
  padding: "5px",
};

export const searchButtonSx = {
  position: "absolute",
  right: "30px",
  top: "16%",
  borderRadius: "100%",
  minWidth: 0,
  width: "35px",
  minHeight: 0,
  height: "35px",
};

export const listOfButtonsSx = {
  height: "300px",
  maxHeight: "300px",
  width: "100%",
  marginTop: "55px",
  flexDirection: "column",
  borderRadius: "3px",
  overflow: "auto",
};

export const buttonProps = {
  type: "button",
  color: "secondary",
  variant: "contained",
  sx: {
    width: "100%",
    height: "40px",
    margin: "2px 0",
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "none",
  },
};
