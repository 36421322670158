/* eslint-disable react/function-component-definition */

// @mui/icons-material
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

// styles
import "./style.css";

const NotSelected = () => {
  const { languageState } = useLanguage();
  return (
    <SitoContainer
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", minHeight: "400px" }}
    >
      <h4 className="dashboard-h3 dashboard-mb-3">{languageState.texts.Chat.NotSelected}</h4>
      <ArrowBackIcon className="bounce-left" color="secondary" sx={{ fontSize: "4rem" }} />
    </SitoContainer>
  );
};

export default NotSelected;
