/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// image
import features01 from "img/reals/features/01.webp";
import features02 from "img/reals/features/02.webp";
import features03 from "img/reals/features/03.webp";
import features04 from "img/reals/features/04.webp";
import features05 from "img/reals/features/05.webp";
import features06 from "img/reals/features/06.webp";

// @emotion
import { css } from "@emotion/css";

// framer-motion
import { motion } from "framer-motion";

// contexts
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";

// @mui icons
import SearchIcon from "@mui/icons-material/Search";

// @mui components
import { Box, IconButton } from "@mui/material";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// images
import bg1 from "img/reals/Carousel/Casas CRAB.png";

// services
import { propertyList } from "services/properties/get";

// utils
import { scrollTo } from "utils/functions";

const Catalogue = () => {
  const location = useLocation();

  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();

  const [toSearch, setToSearch] = useState("");
  const [filtering, setFiltering] = useState("");
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setRouteState({ type: "set", to: 0 });
    scrollTo();
  }, []);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const ulItem = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const onSearch = (e) => {
    e.preventDefault();
  };

  const filterBy = (type) => {
    setFiltering(type);
  };

  const fetch = async () => {
    setLoading(true);

    try {
      const response = await propertyList();

      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          const filter = data.filter((item) => {
            if (item.tipo_propiedad_id === filtering) return item;
            return null;
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filtering !== "") fetch();
  }, [filtering]);

  useEffect(() => {
    const queryParams = location.search.substring(1);
    if (!queryParams) scrollTo(0);
    setRouteState({ type: "set", to: 4, page: languageState.texts.Links[2].label });
  }, [location]);

  return (
    <>
      <SitoContainer
        ignoreDefault
        className="item bg-img cover-background"
        extraProps={{ "data-overlay-dark": "6" }}
        background={`url("${bg1}")`}
        sx={{ height: "80vh" }}
      >
        <SitoContainer ignoreDefault className="container h-100" sx={{ height: "100%" }}>
          <SitoContainer ignoreDefault className="display-table h-100" sx={{ height: "100%" }}>
            <SitoContainer
              ignoreDefault
              className="display-table-cell vertical-align-middle caption"
              sx={{ height: "100%" }}
            >
              <Box
                className="overflow-hidden mobile-width-auto"
                sx={{
                  height: "100%",
                  padding: { md: "0 8rem", xs: "0 40px" },
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <motion.ul
                  variants={container}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                >
                  <motion.div variants={ulItem} viewport={{ once: true }}>
                    <h2 className="margin-60px-bottom text-white">
                      {languageState.texts.Catalogue.Title}
                    </h2>
                  </motion.div>
                  <motion.div
                    variants={ulItem}
                    viewport={{ once: true }}
                    className={css({ width: "300px" })}
                  >
                    <SitoContainer
                      ignoreDefault
                      className="form-group"
                      sx={{ width: "100%", display: "flex", position: "relative" }}
                    >
                      <form onSubmit={onSearch} className={css({ width: "100%" })}>
                        <input
                          value={toSearch}
                          onChange={(e) => setToSearch(e.target.value)}
                          placeholder={languageState.texts.Catalogue.Input}
                        />
                        <IconButton
                          type="submit"
                          sx={{ position: "absolute", right: 0, top: "6px" }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </form>
                    </SitoContainer>
                  </motion.div>
                </motion.ul>
              </Box>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>

      <Section>
        {filtering === "" && (
          <SitoContainer ignoreDefault className="container">
            <SitoContainer
              ignoreDefault
              className="margin-50px-bottom xs-margin-40px-bottom text-center"
            >
              <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
                {languageState.texts.Catalogue.Catalogue}
              </h4>
            </SitoContainer>
            <SitoContainer ignoreDefault className="row">
              <SitoContainer ignoreDefault className="col-lg-4 col-md-12 sm-margin-25px-bottom">
                <SitoContainer ignoreDefault className="margin-25px-bottom">
                  <SitoContainer
                    ignoreDefault
                    className="featured-1 position-relative overflow-hidden border-radius-4"
                  >
                    <SitoContainer ignoreDefault className="featured-img">
                      <SitoImage
                        src={features01}
                        alt=""
                        sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                      />
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="featured-text text-center">
                      <h4 className="margin-5px-bottom">
                        <a
                          href="#"
                          className="font-size28 text-white font-weight-500"
                          onClick={() => filterBy(6)}
                        >
                          {languageState.texts.Home.Properties.Types.Apartments}
                        </a>
                      </h4>
                      <span className="font-size18 text-white"> </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>

                <SitoContainer ignoreDefault>
                  <SitoContainer
                    ignoreDefault
                    className="featured-1 position-relative overflow-hidden border-radius-4"
                  >
                    <SitoContainer ignoreDefault className="featured-img">
                      <SitoImage
                        src={features02}
                        alt=""
                        sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                      />
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="featured-text text-center">
                      <h4 className="margin-5px-bottom">
                        <a
                          href="#"
                          className="font-size28 text-white font-weight-500"
                          onClick={() => filterBy(7)}
                        >
                          {languageState.texts.Home.Properties.Types.Houses}
                        </a>
                      </h4>
                      <span className="font-size18 text-white"> </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
              </SitoContainer>

              <SitoContainer ignoreDefault className="col-lg-4 col-md-12 sm-margin-25px-bottom">
                <SitoContainer ignoreDefault className="margin-25px-bottom">
                  <SitoContainer
                    ignoreDefault
                    className="featured-1 position-relative overflow-hidden border-radius-4"
                  >
                    <SitoContainer ignoreDefault className="featured-img">
                      <SitoImage
                        src={features03}
                        sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                      />
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="featured-text text-center">
                      <h4 className="margin-5px-bottom">
                        <a href="#" className="font-size28 text-white font-weight-500">
                          {languageState.texts.Home.Properties.Types.Lots}
                        </a>
                      </h4>
                      <span className="font-size18 text-white"> </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
                <SitoContainer ignoreDefault>
                  <SitoContainer
                    ignoreDefault
                    className="featured-1 position-relative overflow-hidden border-radius-4"
                  >
                    <SitoContainer ignoreDefault className="featured-img">
                      <SitoImage
                        src={features06}
                        sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                      />
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="featured-text text-center">
                      <h4 className="margin-5px-bottom">
                        <a href="#" className="font-size28 text-white font-weight-500">
                          {languageState.texts.Home.Properties.Types.Vehicles}
                        </a>
                      </h4>
                      <span className="font-size18 text-white"> </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
              </SitoContainer>

              <SitoContainer ignoreDefault className="col-lg-4 col-md-12">
                <SitoContainer ignoreDefault className="margin-25px-bottom">
                  <SitoContainer
                    ignoreDefault
                    className="featured-1 position-relative overflow-hidden border-radius-4"
                  >
                    <SitoContainer ignoreDefault className="featured-img">
                      <SitoImage
                        src={features04}
                        sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                      />
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="featured-text text-center">
                      <h4 className="margin-5px-bottom">
                        <a
                          href="#"
                          className="font-size28 text-white font-weight-500"
                          onClick={() => filterBy(11)}
                        >
                          {languageState.texts.Home.Properties.Types.Locations}
                        </a>
                      </h4>
                      <span className="font-size18 text-white"> </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
                <SitoContainer ignoreDefault>
                  <SitoContainer
                    ignoreDefault
                    className="featured-1 position-relative overflow-hidden border-radius-4"
                  >
                    <SitoContainer ignoreDefault className="featured-img">
                      <SitoImage
                        src={features05}
                        sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                      />
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="featured-text text-center">
                      <h4 className="margin-5px-bottom">
                        <a
                          href="#"
                          className="font-size28 text-white font-weight-500"
                          onClick={() => filterBy(10)}
                        >
                          {languageState.texts.Home.Properties.Types.Offices}
                        </a>
                      </h4>
                      <span className="font-size18 text-white"> </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        )}
        <Loader visible={loading} minimal sx={{ position: "relative !important" }} />
      </Section>
    </>
  );
};

export default Catalogue;
