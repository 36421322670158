/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */

import PropTypes from "prop-types";

// own components
import SitoContainer from "sito-container";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { useTheme, Box, Modal } from "@mui/material";

// contexts
import { useLanguage } from "context/LanguageProvider";

const ShareDialog = (props) => {
  const theme = useTheme();
  const { open, handleClose } = props;

  const { languageState } = useLanguage();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 4,
          top: "50%",
          width: 400,
          left: "50%",
          boxShadow: 24,
          position: "absolute",
          bgcolor: "background.paper",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h5>{languageState.texts.ShareDialog.Title}</h5>
        <SitoContainer
          flexDirection="column"
          sx={{ marginTop: "20px", width: "100%", gap: "10px" }}
        >
          <button
            type="button"
            onClick={handleClose}
            className={`butn ${css({
              background: "none",
              color: "#000",
              border: `2px solid ${theme.palette.primary.main}`,
            })}`}
          >
            {languageState.texts.Buttons.Cancel}
          </button>
          <button type="button" onClick={() => handleClose(1)} className="butn">
            {languageState.texts.Buttons.ViaEmail}
          </button>
          <button type="button" onClick={() => handleClose(2)} className="butn">
            {languageState.texts.Buttons.ViaWhatsapp}
          </button>
        </SitoContainer>
      </Box>
    </Modal>
  );
};

ShareDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.bool.isRequired,
};

export default ShareDialog;
