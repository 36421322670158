/* eslint-disable react/function-component-definition */

// @mui/icons-material
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

// @mui/material
import { Typography } from "@mui/material";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

const NotSelected = () => {
  const { languageState } = useLanguage();
  return (
    <SitoContainer
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", minHeight: "400px" }}
    >
      <Typography variant="subtitle1">{languageState.texts.Chat.NoChats}</Typography>
      <SentimentDissatisfiedIcon color="secondary" sx={{ fontSize: "3rem" }} />
    </SitoContainer>
  );
};

export default NotSelected;
