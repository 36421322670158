/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// @emotion/css
import { css } from "@emotion/css";

// @mui icons
import { ChevronRight, CameraAlt } from "@mui/icons-material";

// @mui components
import { Box, Button } from "@mui/material";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// own components
import Loader from "components/Loader/Loader";
import Sidebar from "views/Profile/Sections/Sidebar/Sidebar";
import ProfilePhoto from "components/MUI/ProfilePhoto/ProfilePhoto";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { userLogged, scrollTo, apiTokenCertified } from "utils/functions";

// get
import { getMe } from "services/users/get";
import { saveSocialMedia, userChangePhoto } from "services/users/post";

// images
import userPhoto from "img/content/myprofile-1.jpg";

const Me = () => {
  const navigate = useNavigate();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const { register, handleSubmit, setValue, getValues } = useForm();

  const [userRPhoto, setUserRPhoto] = useState(userPhoto);

  const [role, setRole] = useState(0);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);
  // eslint-disable-next-line no-unused-vars

  const [type, setType] = useState("");

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await getMe();
      if (response.status === 200) {
        const meData = await response.data;
        setType(meData.rol);
        const { abono_id } = meData.inmobiliaria;
        const { inmobiliaria } = meData;
        const { facebook, instagram, twiter, web } = inmobiliaria;
        setValue("facebook", facebook);
        setValue("instagram", instagram);
        setValue("twitter", twiter);
        setValue("web", web);
        setRole(abono_id);
        setUserData(meData);
        if (meData.urlPhotoProfile !== null)
          setUserRPhoto(meData.urlPhotoProfile);
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/login");
    } catch (err) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
    setLoading(false);
  };

  const retry = () => fetch();

  const localSaveProfile = async (d) => {
    setLoading(true);
    const { password, rpassword } = d;
    if (password === rpassword) {
      try {
        const { web, facebook, twitter, instagram } = d;
        const response = await saveSocialMedia({
          web,
          facebook,
          twiter: twitter,
          instagram,
        });
        console.log(response);
        if (response.status === 200) {
          showNotification(
            "success",
            languageState.texts.Messages.UserModifiedSuccessful
          );
          // retry();
        }
      } catch (err) {
        showNotification("error", languageState.texts.Errors.SomeWrong);
      }
    } else
      showNotification("error", languageState.texts.Errors.DifferentPassword);

    setLoading(false);
  };

  const validate = () => setOk(true);

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return showNotification(
            "error",
            languageState.texts.Errors.InvalidURL
          );
      }
    }
  };

  useEffect(() => {
    if (!userLogged()) navigate("/");
    setRouteState({
      type: "set",
      to: 2,
      page: languageState.texts.Links[6].label,
    });
    scrollTo();
    retry();
  }, []);

  const updateUserPhoto = async (photo) => {
    try {
      const response = await userChangePhoto(photo);
      if (response.status === 200)
        showNotification(
          "success",
          languageState.texts.Messages.PhotoSavedSuccessful
        );
      else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
      else showNotification("error", languageState.texts.Errors.SomeWrong);
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
  };

  const [showProfilePhoto, setShowProfilePhoto] = useState();

  const saveImage = (src, file) => {
    setUserRPhoto(src);
    setShowProfilePhoto(false);
    updateUserPhoto(file);
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <ProfilePhoto
          open={showProfilePhoto}
          handleClose={() => setShowProfilePhoto(false)}
          onSubmit={saveImage}
        />
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[5].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul
                className={css({ "li::after": { display: "none !important" } })}
              >
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRight />
                <li>
                  <Link to="/profile">
                    {languageState.texts.Links[5].label}
                  </Link>
                </li>
                <ChevronRight />
                <li>
                  <Link to="/social-media">
                    {languageState.texts.Profile.Sidebar.Links[3].label}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>

      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer
              ignoreDefault
              className="col-lg-4 sm-margin-30px-bottom"
            >
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-tb padding-30px-lr border-radius-3"
              >
                <SitoContainer
                  ignoreDefault
                  className="text-center margin-30px-bottom"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { md: "229px", xs: "300px" },
                      height: { md: "229px", xs: "300px" },
                    }}
                  >
                    <SitoImage
                      id="profile-photo"
                      src={userRPhoto}
                      alt=""
                      sx={{
                        background: "#222",
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "50%",
                      }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        position: "absolute",
                        bottom: "12px",
                        right: "15px",
                        borderRadius: "100%",
                        minWidth: 0,
                        width: "40px",
                        height: "40px",
                        minHeight: 0,
                      }}
                      onClick={() => setShowProfilePhoto(true)}
                    >
                      <CameraAlt />
                    </Button>
                  </Box>

                  <h4 className="font-size19 no-margin-bottom">
                    {userData.nombre}
                  </h4>
                  <span className="font-size16">{userData.apellidos}</span>
                  <span className="font-size16">{`${type[0]?.toUpperCase()}${type
                    .substring(1)
                    .toLowerCase()}`}</span>
                </SitoContainer>

                <Sidebar
                  currentLink={3}
                  state={userData.inmobiliaria}
                  rol={role}
                />
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-8">
              <SitoContainer
                ignoreDefault
                className="padding-20px-left sm-no-padding-left"
              >
                <SitoContainer
                  ignoreDefault
                  className="theme-shadow padding-40px-all sm-padding-30px-all xs-padding-20px-all border-radius-3"
                >
                  <h4 className="font-size24">
                    {languageState.texts.Profile.Sidebar.Links[3].label}
                  </h4>
                  <form
                    className="margin-20px-bottom"
                    onSubmit={handleSubmit(localSaveProfile)}
                  >
                    {languageState.texts.Profile.socialMedia.map((item) => (
                      <SitoContainer
                        ignoreDefault
                        key={item.id}
                        className="col-md-12"
                      >
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor={item.id}>{`${item.label}`}</label>
                          <input
                            placeholder={item.placeholder}
                            type={item.type}
                            name={item.id}
                            id={item.id}
                            maxLength={item.maxLength}
                            required={item.required}
                            disabled={item.disabled}
                            onInput={validate}
                            onInvalid={invalidate}
                            {...register(item.id)}
                          />
                        </SitoContainer>
                      </SitoContainer>
                    ))}

                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.Buttons.Save}
                      </button>
                    </SitoContainer>
                  </form>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Me;
