/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

// @emotion/css
import { css } from "@emotion/css";

// @mui icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import {
  userLogged,
  scrollTo,
  isAdmin,
  passwordValidation,
  createRecoveringCookie,
  removeRecoveringCookie,
} from "utils/functions";

// services
import { passwordRecovery, modifyUser } from "services/users/post";

const Recovery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, getValues } = useForm();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [loading, setLoading] = useState(false);
  const [recovering, setRecovering] = useState(false);
  const [userData, setUserData] = useState({});

  const [ok, setOk] = useState(1);

  const localRecovery = async (d) => {
    setLoading(true);
    const { email } = d;
    try {
      const response = await passwordRecovery(email);
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        if (data.data === null)
          setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.SomeWrong,
          });
        else {
          createRecoveringCookie(email);
          setNotificationState({
            type: "set",
            ntype: "success",
            message: languageState.texts.Messages.EmailForRecovery,
          });
        }
      } else {
        setLoading(false);
        const { error } = response;
        console.error(error);
        let message;
        if (error.indexOf("422") > -1)
          message = languageState.texts.Errors.Wrong;
        else if (error.indexOf("Error: Network Error") > -1)
          message = languageState.texts.Errors.NotConnected;
        else message = languageState.texts.Errors.SomeWrong;
        setNotificationState({
          type: "set",
          ntype: "error",
          message,
        });
      }
    } catch (err) {
      console.error(err);
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.SomeWrong,
      });
    }
    setLoading(false);
  };

  const localModifyUser = async (d) => {
    setLoading(true);
    const { password, rpassword } = d;
    if (password === rpassword) {
      try {
        const response = await modifyUser(userData.id, {
          password_hash: password,
          password_hash_repeat: rpassword,
        });
        if (response.status === 200) {
          const data = await response.data;
          if (data.data === null)
            setNotificationState({
              type: "set",
              ntype: "error",
              message: languageState.texts.Errors.SomeWrong,
            });
          else {
            setNotificationState({
              type: "set",
              ntype: "success",
              message: languageState.texts.Messages.PasswordResetSuccessful,
            });
            navigate("/login");
          }
        } else {
          setLoading(false);
          const { error } = response;
          let message;
          if (error.indexOf("422") > -1)
            message = languageState.texts.Errors.Wrong;
          else if (error.indexOf("Error: Network Error") > -1)
            message = languageState.texts.Errors.NotConnected;
          else message = languageState.texts.Errors.SomeWrong;
          showNotification("error", message);
        }
      } catch (err) {
        showNotification("error", languageState.texts.Errors.SomeWrong);
      }
    } else
      showNotification("error", languageState.texts.Errors.DifferentPassword);

    setLoading(false);
  };

  useEffect(() => {
    const queryParams = location.search.substring(1);
    const [param, value] = queryParams.split("=");
    if (param === "user" && value) {
      setRecovering(true);
      setUserData({ id: value });
      removeRecoveringCookie();
    }
    if (userLogged()) {
      if (isAdmin()) navigate("/dashboard");
      else navigate("/me");
    } else {
      const loginForm = document.getElementById("login-form");
      setRouteState({
        type: "set",
        to: 3,
        page: languageState.texts.Links[2].label,
      });
      scrollTo((loginForm.offsetHeight - loginForm.offsetTop) / 2);
    }
  }, []);

  const validate = () => {
    setOk(true);
  };

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      const { password, rpassword } = getValues();
      e.target.focus();
      let message;
      setOk(false);
      switch (id) {
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            userData.nombre
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return showNotification("error", message);
        }
        default:
          return showNotification(
            "error",
            languageState.texts.Errors.EmailRequired
          );
      }
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[4].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul
                className={css({ "li::after": { display: "none !important" } })}
              >
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>
                  <Link to="/login#">{languageState.texts.Links[4].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>{languageState.texts.SignIn.titleRecovery}</li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-7 center-col">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-all xs-padding-25px-all border-radius-3"
              >
                {!recovering && (
                  <h3 className="text-center font-size30">
                    {languageState.texts.SignIn.titleRecovery}
                  </h3>
                )}
                <form
                  id="login-form"
                  method="post"
                  action="#!"
                  onSubmit={handleSubmit(
                    !recovering ? localRecovery : localModifyUser
                  )}
                >
                  {recovering && (
                    <h4 className="text-center dashboard-h3">
                      {languageState.texts.SignIn.recovering}
                    </h4>
                  )}

                  <SitoContainer
                    ignoreDefault
                    className="row"
                    sx={{ marginTop: "20px" }}
                  >
                    <SitoContainer ignoreDefault className="col-md-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="email">
                          {languageState.texts.SignIn.itsInputs.email.label}
                        </label>
                        <input
                          placeholder={
                            languageState.texts.SignIn.itsInputs.email
                              .placeholder
                          }
                          type="email"
                          name="email"
                          id="email"
                          required
                          onInput={validate}
                          onInvalid={invalidate}
                          {...register("email")}
                        />
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>

                  <SitoContainer
                    ignoreDefault
                    className="row align-items-center margin-30px-top"
                  >
                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.Buttons.Send}
                      </button>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Recovery;
