/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/function-component-definition */
import { useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";

// sito components
import SitoContainer from "sito-container";

// services
import { subscriptionList } from "services/subscriptions/get";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// own components
import Loader from "components/Loader/Loader";
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import TabView from "components/MUI/TabView/TabView";
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";

// utils
import { apiTokenCertified } from "utils/functions";
import { parseColumns, parseRows } from "../../utils";

const List = () => {
  const navigate = useNavigate();

  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({ type: "set", ntype, message });

  const local11ListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = [];
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };
  const [local11List, setLocal11List] = useReducer(local11ListReducer, []);

  const local12ListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = localListState;
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };
  const [local12List, setLocal12List] = useReducer(local12ListReducer, []);

  const local10ListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = localListState;
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };
  const [local10List, setLocal10List] = useReducer(local10ListReducer, []);

  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetch11 = async (from, abonoId) => {
    setLoading(true);
    const response = await subscriptionList(from, 10, `&filtros[abono_id]=${abonoId}`);
    if (response.status === 200) {
      const { data, total } = await response.data;
      if (data.length) {
        if (!columns.length) setColumns(parseColumns(data, languageState.texts));
        const parsedRows = parseRows(data, languageState.texts);
        if (!from) setLocal11List({ type: "add", toAdd: parsedRows, max: total });
        else setLocal11List({ type: "set", toAdd: parsedRows, from });
      }
    } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    else {
      setLocal11List(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const fetch12 = async (from, abonoId) => {
    setLoading(true);
    const response = await subscriptionList(from, 10, `&filtros[abono_id]=${abonoId}`);
    if (response.status === 200) {
      const { data, total } = await response.data;
      if (data.length) {
        if (!columns.length) setColumns(parseColumns(data, languageState.texts));
        const parsedRows = parseRows(data, languageState.texts);
        if (!from) setLocal12List({ type: "add", toAdd: parsedRows, max: total });
        else setLocal12List({ type: "set", toAdd: parsedRows, from });
      }
    } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    else {
      setLocal12List(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const fetch10 = async (from, abonoId) => {
    setLoading(true);
    const response = await subscriptionList(from, 10, `&filtros[abono_id]=${abonoId}`);
    if (response.status === 200) {
      const { data, total } = await response.data;
      if (data.length) {
        if (!columns.length) setColumns(parseColumns(data, languageState.texts));
        const parsedRows = parseRows(data, languageState.texts);
        if (!from) setLocal10List({ type: "add", toAdd: parsedRows, max: total });
        else setLocal10List({ type: "set", toAdd: parsedRows, from });
      }
    } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    else {
      setLocal10List(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const retry = (seeing, abonoId) => (seeing === 11 ? fetch11(0, abonoId) : fetch12(0, abonoId));

  const [page10, setPage10] = useState(0);
  const [page11, setPage11] = useState(0);
  const [page12, setPage12] = useState(0);

  const onPagination10 = (newPage) => {
    if (local10List[10 * newPage]) fetch10(10 * newPage, 11);
    setPage10(newPage);
  };

  const onPagination11 = (newPage) => {
    if (local11List[10 * newPage]) fetch11(10 * newPage, 11);
    setPage11(newPage);
  };

  const onPagination12 = (newPage) => {
    if (local12List[10 * newPage]) fetch12(10 * newPage, 11);
    setPage12(newPage);
  };

  useEffect(() => {
    if (!local10List.length) retry(10, 10);
    if (!local11List.length) retry(11, 11);
    if (!local12List.length) retry(12, 12);
  }, []);

  const [tab, setTab] = useState(0);
  const handleTab = (e, newValue) => setTab(newValue);

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      <TabView
        tabs={languageState.texts.Subscription.Subscriptions}
        value={tab}
        onChange={handleTab}
        content={[
          <>
            {!loading && local10List.length > 0 && (
              <ComplexTable
                onPageChange={onPagination10}
                page={page10}
                columns={columns}
                rows={local10List}
                noChecks
                hasImage={false}
              />
            )}
            {!loading && !local10List.length && local10List !== -1 && <Empty />}
            {!loading && local10List === -1 && <Error onAction={retry} />}
          </>,
          <>
            {!loading && local11List.length > 0 && (
              <ComplexTable
                onPageChange={onPagination11}
                page={page11}
                columns={columns}
                rows={local11List}
                noChecks
                hasImage={false}
              />
            )}
            {!loading && !local11List.length && local11List !== -1 && <Empty />}
            {!loading && local11List === -1 && <Error onAction={retry} />}
          </>,
          <>
            {!loading && local12List.length > 0 && (
              <ComplexTable
                onPageChange={onPagination12}
                page={page12}
                columns={columns}
                rows={local12List}
                noChecks
                hasImage={false}
              />
            )}
            {!loading && !local12List.length && local12List !== -1 && <Empty />}
            {!loading && local12List === -1 && <Error onAction={retry} />}
          </>,
        ]}
      />
    </SitoContainer>
  );
};

export default List;
