/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui/material
import { Box } from "@mui/material";

// context
import { useRoute } from "context/RouterProvider";

// services
import { subscriptionMercadoPago } from "services/subscriptions/get";

// local components
import Error from "./Error";
import Success from "./Success";
import MercadoPago from "./MercadoPago";

const ClientSubscription = () => {
  const location = useLocation();
  const { setRouteState } = useRoute();

  const [seeing, setSeeing] = useState(-1);

  useEffect(() => {
    setRouteState({
      type: "set",
      to: 7,
    });
  }, []);

  const parseQuery = async (queryParams) => {
    if (queryParams) {
      const [statusName, statusValue] = queryParams.split("=");
      if (statusName === "status") {
        switch (statusValue) {
          case "success":
            setSeeing(0);
            break;
          case "error":
            setSeeing(1);
            break;
          default:
            setSeeing(2);
            break;
        }
      } else if (statusName === "preapproval_id" || statusName === "approval_id") {
        console.log(statusValue);
        setSeeing(2);
        try {
          const response = await subscriptionMercadoPago(statusValue);
          console.log(response);
          if (response) {
            const aTag = document.createElement("a");
            aTag.setAttribute("href", response);
            // aTag.click();
          }
        } catch (err) {
          console.log(err);
          setSeeing(1);
        }
      }
    }
  };

  useEffect(() => {
    const queryParams = location.search.substring(1);
    parseQuery(queryParams);
  }, [location]);

  return (
    <Box>
      {seeing === 1 && <Error />}
      {seeing === 0 && <Success />}
      {seeing === 2 && <MercadoPago />}
    </Box>
  );
};

export default ClientSubscription;
