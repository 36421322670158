/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { collegeList } from "services/colleges/get";
import { modifyCollege } from "services/colleges/post";

// functions
import { getIndexOf, apiTokenCertified } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

const Modify = (props) => {
  const navigate = useNavigate();
  const { navigateToInsert, toEdit } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { reset, register, handleSubmit, setValue } = useForm();

  const [localList, setLocalList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const [collegeToModify, setCollegeToModify] = useState(0);

  const changeCollegeToModify = (e) => setCollegeToModify(Number(e.target.value));

  useEffect(() => {
    if (toEdit !== -1) setCollegeToModify(toEdit);
  }, [toEdit]);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await collegeList();
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          setAllData(data);
          let element = getIndexOf(
            "id",
            collegeToModify === 0 ? collegeToModify + 1 : collegeToModify,
            data
          );
          if (element === -1) element = 0;
          // reading default values
          // name - nombre
          if (data[element].nombre) setValue("name", data[element].nombre);
          setCollegeToModify(data[element].id);
          setLocalList(data);
        }
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      setLocalList(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const retry = () => fetch();

  const localModifyCollege = async (d) => {
    setLoading(true);
    try {
      const { name } = d;
      const data = {
        nombre: name,
      };
      const response = await modifyCollege(collegeToModify, data);
      if (response.status === 200) {
        showNotification("success", languageState.texts.Messages.CollegeModifiedSuccessful);
        reset({ name: "" });
        retry();
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      if (err.message.indexOf("422") > -1) {
        const error = err.response.data.data;
        let message = languageState.texts.Errors.SomeWrong;
        const newInvalids = invalids;
        error.forEach((item) => {
          const enName = languageState.texts.Dashboard.TableAttributesEN[item.field];
          newInvalids[enName] = true;
          message = languageState.texts.Errors.NameTaken;
        });
        setInvalids(newInvalids);
        showNotification("error", message);
      }
    }
    setLoading(false);
  };

  const validate = () => setOk(true);

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return showNotification("error", languageState.texts.Errors.NameRequired);
      }
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (allData.length) {
      const element = getIndexOf("id", collegeToModify, allData);
      // name - nombre
      if (allData[element].nombre) setValue("name", allData[element].nombre);
    }
  }, [collegeToModify]);

  return (
    <SitoContainer
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {!loading && localList.length > 0 && (
        <form onSubmit={handleSubmit(localModifyCollege)} className={css({ width: "100%" })}>
          <SitoContainer ignoreDefault className="form-group" sx={{ width: "100%" }}>
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.College.Modify.Title}
            </h3>
            <label htmlFor="users">{languageState.texts.Dashboard.College.Modify.Label}</label>
            <select
              className="form-control"
              id="users"
              value={collegeToModify}
              onChange={changeCollegeToModify}
            >
              {localList.map((jtem) => (
                <option key={jtem.id} value={jtem.id}>
                  {jtem.nombre}
                </option>
              ))}
            </select>
            <SitoContainer ignoreDefault className="row" sx={{ marginTop: "20px" }}>
              {languageState.texts.SignIn.inputs
                .filter((item) => {
                  if (item.models && item.models.indexOf("college") > -1) return item;
                  return null;
                })
                .map((item) => (
                  <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                    <SitoContainer ignoreDefault className="form-group">
                      <label htmlFor={item.id}>{`${item.label} ${item.required ? "*" : ""}`}</label>
                      <input
                        placeholder={item.collegePlaceholder}
                        type={item.type}
                        name={item.id}
                        id={item.id}
                        maxLength={item.maxLength}
                        required={item.required}
                        onInput={validate}
                        onInvalid={invalidate}
                        {...register(item.id)}
                        className={invalids[item.id] ? "error" : ""}
                      />
                    </SitoContainer>
                  </SitoContainer>
                ))}
            </SitoContainer>
            <SitoContainer ignoreDefault sx={{ marginTop: "20px" }}>
              <button type="submit" className="butn">
                {languageState.texts.Buttons.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
      {!loading && !localList.length && localList !== -1 && <Empty onAction={navigateToInsert} />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

Modify.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
  toEdit: PropTypes.number.isRequired,
};

export default Modify;
