import * as ReactDOMClient from "react-dom/client";

// context
import { SearchProvider } from "context/SearchContext";
import { RouteProvider } from "context/RouterProvider";
import { LanguageProvider } from "context/LanguageProvider";
import { NotificationProvider } from "context/NotificationProvider";

// own components
import Notification from "components/Notification/Notification";

// app
import App from "./App";

// styles
import "./index.css";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(
  <NotificationProvider>
    <SearchProvider>
      <RouteProvider>
        <LanguageProvider>
          <Notification />
          <App />
        </LanguageProvider>
      </RouteProvider>
    </SearchProvider>
  </NotificationProvider>
);
