/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

// @emotion/css
import { css } from "@emotion/css";

// @mui/icons-material
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// @mui/material
import { Box, IconButton } from "@mui/material";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// services
import { fertilizerList } from "services/fertilizers/get";
import { signUp } from "services/users/post";

// config
import config from "config";

// functions
import {
  passwordValidation,
  userLogged,
  scrollTo,
  createValidatingCookie,
  isAdmin,
} from "utils/functions";

// image
import img from "img/reals/header/page-title2.webp";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      name: "",
      lastname: "",
      email: "",
      user: "",
      password: "",
      rpassword: "",
    },
  });
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [showPassword, setShowPassword] = useState(false);
  const [showRPassword, setShowRPassword] = useState(false);

  // const [role, setRole] = useState(1);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  // eslint-disable-next-line no-unused-vars
  const [fertilizers, setFertilizers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [fertilizerSelected, setFertilizerSelected] = useState(0);

  /* const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "fertilizer":
        return setFertilizerSelected(Number(value));
      default:
        return setRole(Number(value));
    }
  }; */

  const [ok, setOk] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [invalids, setInvalids] = useState({});

  const [term, setTerm] = useState(false);

  const [loading, setLoading] = useState(true);

  const toggleTerm = () => setTerm(!term);

  const localSignUp = async (d) => {
    // const roles = ["INMOBILIARIA", "PROPIETARIO"];
    setLoading(true);
    const { user, password, rpassword } = d;
    if (password === rpassword) {
      try {
        const { name, lastname, email } = d;
        const data = {
          nombre: name,
          email,
          apellidos: lastname,
          // rol: roles[role],
          password_hash: password,
          password_hash_repeat: rpassword,
          user,
        };
        // if (role === 1) data.abono_id = 10;
        const response = await signUp(data);
        if (response.status === 200) {
          sessionStorage.setItem(config.userCookie, user);
          createValidatingCookie(email);
          setNotificationState({
            type: "set",
            message: languageState.texts.Messages.UserCreatedSuccessful,
            ntype: "success",
          });
          setTimeout(() => {
            navigate("/email-validation");
          }, 100);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
        if (err.message.indexOf("422") > -1) {
          const error = err.response.data.data;
          const newInvalids = invalids;
          let message = languageState.texts.Errors.SomeWrong;
          let active = false;
          if (error.length === 1) message = languageState.texts.Errors.ServerErrors[error[0].field];
          for (const item of error) {
            if (languageState.texts.Errors.ServerErrors[item.field] && !active) {
              active = true;
              message = languageState.texts.Errors.ServerErrors[item.field];
            }
          }

          setInvalids(newInvalids);

          showNotification("error", message);
        }
      }
    } else showNotification("error", languageState.texts.Errors.DifferentPassword);
    setLoading(false);
  };

  const fetch = async () => {
    setLoading(true);
    try {
      const remoteFertilizers = await fertilizerList();
      if (remoteFertilizers.status === 200) {
        const { data } = await remoteFertilizers.data;
        if (data.length) {
          setFertilizerSelected(data[0].id);
          setFertilizers(data);
        }
      }
    } catch (err) {
      setFertilizers(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const retry = () => fetch();

  useEffect(() => {
    const queryParams = location.search.substring(1);
    const [param, value] = queryParams.split("=");
    if (param === "abono" && value) setFertilizerSelected(Number(value) + 10);
    if (userLogged()) {
      if (isAdmin()) navigate("/dashboard");
      else navigate("/profile");
    }
    scrollTo(0);
    setRouteState({ type: "set", to: 3, page: languageState.texts.Links[3].label });
    retry();
  }, []);

  const onChangeEmail = (event) => {
    const [noAt] = event.target.value.split("@");
    setValue("user", noAt);
  };

  const validate = () => setOk(true);

  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    const { user, email, password, rpassword } = getValues();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      if (e.target.type === "url")
        return setNotificationState({
          type: "set",
          ntype: "error",
          message: languageState.texts.Errors.InvalidURL,
        });
      switch (id) {
        case "tuition":
        case "email":
          message = languageState.texts.Errors.InvalidEmail;
          if (email.length === 0) message = languageState.texts.Errors.EmailRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "user":
          message = languageState.texts.Errors.NameRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            user
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.TermsNdConditionRequired,
          });
      }
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero background={img}>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[3].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul className={css({ "li::after": { display: "none !important" } })}>
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>
                  <Link to="/register" disabled>
                    {languageState.texts.Links[3].label}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-7 center-col">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-all xs-padding-25px-all border-radius-3"
              >
                <h3 className="text-center font-size30">{languageState.texts.Links[3].label}</h3>
                <form
                  id="register-form"
                  method="post"
                  action="#!"
                  onSubmit={handleSubmit(localSignUp)}
                >
                  {/* <SitoContainer ignoreDefault className="row">
                    <SitoContainer ignoreDefault className="col-md-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="fertilizers">
                          {languageState.texts.SignIn.roles.label}
                        </label>
                        <select
                          className={`form-control ${css({ height: "50px !important" })}`}
                          id="roles"
                          value={role}
                          onChange={handleChange}
                        >
                          {languageState.texts.SignIn.roles.roles.map((jtem, i) => (
                            <option key={i} value={i}>
                              {jtem}
                            </option>
                          ))}
                        </select>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer> */}
                  {/* <SitoContainer ignoreDefault className="row">
                    <SitoContainer ignoreDefault className="col-md-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="fertilizers">
                          {languageState.texts.Dashboard.Fertilizer.Select}
                        </label>
                        <select
                          className={`form-control ${css({ height: "50px !important" })}`}
                          id="fertilizers"
                          value={fertilizerSelected}
                          onChange={handleChange}
                          disabled={!fertilizers.length}
                        >
                          {fertilizers.map((jtem) => (
                            <option key={jtem.id} value={jtem.id}>
                              {jtem.nombre}
                            </option>
                          ))}
                        </select>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer> */}
                  <SitoContainer ignoreDefault className="row">
                    {languageState.texts.SignIn.registerInputs.map((item) => (
                      <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor={item.id}>
                            {`${item.label} ${item.required ? "*" : ""}`}
                          </label>
                          <Controller
                            control={control}
                            name={item.id}
                            render={({ field }) => (
                              <input
                                placeholder={item.placeholder}
                                type={item.type}
                                name={item.id}
                                id={item.id}
                                maxLength={item.maxLength}
                                required={item.required}
                                disabled={item.id === "user"}
                                onInput={validate}
                                onInvalid={invalidate}
                                className={invalids[item.id] ? "error" : ""}
                                value={field.value}
                                onChange={(event) => {
                                  if (item.id === "email") onChangeEmail(event);
                                  field.onChange(event);
                                }}
                              />
                            )}
                          />
                        </SitoContainer>
                      </SitoContainer>
                    ))}
                    <SitoContainer ignoreDefault className="col-md-12">
                      <SitoContainer
                        ignoreDefault
                        className="form-group"
                        sx={{ position: "relative" }}
                      >
                        <Box>
                          <label htmlFor="password">
                            {languageState.texts.SignIn.itsInputs.password.label}
                          </label>
                          <Controller
                            control={control}
                            name="password"
                            render={({ field }) => (
                              <input
                                placeholder={
                                  languageState.texts.SignIn.itsInputs.password.placeholder
                                }
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="password"
                                required
                                onInput={validate}
                                onInvalid={invalidate}
                                {...field}
                              />
                            )}
                          />
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translate(0px, -8%)",
                          }}
                        >
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </Box>
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-md-12">
                      <SitoContainer
                        ignoreDefault
                        className="form-group"
                        sx={{ position: "relative" }}
                      >
                        <Box>
                          <label htmlFor="password">
                            {languageState.texts.SignIn.itsInputs.rpassword.label}
                          </label>
                          <Controller
                            control={control}
                            name="rpassword"
                            render={({ field }) => (
                              <input
                                placeholder={
                                  languageState.texts.SignIn.itsInputs.rpassword.placeholder
                                }
                                type={showRPassword ? "text" : "password"}
                                name="rpassword"
                                id="rpassword"
                                required
                                onInput={validate}
                                onInvalid={invalidate}
                                {...field}
                              />
                            )}
                          />
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translate(0px, -8%)",
                          }}
                        >
                          <IconButton onClick={() => setShowRPassword(!showRPassword)}>
                            {showRPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </Box>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>

                  <SitoContainer ignoreDefault className="col-12">
                    <SitoContainer ignoreDefault className="form-group">
                      <label htmlFor="terms" className="no-margin-bottom">
                        <input
                          id="terms"
                          onChange={toggleTerm}
                          type="checkbox"
                          className="min-height-auto"
                          required
                          onInput={validate}
                          onInvalid={invalidate}
                        />
                        {languageState.texts.SignIn.terms.label}
                        <Link to="/terms-conditions" style={{ textDecoration: "underline" }}>
                          {languageState.texts.SignIn.terms.link}
                        </Link>
                      </label>
                    </SitoContainer>
                  </SitoContainer>
                  <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.Buttons.SignUp}
                      </button>
                    </SitoContainer>
                    <SitoContainer
                      ignoreDefault
                      className="col-md-6 text-right xs-text-left xs-margin-20px-top"
                    >
                      <p>
                        {languageState.texts.SignIn.signIn.message}{" "}
                        <Link to="/login#" style={{ textDecoration: "underline" }}>
                          {languageState.texts.SignIn.signIn.link}
                        </Link>
                      </p>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Login;
