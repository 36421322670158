/* eslint-disable import/prefer-default-export */
// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Send a message target
 * @param {string} to - target.
 * @param {string} subject - title
 * @param {string} message - message
 * @returns The data from the response.
 */
export const sendEmail = async (to, subject, message) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmobiliaria/send-mail`,
    { to, subject, message },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 *
 * @param {string} email
 * @param {*} name
 * @param {*} message
 * @returns
 */
export const sendContact = async (email, name, message) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmobiliaria/send-contact`,
    { email, name, message },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Send a message target
 * @param {string} to - target.
 * @param {string} subject - title
 * @param {string} message - message
 * @param {string} body - body
 * @param {string} css - css
 * @returns The data from the response.
 */
export const sendEmailPDF = async (
  to,
  subject,
  message,
  body = "",
  css = ""
) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmobiliaria/generate-pdf-send-mail`,
    { to, subject, message, body, css },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Send a message target
 * @param {string[]} to - target.
 * @returns The data from the response.
 */
export const getLinkers = async (to) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmobiliaria/get-acortador`,
    { urls: to },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};
