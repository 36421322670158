/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// services
import { characteristicList } from "services/characteristics/get";
import { characteristicGroupList } from "services/characteristics/groups/get";
import { createCharacteristic, modifyCharacteristic } from "services/characteristics/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { apiTokenCertified } from "utils/functions";

const Insert = (props) => {
  const { toEdit } = props;
  const [characteristicToModify, setCharacteristicToModify] = useState(0);

  const navigate = useNavigate();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { reset, register, handleSubmit } = useForm();

  const [characteristicGroups, setCharacteristicGroups] = useState([]);
  const [selectedCharacteristicGroup, setSelectedCharacteristicGroup] = useState(0);

  const changeCharacteristicGroup = (e) => {
    const { value } = e.target;
    setSelectedCharacteristicGroup(Number(value));
  };

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [loading, setLoading] = useState(false);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const fetch = async (toModify) => {
    setLoading(true);
    try {
      let response = await characteristicGroupList(0, -1);
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          setSelectedCharacteristicGroup(data[0].id);
          setCharacteristicGroups(data);
        }
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
      response = await characteristicList(0, 1, `filtros[id]=${toModify}`);
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          const [theCharacteristic] = data;
          reset({
            name: theCharacteristic.nombre,
          });
        }
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      setCharacteristicGroups(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const localCreateCharacteristic = async (d) => {
    setLoading(true);
    try {
      const { name } = d;
      let response;
      if (!characteristicToModify)
        response = await createCharacteristic({
          nombre: name,
          grupo_caracteristica_id: selectedCharacteristicGroup,
        });
      else
        response = await modifyCharacteristic(characteristicToModify, {
          nombre: name,
          grupo_caracteristica_id: selectedCharacteristicGroup,
        });
      if (response.status === 200) {
        showNotification("success", languageState.texts.Messages.CharacteristicCreatedSuccessful);
        reset({ name: "" });
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      if (err.message.indexOf("422") > -1) {
        const error = err.response.data.data;
        let message = languageState.texts.Errors.SomeWrong;
        const newInvalids = invalids;
        error.forEach((item) => {
          const enName = languageState.texts.Dashboard.TableAttributesEN[item.field];
          newInvalids[enName] = true;
          message = languageState.texts.Errors.NameTaken;
        });
        setInvalids(newInvalids);
        showNotification("error", message);
      } else console.log(err);
    }
    setLoading(false);
  };

  const validate = () => setOk(true);

  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return showNotification("error", languageState.texts.Errors.NameRequired);
      }
    }
  };

  const retry = (toModify = 0) => fetch(toModify);

  useEffect(() => {
    if (toEdit) {
      setCharacteristicToModify(toEdit);
      retry(toEdit);
    }
  }, [toEdit]);

  return (
    <SitoContainer sx={{ position: "relative", minHeight: "300px" }}>
      <Loader visible={loading} minimal />
      {!loading && (
        <form
          id="register-form"
          method="post"
          action="#!"
          className={css({ width: "100%", padding: "20px" })}
          onSubmit={handleSubmit(localCreateCharacteristic)}
        >
          <SitoContainer alignItems="center" justifyContent="space-between">
            <h3 className="dashboard-h3 dashboard-mb-3">
              {!toEdit
                ? languageState.texts.Dashboard.Characteristic.Insert.Title
                : languageState.texts.Dashboard.Characteristic.Modify.Title}
            </h3>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <SitoContainer ignoreDefault className="form-group">
                <label htmlFor="users">
                  {languageState.texts.Dashboard.CharacteristicGroup.Select}
                </label>
                <select
                  className="form-control"
                  id="users"
                  value={selectedCharacteristicGroup}
                  onChange={changeCharacteristicGroup}
                  disabled={!characteristicGroups.length}
                >
                  {characteristicGroups.map((jtem) => (
                    <option key={jtem.id} value={jtem.id}>
                      {jtem.nombre}
                    </option>
                  ))}
                </select>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            {languageState.texts.SignIn.inputs
              .filter((item) => {
                if (item.models && item.models.indexOf("characteristic") > -1) return item;
                return null;
              })
              .map((item) => (
                <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                  <SitoContainer ignoreDefault className="form-group">
                    <label htmlFor={item.id}>{`${item.label} ${item.required ? "*" : ""}`}</label>
                    <input
                      placeholder={item.characteristicPlaceholder}
                      type={item.type}
                      name={item.id}
                      id={item.id}
                      maxLength={item.maxLength}
                      required={item.required}
                      onInput={validate}
                      onInvalid={invalidate}
                      {...register(item.id)}
                      className={invalids[item.id] ? "error" : ""}
                    />
                  </SitoContainer>
                </SitoContainer>
              ))}
          </SitoContainer>
          <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
            <SitoContainer ignoreDefault className="col-md-6">
              <button type="submit" className="butn">
                {languageState.texts.Buttons.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
    </SitoContainer>
  );
};

Insert.defaultProps = {
  toEdit: undefined,
};

Insert.propTypes = {
  toEdit: PropTypes.number,
};

export default Insert;
