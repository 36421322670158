/* eslint-disable import/prefer-default-export */
// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Send a message target
 * @param {string} message - The message content.
 * @param {number} target - target user
 * @returns The data from the response.
 */
export const sendMessage = async (message, target) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/chat`,
    { mensaje: message, inmobiliaria_recibe: target },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
