/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui icons
import TuneIcon from "@mui/icons-material/Tune";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EnergySavingLeaf from "@mui/icons-material/EnergySavingsLeaf";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PlaceIcon from "@mui/icons-material/Place";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AppsIcon from "@mui/icons-material/Apps";
import ApiIcon from "@mui/icons-material/Api";
import StoreIcon from "@mui/icons-material/Store";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
// import HouseIcon from "@mui/icons-material/House";
// import DomainIcon from "@mui/icons-material/Domain";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

// contexts
import { useRoute } from "context/RouterProvider";

const SideLink = (props) => {
  const { routeState } = useRoute();

  const icons = [
    <TuneIcon className="dashboard-align-middle" />,
    <EqualizerIcon className="dashboard-align-middle" />,
    <PeopleAltIcon className="dashboard-align-middle" />,
    <EnergySavingLeaf className="dashboard-align-middle" />,
    <SchoolIcon className="dashboard-align-middle" />,
    <AccountBalanceIcon className="dashboard-align-middle" />,
    <PlaceIcon className="dashboard-align-middle" />,
    <ApartmentIcon className="dashboard-align-middle" />,
    <LocationCityIcon className="dashboard-align-middle" />,
    <AppsIcon className="dashboard-align-middle" />,
    <ApiIcon className="dashboard-align-middle" />,
    <StoreIcon className="dashboard-align-middle" />,
    <AddBusinessIcon className="dashboard-align-middle" />,
    {
      /* <HouseIcon className="dashboard-align-middle" /> */
    },
    {
      /* <DomainIcon className="dashboard-align-middle" /> */
    },
    <CardMembershipIcon className="dashboard-align-middle" />,
    <AccountCircleIcon className="dashboard-align-middle" />,
    <SettingsIcon className="dashboard-align-middle" />,
    <LogoutIcon className="dashboard-align-middle" />,
  ];

  const { content } = props;
  return (
    <li
      className={`dashboard-sidebar-item ${
        content.index === routeState.route ? "dashboard-sidebar-item-active" : ""
      }`}
    >
      <Link className="dashboard-sidebar-link" to={content.link}>
        {icons[content.index]}
        <span className="dashboard-align-middle">{content.label}</span>
      </Link>
    </li>
  );
};

SideLink.propTypes = {
  content: PropTypes.object.isRequired,
};

export default SideLink;
