/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

// sito components
import SitoContainer from "sito-container";

// prop types
import PropTypes from "prop-types";

// react-router-dom
import { Outlet, useNavigate } from "react-router-dom";

// own components
import Loader from "components/Loader/Loader";
import Navbar from "components/Dashboard/Navbar/Navbar";
import Footer from "components/Dashboard/Footer/Footer";
import Sidebar from "components/Dashboard/Sidebar/Sidebar";

// styles
import { userLogged } from "utils/functions";

// style
import "css/dashboard/app.css";
import "css/dashboard/app.css.map";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const { noFooter } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userLogged()) navigate("/");
    setLoading(false);
  }, []);

  const [show, setShow] = useState(true);

  const changeShow = () => setShow(!show);

  return (
    <SitoContainer
      ignoreDefault
      sx={{ alignItems: "stretch", background: "#222e3c", display: "flex", width: "100%" }}
    >
      <Sidebar visible={show} />
      <SitoContainer
        ignoreDefault
        sx={{
          background: "#f5f7fb",
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          minWidth: 0,
          overflow: "hidden",
          transition:
            "margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out",
          width: "100%",
        }}
      >
        <Navbar changeShow={changeShow} />
        <Outlet />
        {!noFooter && <Footer />}
        <ScrollToTop
          smooth
          className="scroll-to-top"
          component={<i className="fas fa-angle-up" aria-hidden="true"></i>}
        />

        <Loader visible={loading} />
      </SitoContainer>
    </SitoContainer>
  );
};

Dashboard.defaultProps = {
  noFooter: false,
};

Dashboard.propTypes = {
  noFooter: PropTypes.bool,
};

export default Dashboard;
