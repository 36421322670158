// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { registerAuth, getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * @param {number} fertilizer
 * @returns The response from the server.
 */
export const userChangeFertilizer = async (fertilizer) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/change-abono`,
    { abono_id: fertilizer },
    {
      // @ts-ignore
      headers: {
        ...registerAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  return response;
};

/**
 * @param {number} api
 * @returns The response from the server.
 */
export const userChangeTokkoAPI = async (api) => {
  console.log(api);
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/edit-tokko-api`,
    { tokko_api_key: api },
    {
      // @ts-ignore
      headers: {
        ...registerAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  return response;
};

/**
 * @param {File} photo
 * @returns The response from the server.
 */
export const userChangePhoto = async (photo, blob) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/save-photo-blob`,
    { photo, blob: blob.substring(blob.indexOf("base64,") + 7) },
    {
      // @ts-ignore
      headers: {
        ...registerAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  return response;
};

/**
 * Sends a POST request to the API with the email address of the user who wants to reset their
 * password
 * @param {string} email - The email address of the user who wants to reset their password.
 * @returns The response from the server.
 */
export const passwordRecovery = async (email) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/password-reset`,
    { email },
    {
      headers: registerAuth,
    }
  );
  return response;
};

/**
 * Takes a user object and sends it to the backend to be authenticated
 * @param {object} userValues - The user values that are passed in from the form.
 * @returns The response from the server.
 */
export const signIn = async (userValues) => {
  const { email, password } = userValues;
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/login`,
    { email, password /* : md5(password ) */ },
    {
      headers: registerAuth,
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Takes a user object as an argument, and returns a promise that resolves to the data returned from
 * the API
 * @param {object} user - This is the user object that we are sending to the server.
 * @returns The data from the response.
 */
export const signUp = async (user) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/register`,
    { ...user },
    {
      headers: registerAuth,
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Takes a user object, sends it to the API, and returns the response
 * @param {object} user - The user object that contains the user's information.
 * @returns The data from the response.
 */
export const createUser = async (user) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user`,
    { ...user },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the user's ID and the ID of the user to block
 * @param {string} userId - The user id of the user you want to block.
 * @param {string} reason - lock reason
 * @returns The data is being returned.
 */
export const blockUser = async (userId, reason) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/${userId}/bloquear`,
    { razon_bloqueo: reason },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the user's ID and the ID of the user to delete
 * @param {string} userId - The user id of the user you want to delete.
 * @returns The data is being returned.
 */
export const deleteUser = async (userId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/user/${userId}`,
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the user's ID and user data to modify it
 * @param {string} userId - The user id of the user you want to delete.
 * @param {object} userData - User data: name, lastname, email, password, rpassword
 * @returns The data is being returned.
 */
export const modifyUser = async (userId, userData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/user/${userId}`,
    { ...userData },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the user data to save it
 * @param {object} userData - User data: name, lastname, email, password, rpassword
 * @returns The data is being returned.
 */
export const saveProfile = async (userData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/user/edit-profile`,
    { ...userData },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the user data to save it
 * @param {object} userData - User data: name, lastname, email, password, rpassword
 * @returns The data is being returned.
 */
export const saveSocialMedia = async (userData) => {
  console.log(userData);
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/inmobiliaria/me`,
    { ...userData },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};
