/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getAuth } from "auth/auth";
import config from "config";

// cookies
import { getCookie } from "utils/functions";

/**
 *
 * @param {number} from
 * @param {number} limit
 * @param {string} filter
 * @returns
 */
export const userList = async (from = 0, limit = 10, filter = undefined) => {
  console.log(`${config.apiUrl}/user?offset=${from}&limit=${limit}${filter ? `&${filter}` : ""}`);
  const response = await axios.get(
    // @ts-ignore
    `${config.apiUrl}/user?offset=${from}&limit=${limit}${filter ? `&${filter}` : ""}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 *
 * @param {number} propertyId
 * @param {number} from
 * @param {limit} from
 * @returns
 */
export const searchList = async (propertyId, from = 0, limit = 10) => {
  let response;
  try {
    response = await axios.get(
      `${config.apiUrl}/busca/inmueble/${propertyId}?offset=${from}&limit=${limit}`,
      {
        headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};
