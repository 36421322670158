/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useCallback } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

// @mui icons
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

// @emotion/css
import { css } from "@emotion/css";
// @mui components
import { Box, Tooltip, Badge, IconButton } from "@mui/material";

// sito components
import SitoContainer from "sito-container";
// eslint-disable-next-line no-unused-vars
import SitoImage from "sito-image";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// utils
import {
  userLogged,
  isAdmin,
  scrollTo,
  apiTokenCertified,
} from "utils/functions";

// services
import { propertyList } from "services/properties/get";
import { getMe } from "services/users/get";

// image
import logo from "img/reals/logos/logo.png";
import logoWhite from "img/reals/logos/logo-white.png";
import CRABBlanco from "img/reals/logos/CrabBlanco1.png";
import CrabBlanco from "img/reals/logos/CrabBlanco2.png";
import CRABColorBien from "img/reals/logos/CrabColorBien1.png";
import CrabColorBien from "img/reals/logos/CrabColorBien.png";

// styles
import "./styles.css";

const Navbar = () => {
  const navigate = useNavigate();
  const { languageState } = useLanguage();
  const { routeState, setRouteState } = useRoute();
  const [currentLink, setCurrentLink] = useState(0);

  const location = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [matches, setMatches] = useState(0);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const onScroll = useCallback(() => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    if (!routeState.noStick) {
      if (top > 100) setTrigger(true);
      else setTrigger(false);
    } else setTrigger(true);
  }, [setTrigger]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  useEffect(() => {
    console.log(routeState);
    if (routeState.route === 0) scrollTo(0);
    if (routeState.route !== undefined) setCurrentLink(routeState.route);
  }, [routeState]);

  const linkClicked = (e, index) => {
    if (languageState.texts.Links[index].index === 1)
      if (routeState.route !== 0) {
        navigate("/#about-us");
        e.preventDefault();
      }
    setRouteState({ type: "set", to: index });
    if (index === 1) {
      e.preventDefault();
      const services = document.getElementById("services");
      if (services) scrollTo(services.offsetTop - 150);
    }
  };

  useEffect(() => {
    if (currentLink === 1) {
      const services = document.getElementById("services");
      if (services) scrollTo(services.offsetTop - 150);
    }
  }, [currentLink]);

  const [user, setUser] = useState("");

  const fetch = async (from = 0, filters = "") => {
    const response = await propertyList(
      from,
      0,
      `opciones[multimedias]=1&filtros[conMatches]=true${filters}`
    );
    const userData = await getMe();
    let userId = -1;
    if (userData && userData.data) {
      const { inmobiliaria } = userData.data;
      setUser(userData.data.user);
      if (inmobiliaria) userId = inmobiliaria.user_id;
    }
    if (response.status === 200 && userId > 1) {
      const { total } = await response.data;
      setMatches(total);
    } else if (response.error && !apiTokenCertified(response.error))
      navigate("/log-out");
  };

  useEffect(() => {
    if (userLogged()) fetch();
  }, []);

  return (
    <header
      className={`${currentLink === 0 ? "position-absolute" : ""} width-100 ${
        !trigger && !routeState.noStick
          ? currentLink === 0
            ? "transparent-header"
            : "fixedHeader"
          : "scrollHeader"
      } ${css({ zIndex: 21 })}`}
    >
      <SitoContainer ignoreDefault className="navbar-default">
        {/* <!-- start top search --> */}
        <SitoContainer
          ignoreDefault
          className="top-search bg-black-opacity-light"
        >
          <SitoContainer ignoreDefault className="container">
            <form
              className="search-form"
              action="search.html"
              method="GET"
              acceptCharset="utf-8"
            >
              <SitoContainer ignoreDefault className="input-group">
                <span className="input-group-addon cursor-pointer">
                  <button
                    className="search-form_submit fas fa-search font-size18 text-white"
                    type="submit"
                  />
                </span>
                <input
                  type="text"
                  className="search-form_input form-control"
                  name="s"
                  autoComplete="off"
                  placeholder="Type & hit enter..."
                />
                <span className="input-group-addon close-search">
                  <i className="fas fa-times font-size18 margin-5px-top" />
                </span>
              </SitoContainer>
            </form>
          </SitoContainer>
        </SitoContainer>
        {/* <!-- end top search --> */}

        <SitoContainer ignoreDefault className="container">
          <SitoContainer
            ignoreDefault
            className="row align-items-center"
            sx={{ width: "100%" }}
          >
            <SitoContainer ignoreDefault className="col-12 col-lg-12">
              <SitoContainer
                ignoreDefault
                className="menu_area"
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <nav
                  className={`navbar navbar-expand-lg navbar-light no-padding ${css(
                    {
                      width: "100%",
                    }
                  )}`}
                >
                  <SitoContainer
                    ignoreDefault
                    className="navbar-header navbar-header-custom"
                  >
                    {/* <!-- start logo --> */}
                    <Link
                      to="/"
                      onClick={() => setRouteState({ type: "set", to: 0 })}
                      className="navbar-brand inner-logo"
                    >
                      {currentLink === 0 ? (
                        !trigger && !routeState.noStick ? (
                          <Box sx={{ width: "185px", height: "43px" }}>
                            <img
                              className="character"
                              src={CrabBlanco}
                              alt=""
                            />
                            <img className="logo-img" src={CRABBlanco} alt="" />
                          </Box>
                        ) : (
                          <Box sx={{ width: "185px", height: "43px" }}>
                            <img
                              className="character"
                              src={CrabColorBien}
                              alt=""
                            />
                            <img
                              className="logo-img"
                              src={CRABColorBien}
                              alt=""
                            />
                          </Box>
                        )
                      ) : (
                        <Box sx={{ width: "185px", height: "43px" }}>
                          <img
                            className="character"
                            src={CrabColorBien}
                            alt=""
                          />
                          <img
                            className="logo-img"
                            src={CRABColorBien}
                            alt=""
                          />
                        </Box>
                      )}
                    </Link>
                    {/* <!-- end logo --> */}
                  </SitoContainer>

                  <Box className="navbar-toggler" onClick={toggleDropdown} />

                  {/* <!-- start menu area --> */}

                  <ul
                    className={`navbar-nav ml-auto ${
                      showDropdown ? css({ display: "flex !important" }) : ""
                    }`}
                    id="nav"
                  >
                    {languageState.texts.Links.filter((item) => {
                      if (item.logged === 0) return item;
                      if (item.logged > 0 && userLogged()) {
                        if (!item.admin) return item;
                        if (isAdmin(user) && item.admin === 1) return item;
                        if (!isAdmin(user) && item.admin === 2) return item;
                      }
                      if (item.logged < 0 && !userLogged()) return item;
                      return null;
                    }).map((item, i) => (
                      <li
                        key={item.label}
                        className={item.index === currentLink ? "active" : ""}
                      >
                        <Link to={item.to} onClick={(e) => linkClicked(e, i)}>
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  {/* <!-- end menu area --> */}
                </nav>
                {userLogged() ? (
                  <Tooltip title={languageState.texts.Tooltips.Matches}>
                    <Badge badgeContent={matches} color="primary">
                      <Link to="/properties">
                        <IconButton color="inherit">
                          {matches === 0 ? (
                            <FavoriteBorderIcon />
                          ) : (
                            <FavoriteIcon />
                          )}
                        </IconButton>
                      </Link>
                    </Badge>
                  </Tooltip>
                ) : null}
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </header>
  );
};

export default Navbar;
