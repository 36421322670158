/* eslint-disable react/function-component-definition */
import { Link } from "react-router-dom";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// layouts
import Section from "layouts/Section/Section";

// image
import content01 from "img/reals/about/01.webp";

// contexts
import { useLanguage } from "context/LanguageProvider";

const About = () => {
  const { languageState } = useLanguage();
  return (
    <Section ignoreDefault className="no-padding-bottom">
      <SitoContainer ignoreDefault className="container">
        <SitoContainer ignoreDefault className="row">
          <SitoContainer ignoreDefault className="col-md-6">
            <SitoContainer ignoreDefault className="about-us">
              <h4 className="width-50 md-width-60 font-size38 sm-font-size34 xs-font-size30 line-height-45 font-weight-500 margin-30px-bottom sm-margin-20px-bottom xs-margin-15px-bottom sm-width-95 xs-width-100">
                {languageState.texts.Home.About.Title}
              </h4>
              <p className="margin-40px-bottom sm-margin-30px-bottom xs-margin-20px-bottom width-70 md-width-80 sm-width-95 xs-width-100">
                {languageState.texts.Home.About.Description}
              </p>
              <Link to="contact-us" className="butn">
                {languageState.texts.Home.About.ReadMore}
              </Link>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer ignoreDefault className="col-md-6 xs-display-none">
            <SitoContainer ignoreDefault className="text-right xs-text-center">
              <SitoImage src={content01} sx={{ width: "100%", height: "100%" }} alt="" />
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

export default About;
