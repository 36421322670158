/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getAuth } from "auth/auth";
import config from "config";

// cookies
import { getCookie } from "utils/functions";

/**
 *
 * @param {number} from
 * @param {number} limit
 * @param {string} filter
 * @returns
 */
export const stateList = async (from = 0, limit = 10, filter = 0) => {
  let response;
  try {
    response = await axios.get(
      `${config.apiUrl}/estado?offset=${from}&limit=${limit}${`&${filter}` || ""}`,
      {
        headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};
