const RoleEnum = {
  INMOBILIARIA: 0,
  PROPIETARIO: 1,
};

export const RoleTextEnum = {
  INMOBILIARIA: "INMOBILIARIA",
  PROPIETARIO: "PROPIETARIO",
};

export const FertilizerEnum = {
  Basic: 10,
  Second: 11,
  Third: 12,
};

export default RoleEnum;
