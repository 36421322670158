/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/function-component-definition */
import { useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

// own components
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { characteristicGroupList } from "services/characteristics/groups/get";
import { deleteCharacteristicGroup } from "services/characteristics/groups/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { apiTokenCertified } from "utils/functions";
import { parseColumns, parseRows } from "../../utils";

const List = (props) => {
  const navigate = useNavigate();
  const { navigateToInsert, onEdit } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const localListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = [];
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };

  const [localList, setLocalList] = useReducer(localListReducer, []);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const fetch = async (from = 0) => {
    setLoading(true);
    const response = await characteristicGroupList();
    if (response.status === 200) {
      const { data, total } = await response.data;
      if (data.length) {
        if (!columns.length) setColumns(parseColumns(languageState.texts));
        const parsedRows = parseRows(data);
        if (!from) setLocalList({ type: "add", toAdd: parsedRows, max: total });
        else setLocalList({ type: "set", toAdd: parsedRows, from });
      }
    } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    else {
      setLocalList(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const [page, setPage] = useState(0);

  const onPagination = (newPage) => {
    if (localList[10 * newPage]) fetch(10 * newPage);
    setPage(newPage);
  };

  const retry = () => fetch();

  const editToDo = (selected) => onEdit(selected[0].value);

  const removeToDo = async (selected) => {
    setLoading(true);
    try {
      let ok = true;
      for (const item of selected) {
        const response = await deleteCharacteristicGroup(item);
        if (response.status !== 200) {
          showNotification("error", languageState.texts.Errors.SomeWrong);
          ok = false;
          break;
        } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
      }
      if (ok) {
        showNotification(
          "success",
          languageState.texts.Messages.CharacteristicGroupDeletedSuccessful
        );
        setLocalList([]);
        retry();
      } else setLoading(false);
    } catch (error) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {!loading && localList.length > 0 && (
        <ComplexTable
          onPageChange={onPagination}
          page={page}
          columns={columns}
          rows={localList}
          onDelete={removeToDo}
          onEdit={editToDo}
          hasImage={false}
        />
      )}
      {!loading && !localList.length && localList !== -1 && <Empty onAction={navigateToInsert} />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

List.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default List;
