/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getAuth } from "auth/auth";
import config from "config";

// cookies
import { getCookie } from "utils/functions";

/**
 *
 * @param {number} from
 * @param {number} limit
 * @returns
 */
export const conversationList = async (from = 0, limit = 10) => {
  let response;
  try {
    response = await axios.get(
      `${config.apiUrl}/chat/conversation-user-list?offset=${from}&limit=${limit}`,
      {
        headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};

/**
 *
 * @param {number} target
 * @param {number} from
 * @param {number} limit
 * @returns
 */
export const messageFromChat = async (target, from = 0, limit = 10) => {
  let response;
  try {
    response = await axios.get(
      `${config.apiUrl}/chat/get-messages/${target}?offset=${from}&limit=${limit}`,
      {
        headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};
