export const numberArg = (event = undefined, func = undefined) => {
  let value = event;
  if (typeof event === "object") {
    const { target } = event;
    value = target.value;
  } else if (typeof event === "number") value = String(event).replace(".", ",");
  if (value.length) {
    const exp = /^[1-9]\d{0,2}(\.\d{3})*(,\d*)?$/g;
    const parsed = value.replaceAll(".", "");
    let newString = "";
    let array = [];
    let j = 0;
    for (let i = parsed.length - 1; i >= 0; i -= 1) {
      j += 1;
      if (parsed[i] === ",") j = 0;
      array.push(parsed[i]);
      if (j === 3) {
        array.push(".");
        j = 0;
      }
    }
    array.reverse().forEach((item) => (newString += item));
    if (newString[0] === ".") newString = newString.substring(1);
    if (exp.test(newString)) {
      if (func) func(event, newString);
      else return newString;
    }
  } else {
    if (func) func(event, "");
    else return "";
  }
};

/**
 *
 * @param {string} value
 * @returns
 */
export const strToNumber = (value) =>
  value.replaceAll(".", "").replace(",", ".");
