/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// @mui components
import { Button } from "@mui/material";

// own components
import Loader from "components/Loader/Loader";

// services
import { propertyList } from "services/properties/get";
import { createMultimedia } from "services/multimedias/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { apiTokenCertified } from "utils/functions";

const Insert = () => {
  const navigate = useNavigate();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { reset, register, handleSubmit, setValue, getValues /* , watch */ } =
    useForm();

  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(0);

  const changeProperty = (e) => {
    const { value } = e.target;
    setSelectedProperty(Number(value));
  };

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [loading, setLoading] = useState(false);

  const [invalids, setInvalids] = useState({});

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await propertyList();
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          setSelectedProperty(data[0].id);
          setProperties(data);
        }
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    } catch (err) {
      setProperties(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const retry = () => fetch();

  const [imageIds, setImageIds] = useState({});
  const [loadingPhotos, setLoadingPhotos] = useState(false);

  useEffect(() => {
    if (loadingPhotos)
      setTimeout(() => {
        setLoadingPhotos(false);
      }, 500);
  }, [loadingPhotos]);

  const [flatIds, setFlatIds] = useState({});
  const [loadingFlats, setLoadingFlats] = useState(false);

  useEffect(() => {
    if (loadingFlats)
      setTimeout(() => {
        setLoadingFlats(false);
      }, 500);
  }, [loadingFlats]);

  const localCreateMultimedia = async (d) => {
    setLoading(true);
    try {
      const { video, video360, photos, flats } = d;
      const response = await createMultimedia({
        inmueble_id: selectedProperty,
        video: video[0],
        video360: video360[0],
        imagenes: photos,
        planos: flats,
      });

      if (response.status === 200) {
        showNotification(
          "success",
          languageState.texts.Messages.MultimediaCreatedSuccessful
        );
        setImageIds([]);
        setFlatIds([]);
        reset({ video: "", video360: "", photos: "", flats: "" });
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    } catch (err) {
      if (err.message.indexOf("422") > -1) {
        const error = err.response.data.data;
        let message = languageState.texts.Errors.SomeWrong;
        const newInvalids = invalids;
        error.forEach((item) => {
          const enName =
            languageState.texts.Dashboard.TableAttributesEN[item.field];
          newInvalids[enName] = true;
          message = languageState.texts.Errors.NameTaken;
        });
        setInvalids(newInvalids);
        showNotification("error", message);
      }
    }
    setLoading(false);
  };

  const removePhoto = (e) => {
    setLoadingPhotos(true);
    let { id } = e.target;
    if (!id) id = e.target.parentNode.id;
    const photoId = id.split("[!]")[2];
    let index = -1;
    const newImage = imageIds;
    Object.values(newImage).filter((item, i) => {
      if (item.value === photoId) index = i;
      return null;
    });
    delete newImage[photoId];
    const newArrayOfFiles = getValues("photos");
    newArrayOfFiles.splice(index, 1);
    setValue("photos", newArrayOfFiles);
    setImageIds(newImage);
  };

  const removeFlat = (e) => {
    setLoadingFlats(true);
    let { id } = e.target;
    if (!id) id = e.target.parentNode.id;
    const flatId = id.split("[!]")[2];
    let index = -1;
    const newImage = imageIds;
    Object.values(newImage).filter((item, i) => {
      if (item.value === `flats[!]${flatId}`) index = i;
      return null;
    });
    delete newImage[`flats[!]${flatId}`];
    const newArrayOfFiles = getValues("flats");
    newArrayOfFiles.splice(index, 1);
    setValue("flats", newArrayOfFiles);
    setFlatIds(newImage);
  };

  const onUploadFile = useCallback(
    (event) => {
      const { files, id /* , value */ } = event.target;
      if (id === "flats") setLoadingFlats(true);
      else setLoadingPhotos(true);
      const filesAsArray = [];
      for (let i = 0; i < files.length; i += 1)
        filesAsArray.push(files.item(i));
      const file = files[0];
      if (getValues(id) && getValues(id).length > 0)
        setValue(id, [...getValues(id), ...filesAsArray]);
      else setValue(id, filesAsArray);
      if (!file) return;
      // file name
      //! setVideo(e.target.value);
      // entire file
      //! setVideoFile(file);
      /* const count = document.getElementById(`count-${id}`);
      if (filesAsArray.length > 1 || getValues(id).length > 1)
        count.innerText = `${filesAsArray.length} ${languageState.texts.FileCount}`;
      else count.innerText = `${filesAsArray.length} ${languageState.texts.OnlyOneFile}`; */
      const container = document.getElementById(`input-${id}`);
      // container.innerText = "";
      for (const item of filesAsArray) {
        const newReader = new FileReader();
        newReader.onload = async (e) => {
          const content = e.target.result;
          const div = document.createElement("div");
          if (content.indexOf("data:image") > -1) {
            if (id === "photos")
              imageIds[`${id}[!]${item.name}`] = {
                value: `${id}[!]${item.name}`,
                content,
              };
            else
              flatIds[`${id}[!]${item.name}`] = {
                value: `${id}[!]${item.name}`,
                content,
              };
            // setImageIds({ value: item.name, content });
          } else if (content.indexOf("data:video") > -1) {
            div.style.width = "100%";
            // eslint-disable-next-line no-use-before-define
            div.innerHTML += `<video width="100%" height="300" controls><source src="${content}"} type="${item.type}" /></video>`;
          }
          container.appendChild(div);
        };
        newReader.readAsDataURL(item);
      }
    },
    [setValue, imageIds, setImageIds, loadingPhotos]
  );

  useEffect(() => {
    retry();
  }, []);

  const labelForFile = css({
    marginBottom: "0 !important",
  });

  const thumbnail = {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "3px",
  };

  return (
    <SitoContainer sx={{ position: "relative", minHeight: "300px" }}>
      <Loader visible={loading} minimal />
      {!loading && (
        <form
          id="register-form"
          method="post"
          action="#!"
          className={css({ width: "100%" })}
          onSubmit={handleSubmit(localCreateMultimedia)}
        >
          <SitoContainer alignItems="center" justifyContent="space-between">
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.Multimedia.Insert.Title}
            </h3>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <SitoContainer ignoreDefault className="form-group">
                <label htmlFor="users">
                  {languageState.texts.Dashboard.Property.Select}
                </label>
                <select
                  className="form-control"
                  id="users"
                  value={selectedProperty}
                  onChange={changeProperty}
                  disabled={!properties.length}
                >
                  {properties.map((jtem) => (
                    <option key={jtem.id} value={jtem.id}>
                      {jtem.titulo}
                    </option>
                  ))}
                </select>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            {languageState.texts.Dashboard.Multimedia.Inputs.filter((item) => {
              if (item.hidden) return null;
              return item;
            }).map((item) => (
              <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                <SitoContainer
                  ignoreDefault
                  className="form-group"
                  sx={
                    item.type
                      ? {
                          display: "flex",
                          alignItems: "center",
                        }
                      : {}
                  }
                >
                  <label
                    className={
                      item.type === "file" || item.type === "files"
                        ? labelForFile
                        : ""
                    }
                    htmlFor={item.id}
                  >{`${item.label} ${item.required ? "*" : ""}`}</label>
                  <input
                    placeholder={item.placeholder}
                    type={item.type === "files" ? "file" : item.type}
                    multiple={item.type === "files"}
                    name={item.id}
                    accept={item.accept}
                    id={item.id}
                    required={item.required}
                    className={invalids[item.id] ? "error" : ""}
                    {...register(item.id)}
                    onChange={onUploadFile}
                  />
                  {(item.type === "file" || item.type === "files") && (
                    <>
                      <Button
                        variant="contained"
                        sx={{
                          margin: "0 20px",
                          minWidth: 0,
                          borderRadius: "100%",
                          padding: "6px",
                        }}
                        onClick={() => document.getElementById(item.id).click()}
                      >
                        <FileUploadIcon />
                      </Button>
                      <span id={`count-${item.id}`} />
                    </>
                  )}
                </SitoContainer>
                <SitoContainer
                  id={`input-${item.id}`}
                  ignoreDefault
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                />
              </SitoContainer>
            ))}
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <SitoContainer
                ignoreDefault
                className="form-group"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <label className={labelForFile} htmlFor="photos">
                  {languageState.texts.Dashboard.Multimedia.Inputs[2].label}
                </label>
                {!loadingPhotos && (
                  <input
                    type="file"
                    multiple
                    name="photos"
                    accept=".png,.jpeg,.jpg"
                    id="photos"
                    onChange={onUploadFile}
                  />
                )}
              </SitoContainer>
              <SitoContainer
                id="input-photos"
                ignoreDefault
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                }}
              >
                {loadingPhotos ? (
                  <SitoContainer
                    sx={{
                      width: "150px",
                      height: "150px",
                      position: "relative",
                    }}
                  >
                    <Loader visible minimal />
                  </SitoContainer>
                ) : (
                  <>
                    {Object.values(imageIds).map((item) => (
                      <SitoContainer
                        sx={{
                          marginRight: "10px",
                          marginTop: "10px",
                          position: "relative",
                          width: "150px",
                          height: "150px",
                        }}
                      >
                        <SitoImage
                          sx={thumbnail}
                          key={item.value}
                          src={item.content}
                          alt={item.value}
                        />
                        <Button
                          variant="contained"
                          sx={{
                            position: "absolute",
                            right: "-5px",
                            top: "-5px",
                            borderRadius: "100%",
                            minWidth: 0,
                            padding: "5px",
                          }}
                          color="error"
                          id={`button[!]${item.value}`}
                          onClick={removePhoto}
                        >
                          <CloseIcon
                            id={`icon[!]${item.value}`}
                            sx={{ fontSize: "20px" }}
                          />
                        </Button>
                      </SitoContainer>
                    ))}
                    <Button
                      variant="outlined"
                      sx={{
                        padding: "30px",
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "3px",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                      onClick={() => document.getElementById("photos").click()}
                    >
                      <AddAPhotoIcon />
                    </Button>
                  </>
                )}
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <SitoContainer
                ignoreDefault
                className="form-group"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <label className={labelForFile} htmlFor="photos">
                  {languageState.texts.Dashboard.Multimedia.Inputs[3].label}
                </label>
                {!loadingFlats && (
                  <input
                    type="file"
                    multiple
                    name="flats"
                    accept="image/*"
                    id="flats"
                    onChange={onUploadFile}
                  />
                )}
              </SitoContainer>
              <SitoContainer
                id="input-flats"
                ignoreDefault
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                }}
              >
                {loadingFlats ? (
                  <SitoContainer
                    sx={{
                      width: "150px",
                      height: "150px",
                      position: "relative",
                    }}
                  >
                    <Loader visible minimal />
                  </SitoContainer>
                ) : (
                  <>
                    {Object.values(flatIds).map((item) => (
                      <SitoContainer
                        sx={{
                          marginRight: "10px",
                          marginTop: "10px",
                          position: "relative",
                          width: "150px",
                          height: "150px",
                        }}
                      >
                        <SitoImage
                          sx={thumbnail}
                          key={item.value}
                          src={item.content}
                          alt={item.value}
                        />
                        <Button
                          variant="contained"
                          sx={{
                            position: "absolute",
                            right: "-5px",
                            top: "-5px",
                            borderRadius: "100%",
                            minWidth: 0,
                            padding: "5px",
                          }}
                          color="error"
                          id={`button[!]${item.value}`}
                          onClick={removeFlat}
                        >
                          <CloseIcon
                            id={`icon[!]${item.value}`}
                            sx={{ fontSize: "20px" }}
                          />
                        </Button>
                      </SitoContainer>
                    ))}
                    <Button
                      variant="outlined"
                      sx={{
                        padding: "30px",
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "3px",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                      onClick={() => document.getElementById("flats").click()}
                    >
                      <AddAPhotoIcon />
                    </Button>
                  </>
                )}
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer
            ignoreDefault
            className="row align-items-center margin-30px-top"
          >
            <SitoContainer ignoreDefault className="col-md-6">
              <button type="submit" className="butn">
                {languageState.texts.Buttons.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
    </SitoContainer>
  );
};

export default Insert;
