/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @emotion/css
import { css } from "@emotion/css";

// @mui icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";

// utils
import { isValidatingEmail } from "utils/functions";

const EmailValidation = () => {
  const navigate = useNavigate();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isValidatingEmail()) navigate("/login");
    else setLoading(false);
    setRouteState({ type: "set", to: 2, page: languageState.texts.Links[2].label });
  }, []);

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[3].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul className={css({ "li::after": { display: "none !important" } })}>
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>
                  <Link to="#/login">{languageState.texts.Links[3].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>{languageState.texts.SignIn.titleEmail}</li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-7 center-col">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-all xs-padding-25px-all border-radius-3"
              >
                <h3 className="text-center font-size30">
                  {languageState.texts.SignIn.emailValidation}
                </h3>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default EmailValidation;
