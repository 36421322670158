// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Takes a fertilizer object, sends it to the API, and returns the response
 * @param {object} fertilizer - The fertilizer object that contains the fertilizer's information.
 * @returns The data from the response.
 */
export const createFertilizer = async (fertilizer) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/abono`,
    { ...fertilizer },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the fertilizer's ID and the ID of the fertilizer to delete
 * @param {string} fertilizerId - The fertilizer id of the fertilizer you want to delete.
 * @returns The data is being returned.
 */
export const deleteFertilizer = async (fertilizerId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/abono/${fertilizerId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the fertilizer's ID and fertilizer data to modify it
 * @param {string} fertilizerId - The fertilizer id of the fertilizer you want to delete.
 * @param {object} fertilizerData - Fertilizer data: name
 * @returns The data is being returned.
 */
export const modifyFertilizer = async (fertilizerId, fertilizerData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/abono/${fertilizerId}`,
    { ...fertilizerData },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
