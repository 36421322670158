/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// @emotion/css
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// services
import { createUser, modifyUser } from "services/users/post";
import { userList } from "services/users/get";
import { createAgent } from "services/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// functions
import { passwordValidation, isAdmin, apiTokenCertified } from "utils/functions";
import { UserTypesEnum } from "utils/Enum";

// enum
import RoleEnum from "views/Profile/RoleEnum";

const Insert = (props) => {
  const { toEdit } = props;
  const [userToModify, setUserToModify] = useState(0);

  const navigate = useNavigate();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { reset, register, handleSubmit, getValues } = useForm();
  const [role, setRole] = useState(1);
  const [userType, setUserType] = useState(0);

  const changeUserType = (e) => setUserType(Number(e.target.value));

  const [loading, setLoading] = useState(false);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const localCreateUser = async (d) => {
    const roles = ["INMOBILIARIA", "PROPIETARIO"];
    setLoading(true);
    const { user, password, rpassword } = d;
    if (password === rpassword) {
      try {
        const {
          name,
          lastname,
          email,
          fertilizer,
          college,
          phone,
          dni,
          matricula,
          cuit,
          businessName,
          web,
          facebook,
          twitter,
          instagram,
        } = d;
        let response;
        if (!userToModify) {
          if (userType === UserTypesEnum.Normal)
            response = await createUser({
              nombre: name,
              apellidos: lastname,
              email,
              abono_id: Number(fertilizer),
              colegio_id: Number(college),
              telefono: phone,
              rol: roles[role],
              password_hash: password,
              password_hash_repeat: rpassword,
              user,
              dni: role === RoleEnum.PROPIETARIO ? dni : "",
              matricula: role === RoleEnum.PROPIETARIO ? "" : matricula,
              cuit: role === RoleEnum.PROPIETARIO ? "" : cuit,
              razon_social: role === RoleEnum.PROPIETARIO ? "" : businessName,
              web: role === RoleEnum.PROPIETARIO ? "" : web,
              facebook: role === RoleEnum.PROPIETARIO ? "" : facebook,
              twitter: role === RoleEnum.PROPIETARIO ? "" : twitter,
              instagram: role === RoleEnum.PROPIETARIO ? "" : instagram,
            });
          else
            response = await createAgent({
              nombre: name,
              apellidos: lastname,
              email,
              telefono: phone,
              password_hash: password,
              password_hash_repeat: rpassword,
              user,
            });
        } else {
          // eslint-disable-next-line no-lonely-if
          if (userType === UserTypesEnum.Normal)
            response = await modifyUser({
              nombre: name,
              apellidos: lastname,
              email,
              password_hash: password,
              password_hash_repeat: rpassword,
            });
        }
        if (response.status === 200) {
          showNotification("success", languageState.texts.Messages.UserCreatedSuccessful);
          reset({
            name: "",
            lastname: "",
            email: "",
            phone: "",
            password: "",
            rpassword: "",
            user: "",
          });
        } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
      } catch (err) {
        if (err.message.indexOf("422") > -1) {
          const error = err.response.data.data;
          let message = languageState.texts.Errors.SomeWrong;
          const newInvalids = invalids;
          error.forEach((item) => {
            const enName = languageState.texts.Dashboard.TableAttributesEN[item.field];
            newInvalids[enName] = true;
            message = languageState.texts.Errors.NameTaken;
          });
          setInvalids(newInvalids);
          showNotification("error", message);
        }
      }
    } else showNotification("error", languageState.texts.Errors.DifferentPassword);
    setLoading(false);
  };

  const changeRole = (e) => {
    const { value } = e.target;
    if (Number(value) === 0) return setRole(1);
    return setRole(0);
  };

  const validate = () => setOk(true);

  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    const { user, email, password, rpassword } = getValues();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "tuition":
        case "email":
          message = languageState.texts.Errors.InvalidEmail;
          if (email.length === 0) message = languageState.texts.Errors.EmailRequired;
          return showNotification("error", message);
        case "user":
          message = languageState.texts.Errors.NameRequired;
          return showNotification("error", message);
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            user
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return showNotification("error", message);
        }
        default:
          return showNotification("error", languageState.texts.Errors.TermsNdConditionRequired);
      }
    }
  };

  const fetch = async (toModify) => {
    setLoading(true);
    try {
      const response = await userList(0, 1, `filtros[id]=${toModify}`);
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          const [theUser] = data;
          reset({
            name: theUser.nombre,
            lastname: theUser.apellidos,
            email: theUser.email,
          });
        }
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const retry = (toModify = 0) => fetch(toModify);

  useEffect(() => {
    if (toEdit) {
      setUserToModify(toEdit);
      retry(toEdit);
    }
  }, [toEdit]);

  return (
    <SitoContainer sx={{ position: "relative", minHeight: "300px" }}>
      <Loader visible={loading} minimal />
      {!loading && (
        <form
          id="register-form"
          method="post"
          action="#!"
          onSubmit={handleSubmit(localCreateUser)}
          className={css({ padding: "20px" })}
        >
          <SitoContainer alignItems="center" justifyContent="space-between">
            <h3 className="dashboard-h3 dashboard-mb-3">
              {!toEdit
                ? languageState.texts.Dashboard.User.Insert.Title
                : languageState.texts.Dashboard.User.Modify.Title}
            </h3>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            {/* <SitoContainer>
              <select
                className="form-control"
                id="userType"
                value={userType}
                onChange={changeUserType}
              >
                {languageState.texts.Dashboard.User.Type.Types.map((jtem, i) => (
                  <option key={jtem.label} value={i}>
                    {jtem.label}
                  </option>
                ))}
              </select>
                </SitoContainer> */}
            {languageState.texts.SignIn.inputs
              .filter((item) => {
                if ((item.admin || !item.admin) && isAdmin() && userType === UserTypesEnum.Normal)
                  return item;
                if (!isAdmin() && !item.admin && userType === UserTypesEnum.Normal) return item;
                if (userType === UserTypesEnum.Agent && item.agent) return item;
                return null;
              })
              .map((item) => (
                <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                  {!item.hidden ? (
                    <>
                      {item.type !== "select" &&
                        ((item.role && item.role === role + 1) || !item.role) && (
                          <SitoContainer ignoreDefault className="form-group">
                            <label htmlFor={item.id}>
                              {`${item.label} ${item.required ? "*" : ""}`}
                            </label>
                            <input
                              placeholder={item.placeholder}
                              type={item.type}
                              name={item.id}
                              id={item.id}
                              maxLength={item.maxLength}
                              required={item.required}
                              onInput={validate}
                              onInvalid={invalidate}
                              {...register(item.id)}
                              className={invalids[item.id] ? "error" : ""}
                            />
                          </SitoContainer>
                        )}
                      {item.type === "select" && isAdmin() && (
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor={item.id}>
                            {`${item.label} ${item.required ? "*" : ""}`}
                          </label>
                          <select
                            className="form-control"
                            id={item.id}
                            {...register(item.id)}
                            onChange={changeRole}
                          >
                            {item.options.map((jtem, i) => (
                              <option key={jtem.label} value={i}>
                                {jtem.label}
                              </option>
                            ))}
                          </select>
                        </SitoContainer>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </SitoContainer>
              ))}
            {languageState.texts.Dashboard.User.Inputs.map((item) => (
              <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                <SitoContainer ignoreDefault className="form-group">
                  <label htmlFor={item.id}>{`${item.label} ${item.required ? "*" : ""}`}</label>
                  <input
                    placeholder={item.placeholder}
                    type={item.type}
                    name={item.id}
                    id={item.id}
                    maxLength={item.maxLength}
                    required={item.required}
                    onInput={validate}
                    onInvalid={invalidate}
                    {...register(item.id)}
                    className={invalids[item.id] ? "error" : ""}
                  />
                </SitoContainer>
              </SitoContainer>
            ))}
          </SitoContainer>

          <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
            <SitoContainer ignoreDefault className="col-md-6">
              <button type="submit" className="butn">
                {languageState.texts.Buttons.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
    </SitoContainer>
  );
};

Insert.defaultProps = {
  toEdit: undefined,
};

Insert.propTypes = {
  toEdit: PropTypes.number,
};

export default Insert;
