/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getAuth } from "auth/auth";
import config from "config";

// cookies
import { getCookie } from "utils/functions";

/**
 *
 * @param {number} propertyId
 * @returns
 */
export const matchGET = async (
  propertyId,
  offsetMatches = 0,
  limitMatches = 10,
  propertyO = true,
  propertyD = true,
  filters = ""
) => {
  let response;
  try {
    response = await axios.get(
      `${config.apiUrl}/match/${propertyId}?offset=${offsetMatches}&limit=${limitMatches}&opciones[inmuebleO]=${propertyO}&opciones[inmuebleD]=${propertyD}${filters}`,
      {
        headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};
