/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// @mui components
import { Box } from "@mui/material";

// @mui icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// layouts
import Section from "layouts/Section/Section";

// image
import blog01 from "img/reals/categories/01.webp";
import blog02 from "img/reals/categories/02.webp";
import blog03 from "img/reals/categories/03.webp";

// contexts
import { useLanguage } from "context/LanguageProvider";

const UserCategories = (props) => {
  const { selectPlan } = props;
  const { languageState } = useLanguage();

  const images = [blog02, blog01, blog03];

  const linkCss = css({
    transition: "none !important",
    textDecoration: "underline !important",
    h5: {
      transition: "all 500ms ease !important",
    },
    p: {
      transition: "all 500ms ease !important",
    },
    "&:hover": {
      h5: {
        color: "#ff9d33",
      },
      p: {
        color: "#ff9d33",
      },
    },
  });

  return (
    <Section sx={{ overflow: "auto" }}>
      <SitoContainer ignoreDefault className="container">
        <SitoContainer
          ignoreDefault
          className="margin-50px-bottom xs-margin-40px-bottom text-center"
        >
          <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
            {selectPlan
              ? languageState.texts.Home.UserCategories.TitleMust
              : languageState.texts.Home.UserCategories.Title}
          </h4>
        </SitoContainer>

        <SitoContainer ignoreDefault className="row" sx={{ justifyContent: "center" }}>
          {languageState.texts.Home.Plans.map((item, i) => (
            <SitoContainer key={item.name} ignoreDefault className="col-lg-4 sm-margin-40px-bottom">
              <Link
                to={`/register?abono=${i}`}
                className={linkCss}
                onClick={!selectPlan ? (e) => selectPlan(e, i) : () => {}}
              >
                <SitoImage
                  src={images[i]}
                  alt=""
                  sx={{ borderRadius: "3px", width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Link>
              <Box
                className="blog-text"
                sx={{
                  height: "175px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: {
                    lg: "-115px !important",
                    xs: "-150px !important",
                  },
                }}
              >
                <h5 className="font-size20 xs-font-size18 margin-5px-top margin-5px-bottom font-weight-500">
                  {item.name}
                </h5>
                <p>{item.description}</p>
                {!selectPlan ? (
                  <Link to={`/register?abono=${i}`} className={linkCss}>
                    {languageState.texts.Home.UserCategories.Register}
                    <OpenInNewIcon sx={{ fontSize: "medium", marginLeft: "10px" }} />
                  </Link>
                ) : (
                  <Link
                    to={`/register?abono=${i}`}
                    className={linkCss}
                    onClick={(e) => selectPlan(e, i)}
                  >
                    {selectPlan
                      ? languageState.texts.Home.UserCategories.Select
                      : languageState.texts.Home.UserCategories.Register}
                  </Link>
                )}
              </Box>
            </SitoContainer>
          ))}
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

UserCategories.defaultProps = {
  selectPlan: undefined,
};

UserCategories.propTypes = {
  selectPlan: PropTypes.func,
};

export default UserCategories;
