/* eslint-disable react/function-component-definition */

// @mui icons
import LoginIcon from "@mui/icons-material/Login";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import FavoriteIcon from "@mui/icons-material/Favorite";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

// layouts
import Section from "layouts/Section/Section";

const Services = () => {
  const { languageState } = useLanguage();

  const icons = [
    <LoginIcon
      sx={{ fontSize: "40px" }}
      className="ti-check-box font-size44 xs-font-size40 text-theme-color"
    />,
    <ManageAccountsIcon
      sx={{ fontSize: "40px" }}
      className="ti-check-box font-size44 xs-font-size40 text-theme-color"
    />,
    <NotificationImportantIcon
      sx={{ fontSize: "40px" }}
      className="ti-check-box font-size44 xs-font-size40 text-theme-color"
    />,
    <FavoriteIcon
      sx={{ fontSize: "40px" }}
      className="ti-check-box font-size44 xs-font-size40 text-theme-color"
    />,
  ];

  return (
    <Section id="services">
      <SitoContainer ignoreDefault className="container">
        <SitoContainer
          ignoreDefault
          className="col-md-6 xs-margin-40px-bottom"
          sx={{ width: "100% !important", maxWidth: "100% !important", marginBottom: "20px" }}
        >
          <h4 className="font-size38 sm-font-size30 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
            {languageState.texts.Home.TitleHowItWorks}
          </h4>
        </SitoContainer>
        <SitoContainer ignoreDefault className="row">
          {languageState.texts.Home.HowItWorks.map((item) => (
            <SitoContainer
              key={item.title}
              ignoreDefault
              className="col-lg-3 col-md-6 sm-margin-30px-bottom"
            >
              <SitoContainer
                ignoreDefault
                className="theme-shadow border-radius-3 padding-30px-lr padding-40px-tb"
                sx={{ height: "405px" }}
              >
                {icons[item.icon]}
                <h5 className="font-size20 margin-20px-bottom margin-30px-top xs-margin-20px-top">
                  {item.title}
                </h5>
                <p className="no-margin-bottom">{item.description}</p>
              </SitoContainer>
            </SitoContainer>
          ))}
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

export default Services;
