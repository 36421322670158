/* eslint-disable no-param-reassign */
import { useRef, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// framer-motion
import { useInView } from "framer-motion";

// @mui/material
import {
  Box,
  Dialog,
  Tooltip,
  Select,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Typography,
  IconButton,
  InputLabel,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

// @mui/icons-material
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// context
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// services
import { userList } from "services/searchs/get";
import { fertilizerList } from "services/fertilizers/get";

// own components
import Empty from "components/MUI/Empty/Empty";
import Loader from "components/Loader/Loader";

// utils
import { apiTokenCertified } from "utils/functions";

function SearchWrapper(props) {
  const navigate = useNavigate();
  const { languageState } = useLanguage();

  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(0);

  const { handleClose, open } = props;

  const [toSearch, setToSearch] = useState("");

  const handlePlan = (e) => setPlan(e.target.value);
  const handleChange = (e) => setToSearch(e.target.value);

  const modelsReducer = (modeState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { newArray } = action;
        return newArray.map((item) => ({
          id: item.id,
          value: true,
          label: item.label,
          type: item.type,
        }));
      }
      case "change": {
        const { index, value } = action;
        modeState[index].value = value;
        return [...modeState];
      }
      default:
        return [];
    }
  };
  const [models, setModels] = useReducer(modelsReducer, []);

  const resultReducer = (resultState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = resultState;
        const { toAdd } = action;
        toAdd.forEach((item) => {
          if (!resultState.find((jtem) => item.title === jtem.title)) newLocalList.push(item);
        });
        return newLocalList;
      }
      case "set": {
        const newLocalList = resultState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };
  const [result, setResult] = useReducer(resultReducer, []);
  const [loading, setLoading] = useState(true);

  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [showFilters, setShowFilters] = useState(false);

  const fetchPlan = async () => {
    setLoading(true);
    const response = await fertilizerList(0, -1);
    if (response.status === 200) {
      const { data } = await response.data;
      if (data.length) setPlans(data.map((item) => ({ id: item.id, name: item.nombre })));
    } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    else {
      setPlans(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref);

  const fetch = async (from = 0, input = "", rModels = [], rPlan = 0) => {
    setLoading(true);
    if (input.length)
      try {
        let filters = "";
        rModels
          .filter((item) => item.id !== "plan")
          .forEach((item) => {
            filters += `filtros${encodeURIComponent("[")}${item.id}${encodeURIComponent(
              "]"
            )}=${input}`;
          });
        if (rPlan) {
          if (filters.length)
            filters += `filtros${encodeURIComponent("[")}plan_id${encodeURIComponent("]")}=${plan}`;
          else
            filters += `filtros&${encodeURIComponent("[")}plan_id${encodeURIComponent(
              "]"
            )}=${plan}`;
        }
        const response = await userList(from, 10, filters);
        const { data, total } = await response.data;
        setHasMore(from < total);
        if (data.length) {
          const parsedList = [];
          data.forEach((item) => {
            const { nombre, rol, email, apellidos } = item;
            parsedList.push({
              title: `${nombre} ${apellidos}`,
              role: rol,
              email,
            });
          });
          setResult({ type: "add", toAdd: parsedList });
        } else setHasMore(false);
      } catch (err) {
        console.log(err);
      }
    setLoading(false);
  };

  useEffect(() => {
    console.log("inview", isInView, page);
    if (isInView) setPage(page + 1);
  }, [isInView]);

  useEffect(() => {
    console.log(page);
    fetch(page * 10, toSearch, models, plan);
  }, [page, toSearch, models, plan]);

  useEffect(() => {
    setModels({ type: "set", newArray: languageState.texts.SearchWrapper.Models });
  }, []);

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box sx={{ padding: "20px" }}>
        <Typography variant="h5">{languageState.texts.Search.Title}</Typography>
        <FormControl
          sx={{ width: "100%", position: "relative", margin: "20px 0" }}
          variant="outlined"
        >
          <InputLabel htmlFor="search">{languageState.texts.Search.Label}</InputLabel>
          <OutlinedInput
            id="search"
            sx={{ width: "100%" }}
            value={toSearch}
            onChange={handleChange}
            label={languageState.texts.Search.Label}
            placeholder={`${languageState.texts.Search.Label}...`}
            startAdornment={
              <InputAdornment position="start">
                <IconButton type="submit" color="primary">
                  <SearchIcon />
                </IconButton>
                <Tooltip
                  title={
                    !showFilters
                      ? languageState.texts.Search.ShowFilter
                      : languageState.texts.Search.HideFilter
                  }
                >
                  <IconButton onClick={() => setShowFilters(!showFilters)}>
                    {!showFilters ? <FilterAltIcon /> : <FilterAltOffIcon />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormGroup
          sx={{
            width: "560px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            transition: "all 500ms ease",
            overflow: "hidden",
            height: showFilters ? "160px" : "0px",
          }}
        >
          {!loading &&
            models.map((item, i) => (
              <Box key={item.id} sx={{ width: "230px" }}>
                {item.type === "select" ? (
                  <FormControl fullWidth>
                    <InputLabel id="plan">{languageState.texts.Search.Plan}</InputLabel>
                    <Select
                      labelId="plan"
                      id="plan1"
                      value={plan}
                      label={languageState.texts.Search.Plan}
                      onChange={handlePlan}
                    >
                      <MenuItem value={0}>{languageState.texts.Search.NoPlan}</MenuItem>
                      {plans !== -1 &&
                        plans.map((jtem) => (
                          <MenuItem value={jtem.id} key={jtem.id}>
                            {jtem.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    key={item.id}
                    control={
                      <Checkbox
                        checked={item.value}
                        onChange={(e) =>
                          setModels({ type: "change", index: i, value: e.target.checked })
                        }
                      />
                    }
                    label={
                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {item.label}
                      </Box>
                    }
                  />
                )}
              </Box>
            ))}
        </FormGroup>
        <Typography variant="h5">{languageState.texts.SearchWrapper.Title}</Typography>
        <Box
          sx={{
            height: "300px",
            overflow: "auto",
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {result.length > 0
            ? result.map((item, i) => (
                <Accordion defaultExpanded={i === 0} key={item.tile} sx={{ width: "100%" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} id={item.tile}>
                    <Typography>{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: "text.secondary" }}>{item.role}</Typography>
                    <Typography>{item.email}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            : null}
          {!loading && !hasMore && !result.length ? <Empty sx={{ height: "300px" }} /> : null}
          <Loader
            visible={loading}
            minimal
            sx={{
              width: "100%",
              height: "100%",
              background: "#00000000 !important",
            }}
          />
          <div ref={ref}>
            <Loader
              visible={hasMore}
              sx={{
                width: "100%",
                background: "#00000000 !important",
                height: "105px !important",
                position: "inherit !important",
                marginTop: "20px !important",
              }}
            />
          </div>
        </Box>
      </Box>
    </Dialog>
  );
}

SearchWrapper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SearchWrapper;
