/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

import PropTypes from "prop-types";

import { css } from "@emotion/css";

import { useEffect } from "react";
import { Link } from "react-router-dom";

// sito components
import SitoContainer from "sito-container";

// @mui components
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

// @mui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// side components
import SideHeader from "./components/SideHeader/SideHeader";
import SideLink from "./components/SideLink/SideLink";

const Sidebar = (props) => {
  const { visible } = props;

  const { languageState } = useLanguage();
  const { setRouteState } = useRoute();

  useEffect(() => {
    setRouteState({ type: "set", to: 0 });
  }, []);

  const justShow = css({
    marginLeft: "0px !important",
  });

  const justHide = css({
    marginLeft: "-280px !important",
  });

  const scroll = css({
    overflow: "auto",
  });

  return (
    <nav
      id="sidebar"
      className={`dashboard-sidebar js-sidebar ${visible ? justShow : justHide} ${scroll}`}
    >
      <SitoContainer ignoreDefault className="dashboard-sidebar-content js-simplebar">
        <Link className="dashboard-sidebar-brand" to="/">
          <span className="dashboard-align-middle">{languageState.texts.Title}</span>
        </Link>

        <ul className="dashboard-sidebar-nav">
          {languageState.texts.Dashboard.Sidebar.Links.filter((item) => !item.hidden).map(
            (item, i) => (
              <SitoContainer ignoreDefault key={item.label}>
                <Accordion
                  elevation={0}
                  sx={{ background: "none", padding: 0 }}
                  defaultExpanded={i === 0}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <SideHeader text={item.label} />
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {item.links.map((jtem) => (
                      <SideLink content={jtem} />
                    ))}
                  </AccordionDetails>
                </Accordion>
              </SitoContainer>
            )
          )}
        </ul>
      </SitoContainer>
    </nav>
  );
};

Sidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Sidebar;
