/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

// @mui/material
import { useTheme, Typography } from "@mui/material";

// sito components
import SitoImage from "sito-image";
import SitoContainer from "sito-container";

// images
import userPhoto from "img/content/myprofile-1.jpg";

const TopBar = (props) => {
  const theme = useTheme();
  const { src, name } = props;

  return (
    <SitoContainer
      alignItems="center"
      sx={{
        padding: "20px",
        width: "100%",
        height: "80px",
        background: theme.palette.background.paper,
      }}
    >
      <SitoImage
        src={src && src !== "" ? src : userPhoto}
        sx={{
          width: "65px",
          height: "65px",
          borderRadius: "100%",
          img: {
            objectFit: "cover",
          },
        }}
      />
      <Typography sx={{ marginLeft: "20px" }}>{name}</Typography>
    </SitoContainer>
  );
};

TopBar.defaultProps = {
  src: userPhoto,
  name: "",
};

TopBar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
};

export default TopBar;
