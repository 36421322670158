/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// services
import { createCollege } from "services/colleges/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { apiTokenCertified } from "utils/functions";

const Insert = () => {
  const navigate = useNavigate();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { reset, register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const localCreateCollege = async (d) => {
    setLoading(true);
    try {
      const { name } = d;
      const response = await createCollege({
        nombre: name,
      });

      if (response.status === 200) {
        showNotification("success", languageState.texts.Messages.CollegeCreatedSuccessful);
        reset({ name: "" });
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      if (err.message.indexOf("422") > -1) {
        const error = err.response.data.data;
        let message = languageState.texts.Errors.SomeWrong;
        const newInvalids = invalids;
        error.forEach((item) => {
          const enName = languageState.texts.Dashboard.TableAttributesEN[item.field];
          newInvalids[enName] = true;
          message = languageState.texts.Errors.NameTaken;
        });
        setInvalids(newInvalids);
        showNotification("error", message);
      }
    }
    setLoading(false);
  };

  const validate = () => setOk(true);

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return showNotification("error", languageState.texts.Errors.NameRequired);
      }
    }
  };

  return (
    <SitoContainer sx={{ position: "relative", minHeight: "300px" }}>
      <Loader visible={loading} minimal />
      {!loading && (
        <form
          id="register-form"
          method="post"
          action="#!"
          className={css({ width: "100%" })}
          onSubmit={handleSubmit(localCreateCollege)}
        >
          <SitoContainer alignItems="center" justifyContent="space-between">
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.College.Insert.Title}
            </h3>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            {languageState.texts.SignIn.inputs
              .filter((item) => {
                if (item.models && item.models.indexOf("college") > -1) return item;
                return null;
              })
              .map((item) => (
                <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                  <SitoContainer ignoreDefault className="form-group">
                    <label htmlFor={item.id}>{`${item.label} ${item.required ? "*" : ""}`}</label>
                    <input
                      placeholder={item.collegePlaceholder}
                      type={item.type}
                      name={item.id}
                      id={item.id}
                      maxLength={item.maxLength}
                      required={item.required}
                      onInput={validate}
                      onInvalid={invalidate}
                      {...register(item.id)}
                      className={invalids[item.id] ? "error" : ""}
                    />
                  </SitoContainer>
                </SitoContainer>
              ))}
          </SitoContainer>
          <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
            <SitoContainer ignoreDefault className="col-md-6">
              <button type="submit" className="butn">
                {languageState.texts.Buttons.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
    </SitoContainer>
  );
};

export default Insert;
