/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable eqeqeq */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
import { useState } from "react";

import PropTypes from "prop-types";

// @emotion
import { css } from "@emotion/css";

import {
  useTheme,
  Paper,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  Toolbar,
  Link,
  Radio,
} from "@mui/material";

// sito components
import SitoContainer from "sito-container";

// @mui/icons-material
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import Favorite from "@mui/icons-material/Favorite";
import DeleteIcon from "@mui/icons-material/Delete";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// @mui styles
import { alpha } from "@mui/material/styles";

// @mui utils
import { visuallyHidden } from "@mui/utils";

// contexts
import { useLanguage } from "context/LanguageProvider";

// styles
import "./style.css";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, order, orderBy) {
  const stabilizedThis = array.sort((itemA, itemB) => {
    if (itemA && itemB && itemA[orderBy] && itemB[orderBy]) {
      if (itemA[orderBy].real && itemB[orderBy].real) {
        if (itemA[orderBy].real < itemB[orderBy].real) {
          if (order === "asc") return -1;
          return 1;
        }
        if (itemA[orderBy].real > itemB[orderBy].real) {
          if (order === "asc") return 1;
          return -1;
        }
      } else if (itemA[orderBy].value && itemB[orderBy].value) {
        if (itemA[orderBy].value < itemB[orderBy].value) {
          if (order === "asc") return -1;
          return 1;
        }
        if (itemA[orderBy].value > itemB[orderBy].value) {
          if (order === "asc") return 1;
          return -1;
        }
      } else {
        if (itemA[orderBy] < itemB[orderBy]) {
          if (order === "asc") return -1;
          return 1;
        }
        if (itemA[orderBy] > itemB[orderBy]) {
          if (order === "asc") return 1;
          return -1;
        }
      }
    }
    return 0;
  });

  return stabilizedThis;
}

function ComplexTableHead(props) {
  const {
    likeRadioSelection,
    noChecks,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const onSelectedAllChange = (e) => {
    onSelectAllClick(e);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {!likeRadioSelection && !noChecks && (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectedAllChange}
              onClick={onSelectedAllChange}
            />
          )}
        </TableCell>
        <Link
          sx={{ display: "none" }}
          id="to-link"
          href="#"
          target="_blank"
          rel="noopener"
        />
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={"none"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              span: {
                width: { md: headCell.width, xs: "100%" },
              },
              svg: {
                display:
                  headCell.id === "count" || headCell.id === "imagen"
                    ? "none"
                    : "inherit",
              },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <SitoContainer
                flexDirection={headCell.id === "count" ? "row" : "column"}
              >
                {headCell.id === "count" ? (
                  <Favorite
                    sx={{
                      marginLeft: { md: "-10px", xs: "10px" },
                      display: "inherit !important",
                    }}
                    color="primary"
                  />
                ) : (
                  headCell.label
                )}
                <span className={css({ fontSize: "11px" })}>
                  {headCell.extraLabel}
                </span>
              </SitoContainer>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ComplexTableHead.defaultProps = {
  noChecks: false,
};

ComplexTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  likeRadioSelection: PropTypes.bool.isRequired,
  noChecks: PropTypes.bool,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ComplexTableToolbar = (props) => {
  const {
    canActivate,
    numSelected,
    onDelete,
    onEdit,
    onShow,
    onSelect,
    filters,
    filtering,
    onShare,
    onChat,
    filterHeight,
  } = props;

  const { languageState } = useLanguage();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        flexDirection: numSelected > 0 ? "row" : "column",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}{" "}
          {numSelected === 1
            ? languageState.texts.OneSelected
            : languageState.texts.NSelected}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        />
      )}
      {numSelected === 0 && (
        <SitoContainer
          fullWidth
          sx={{
            marginTop: "20px",
            width: "97%",
            overflow: "hidden",
            transition: `height ${
              Number(filterHeight.split("px")[0]) + 500
            }ms ease`,
            height: filtering ? filterHeight : "40px",
          }}
        >
          {filters}
        </SitoContainer>
      )}
      {numSelected === 1 && onChat ? (
        <Tooltip title={languageState.texts.Dashboard.Submit.Chat}>
          <IconButton onClick={onChat}>
            <ChatIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {numSelected === 1 && (
        <>
          {onEdit && (
            <Tooltip title={languageState.texts.Dashboard.Submit.Edit}>
              <IconButton onClick={onEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {onShow && (
            <Tooltip title={languageState.texts.Dashboard.Submit.See}>
              <IconButton onClick={onShow}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {numSelected > 0 && (
        <>
          {onShare && (
            <Tooltip title={languageState.texts.Dashboard.Submit.Share}>
              <IconButton onClick={onShare}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip
              title={
                !canActivate
                  ? languageState.texts.Dashboard.Submit.Delete
                  : languageState.texts.Dashboard.Submit.Deactivate
              }
            >
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {onSelect && (
            <Tooltip title={languageState.texts.Dashboard.Submit.Select}>
              <Button variant="contained" onClick={onSelect}>
                {languageState.texts.Buttons.Import}
              </Button>
            </Tooltip>
          )}
        </>
      )}
    </Toolbar>
  );
};

ComplexTableToolbar.defaultProps = {
  onEdit: undefined,
  onShow: undefined,
  onShare: undefined,
  onDelete: undefined,
  onSelect: undefined,
  onChat: undefined,
  canActivate: false,
  filters: <></>,
  filtering: false,
  filterHeight: "180px",
};

ComplexTableToolbar.propTypes = {
  canActivate: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onShow: PropTypes.func,
  onSelect: PropTypes.func,
  onShare: PropTypes.func,
  onChat: PropTypes.func,
  filters: PropTypes.node,
  filtering: PropTypes.bool,
  filterHeight: PropTypes.string,
};

export default function ComplexTable(props) {
  const theme = useTheme();

  const {
    canActivate,
    onPageChange,
    page,
    columns,
    rows,
    onDelete,
    onActivate,
    onBlock,
    onEdit,
    onSelect,
    onShare,
    onChat,
    canShow,
    filters,
    filtering,
    likeRadioSelection,
    noChecks,
    propOnClick,
    hasImage,
    filterHeight,
  } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   *
   * @param {boolean} all
   * @returns
   */
  const handleSelectAllClick = () => {
    if (selected.length === 0) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    if (!likeRadioSelection) {
      let selectedIndex = -1;
      let newSelected = [];
      let isLocalSelected = false;
      selected.forEach((item) => {
        if (item === id) {
          selectedIndex = selected.indexOf(item);
          isLocalSelected = true;
        }
      });
      if (!isLocalSelected)
        rows.forEach((item) => {
          if (item.id === id) selectedIndex = rows.indexOf(item);
        });
      if (selected.length && isLocalSelected) {
        if (selectedIndex === -1)
          newSelected = newSelected.concat(selected, id);
        else if (selectedIndex === 0)
          newSelected = newSelected.concat(selected.slice(1));
        else if (selectedIndex === selected.length - 1)
          newSelected = newSelected.concat(selected.slice(0, -1));
        else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          );
        }
      } else {
        newSelected = [...selected];
        newSelected.push(id);
      }
      if (
        newSelected.length === 1 &&
        selected.length === 1 &&
        newSelected[0] === selected[0]
      )
        setSelected([]);
      else setSelected(newSelected);
    } else setSelected([id]);
  };

  const handleChangePage = (event, newPage) => onPageChange(newPage);

  const handleChangeRowsPerPage = (event) =>
    setRowsPerPage(parseInt(event.target.value, 10));

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onLocalDelete = () => onDelete(selected);

  const onLocalBlock = () => onBlock(selected);

  const onLocalEdit = () => onEdit(selected);

  const onLocalSelect = () => onSelect(selected);

  const onLocalShare = () => onShare(selected);

  const onLocalChat = () => {
    onChat(selected);
  };

  const onShow = () => {
    const link = document.getElementById("to-link");
    link.href = `#/details?id=${selected[0]}`;
    link.click();
  };

  const onLocalActivate = () => onActivate(selected);

  const getCellWidth = (key, row) => {
    if (key === "titulo") return "130px";
    if (key === "direccion") return "130px";
    if (key !== "count" && key !== "image" && key !== "id")
      return row[key].width || "100%";
    if (key === "count" || key === "id") return "100%";
    if (key === "image") return "100px";
  };

  const printValue = (row, item) => {
    if (item === "titulo" || item === "direccion")
      return row[item].length > 30
        ? `${row[item].substring(0, 30)}...`
        : row[item];
    return row[item].value !== undefined ? row[item].value : row[item];
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <ComplexTableToolbar
          canActivate={canActivate}
          numSelected={selected.length}
          onDelete={onDelete ? onLocalDelete : undefined}
          onActivate={onActivate ? onLocalActivate : undefined}
          onBlock={onBlock ? onLocalBlock : undefined}
          onEdit={onEdit ? onLocalEdit : undefined}
          onShow={canShow ? onShow : undefined}
          onChat={onChat ? onLocalChat : undefined}
          onSelect={onSelect ? onLocalSelect : undefined}
          onShare={onShare ? onLocalShare : undefined}
          filters={filters}
          filtering={filtering}
          filterHeight={filterHeight}
        />
        <TableContainer sx={{ minHeight: "500px" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <ComplexTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              likeRadioSelection={likeRadioSelection}
              noChecks={noChecks}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {onPageChange &&
                stableSort(rows, order, orderBy)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={
                          propOnClick
                            ? (e) => {
                                e.preventDefault();
                                propOnClick(e, row.id);
                              }
                            : (event) => handleClick(event, row.id)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{
                          cursor: "pointer",
                          div: { padding: noChecks ? "10px 0" : "" },
                          backgroundColor: row.state
                            ? `${theme.palette.error.light} !important`
                            : "",
                          "&:hover": {
                            backgroundColor: row.state
                              ? `${theme.palette.error.main} !important`
                              : "",
                          },
                        }}
                      >
                        <TableCell padding="checkbox">
                          {!noChecks && !likeRadioSelection && (
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          )}
                          {likeRadioSelection && (
                            <Radio
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          )}
                        </TableCell>
                        {Object.keys(row)
                          .filter((item) => {
                            if (item !== "imagen") return item;
                            return null;
                          })
                          .map((item) => (
                            <>
                              {item === "id" && hasImage && (
                                <TableCell
                                  align="right"
                                  component="td"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  sx={{
                                    padding: 0,
                                    div: {
                                      padding: { md: 0, xs: "0 16px 0 0" },
                                    },
                                  }}
                                >
                                  {row.imagen}
                                </TableCell>
                              )}
                              <TableCell
                                align="left"
                                component="td"
                                id={labelId}
                                key={row[item].key || item}
                                scope="row"
                                padding="none"
                                sx={{
                                  div: { width: "100%" },
                                  span: {
                                    width: {
                                      md: getCellWidth(item, row),
                                      xs: item !== "count" ? "150px" : "40px",
                                    },
                                    marginLeft: {
                                      md: 0,
                                      xs: item === "count" ? "20px" : 0,
                                    },
                                    paddingRight: "16px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textAlign: "left",
                                  },
                                }}
                              >
                                {row[item] && (
                                  <SitoContainer
                                    flexDirection="column"
                                    alignItems="flex-start"
                                  >
                                    {row[item].primary !== undefined ? (
                                      <>
                                        <span>{row[item].primary}</span>
                                        <span>{row[item].secondary}</span>
                                      </>
                                    ) : (
                                      <span>{printValue(row, item)}</span>
                                    )}
                                  </SitoContainer>
                                )}
                              </TableCell>{" "}
                            </>
                          ))}
                      </TableRow>
                    );
                  })}
              {!onPageChange &&
                stableSort(rows, order, orderBy).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={
                        propOnClick
                          ? () => propOnClick(row.id)
                          : (event) => handleClick(event, row.id)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{
                        div: { padding: noChecks ? "10px 0" : "" },
                        backgroundColor: row.state
                          ? `${theme.palette.error.light} !important`
                          : "",
                        "&:hover": {
                          backgroundColor: row.state
                            ? `${theme.palette.error.main} !important`
                            : "",
                        },
                      }}
                    >
                      <TableCell padding="checkbox">
                        {!noChecks && !likeRadioSelection && (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        )}
                        {likeRadioSelection && (
                          <Radio
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        )}
                      </TableCell>
                      {Object.keys(row)
                        .filter((item) => {
                          if (item !== "imagen") return item;
                          return null;
                        })
                        .map((item) => (
                          <>
                            {item === "id" && hasImage && (
                              <TableCell
                                align="right"
                                component="td"
                                id={labelId}
                                scope="row"
                                padding="none"
                                sx={{
                                  padding: 0,
                                  div: {
                                    padding: { md: 0, xs: "0 16px 0 0" },
                                  },
                                }}
                              >
                                {row.imagen}
                              </TableCell>
                            )}
                            <TableCell
                              align="left"
                              component="td"
                              id={labelId}
                              key={row[item].key || item}
                              scope="row"
                              padding="none"
                              sx={{
                                div: { width: "100%" },
                                span: {
                                  width: {
                                    md: getCellWidth(item, row),
                                    xs: item !== "count" ? "150px" : "40px",
                                  },
                                  marginLeft: {
                                    md: 0,
                                    xs: item === "count" ? "20px" : 0,
                                  },
                                  paddingRight: "16px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                },
                              }}
                            >
                              {row[item] && (
                                <SitoContainer
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  {row[item].primary ? (
                                    <>
                                      <span>{row[item].primary}</span>
                                      <span>{row[item].secondary}</span>
                                    </>
                                  ) : (
                                    <span>{row[item].value || row[item]}</span>
                                  )}
                                </SitoContainer>
                              )}
                            </TableCell>{" "}
                          </>
                        ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {onPageChange !== undefined && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </Paper>
    </Box>
  );
}

ComplexTable.defaultProps = {
  onEdit: undefined,
  onBlock: undefined,
  onDelete: undefined,
  onActivate: undefined,
  onSelect: undefined,
  onShare: undefined,
  onChat: undefined,
  canActivate: false,
  canShow: false,
  onPageChange: undefined,
  likeRadioSelection: false,
  noChecks: false,
  filters: <></>,
  filtering: false,
  propOnClick: undefined,
  hasImage: true,
  page: 0,
  filterHeight: "180px",
};

ComplexTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onBlock: PropTypes.func,
  onEdit: PropTypes.func,
  onActivate: PropTypes.func,
  onSelect: PropTypes.func,
  onShare: PropTypes.func,
  onChat: PropTypes.func,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  canShow: PropTypes.bool,
  canActivate: PropTypes.bool,
  likeRadioSelection: PropTypes.bool,
  noChecks: PropTypes.bool,
  filters: PropTypes.node,
  filtering: PropTypes.bool,
  propOnClick: PropTypes.func,
  hasImage: PropTypes.bool,
  filterHeight: PropTypes.string,
};
