/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

// sito components
import SitoContainer from "sito-container";

// prop types
import PropTypes from "prop-types";

// react-router-dom
import { Outlet } from "react-router-dom";

// own components
import Loader from "components/Loader/Loader";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";

import "css/plugins.css";
import "css/styles.css";

const View = (props) => {
  const { noFooter, noNavbar } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <SitoContainer ignoreDefault className="main-wrapper">
      {!noNavbar ? <Navbar /> : null}
      <Outlet />
      {!noFooter && <Footer />}
      <ScrollToTop
        smooth
        className="scroll-to-top"
        component={<i className="fas fa-angle-up" aria-hidden="true"></i>}
      />
      <Loader visible={loading} />
    </SitoContainer>
  );
};

View.defaultProps = {
  noFooter: false,
  noNavbar: false,
};

View.propTypes = {
  noFooter: PropTypes.bool,
  noNavbar: PropTypes.bool,
};

export default View;
