/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useReducer, useCallback, useRef } from "react";

import PropTypes from "prop-types";

// @emotion/css
import { css } from "@emotion/css";

// framer-motion
import { useInView } from "framer-motion";

// sito components
import SitoContainer from "sito-container";

// @mui/material
import { useTheme, Button, CircularProgress } from "@mui/material";

// @mui/icons-material
import SendIcon from "@mui/icons-material/Send";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// services
import { userList } from "services/users/get";
import { sendMessage } from "services/chat/post";
import { propertyList } from "services/properties/get";
import { messageFromChat } from "services/chat/get";

// own components
import Loader from "components/Loader/Loader";

// local components
import TopBar from "./TopBar/TopBar";
import Message from "./Message/Message";
import NotSelected from "./NoSelected/NoSelected";
import { useChat } from "../Context/ChatProvider";

const Content = (props) => {
  const theme = useTheme();
  const { ownUser, selectedChat, propertyChat } = props;

  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  const [loading, setLoading] = useState(false);

  const messagesReducer = (messagesState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { newMessages } = action;
        return newMessages;
      }
      case "add": {
        const { newMessages, after } = action;
        let newArray = [];
        if (after) {
          newArray = [...messagesState];
          newMessages.forEach((item) => {
            if (!newArray.find((jtem) => item.id === jtem.id))
              newArray.push(item);
          });
        } else {
          newMessages.forEach((item) => {
            if (!messagesState.find((jtem) => item.id === jtem.id))
              newArray.push(item);
          });
          messagesState.forEach((item) => newArray.push(item));
        }
        return newArray;
      }
      default:
        return [];
    }
  };

  const [messages, setMessages] = useReducer(messagesReducer, []);

  const fetchMessages = useCallback(
    async (from = 0) => {
      try {
        const response = await messageFromChat(selectedChat, from, 30);
        console.log(response);
        const { data, total } = response.data;
        setHasMore(messages.length + data.length < total);
        setMessages({
          type: from === 0 ? "set" : "add",
          newMessages: data,
          after: from === 0 ? true : false,
        });
        console.log(data);
        if (!data.length) setHasMore(false);
      } catch (err) {
        showNotification("error", languageState.texts.Errors.NotConnected);
      }
    },
    [selectedChat, messages]
  );

  const [user, setUser] = useState({});

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await userList(0, 1, `filtros[id]=${selectedChat}`);
      console.log("selected chat", selectedChat);
      if (response.status === 200) {
        const { data } = response.data;
        if (data.length) {
          console.log("user", data);
          const { nombre, urlPhotoProfile, id } = data[0];
          setUser({ nombre, urlPhotoProfile, id });
        } else showNotification("error", languageState.texts.Errors.SomeWrong);
      }
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  }, [selectedChat]);

  useEffect(() => {
    console.log("cambio", selectedChat);
    if (selectedChat > -1) {
      fetchUserData();
      fetchMessages();
    }
  }, [selectedChat]);

  const [sendingMessage, setSendingMessage] = useState(false);

  const onSubmit = async (e) => {
    setSendingMessage(true);
    e.preventDefault();
    try {
      const response = await sendMessage(message, user.id);
      console.log(response, user.id);
      if (response.status) {
        setMessages({ type: "add", after: true, newMessages: [response.data] });
        setMessage("");
        setTimeout(() => {
          setChatState({ type: "set", value: true });
        }, 500);
      }
    } catch (err) {
      console.error(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
    setSendingMessage(false);
  };

  const ref = useRef(null);

  const { setChatState } = useChat();

  useEffect(() => {
    try {
      const url = "https://socket.crabhaus.com/";
      const socket = io(url, {
        transports: ["polling"],
      });
      console.log("socket", socket.connected, socket);
      socket.on("connection", (socket) => {
        console.log("connected");
        console.log("socket", socket.connected);
      });
      socket.on("connect", (socket) => {
        console.log("connected");
      });
      socket.on("usuario-desconectado", (data) => {
        console.log("disconnect", data);
      });
      socket.emit("configurar-usuario", {
        nombre: ownUser.nombre,
        id_user: ownUser.id,
        rol: ownUser.rol,
      });
      socket.on("message-new", (data) => {
        console.info(data);
        fetchMessages();
        setTimeout(() => {
          setChatState({ type: "set", value: true });
        }, 500);
      });
      socket.on("disconnect", (data) => {
        console.log("disconnect", data);
      });
      socket.on("disconnected", (data) => {
        console.log("disconnect", data);
      });
      console.log("socket", socket.connected);
    } catch (err) {
      console.log("ERROR!");
      console.error(err);
    }
    // Disconnect socket on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchProperty = async (propertyToFetch) => {
    try {
      setLoading(true);
      const response = await propertyList(
        0,
        1,
        `filtros[id]=${propertyToFetch}&opciones[datosInmobiliaria]=true`
      );
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          const [theProperty] = data;
          const title = theProperty.inmuebleCaracteristicas.find(
            (item) => item.caracteristica_id === 73
          );
          if (title)
            setMessage(
              `${languageState.texts.EmailDialog.MessageContent} ${title.valor}`
            );
        }
      }
    } catch (err) {
      console.error(err);
      showNotification("error", languageState.texts.Errors.LoadingProperty);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (propertyChat > -1) fetchProperty(propertyChat);
  }, [propertyChat]);

  useEffect(() => {
    if (messages.length) {
      const content = document.getElementById("content");
      if (content !== null && page === 0)
        content.scrollTop = content.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (page > 0) fetchMessages(page * 10);
  }, [page]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef(null);
  const onScrollContent = useCallback(
    (e) => {
      if (!contentRef.current.scrollTop && hasMore) setPage(page + 1);
    },
    [scrollPosition, hasMore]
  );

  useEffect(() => {
    const content = document.getElementById("content");
    if (content !== null) content.addEventListener("scroll", onScrollContent);
    return () => {
      if (content !== null)
        content.removeEventListener("scroll", onScrollContent);
    };
  }, [contentRef.current, onScrollContent]);

  return (
    <SitoContainer
      sx={{
        position: "relative",
        flex: 1,
        width: "100%",
        height: "calc(100vh - 85px)",
      }}
    >
      <Loader visible={loading} minimal />
      {selectedChat === -1 ? (
        <NotSelected />
      ) : (
        <SitoContainer flexDirection="column" sx={{ width: "100%" }}>
          <TopBar name={user.nombre} src={user.urlPhotoProfile} />
          <SitoContainer
            id="content"
            ref={contentRef}
            flexDirection="column"
            sx={{
              height: "100%",
              overflow: "auto",
              padding: "20px 10px",
              position: "relative",
            }}
          >
            {messages.length > 0 && hasMore ? (
              <Loader
                visible
                sx={{
                  height: "64px !important",
                  position: "inherit !important",
                  marginTop: "20px !important",
                  div: {
                    width: "40px",
                    height: "40px",
                    div: { width: "25px", height: "25px" },
                  },
                }}
              />
            ) : null}

            {messages
              .filter((item) => item.mensaje !== null)
              .map((item) => (
                <SitoContainer
                  key={item.id}
                  sx={{ width: "100%" }}
                  flexDirection="column"
                  alignItems={
                    item.inmobiliaria_envia === user.id
                      ? "flex-start"
                      : "flex-end"
                  }
                >
                  <Message
                    item={item}
                    color={item.inmobiliaria_envia === ownUser.id}
                  />
                </SitoContainer>
              ))}
          </SitoContainer>
          <form onSubmit={onSubmit}>
            <SitoContainer
              alignItems="center"
              sx={{
                gap: "20px",
                paddingLeft: "20px",
                paddingBottom: "10px",
                paddingRight: "10px",
                position: "relative",
              }}
            >
              <input
                required
                className={css({ borderRadius: "25px" })}
                id="message"
                type="message"
                name="message"
                value={message}
                onChange={handleChange}
                placeholder={languageState.texts.Chat.Input}
              />
              {!sendingMessage ? (
                <Button
                  variant="contained"
                  sx={{
                    width: "36px",
                    height: "36px",
                    minWidth: 0,
                    minHeight: 0,
                    borderRadius: "97%",
                  }}
                  type="submit"
                >
                  <SendIcon sx={{ fontSize: "20px" }} />
                </Button>
              ) : (
                <Loader
                  minimal
                  visible
                  sx={{
                    marginRight: "10px",
                    position: "inherit !important",
                    width: "inherit !important",
                    div: {
                      width: "40px",
                      height: "40px",
                      div: { width: "25px", height: "25px" },
                    },
                  }}
                />
              )}
            </SitoContainer>
          </form>
        </SitoContainer>
      )}
    </SitoContainer>
  );
};

Content.propTypes = {
  selectedChat: PropTypes.number.isRequired,
  propertyChat: PropTypes.number.isRequired,
  ownUser: PropTypes.shape({
    urlPhotoProfile: PropTypes.string,
    nombre: PropTypes.string,
    id: PropTypes.number,
    rol: PropTypes.string,
  }).isRequired,
};

export default Content;
