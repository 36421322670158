/* eslint-disable react/function-component-definition */

// sito components
import SitoContainer from "sito-container";

import { useLanguage } from "context/LanguageProvider";

const MercadoPago = () => {
  const { languageState } = useLanguage();

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ width: "100%", minHeight: "60vh" }}
    >
      <h1 className="dashboard-h3 dashboard-mb-3">
        {languageState.texts.Subscription.MercadoPago.Title}
      </h1>
    </SitoContainer>
  );
};

export default MercadoPago;
