// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Sends a POST request to the API with the propertyCharacteristic's ID and propertyCharacteristic data to modify it
 * @param {string} propertyId - The propertyCharacteristic id of the propertyCharacteristic you want to delete.
 * @param {object[]} propertyCharacteristics - PropertyCharacteristic data: propertyCharacteristicTypeId, stateId, neighborhoodId, cityId, provinceId, title, environments, bedrooms, bathrooms, garage, address, usdValue, pesosValue, totalSurface, deckSurface, description, latitude, longitude, postDate
 * @returns The data is being returned.
 */
export const createPropertyCharacteristic = async (propertyId, propertyCharacteristics) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmueble-caracteristica/create-masivo`,
    { inmueble_id: propertyId, caracteristica_array: propertyCharacteristics },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the propertyCharacteristic's ID and propertyCharacteristic data to modify it
 * @param {string} propertyId - The propertyCharacteristic id of the propertyCharacteristic you want to delete.
 * @param {object[]} propertyCharacteristics - PropertyCharacteristic data: propertyCharacteristicTypeId, stateId, neighborhoodId, cityId, provinceId, title, environments, bedrooms, bathrooms, garage, address, usdValue, pesosValue, totalSurface, deckSurface, description, latitude, longitude, postDate
 * @returns The data is being returned.
 */
export const deletePropertyCharacteristic = async (propertyId, propertyCharacteristics) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmueble-caracteristica/delete-masivo`,
    { inmueble_id: propertyId, caracteristica_ids: propertyCharacteristics },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  return response;
};

/**
 * Sends a POST request to the API with the propertyCharacteristic's ID and propertyCharacteristic data to modify it
 * @param {string} propertyId - The propertyCharacteristic id of the propertyCharacteristic you want to delete.
 * @param {object[]} propertyCharacteristics - PropertyCharacteristic data: propertyCharacteristicTypeId, stateId, neighborhoodId, cityId, provinceId, title, environments, bedrooms, bathrooms, garage, address, usdValue, pesosValue, totalSurface, deckSurface, description, latitude, longitude, postDate
 * @returns The data is being returned.
 */
export const modifyPropertyCharacteristic = async (propertyId, propertyCharacteristics) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/inmueble-caracteristica/update-masivo`,
    { inmueble_id: propertyId, caracteristica_array: propertyCharacteristics },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
