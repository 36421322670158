/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getAuth } from "auth/auth";
import config from "config";

// cookies
import { getCookie } from "utils/functions";

/**
 *
 * @param {number} from
 * @param {number} limit
 * @returns
 */
export const subscriptionList = async (from = 0, limit = 10, filters = "") => {
  let response;
  try {
    response = await axios.get(
      `${config.apiUrl}/mercadopago/subscriptions?offset=${from}&limit=${limit}${filters}`,
      {
        headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};

/**
 * Takes a subscription object, sends it to the API, and returns the response
 * @param {string} approvalId - The subscription object that contains the subscription's information.
 * @returns The data from the response.
 */
export const subscriptionMercadoPago = async (approvalId) => {
  console.log("getCookie(config.jwtKey)", getCookie(config.jwtKey));
  const response = await axios.get(
    // @ts-ignore
    `${config.apiUrl}/mercadopago/subscription-result?preapproval_id=${approvalId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
