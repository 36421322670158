/* eslint-disable react/function-component-definition */
import { useEffect } from "react";

import { createCookie } from "utils/functions";

import config from "config";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem(config.userCookie);
    localStorage.removeItem(config.passwordCookie);
    sessionStorage.removeItem(config.userCookie);
    const date = new Date();
    createCookie(
      config.jwtKey,
      `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
      ""
    );
    createCookie(
      config.userCookie,
      `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
      ""
    );
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }, []);

  return <div />;
};

export default Logout;
