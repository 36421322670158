/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// @emotion/css
import { css } from "@emotion/css";

// @mui components
import { Box, Autocomplete, TextField, Button } from "@mui/material";

// @mui icons
import { ChevronRight, CameraAlt } from "@mui/icons-material";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// own components
import Loader from "components/Loader/Loader";
import Sidebar from "views/Profile/Sections/Sidebar/Sidebar";
import ProfilePhoto from "components/MUI/ProfilePhoto/ProfilePhoto";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import {
  hasSelectedPlan,
  passwordValidation,
  userLogged,
  scrollTo,
  apiTokenCertified,
  isAdmin,
} from "utils/functions";

// services
import { getMe } from "services/users/get";
/* import { collegeList } from "services/colleges/get"; */
import { saveProfile, userChangePhoto } from "services/users/post";

// images
import userPhoto from "img/content/myprofile-1.jpg";

// enum
/* import RoleEnum from "./RoleEnum"; */

const Me = () => {
  const navigate = useNavigate();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const { register, handleSubmit, setValue, getValues } = useForm();

  const [userRPhoto, setUserRPhoto] = useState(userPhoto);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [invalids, setInvalids] = useState({});
  /* const [colleges, setColleges] = useState([]); */
  /* const [collegeSelected, setCollegeSelected] = useState(0); */
  const [role, setRole] = useState(0);

  /* const handleChange = (e) => {
    const { value } = e.target;
    setCollegeSelected(value);
  }; */

  const [country, setCountry] = useState({
    code: "AR",
    label: "Argentina",
    phone: "+54",
  });

  const [type, setType] = useState("");

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await getMe();
      if (response.status === 200) {
        const meData = await response.data;
        setType(meData.rol);
        if (isAdmin(meData.user)) setRole("Admin");
        else {
          const { abono_id } = meData.inmobiliaria;
          if (!hasSelectedPlan(abono_id)) window.location.href = "/";
          setRole(abono_id);
          // inmobiliaria
          const { dni, matricula, cuit, razon_social } = meData.inmobiliaria;
          setValue("tuition", matricula);
          setValue("businessName", razon_social);
          setValue("cuit", cuit);
          setValue("dni", dni);
        }
        setValue("user", meData.user);
        const parsedPhone =
          meData.telefono !== null ? meData.telefono.split(" ") : "";
        let parsedCountry = { code: "AR", label: "Argentina", phone: "+54" };
        if (parsedPhone.length) {
          const filterCountry = languageState.texts.Countries.filter(
            (item) => parsedPhone[0] === item.phone
          );
          if (filterCountry.length) [parsedCountry] = filterCountry;
        }
        setCountry(parsedCountry);
        setValue("phone", !parsedPhone.length ? parsedPhone : parsedPhone[1]);
        setValue("name", meData.nombre);
        setValue("tokko", meData.tokko);
        setValue("lastname", meData.apellidos);
        setValue("email", meData.email);

        setUserData(meData);
        if (meData.urlPhotoProfile !== null)
          setUserRPhoto(meData.urlPhotoProfile);
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
    setLoading(false);
  };

  const retry = () => fetch();

  const localSaveProfile = async (d) => {
    setLoading(true);
    const { password, rpassword } = d;
    if (password === rpassword) {
      try {
        const {
          name,
          lastname,
          phone,
          tokko,
          email,
          dni,
          tuition,
          cuit,
          businessName,
        } = d;
        const data = {
          nombre: name,
          apellidos: lastname,
          email,
          telefono: `${country.phone} ${phone}`,
          tokko_api_key: tokko,
          dni,
          matricula: tuition,
          cuit,
          razon_social: businessName,
          /* colegio_id: collegeSelected, */
        };
        const response = await saveProfile(data);
        if (response.status === 200) {
          showNotification(
            "success",
            languageState.texts.Messages.UserModifiedSuccessful
          );
          retry();
        } else if (response.error && !apiTokenCertified(response.error))
          navigate("/log-out");
      } catch (err) {
        console.error(err);
        showNotification("error", languageState.texts.Errors.SomeWrong);
      }
    } else
      showNotification("error", languageState.texts.Errors.DifferentPassword);

    setLoading(false);
  };

  const validate = () => setOk(true);

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    const { user, password, rpassword } = getValues();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            user
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return showNotification("error", message);
        }
        default:
          return showNotification(
            "error",
            languageState.texts.Errors.TermsNdConditionRequired
          );
      }
    }
  };

  useEffect(() => {
    if (!userLogged()) navigate("/");
    setRouteState({
      type: "set",
      to: 6,
      page: languageState.texts.Links[6].label,
    });
    scrollTo();
    retry();
  }, []);

  const updateUserPhoto = async (photo, blob) => {
    try {
      const response = await userChangePhoto(photo, blob);
      if (response.status === 200)
        showNotification(
          "success",
          languageState.texts.Messages.PhotoSavedSuccessful
        );
      else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
      else showNotification("error", languageState.texts.Errors.SomeWrong);
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
  };

  const [showProfilePhoto, setShowProfilePhoto] = useState();

  const saveImage = (src, file) => {
    console.log(/* src, */ file);
    setUserRPhoto(src);
    setShowProfilePhoto(false);
    updateUserPhoto(file, src);
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <ProfilePhoto
          open={showProfilePhoto}
          handleClose={() => setShowProfilePhoto(false)}
          onSubmit={saveImage}
        />
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[5].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul
                className={css({ "li::after": { display: "none !important" } })}
              >
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRight />
                <li>
                  <Link to="/profile">
                    {languageState.texts.Links[5].label}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>

      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer
              ignoreDefault
              className="col-lg-4 sm-margin-30px-bottom"
            >
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-tb padding-30px-lr border-radius-3"
              >
                <SitoContainer
                  ignoreDefault
                  className="text-center margin-30px-bottom"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { md: "229px", xs: "300px" },
                      height: { md: "229px", xs: "300px" },
                      position: "relative",
                    }}
                  >
                    <SitoImage
                      id="profile-photo"
                      src={userRPhoto}
                      alt=""
                      sx={{
                        background: "#222",
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "50%",
                      }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        position: "absolute",
                        bottom: "12px",
                        right: "15px",
                        borderRadius: "100%",
                        minWidth: 0,
                        width: "40px",
                        height: "40px",
                        minHeight: 0,
                      }}
                      onClick={() => setShowProfilePhoto(true)}
                    >
                      <CameraAlt />
                    </Button>
                  </Box>
                  <h4 className="font-size19 no-margin-bottom">
                    {userData.nombre}
                  </h4>
                  <span className="font-size16">{userData.apellidos}</span>
                  <span className="font-size16">{`${type[0]?.toUpperCase()}${type
                    .substring(1)
                    .toLowerCase()}`}</span>
                </SitoContainer>

                <Sidebar currentLink={0} role={role} />
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-8">
              <SitoContainer
                ignoreDefault
                className="padding-20px-left sm-no-padding-left"
              >
                <SitoContainer
                  ignoreDefault
                  className="theme-shadow padding-40px-all sm-padding-30px-all xs-padding-20px-all border-radius-3"
                >
                  <h4 className="font-size24">
                    {languageState.texts.Links[5].label}
                  </h4>
                  <form
                    className="margin-20px-bottom"
                    onSubmit={handleSubmit(localSaveProfile)}
                  >
                    {languageState.texts.Profile.inputs
                      .filter((item) => !item.role || item.role === role)
                      .map((item) => (
                        <SitoContainer
                          ignoreDefault
                          key={item.id}
                          className="col-md-12"
                        >
                          <SitoContainer ignoreDefault className="form-group">
                            <label htmlFor={item.id}>{`${item.label}`}</label>
                            {item.id !== "phone" ? (
                              <input
                                placeholder={item.placeholder}
                                type={item.type}
                                name={item.id}
                                id={item.id}
                                maxLength={item.maxLength}
                                required={item.required}
                                disabled={item.disabled}
                                onInput={validate}
                                onInvalid={invalidate}
                                className={invalids[item.id] ? "error" : ""}
                                {...register(item.id)}
                              />
                            ) : (
                              <SitoContainer>
                                <Autocomplete
                                  sx={{
                                    width: "180px",
                                    label: { marginTop: "-3px" },
                                    div: {
                                      height: "50px",
                                    },
                                  }}
                                  value={country}
                                  onChange={(event, newValue) => {
                                    setCountry(newValue);
                                  }}
                                  options={languageState.texts.Countries}
                                  autoHighlight
                                  getOptionLabel={(option) => option.phone}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                      />
                                      ({option.code}) {option.phone}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <>
                                      {languageState.texts.Countries.filter(
                                        (jtem) =>
                                          jtem.phone === params.inputProps.value
                                      ).length > 0 && (
                                        <img
                                          className={css({
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(55%)",
                                            objectFit: "contain",
                                            left: "5%",
                                            width: "20px",
                                            height: "15px",
                                          })}
                                          loading="lazy"
                                          src={`https://flagcdn.com/w20/${languageState.texts.Countries.filter(
                                            (jtem) =>
                                              jtem.phone ===
                                              params.inputProps.value
                                          )[0].code.toLowerCase()}.png`}
                                          srcSet={`https://flagcdn.com/w40/${languageState.texts.Countries.filter(
                                            (jtem) =>
                                              jtem.phone ===
                                              params.inputProps.value
                                          )[0].code.toLowerCase()}.png 2x`}
                                          alt=""
                                        />
                                      )}
                                      <TextField
                                        {...params}
                                        label={
                                          languageState.texts.Labels.Country
                                        }
                                        inputProps={{
                                          ...params.inputProps,
                                          className: css({
                                            padding:
                                              "4px 0 5px 30px !important",
                                          }),
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                        id="phone-country"
                                      />
                                    </>
                                  )}
                                />
                                <input
                                  placeholder={item.placeholder}
                                  type={item.type}
                                  name={item.id}
                                  id={item.id}
                                  maxLength={item.maxLength}
                                  required={item.required}
                                  disabled={item.disabled}
                                  onInput={validate}
                                  onInvalid={invalidate}
                                  className={`${
                                    invalids[item.id] ? "error" : ""
                                  } ${css({
                                    width: "62% !important",
                                    flex: "1 !important",
                                  })}`}
                                  {...register(item.id)}
                                />
                              </SitoContainer>
                            )}
                          </SitoContainer>
                        </SitoContainer>
                      ))}
                    {/* {role === RoleEnum.INMOBILIARIA && (
                      <SitoContainer ignoreDefault className="row">
                        <SitoContainer ignoreDefault className="col-md-12">
                          <SitoContainer ignoreDefault className="form-group">
                            <label htmlFor="colleges">
                              {languageState.texts.Dashboard.College.Select}
                            </label>
                            <select
                              className="form-control"
                              id="colleges"
                              value={collegeSelected}
                              onChange={handleChange}
                              disabled={!colleges.length}
                            >
                              {colleges.map((jtem) => (
                                <option key={jtem.id} value={jtem.id}>
                                  {jtem.nombre}
                                </option>
                              ))}
                            </select>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                    )} */}
                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.Buttons.Save}
                      </button>
                    </SitoContainer>
                  </form>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Me;
