/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { Box, Button, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// @mui/icons-material
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

// sito components
import SitoContainer from "sito-container";

// utils
import { scrollTo } from "utils/functions";

// services
import { report } from "services/analytics/analytics";

// components
import Loader from "components/Loader/Loader";

// contexts
import { useNotification } from "context/NotificationProvider";
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

const Main = () => {
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [countDeleted, setCountDeleted] = useState(0);
  const [lastMonthDeleted, setLastMonthDeleted] = useState(0);

  const [countUsers, setCountUsers] = useState(0);
  const [lastMonthUsers, setLastMonthUsers] = useState(0);

  const [countMatches, setCountMatches] = useState(0);
  const [lastMonthMatches, setLastMonthMatches] = useState(0);

  const [countProperties, setCountProperties] = useState(0);
  const [lastMonthProperties, setLastMonthProperties] = useState(0);

  const [loading, setLoading] = useState(true);

  const [plans, setPlans] = useState({});

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const thisDate = new Date();
  const [startDate, setStartDate] = useState(
    dayjs(
      `${thisDate.getFullYear()}-${thisDate.getMonth() + 1}-${
        thisDate.getDate() - (thisDate.getDate() - 1)
      }T${thisDate.getHours()}:${thisDate.getMinutes()}:${thisDate.getSeconds()}`
    )
  );

  const handleStartDate = (newValue) => setStartDate(newValue);

  const [endDate, setEndDate] = useState(
    dayjs(
      `${thisDate.getFullYear()}-${
        thisDate.getMonth() + 1
      }-${thisDate.getDate()}T${thisDate.getHours()}:${thisDate.getMinutes()}:${thisDate.getSeconds()}`
    )
  );

  const handleEndDate = (newValue) => setEndDate(newValue);

  /**
   *
   * @param {date} date
   * @returns
   */
  const parseDateYMD = (date) =>
    `${date.year()}-${date.month() + 1 < 10 ? `0${date.month() + 1}` : date.month() + 1}-${
      date.date() < 10 ? `0${date.date()}` : date.date()
    }`;

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const response = await report(parseDateYMD(startDate), parseDateYMD(endDate));
      if (response.status === 200) {
        const {
          cant_delete_users,
          cant_new_matches,
          cant_new_properties,
          cant_new_users,
          cant_users_per_plan,
        } = response.data;
        setCountDeleted(cant_delete_users);
        setCountProperties(cant_new_properties);
        setCountUsers(cant_new_users);
        setCountMatches(cant_new_matches);
        setPlans(cant_users_per_plan);
      }
      // last month
      const beforeStartDate = dayjs(
        `${startDate.year()}-${startDate.month()}-${
          startDate.date() - (startDate.date() - 1)
        }T${startDate.hour()}:${startDate.minute()}:${startDate.second()}`
      );
      beforeStartDate.set("month", beforeStartDate.month - 1);
      const responseLast = await report(parseDateYMD(beforeStartDate), parseDateYMD(startDate));
      if (responseLast.status === 200) {
        const { cant_delete_users, cant_new_matches, cant_new_properties, cant_new_users } =
          responseLast.data;
        setLastMonthDeleted(cant_delete_users);
        setLastMonthProperties(cant_new_properties);
        setLastMonthUsers(cant_new_users);
        setLastMonthMatches(cant_new_matches);
      }
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
    setLoading(false);
  }, [startDate, endDate]);

  useEffect(() => {
    setRouteState({
      type: "set",
      to: 1,
      page: languageState.texts.Dashboard.Sidebar.Links[1].label,
    });
    scrollTo();
    fetch();
  }, []);

  const [width, setWidth] = useState("300px");

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [width]);

  useEffect(() => {
    const ctx = document.getElementById("myChart");
    const parent = document.getElementById("parent");
    const handleResize = () => {
      if (ctx !== null) setWidth(`${parent.offsetWidth}px`);
    };
    if (parent !== null) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (parent !== null) window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    const ctx = document.getElementById("myChart");
    if (ctx !== null) {
      new Chart(ctx, {
        type: "pie",
        data: {
          hoverOffset: 4,
          // labels: Object.keys(plans),
          datasets: [
            {
              backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
              label: languageState.texts.Dashboard.Analytics.Plans,
              data: Object.values(plans),
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [loading]);

  return (
    <main className="dashboard-content">
      <SitoContainer ignoreDefault className="dashboard-container-fluid dashboard-p-0">
        <h1 className="dashboard-h3 dashboard-mb-3">
          {languageState.texts.Dashboard.Analytics.Title.Light}{" "}
          <strong>{languageState.texts.Dashboard.Analytics.Title.Bold}</strong>
        </h1>

        <SitoContainer ignoreDefault className="dashboard-row">
          <Box sx={{ width: "100%" }}>
            {/* dates */}
            <Box
              className="first-child-margin"
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                width: "100%",
                marginTop: "20px",
                div: { flex: 1 },
                marginBottom: "20px",
              }}
            >
              {/* start date */}
              <DateTimePicker
                id="start-date"
                label={languageState.texts.Dashboard.Analytics.Inputs.StartDate.Label}
                value={startDate}
                onChange={handleStartDate}
                renderInput={(params) => <TextField {...params} />}
                required
                variant="outlined"
                className="margin-right-20"
              />
              {/* end date */}
              <DateTimePicker
                id="end-date"
                label={languageState.texts.Dashboard.Analytics.Inputs.EndDate.Label}
                value={endDate}
                onChange={handleEndDate}
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: "100%", marginTop: "20px" }}
                required
                variant="outlined"
              />
              <Button onClick={fetch} variant="contained">
                {languageState.texts.Buttons.Apply}
              </Button>
            </Box>
            <SitoContainer ignoreDefault className="dashboard-row">
              <SitoContainer ignoreDefault className="dashboard-col-sm-6">
                <SitoContainer ignoreDefault className="dashboard-card">
                  <SitoContainer ignoreDefault className="dashboard-card-body">
                    <SitoContainer ignoreDefault className="dashboard-row">
                      <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                        <h5 className="dashboard-card-title">
                          {languageState.texts.Dashboard.Analytics.NewUsers}
                        </h5>
                      </SitoContainer>

                      <SitoContainer ignoreDefault className="dashboard-col-auto">
                        <SitoContainer
                          ignoreDefault
                          className="dashboard-stat dashboard-text-primary"
                        >
                          <i className="dashboard-align-middle" data-feather="truck">
                            <PersonAddAlt1Icon
                              sx={{ width: "35px !important", height: "35px !important" }}
                            />
                          </i>
                        </SitoContainer>
                      </SitoContainer>
                    </SitoContainer>
                    <h1 className="dashboard-mt-1 dashboard-mb-3">{countUsers}</h1>
                    <SitoContainer ignoreDefault className="dashboard-mb-0">
                      <Typography
                        variant="span"
                        sx={{
                          color:
                            countUsers < lastMonthUsers ? "red !important" : "green !important",
                        }}
                      >
                        {countUsers < lastMonthUsers ? "" : "+"}
                        {countUsers - lastMonthUsers}
                      </Typography>
                      <span className="dashboard-text-muted">
                        {" "}
                        {languageState.texts.Dashboard.Analytics.LastMonth}
                      </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
                <SitoContainer ignoreDefault className="dashboard-card">
                  <SitoContainer ignoreDefault className="dashboard-card-body">
                    <SitoContainer ignoreDefault className="dashboard-row">
                      <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                        <h5 className="dashboard-card-title">
                          {languageState.texts.Dashboard.Analytics.NewProperties}
                        </h5>
                      </SitoContainer>

                      <SitoContainer ignoreDefault className="dashboard-col-auto">
                        <SitoContainer
                          ignoreDefault
                          className="dashboard-stat dashboard-text-primary"
                        >
                          <i className="dashboard-align-middle" data-feather="users">
                            <HomeIcon
                              sx={{ width: "35px !important", height: "35px !important" }}
                            />
                          </i>
                        </SitoContainer>
                      </SitoContainer>
                    </SitoContainer>
                    <h1 className="dashboard-mt-1 dashboard-mb-3">{countProperties}</h1>
                    <SitoContainer ignoreDefault className="dashboard-mb-0">
                      <Typography
                        variant="span"
                        sx={{
                          color:
                            countProperties < lastMonthProperties
                              ? "red !important"
                              : "green !important",
                        }}
                      >
                        {countProperties < lastMonthProperties ? "" : "+"}
                        {countProperties - lastMonthProperties}
                      </Typography>
                      <span className="dashboard-text-muted">
                        {" "}
                        {languageState.texts.Dashboard.Analytics.LastMonth}
                      </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-col-sm-6">
                <SitoContainer ignoreDefault className="dashboard-card">
                  <SitoContainer ignoreDefault className="dashboard-card-body">
                    <SitoContainer ignoreDefault className="dashboard-row">
                      <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                        <h5 className="dashboard-card-title">
                          {languageState.texts.Dashboard.Analytics.NewMatches}
                        </h5>
                      </SitoContainer>

                      <SitoContainer ignoreDefault className="dashboard-col-auto">
                        <SitoContainer
                          ignoreDefault
                          className="dashboard-stat dashboard-text-primary"
                        >
                          <i className="dashboard-align-middle" data-feather="dollar-sign">
                            <FavoriteIcon
                              sx={{ width: "35px !important", height: "35px !important" }}
                            />
                          </i>
                        </SitoContainer>
                      </SitoContainer>
                    </SitoContainer>
                    <h1 className="dashboard-mt-1 dashboard-mb-3">{countMatches}</h1>
                    <SitoContainer ignoreDefault className="dashboard-mb-0">
                      <Typography
                        variant="span"
                        sx={{
                          color:
                            countMatches < lastMonthMatches ? "red !important" : "green !important",
                        }}
                      >
                        {countMatches < lastMonthMatches ? "" : "+"}
                        {countMatches - lastMonthMatches}
                      </Typography>
                      <span className="dashboard-text-muted">
                        {" "}
                        {languageState.texts.Dashboard.Analytics.LastMonth}
                      </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
                <SitoContainer ignoreDefault className="dashboard-card">
                  <SitoContainer ignoreDefault className="dashboard-card-body">
                    <SitoContainer ignoreDefault className="dashboard-row">
                      <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                        <h5 className="dashboard-card-title">
                          {languageState.texts.Dashboard.Analytics.DeletedUsers}
                        </h5>
                      </SitoContainer>

                      <SitoContainer ignoreDefault className="dashboard-col-auto">
                        <SitoContainer
                          ignoreDefault
                          className="dashboard-stat dashboard-text-primary"
                        >
                          <i className="dashboard-align-middle" data-feather="shopping-cart">
                            <PersonRemoveIcon
                              sx={{ width: "35px !important", height: "35px !important" }}
                            />
                          </i>
                        </SitoContainer>
                      </SitoContainer>
                    </SitoContainer>
                    <h1 className="dashboard-mt-1 dashboard-mb-3">{countDeleted}</h1>
                    <SitoContainer ignoreDefault className="dashboard-mb-0">
                      <Typography
                        variant="span"
                        sx={{
                          color:
                            countDeleted < lastMonthDeleted ? "red !important" : "green !important",
                        }}
                      >
                        {countDeleted < lastMonthDeleted ? "" : "+"}
                        {countDeleted - lastMonthDeleted}
                      </Typography>
                      <span className="dashboard-text-muted">
                        {" "}
                        {languageState.texts.Dashboard.Analytics.LastMonth}
                      </span>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </Box>
        </SitoContainer>

        <SitoContainer ignoreDefault className="dashboard-row">
          <SitoContainer
            ignoreDefault
            className="dashboard-card dashboard-flex-fill dashboard-w-100"
          >
            <SitoContainer ignoreDefault className="dashboard-card-header">
              <h5 className="dashboard-card-title dashboard-mb-0">
                {languageState.texts.Dashboard.Analytics.Plans}
              </h5>
            </SitoContainer>
            <SitoContainer ignoreDefault className="dashboard-card-body dashboard-d-flex">
              <SitoContainer
                ignoreDefault
                className="dashboard-align-self-center dashboard-w-100"
                sx={{ flexDirection: "row !important" }}
              >
                <SitoContainer ignoreDefault className="dashboard-py-3">
                  <SitoContainer
                    ignoreDefault
                    className="dashboard-chart dashboard-chart-xs"
                    id="parent"
                    sx={{ height: "300px" }}
                  >
                    {!loading ? (
                      <canvas
                        className={css({ width: "100%", height: "300px" })}
                        id="myChart"
                      ></canvas>
                    ) : (
                      <Loader minimal />
                    )}
                  </SitoContainer>
                </SitoContainer>
                <Box>
                  {Object.keys(plans).map((item) => (
                    <Box display="flex" justifyContent="space-between" key={item}>
                      <Box>{item}</Box>
                      <Box className="dashboard-text-end">{plans[item]}</Box>
                    </Box>
                  ))}
                </Box>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </main>
  );
};

export default Main;
