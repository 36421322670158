// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";
import { RestaurantMenuSharp } from "@mui/icons-material";

/**
 * Takes a search object, sends it to the API, and returns the response
 * @param {object} search - The search object that contains the search's information.
 * @returns The data from the response.
 */
export const createSearch = async (search) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/busca/create-masivo`,
    { ...search },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Takes a search object, sends it to the API, and returns the response
 * @param {object} search - The search object that contains the search's information.
 * @returns The data from the response.
 */
export const createSearchDivision = async (search) => {
  // @ts-ignore
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/busca-localidad`,
    { ...search },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  return response
};

/**
 * Takes a search object, sends it to the API, and returns the response
 * @param {object} search - The search object that contains the search's information.
 * @returns The data from the response.
 */
export const createSearchTypes = async (search) => {
    // @ts-ignore
    const response = await axios.post(
        // @ts-ignore
        `${config.apiUrl}/busca-tipo-propiedad`,
        { ...search },
        {
            // @ts-ignore
            headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
        }
    );
    return response
};

/**
 * Sends a POST request to the API with the search's ID and the ID of the search to delete
 * @param {object} searchId - The search id of the search you want to delete.
 * @returns The data is being returned.
 */
export const deleteSearch = async (searchId) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/busca/delete-masivo`,
    { ...searchId },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  return response
};

/**
 * Sends a POST request to the API with the search's ID and the ID of the search to delete
 * @param {object} searchId - The search id of the search you want to delete.
 * @returns The data is being returned.
 */
export const deleteSearchDivision = async (searchId) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/busca-localidad/delete-masivo`,
    { ...searchId },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  return response
};

/**
 * Sends a POST request to the API with the search's ID and the ID of the search to delete
 * @param {object} searchId - The search id of the search you want to delete.
 * @returns The data is being returned.
 */
export const deleteSearchTypes = async (searchId) => {
  const response = await axios.post(
      // @ts-ignore
      `${config.apiUrl}/busca-tipo-propiedad/delete-masivo`,
      { ...searchId },
      {
        // @ts-ignore
        headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
      }
  );
  return response
};


/**
 * Takes a search object, sends it to the API, and returns the response
 * @param {object} search - The search object that contains the search's information.
 * @returns The data from the response.
 */
export const modifySearch = async (search) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/busca/update-masivo`,
    { ...search },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  return response
};
