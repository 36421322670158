// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Takes a college object, sends it to the API, and returns the response
 * @param {object} college - The college object that contains the college's information.
 * @returns The data from the response.
 */
export const createCollege = async (college) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/colegio`,
    { ...college },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the college's ID and the ID of the college to delete
 * @param {string} collegeId - The college id of the college you want to delete.
 * @returns The data is being returned.
 */
export const deleteCollege = async (collegeId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/colegio/${collegeId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the college's ID and college data to modify it
 * @param {string} collegeId - The college id of the college you want to delete.
 * @param {object} collegeData - College data: name
 * @returns The data is being returned.
 */
export const modifyCollege = async (collegeId, collegeData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/colegio/${collegeId}`,
    { ...collegeData },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
