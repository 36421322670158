/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/function-component-definition */
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useReducer } from "react";

// images
/* import CasasCrab from "img/reals/Carousel/Casas CRAB.png";
import Locales from "img/reals/Carousel/Locales CRAB.jpg";
import LotesCrab from "img/reals/Carousel/Lotes-CRAB.jpg";
import Oficinas from "img/reals/Carousel/Oficinas 2 CRAB.jpg";
import ImagenDrom from "img/reals/Carousel/Imagen Dron 1 El Canton .JPG";
 */
import logo from "img/reals/logos/apple-touch-icon-114x114.png";

// @emotion
import { css } from "@emotion/css";

// contexts
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// @mui icons
import EmailIcon from "@mui/icons-material/Email";
import ImageIcon from "@mui/icons-material/Image";
import VrpanoIcon from "@mui/icons-material/Vrpano";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

// @mui components
import { useTheme, Box, Tooltip, Button, IconButton } from "@mui/material";

// own components
import Map from "components/Map/Map";
import Loader from "components/Loader/Loader";
import Carousel from "components/Carousel/Carousel";
import TabViewIcon from "components/MUI/TabView/TabViewIcon";
import EmailDialog from "components/MUI/EmailDialog/EmailDialog";
import WhatsappDialog from "components/MUI/WhatsappDialog/WhatsappDialog";

// layouts
import Section from "layouts/Section/Section";

// sito components
import SitoImage from "sito-image";
import SitoContainer from "sito-container";

// services
import { locationList } from "services/geolocation/location/get";
import { propertyList, propertyService } from "services/properties/get";
import { downloadPropertyAsPDF } from "services/properties/post";
import { propertyTypeList } from "services/properties/types/get";

import { sendEmail } from "services/email/post";

// utils
import { scrollTo, apiTokenCertified } from "utils/functions";
import createPdf from "utils/pdf";

// styles
import "./style.css";

const Details = () => {
  //  const images = [CasasCrab, ImagenDrom, Locales, LotesCrab, Oficinas];

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [whatsappDialog, setWhatsappDialog] = useState(false);
  const onCloseWhatsappDialog = () => setWhatsappDialog(false);

  const [emailDialog, setEmailDialog] = useState(false);
  const onCloseEmailDialog = () => setEmailDialog(false);

  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState({});

  const [propertyType, setPropertyType] = useState("");

  const fetchPropertyType = async (propertyTypeId) => {
    try {
      const propertyTypePromise = await propertyTypeList(
        0,
        1,
        `filtros[id]=${propertyTypeId}`
      );
      if (propertyTypePromise.status === 200) {
        const propertyTypeData = await propertyTypePromise.data.data;
        if (propertyTypeData.length)
          setPropertyType(propertyTypeData[0].nombre);
        else
          showNotification(
            "error",
            languageState.texts.Errors.LoadingPropertyType
          );
      } else if (
        propertyTypePromise.error &&
        !apiTokenCertified(propertyTypePromise.error)
      )
        navigate("/login");
    } catch (err) {
      showNotification("error", languageState.texts.Errors.LoadingPropertyType);
    }
  };

  const [title, setTitle] = useState("");
  const [garages, setGarages] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [pesosValue, setPesosValue] = useState(0);
  const [deckSurface, setDeckSurface] = useState(0);
  const [description, setDescription] = useState("");
  const [environments, setEnvironments] = useState(0);
  const [totalSurface, setTotalSurface] = useState(0);
  const [characteristics, setCharacteristics] = useState([]);

  const fetchCharacteristics = async (localCharacteristics) => {
    try {
      let i = 0;
      const toCharacteristics = [];
      localCharacteristics.forEach((item) => {
        const idCharacteristic = item.caracteristica_id;
        switch (idCharacteristic) {
          case 73: // title
            setTitle(item.valor);
            break;
          case 74: // environments
            setEnvironments(item.valor);
            break;
          case 75: // bedrooms
            setBedrooms(item.valor);
            break;
          case 76: // bathrooms
            setBathrooms(item.valor);
            break;
          case 77: // garages
            setGarages(item.valor);
            break;
          case 78: // usd_value
            setUsdValue(item.valor);
            break;
          case 79: // pesos_value
            setPesosValue(item.valor);
            break;
          case 80: // totalSurface
            setTotalSurface(item.valor);
            break;
          case 81: // deckSurface
            setDeckSurface(item.valor);
            break;
          case 82: // description
            setDescription(item.valor);
            break;
          default: {
            if (i === 0) {
              toCharacteristics.push({ left: item.caracteristica.nombre });
              i += 1;
            } else if (i === 1) {
              toCharacteristics[toCharacteristics.length - 1].right =
                item.caracteristica.nombre;
              i = 0;
            }
            break;
          }
        }
      });
      setCharacteristics(toCharacteristics);
    } catch (err) {
      console.log(err);
    }
  };

  const [user, setUser] = useState({});

  const videoReducer = (videoState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { value, content } = action;
        return {
          value,
          content,
        };
      }
      case "remove":
      default:
        return {
          value: "",
          content: "",
        };
    }
  };

  const [loadingVideo, setLoadingVideo] = useState(false);

  useEffect(() => {
    if (loadingVideo)
      setTimeout(() => {
        setLoadingVideo(false);
      }, 500);
  }, [loadingVideo]);

  const [videoIds, setVideoIds] = useReducer(videoReducer, {});

  const video360Reducer = (video360State, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { value, content } = action;
        return {
          value,
          content,
        };
      }
      case "remove":
      default:
        return {
          value: "",
          content: "",
        };
    }
  };

  const [loadingVideo360, setLoadingVideo360] = useState(false);

  useEffect(() => {
    if (loadingVideo360)
      setTimeout(() => {
        setLoadingVideo360(false);
      }, 500);
  }, [loadingVideo360]);

  const [video360Ids, setVideo360Ids] = useReducer(video360Reducer, {});

  const imageReducer = (imageState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newImageState = { ...imageState };
        const { value, content } = action;
        newImageState[value] = { value, content };
        return { ...newImageState };
      }
      case "remove": {
        const newImageState = { ...imageState };
        const { value } = action;
        delete newImageState[value];
        return { ...newImageState };
      }
      case "clean":
      default:
        return {};
    }
  };

  const [loadingPhotos, setLoadingPhotos] = useState(false);

  useEffect(() => {
    if (loadingPhotos) {
      setTimeout(() => {
        setLoadingPhotos(false);
      }, 500);
    }
  }, [loadingPhotos]);

  const [imageIds, setImageIds] = useReducer(imageReducer, {});

  const flatReducer = (flatState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newFlatState = { ...flatState };
        const { value, content } = action;
        newFlatState[value] = { value, content };
        return { ...newFlatState };
      }
      case "remove": {
        const newFlatState = { ...flatState };
        const { value } = action;
        delete newFlatState[value];
        return { ...newFlatState };
      }
      case "clean":
      default:
        return {};
    }
  };

  const [loadingFlats, setLoadingFlats] = useState(false);

  useEffect(() => {
    if (loadingFlats)
      setTimeout(() => {
        setLoadingFlats(false);
      }, 500);
  }, [loadingFlats]);

  const [flatIds, setFlatIds] = useReducer(flatReducer, {});

  const fetchMultimedia = async (multimedia) => {
    if (multimedia !== null) {
      multimedia.forEach((item) => {
        if (item.tipo === "IMAGEN")
          setImageIds({
            type: "add",
            value: `photos[!]${item.nombre}`,
            content: item.url,
          });
        else if (item.tipo === "PLANO")
          setFlatIds({
            type: "add",
            value: `flats[!]${item.nombre}`,
            content: item.url,
          });
        else if (item.tipo === "VIDEO")
          setVideoIds({
            type: "set",
            value: `video[!]${item.nombre}`,
            content: item.url,
          });
        else if (item.tipo === "RECORRIDO360")
          setVideo360Ids({
            type: "set",
            value: `video[!]${item.nombre}`,
            content: item.url,
          });
      });
      setLoadingPhotos(true);
      setLoadingFlats(true);
      setLoadingVideo(true);
      setLoadingVideo360(true);
    }
  };

  const [locationData, setLocationData] = useState({});

  // geolocation
  const fetchGeolocation = async (locationId) => {
    const locationPromise = await locationList(
      0,
      1,
      `filtros[id]=${locationId}`
    );
    if (locationPromise.status === 200) {
      const { data } = await locationPromise.data;
      if (data.length) {
        const [theLocation] = data;
        setLocationData(theLocation);
      }
    } else
      showNotification("error", languageState.texts.Errors.LoadingLocations);
  };

  const [id, setId] = useState(0);

  useEffect(() => {
    scrollTo();
  }, []);

  const fetch = async () => {
    setLoading(true);
    if (id) {
      try {
        const response = await propertyService(
          id,
          `opciones[datosInmobiliaria]=true&opciones[multimedias]=true`
        );

        if (response.status === 200) {
          const data = await response.data;
          console.log(data);
          setProperty(data);
          await fetchGeolocation(data.localidad_id);
          await fetchCharacteristics(data.inmuebleCaracteristicas);
          await fetchPropertyType(data.tipo_propiedad_id);
          const {
            apellidos,
            email,
            imagen,
            nombre,
            telefono,
            urlPhotoProfile,
          } = data.datosInmobiliaria;
          setUser({
            apellidos,
            email,
            imagen,
            nombre,
            telefono,
            urlPhotoProfile,
          });
          fetchMultimedia(data.multimedias);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (id) fetch();
  }, [id]);

  useEffect(() => {
    scrollTo(0);
    setRouteState({ type: "noStick" });
    const queryParams = location.search.substring(1);
    const [param, value] = queryParams.split("=");
    if (param === "id" && value) setId(Number(value));
  }, []);

  const [tab, setTab] = useState(0);

  const handleChange = (e, newTab) => setTab(newTab);

  const pLeft = css({
    flex: 1,
    borderBottom: "solid #dee2e6 1px",
    marginRight: "20px",
  });

  const pRight = css({ flex: 1, borderBottom: "solid #dee2e6 1px" });

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  const [text /* setText */] = useState("old boring text");
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  /*  const handleAfterPrint = useCallback(() => {
    // setDownloadingPDF(false);
    setLoading(false);
  }, []); */

  /* const handleBeforePrint = useCallback(() => {}, []);

  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);
    setDownloadingPDF(true);
    setText("Loading new text...");
    console.log("ms", Object.values(imageIds).length * 1000);

    return new Promise((resolve) => {
      setTimeout(() => {
        onBeforeGetContentResolve.current = resolve;
        // setDownloadingPDF(false);
        setText("New, Updated Text!");
        resolve();
      }, Object.values(imageIds).length * 1000);
    });
  }, [setLoading, setText, imageIds, setImageIds]); */

  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  /* const reactToPrintContent = useCallback(() => componentRef.current, [componentRef.current]); */

  const [canDownload /* setCanDownload */] = useState(true);

  /* const loadedReducer = (loadedState, action) => {
    const { type } = action;
    switch (type) {
      case "loaded": {
        const { index } = action;
        console.log("index", index, Object.values(imageIds).length);
        return [...loadedState, index];
      }
      default:
        return [];
    }
  }; */

  /* const [loaded, setLoaded] = useReducer(loadedReducer, []); */

  /* const reactToPrintTrigger = useCallback(
    () => (
      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
      // to the root node of the returned component as it will be overwritten.

      // Bad: the `onClick` here will be overwritten by `react-to-print`
      // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

      // Good
      <Tooltip
        title={
          canDownload
            ? languageState.texts.Tooltips.DownloadPDF
            : languageState.texts.Tooltips.CantDownload
        }
      >
        <Button
          type="button"
          color="secondary"
          disabled={!canDownload}
          sx={{
            position: "absolute",
            borderRadius: "100%",
            minWidth: 0,
            minHeight: 0,
            padding: 0,
            zIndex: 10,
            width: "40px",
            height: "40px",
            top: { lg: "160px", md: "135px", xs: "165px" },
            left: { md: "45%", xs: "85%" },
          }}
          variant="contained"
        >
          <PictureAsPdfIcon />
        </Button>
      </Tooltip>
    ),
    [canDownload, setCanDownload]
  ); */

  const emailSend = async (subject, to, message) => {
    try {
      await sendEmail(to, subject, message);
      setEmailDialog(false);
    } catch (err) {
      console.error(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
  };

  const downloadPDF = async () => {
    try {
      const html = await createPdf([id], languageState.texts);
      console.log(html);
      if (html.body && html.css) {
        const response = await downloadPropertyAsPDF(html.body, html.css);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.style = "display: none";
        link.href = window.URL.createObjectURL(
          new Blob([response], { type: "application/pdf" })
        );
        console.log(link.href);
        link.target = "_blank";
        link.download = "file.pdf";
        link.click();
        // console.log(response);
      }
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
  };

  return (
    <Section
      id="to-print"
      ignoreDefault
      className="no-padding-bottom"
      sx={{ padding: 0 }}
    >
      <Loader visible={loading} />
      {/* <ReactToPrint
        content={reactToPrintContent}
        documentTitle="propiedad"
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        print={printResult}
        removeAfterPrint
        trigger={reactToPrintTrigger}
      /> */}
      {/* <Tooltip
        title={
          canDownload
            ? languageState.texts.Tooltips.DownloadPDF
            : languageState.texts.Tooltips.CantDownload
        }
      >
        <Button
          type="button"
          color="secondary"
          sx={{
            position: "absolute",
            borderRadius: "100%",
            minWidth: 0,
            minHeight: 0,
            padding: 0,
            zIndex: 10,
            width: "40px",
            height: "40px",
            top: { lg: "160px", md: "135px", xs: "165px" },
            left: { md: "45%", xs: "85%" },
          }}
          variant="contained"
          onClick={downloadPDF}
        >
          <PictureAsPdfIcon />
        </Button>
      </Tooltip> */}
      <Box ref={componentRef}>
        <Box
          id="somediv"
          sx={{
            background: theme.palette.primary.main,
            position: "relative",
          }}
        >
          <Box
            sx={{
              background: "white",
              position: "absolute",
              left: "50%",
              top: 0,
              width: "100%",
              height: "100%",
              display: { md: "flex", xs: "none" },
            }}
          ></Box>
          <Box
            className="container"
            sx={{
              display: "flex",
              paddingTop: { md: 0, xs: "30px" },
              marginTop: { lg: "-20px", xs: 0 },
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <Box
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{
                padding: "40px 0 40px 0",
                width: { md: "50%", xs: "100%" },
                margin: { xs: 0, md: "auto" },
                maxWidth: "100%",
              }}
              className="container"
            >
              <h2 className="text-white">{title}</h2>
              <span className="alt-font">{locationData.name}</span>
            </Box>
            <Box
              flexDirection="column"
              sx={{
                background: "white",
                width: "50%",
                padding: "40px 0 40px 40px",
                display: { md: "flex", xs: "none" },
                position: "relative",
              }}
            >
              <h2>{property.direccion}</h2>
              <span className="alt-font">
                {languageState.texts.Details.Top.Date}{" "}
                {property.fecha_publicacion &&
                  property.fecha_publicacion.split(" ")[0]}
              </span>
            </Box>
          </Box>
        </Box>
        <Box className="container">
          <Section sx={{ padding: "0", width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: downloadingPDF
                  ? "column"
                  : { xs: "column", md: "row" },
              }}
            >
              <SitoContainer
                flexDirection="column"
                sx={{ width: "400px", marginBottom: { md: 0, xs: "20px" } }}
              >
                <h4 className={css({ marginTop: "20px" })}>
                  {languageState.texts.Details.Contact.Title}
                </h4>
                <SitoContainer
                  alignItems="center"
                  sx={{ width: "100%", marginBottom: "20px" }}
                >
                  <SitoImage
                    sx={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    src={
                      user.urlPhotoProfile !== null
                        ? user.urlPhotoProfile
                        : logo
                    }
                    alt="user"
                  />
                  <h4
                    className={css({
                      fontSize: "24px !important",
                      marginLeft: "20px",
                    })}
                  >{`${user.nombre} ${user.apellidos}`}</h4>
                </SitoContainer>
                {user.telefono && (
                  <WhatsappDialog
                    number={user.telefono}
                    propertyData={[{ address: property.direccion }]}
                    open={whatsappDialog}
                    handleClose={onCloseWhatsappDialog}
                  />
                )}
                {user.telefono && (
                  <Button
                    onClick={() => setWhatsappDialog(true)}
                    color="secondary"
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      display: "flex",
                      marginBottom: "20px",
                    }}
                  >
                    <WhatsAppIcon sx={{ marginRight: "10px" }} />
                    {languageState.texts.Buttons.Whatsapp}
                  </Button>
                )}
                {user.email && (
                  <EmailDialog
                    email={user.email}
                    propertyData={[
                      {
                        title,
                        email: property.datosInmobiliaria.email,
                        address: property.direccion,
                      },
                    ]}
                    open={emailDialog}
                    handleClose={onCloseEmailDialog}
                    onSubmit={emailSend}
                  />
                )}
                {user.email && (
                  <Button
                    onClick={() => {
                      // setDownloadingPDF(true);
                      setEmailDialog(true);
                    }}
                    color="secondary"
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      display: "flex",
                      marginBottom: "20px",
                    }}
                  >
                    <EmailIcon sx={{ marginRight: "10px" }} />
                    {languageState.texts.Buttons.Email}
                  </Button>
                )}
              </SitoContainer>

              <TabViewIcon
                sx={{ flex: 1 }}
                value={tab}
                onChange={handleChange}
                tabsPosition="down"
                tabs={[
                  <Tooltip title={languageState.texts.Tooltips.Images}>
                    <IconButton>
                      <ImageIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title={languageState.texts.Tooltips.Planes}>
                    <IconButton>
                      <BorderAllIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title={languageState.texts.Tooltips.Videos}>
                    <IconButton>
                      <OndemandVideoIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title={languageState.texts.Tooltips.Panoramic}>
                    <IconButton>
                      <VrpanoIcon />
                    </IconButton>
                  </Tooltip>,
                ]}
                content={[
                  <Box
                    sx={{
                      minHeight: "400px",
                      width: "100%",
                      height: "400px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {loadingPhotos ? (
                      <SitoContainer
                        sx={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <Loader visible minimal />
                      </SitoContainer>
                    ) : (
                      <Carousel
                        height="100%"
                        width="100%"
                        content={Object.values(imageIds).map((item, i) => (
                          <SitoContainer
                            ignoreDefault
                            key={i}
                            className="item bg-img cover-background"
                            background={`url("${item.content}")`}
                            sx={{ height: "100%", width: "100% !important" }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="container h-100"
                              sx={{ height: "100%", width: "100% !important" }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="display-table h-100"
                                sx={{
                                  height: "100%",
                                  width: "100% !important",
                                }}
                              >
                                <SitoContainer
                                  ignoreDefault
                                  className="display-table-cell vertical-align-middle caption"
                                  sx={{
                                    height: "100%",
                                    width: "100% !important",
                                  }}
                                ></SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                        ))}
                        id="imageIds"
                        arrows
                        indicators
                      />
                    )}
                  </Box>,
                  <Box
                    sx={{
                      minHeight: "400px",
                      width: "100%",
                      height: "400px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {loadingFlats ? (
                      <SitoContainer
                        sx={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <Loader visible minimal />
                      </SitoContainer>
                    ) : (
                      <Carousel
                        height="100%"
                        width="100%"
                        content={Object.values(flatIds).map((item) => (
                          <SitoContainer
                            ignoreDefault
                            key={item.value}
                            className="item bg-img cover-background"
                            extraProps={{ "data-overlay-dark": "6" }}
                            background={`url("${item.content}")`}
                            sx={{ height: "100%", width: "100% !important" }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="container h-100"
                              sx={{ height: "100%", width: "100% !important" }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="display-table h-100"
                                sx={{
                                  height: "100%",
                                  width: "100% !important",
                                }}
                              >
                                <SitoContainer
                                  ignoreDefault
                                  className="display-table-cell vertical-align-middle caption"
                                  sx={{
                                    height: "100%",
                                    width: "100% !important",
                                  }}
                                ></SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                        ))}
                        id="flats"
                        arrows
                        indicators
                      />
                    )}
                  </Box>,
                  <Box
                    sx={{
                      minHeight: "400px",
                      width: "100%",
                      height: "400px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {loadingVideo ? (
                      <SitoContainer
                        sx={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <Loader visible minimal />
                      </SitoContainer>
                    ) : (
                      <SitoContainer
                        sx={{
                          marginRight: "10px",
                          marginTop: "10px",
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {Object.values(videoIds).map((item) => (
                          <video
                            key={item.value}
                            width="100%"
                            height="100%"
                            controls
                          >
                            <source src={item.content} />
                          </video>
                        ))}
                      </SitoContainer>
                    )}
                  </Box>,
                  <Box
                    sx={{
                      minHeight: "400px",
                      width: "100%",
                      height: "400px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {loadingVideo360 ? (
                      <SitoContainer
                        sx={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <Loader visible minimal />
                      </SitoContainer>
                    ) : (
                      <SitoContainer
                        sx={{
                          marginRight: "10px",
                          marginTop: "10px",
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {Object.values(video360Ids).map((item) => (
                          <video
                            key={item.value}
                            width="100%"
                            height="100%"
                            controls
                          >
                            <source src={item.content} />
                          </video>
                        ))}
                      </SitoContainer>
                    )}
                  </Box>,
                ]}
              />
            </Box>
          </Section>
          <Section sx={{ padding: "20px", marginTop: "20px" }}>
            <p>{description}</p>
          </Section>
          <Section sx={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              {/* Details */}
              <Box sx={{ flex: 1 }}>
                <h2>{languageState.texts.Details.Details.Title}</h2>
                <SitoContainer flexDirection="column" sx={{ width: "100%" }}>
                  {/* Type */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.Type}
                    </p>
                    <p className={pRight}>{propertyType}</p>
                  </SitoContainer>
                  {/* Environments */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.Environments}
                    </p>
                    <p className={pRight}>{environments}</p>
                  </SitoContainer>
                  {/* Baths */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.Baths}
                    </p>
                    <p className={pRight}>{bathrooms}</p>
                  </SitoContainer>
                  {/* Garages */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.Garages}
                    </p>
                    <p className={pRight}>{garages}</p>
                  </SitoContainer>
                  {/* Bedrooms */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.Bedrooms}
                    </p>
                    <p className={pRight}>{bedrooms}</p>
                  </SitoContainer>
                  {/* Covered area */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.CoveredArea}
                    </p>
                    <p className={pRight}>{deckSurface} m2</p>
                  </SitoContainer>
                  {/* Total area */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.TotalArea}
                    </p>
                    <p className={pRight}>{totalSurface} m2</p>
                  </SitoContainer>
                  {/* USD Value */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.USDValue}
                    </p>
                    <p className={pRight}>$ {usdValue}</p>
                  </SitoContainer>
                  {/* Pesos Value */}
                  <SitoContainer sx={{ width: "100%" }}>
                    <p className={pLeft}>
                      {languageState.texts.Details.Details.PesosValue}
                    </p>
                    <p className={pRight}>$ {pesosValue}</p>
                  </SitoContainer>
                </SitoContainer>
              </Box>
              {/* Characteristics */}
              <Box sx={{ flex: 1, marginLeft: { md: "20px", xs: 0 } }}>
                <h2>{languageState.texts.Details.Characteristics.Title}</h2>
                <SitoContainer flexDirection="column" sx={{ width: "100%" }}>
                  {characteristics.length > 0 ? (
                    characteristics.map((item) => (
                      <SitoContainer key={item.left} sx={{ width: "100%" }}>
                        <p className={pLeft}>{item.left}</p>
                        <p className={pRight}>{item.right}</p>
                      </SitoContainer>
                    ))
                  ) : (
                    <p>{languageState.texts.Details.Characteristics.Empty}</p>
                  )}
                </SitoContainer>
              </Box>
            </Box>
          </Section>
        </Box>
      </Box>
      {!downloadingPDF && (
        <Box className="container">
          {/* Map */}
          <Section sx={{ padding: "20px" }}>
            <h2>{languageState.texts.Details.Map.Title}</h2>
            <p>{locationData.full_location}</p>
            {property.latitud && property.longitud && (
              <Map
                point={`${property.latitud}, ${property.longitud}`}
                noButton
                lat={property.latitud}
                lng={property.longitud}
                noGeocoder
              />
            )}
          </Section>
        </Box>
      )}
    </Section>
  );
};

export default Details;
