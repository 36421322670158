/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useEffect, useState, useCallback } from "react";

// sito components
import SitoContainer from "sito-container";

// own components
import TabView from "components/MUI/TabView/TabView";

// utils
import { scrollTo } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// sections
import List from "./Sections/List/List";
import Insert from "./Sections/Insert/Insert";

const Main = () => {
  const { languageState } = useLanguage();
  const { setRouteState } = useRoute();

  const [tab, setTab] = useState(0);
  const [labelTabs, setLabelTabs] = useState(languageState.texts.Dashboard.Fertilizer.Tabs);
  const [toEdit, setToEdit] = useState(-1);

  const handleChange = (e, newTab) => setTab(newTab);

  const navigateToInsert = () => setTab(1);

  const navigateToModify = useCallback(
    (id) => {
      setTab(1);
      setToEdit(id);
      setLabelTabs(languageState.texts.Dashboard.Fertilizer.TabsModify);
    },
    [setToEdit, toEdit]
  );

  useEffect(() => {
    if (tab === 0) setLabelTabs(languageState.texts.Dashboard.Fertilizer.Tabs);
    setToEdit(0);
  }, [tab]);

  const tabs = [
    <List navigateToInsert={navigateToInsert} onEdit={navigateToModify} />,
    <Insert toEdit={toEdit} />,
  ];

  useEffect(() => {
    setRouteState({
      type: "set",
      to: 9,
    });
    scrollTo();
  }, []);

  return (
    <main className="dashboard-content">
      <SitoContainer ignoreDefault className="dashboard-container-fluid dashboard-p-0">
        <h1 className="dashboard-h3 dashboard-mb-3">
          {languageState.texts.Dashboard.CharacteristicGroup.Title.light}{" "}
          <strong>{languageState.texts.Dashboard.CharacteristicGroup.Title.strong}</strong>
        </h1>

        <SitoContainer ignoreDefault className="dashboard-row">
          <SitoContainer ignoreDefault className="dashboard-w-100 dashboard-d-flex">
            <SitoContainer ignoreDefault className="dashboard-w-100 dashboard-card">
              <TabView value={tab} onChange={handleChange} tabs={labelTabs} content={tabs} />
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </main>
  );
};

export default Main;
