import { numberArg } from "../../../utils/parser";

export const parseColumnsForMatches = (texts) => {
  const parsedColumns = [];
  parsedColumns.push({
    id: "count",
    width: 50,
    numeric: false,
    disablePadding: false,
    label: texts.Dashboard.TableAttributes.count,
    align: "left",
  });
  parsedColumns.push({
    id: "imagen",
    width: 70,
    numeric: false,
    disablePadding: false,
    align: "left",
    label: texts.Dashboard.TableAttributes.imagen,
  });
  parsedColumns.push({
    id: "id",
    width: "100%",
    numeric: false,
    disablePadding: false,
    label: texts.Dashboard.TableAttributes.id,
    align: "left",
  });
  parsedColumns.push({
    id: "titulo",
    width: 100,
    numeric: false,
    disablePadding: false,
    label: texts.Dashboard.TableAttributes.titulo,
    align: "right",
  });
  parsedColumns.push({
    id: "direccion",
    label: texts.Dashboard.TableAttributes.direccion,
    width: 100,
    disablePadding: false,
    numeric: texts.Dashboard.TableAttributeTypes.direccion === "number",
    align: "right",
  });
  parsedColumns.push({
    id: "valor",
    label: texts.Dashboard.TableAttributes.valor,
    width: 80,
    disablePadding: false,
    numeric: texts.Dashboard.TableAttributeTypes.valor === "number",
    align: "right",
  });
  parsedColumns.push({
    id: "dormitorios",
    label: texts.Dashboard.TableAttributes.dormitorios,
    extraLabel: texts.Dashboard.TableAttributes.banos,
    width: 90,
    disablePadding: false,
    numeric: texts.Dashboard.TableAttributeTypes.dormitorios === "number",
    align: "right",
  });
  parsedColumns.push({
    id: "superficie_cubierta",
    label: texts.Dashboard.TableAttributes.superficie_cubierta,
    extraLabel: texts.Dashboard.TableAttributes.superficie_total,
    width: 100,
    disablePadding: false,
    numeric:
      texts.Dashboard.TableAttributeTypes.superficie_cubierta === "number",
    align: "right",
  });
  /* parsedColumns.push({
    id: "superficie_total",
    label: texts.Dashboard.TableAttributes.superficie_total,
    extraLabel: texts.Dashboard.TableAttributes.superficie_total,
    width: 100,
    disablePadding: false,
    numeric: texts.Dashboard.TableAttributeTypes.superficie_total === "number",
    align: "right",
  }); */
  return parsedColumns;
};

export const parseColumns = (texts) => {
  const parsedColumns = [];
  parsedColumns.push({
    id: "imagen",
    width: 100,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.selectAll,
  });
  parsedColumns.push({
    id: "id",
    width: "100%",
    numeric: false,
    disablePadding: false,
    label: texts.Dashboard.TableAttributes.id,
    align: "right",
  });
  parsedColumns.push({
    id: "titulo",
    width: 100,
    numeric: false,
    disablePadding: false,
    label: texts.Dashboard.TableAttributes.titulo,
    align: "right",
  });
  parsedColumns.push({
    id: "direccion",
    label: texts.Dashboard.TableAttributes.direccion,
    width: 100,
    disablePadding: false,
    numeric: texts.Dashboard.TableAttributeTypes.direccion === "number",
    align: "right",
  });
  parsedColumns.push({
    id: "valor",
    label: texts.Dashboard.TableAttributes.valor,
    width: 80,
    disablePadding: false,
    numeric: texts.Dashboard.TableAttributeTypes.valor === "number",
    align: "right",
  });
  parsedColumns.push({
    id: "dormitorios",
    label: texts.Dashboard.TableAttributes.dormitorios,
    extraLabel: texts.Dashboard.TableAttributes.banos,
    width: 90,
    disablePadding: false,
    numeric: texts.Dashboard.TableAttributeTypes.dormitorios === "number",
    align: "right",
  });
  parsedColumns.push({
    id: "superficie_cubierta",
    label: texts.Dashboard.TableAttributes.superficie_cubierta,
    extraLabel: texts.Dashboard.TableAttributes.superficie_total,
    width: 100,
    disablePadding: false,
    numeric:
      texts.Dashboard.TableAttributeTypes.superficie_cubierta === "number",
    align: "right",
  });
  return parsedColumns;
};

export const prepareRowsForMatches = (texts, item) => {
  const { id, cantMatch, direccion, inmuebleCaracteristicas } = item;
  const parsedItem = {
    count: cantMatch,
    id,
    titulo: "",
    direccion,
    valor: "",
    dormitorios: "",
    superficie_cubierta: "",
    // superficie_total: "",
  };

  inmuebleCaracteristicas.forEach((jtem) => {
    switch (jtem.caracteristica.id) {
      case 73:
        parsedItem.titulo = jtem.valor;
        break;
      case 75:
        parsedItem.dormitorios = jtem.valor;
        break;
      case 76:
        parsedItem.banos = jtem.valor;
        break;
      case 77:
        parsedItem.valor_pesos = jtem.valor;
        break;
      case 78:
        parsedItem.valor_usd = jtem.valor;
        break;
      case 80:
        parsedItem.superficie_total = jtem.valor;
        break;
      case 81:
        parsedItem.superficie_cubierta = jtem.valor;
        break;
      default:
        break;
    }
  });
  // parsing value
  if (parsedItem.valor_usd) {
    parsedItem.valor = {
      primary: `${texts.Dashboard.TableAttributes.usd} ${
        numberArg(String(parsedItem.valor_usd)) || 0
      }`,
      secondary: 0,
      real: parsedItem.valor_usd ? Number(parsedItem.valor_usd) : 0,
    };
    delete parsedItem.valor_usd;
  }
  if (parsedItem.valor_pesos) {
    if (!parsedItem.valor.primary) {
      parsedItem.valor = {
        secondary: `${texts.Dashboard.TableAttributes.pesos} ${
          numberArg(String(parsedItem.valor_pesos)) || 0
        }`,
        primary: 0,
        real: parsedItem.valor_pesos ? Number(parsedItem.valor_pesos) : 0,
        realP: parsedItem.valor_pesos ? Number(parsedItem.valor_pesos) : 0,
      };
    } else {
      parsedItem.valor.secondary = `${texts.Dashboard.TableAttributes.pesos} ${
        parsedItem.valor_pesos ? Number(parsedItem.valor_pesos) : 0
      }`;
      parsedItem.valor.realP = parsedItem.valor_usd || 0;
    }

    delete parsedItem.valor_pesos;
    delete parsedItem.valor_usd;
  }
  // bedrooms
  if (!parsedItem.dormitorios)
    parsedItem.dormitorios = {
      primary: `0 ${texts.Dashboard.TableAttributes.dormitorios}`,
      real: 0,
    };
  else
    parsedItem.dormitorios = {
      primary: `${parsedItem.dormitorios} ${texts.Dashboard.TableAttributes.dormitorios}`,
      real: Number(parsedItem.dormitorios),
    };
  // bathrooms
  if (!parsedItem.banos)
    parsedItem.dormitorios.secondary = `0 ${texts.Dashboard.TableAttributes.banos}`;
  else
    parsedItem.dormitorios.secondary = `${parsedItem.banos} ${texts.Dashboard.TableAttributes.banos}`;
  delete parsedItem.banos;
  // surfaces covered
  if (!parsedItem.superficie_cubierta)
    parsedItem.superficie_cubierta = {
      primary: `0 m² ${texts.Dashboard.TableAttributes.cub}`,
      real: 0,
    };
  else
    parsedItem.superficie_cubierta = {
      primary: `${parsedItem.superficie_cubierta} m² ${texts.Dashboard.TableAttributes.cub}`,
      real: Number(parsedItem.superficie_cubierta),
    };
  // total surface
  if (!parsedItem.superficie_total) {
    parsedItem.superficie_cubierta.secondary = `0 m² ${texts.Dashboard.TableAttributes.tot}`;
    // parsedItem.superficie_total = `0 m² ${texts.Dashboard.TableAttributes.tot}`;
  } else {
    parsedItem.superficie_cubierta.secondary = `${parsedItem.superficie_total} m² ${texts.Dashboard.TableAttributes.tot}`;
    // parsedItem.superficie_total = `${parsedItem.superficie_total} m² ${texts.Dashboard.TableAttributes.tot}`;
  }
  //* seeing state
  if (item.estado !== "ACTIVO" && item.estado !== "ENCONTRADO")
    parsedItem.state = true;
  delete parsedItem.superficie_total;
  return parsedItem;
};

export const prepareRows = (texts, item) => {
  const { id, direccion, inmuebleCaracteristicas } = item;
  const parsedItem = {
    id,
    titulo: "",
    direccion,
    valor: "",
    dormitorios: "",
    superficie_cubierta: "",
    /* superficie_total: "", */
  };
  inmuebleCaracteristicas.forEach((jtem) => {
    switch (jtem.caracteristica.id) {
      case 73:
        parsedItem.titulo = jtem.valor;
        break;
      case 75:
        parsedItem.dormitorios = jtem.valor;
        break;
      case 76:
        parsedItem.banos = jtem.valor;
        break;
      case 79:
        parsedItem.valor_pesos = jtem.valor;
        break;
      case 78:
        parsedItem.valor_usd = jtem.valor;
        break;
      case 80:
        parsedItem.superficie_total = jtem.valor;
        break;
      case 81:
        parsedItem.superficie_cubierta = jtem.valor;
        break;
      default:
        break;
    }
  });
  // parsing value
  if (parsedItem.valor_usd) {
    parsedItem.valor = {
      primary: `${texts.Dashboard.TableAttributes.usd} ${
        numberArg(String(parsedItem.valor_usd)) || 0
      }`,
      secondary: 0,
      realU: parsedItem.valor_usd ? Number(parsedItem.valor_usd) : 0,
    };
    delete parsedItem.valor_usd;
  }
  if (parsedItem.valor_pesos) {
    if (!parsedItem.valor.primary) {
      parsedItem.valor = {
        secondary: `${texts.Dashboard.TableAttributes.pesos} ${
          numberArg(String(parsedItem.valor_pesos)) || 0
        }`,
        primary: 0,
        realP: parsedItem.valor_pesos ? Number(parsedItem.valor_pesos) : 0,
      };
    } else {
      parsedItem.valor.secondary = `${texts.Dashboard.TableAttributes.pesos} ${
        parsedItem.valor_pesos ? Number(parsedItem.valor_pesos) : 0
      }`;
      parsedItem.valor.realP = parsedItem.valor_usd || 0;
    }

    delete parsedItem.valor_pesos;
    delete parsedItem.valor_usd;
  }
  // bedrooms
  if (!parsedItem.dormitorios)
    parsedItem.dormitorios = {
      primary: `0 ${texts.Dashboard.TableAttributes.dormitorios}`,
      real: 0,
    };
  else
    parsedItem.dormitorios = {
      primary: `${parsedItem.dormitorios} ${texts.Dashboard.TableAttributes.dormitorios}`,
      real: Number(parsedItem.dormitorios),
    };
  // bathrooms
  if (!parsedItem.banos)
    parsedItem.dormitorios.secondary = `0 ${texts.Dashboard.TableAttributes.banos}`;
  else
    parsedItem.dormitorios.secondary = `${parsedItem.banos} ${texts.Dashboard.TableAttributes.banos}`;
  delete parsedItem.banos;
  // surfaces covered
  if (!parsedItem.superficie_cubierta)
    parsedItem.superficie_cubierta = {
      primary: `0 m² ${texts.Dashboard.TableAttributes.cub}`,
      real: 0,
    };
  else
    parsedItem.superficie_cubierta = {
      primary: `${parsedItem.superficie_cubierta} m² ${texts.Dashboard.TableAttributes.cub}`,
      real: Number(parsedItem.superficie_cubierta),
    };
  // total surface
  if (!parsedItem.superficie_total) {
    parsedItem.superficie_cubierta.secondary = `0 m² ${texts.Dashboard.TableAttributes.tot}`;
    /* parsedItem.superficie_total = `0 m² ${texts.Dashboard.TableAttributes.tot}`; */
  } else {
    parsedItem.superficie_cubierta.secondary = `${parsedItem.superficie_total} m² ${texts.Dashboard.TableAttributes.tot}`;
    /* parsedItem.superficie_total = `${parsedItem.superficie_total} m² ${texts.Dashboard.TableAttributes.tot}`; */
  }
  delete parsedItem.superficie_total;
  //* seeing state
  if (item.estado !== "ACTIVO" && item.estado !== "ENCONTRADO")
    parsedItem.state = true;
  return parsedItem;
};
