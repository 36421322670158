/* eslint-disable camelcase */
// @ts-check

// @ts-ignore
import { parseDate } from "utils/functions";

/**
 *
 * @param {object[]} data
 * @param {object} texts
 */
export const parseColumns = (data, texts) => {
  const parsedColumns = [];
  parsedColumns.push({
    id: "id",
    width: 20,
    numeric: false,
    disablePadding: true,
    label: "Id",
  });
  parsedColumns.push({
    id: "nombre",
    width: 150,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.nombre,
  });
  parsedColumns.push({
    id: "status",
    width: 70,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.status,
  });
  parsedColumns.push({
    id: "reason",
    width: 70,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.reason,
  });
  parsedColumns.push({
    id: "frequency_type",
    width: 70,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.frequency_type,
  });
  parsedColumns.push({
    id: "transaction_amount",
    width: 50,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.transaction_amount,
  });
  parsedColumns.push({
    id: "start_date",
    width: 100,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.start_date,
  });
  return parsedColumns;
};

/**
 *
 * @param {object[]} data
 * @param {object} texts
 */
export const parseRows = (data, texts) => {
  const parsedRows = [];
  data.forEach((item) => {
    const { status, reason, auto_recurring, user } = item;
    try {
      console.log(item);
      const { id, nombre, apellidos } = user;
      const { frequency_type, transaction_amount, start_date } = auto_recurring;
      parsedRows.push({
        id: { key: "id", value: id, width: "60px", textAlign: "left" },
        nombre: {
          key: "nombre",
          value: `${nombre} ${apellidos}`,
          width: "200px",
          textAlign: "left",
        },
        status: {
          key: "status",
          value: texts.Subscription.Statuses[status],
          width: "100px",
          textAlign: "left",
        },
        reason: {
          key: "reason",
          value: reason,
          width: "100px",
          textAlign: "left",
        },
        frequency_type: {
          key: "frequency",
          value: texts.Subscription.Frequencies[frequency_type],
          width: "100px",
          textAlign: "left",
        },
        transaction_amount: {
          key: "transaction",
          value: transaction_amount,
          width: "100px",
          textAlign: "left",
        },
        start_date: {
          key: "start",
          value: parseDate(start_date),
          width: "150px",
          textAlign: "left",
        },
      });
    } catch {
      console.error("null user", item);
    }
  });
  return parsedRows;
};
