/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable camelcase */
import { useState, useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import { getUserLanguage } from "some-javascript-utils/browser";

// @mui
import { ThemeProvider, CssBaseline } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dark from "assets/theme/dark";

// components
import Loader from "components/Loader/Loader";

// layouts
import View from "layouts/View/View";
import DashboardView from "layouts/Dashboard/Dashboard";

// views
import Chat from "views/Chat/Chat";
import Home from "views/Home/Home";
import Login from "views/Login/Login";
import About from "views/About/About";
import Logout from "views/Login/Logout";
import Recovery from "views/Login/Recovery";
import Details from "views/Details/Details";
import PublicDetails from "views/Details/PublicDetails";
import Contact from "views/Contact/Contact";
import Register from "views/Register/Register";
import NotFound from "views/NotFound/NotFound";
import Catalogue from "views/Catalogue/Catalogue";
import EmailValidation from "views/Login/EmailValidation";
import TermsConditions from "views/TermsConditions/TermsConditions";
// dashboard views
import Dashboard from "views/Dashboard/Dashboard";
import User from "views/Dashboard/User/User";
import Fertilizer from "views/Dashboard/Fertilizer/Fertilizer";
import College from "views/Dashboard/College/College";
import Bank from "views/Dashboard/Bank/Bank";
import CharacteristicGroup from "views/Dashboard/CharacteristicGroup/CharacteristicGroup";
import Characteristic from "views/Dashboard/Characteristic/Characteristic";
import Property from "views/Dashboard/Property/Property";
import PropertyType from "views/Dashboard/PropertyType/PropertyType";
import Subscription from "views/Dashboard/Subscription/Subscription";
import Multimedia from "views/Dashboard/Multimedia/Multimedia";

// profile
import Me from "views/Profile/Me";
import ChangePassword from "views/Profile/ChangePassword";
import SocialMedia from "views/Profile/SocialMedia";
import PickPlan from "views/Register/PickPlan";
import Payments from "views/Profile/Payments";
// subscription
import ClientSubscription from "views/Subscriptions/ClientSubscription";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { userLogged, hasSelectedPlan } from "utils/functions";

// services
import { getMe } from "services/users/get";
import { userChangeFertilizer } from "services/users/post";
import { ChatProvider } from "views/Chat/Context/ChatProvider";

function App() {
  const { languageState, setLanguageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const fetch = async () => {
    try {
      if (userLogged()) {
        const response = await getMe();
        if (response.status === 200) {
          const { data } = response;
          const { abono_id } = data.inmobiliaria;
          if (!hasSelectedPlan(abono_id)) setVisible(true);
        }
      }
      setLoading(false);
    } catch (err) {
      // comment showNotification("error", languageState.texts.Errors.NotConnected);
    }
  };

  const onSelectPlan = async (plan) => {
    try {
      const response = await userChangeFertilizer(plan + 10);
      if (response.status === 200 || response.status === 204) {
        const { data } = response;
        const aTag = document.createElement("a");
        aTag.setAttribute("href", data);
        aTag.click();
      }
    } catch (err) {
      console.error(err);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
  };

  useEffect(() => {}, [visible]);

  useEffect(() => {
    try {
      setLanguageState({
        type: "set",
        lang: getUserLanguage("crabhaus-user-lang"),
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    fetch();
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={dark}>
        <CssBaseline />
        {!loading ? (
          <HashRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              {!visible ? (
                <>
                  <Route path="/" element={<View />}>
                    <Route index element={<Home />} />
                    <Route
                      exact
                      path="/chat"
                      element={
                        <ChatProvider>
                          <Chat />
                        </ChatProvider>
                      }
                    />
                    <Route exact path="/catalogue" element={<Catalogue />} />
                    <Route exact path="/details" element={<Details />} />
                    <Route exact path="/about-us" element={<About />} />
                    <Route exact path="/contact-us" element={<Contact />} />
                    <Route exact path="/recovery" element={<Recovery />} />
                    <Route exact path="/register" element={<Register />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route
                      exact
                      path="/email-validation"
                      element={<EmailValidation />}
                    />
                    <Route exact path="/profile" element={<Me />} />
                    <Route exact path="/payments" element={<Payments />} />
                    <Route
                      exact
                      path="/social-media"
                      element={<SocialMedia />}
                    />
                    <Route
                      exact
                      path="/change-password"
                      element={<ChangePassword />}
                    />
                    <Route exact path="/properties" element={<Property />} />
                    <Route exact path="/multimedia" element={<Multimedia />} />
                    <Route
                      exact
                      path="/terms-conditions"
                      element={<TermsConditions />}
                    />
                  </Route>
                  <Route path="/dashboard" element={<DashboardView />}>
                    <Route index element={<Dashboard />} />
                    <Route exact path="/dashboard/users" element={<User />} />
                    <Route
                      exact
                      path="/dashboard/fertilizers"
                      element={<Fertilizer />}
                    />
                    <Route
                      exact
                      path="/dashboard/colleges"
                      element={<College />}
                    />
                    {/* <Route exact path="/dashboard/banks" element={<Bank />} /> */}
                    <Route
                      exact
                      path="/dashboard/characteristics-group"
                      element={<CharacteristicGroup />}
                    />
                    <Route
                      exact
                      path="/dashboard/characteristics"
                      element={<Characteristic />}
                    />
                    <Route
                      exact
                      path="/dashboard/properties"
                      element={<Property />}
                    />
                    <Route
                      exact
                      path="/dashboard/property-types"
                      element={<PropertyType />}
                    />
                    {/* <Route exact path="/dashboard/states" element={<State />} /> */}
                    <Route
                      exact
                      path="/dashboard/multimedia"
                      element={<Multimedia />}
                    />
                    <Route
                      exact
                      path="/dashboard/subscriptions"
                      element={<Subscription />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </>
              ) : (
                <>
                  <Route
                    path="/"
                    element={<PickPlan onSelectPlan={onSelectPlan} />}
                  />
                  <Route
                    exact
                    path="/profile"
                    element={<PickPlan onSelectPlan={onSelectPlan} />}
                  />
                  <Route
                    path="*"
                    element={<PickPlan onSelectPlan={onSelectPlan} />}
                  />
                </>
              )}
              <Route exact path="/public-details" element={<View noNavbar />}>
                <Route index element={<PublicDetails />} />
              </Route>
              <Route
                exact
                path="/subscription"
                element={<ClientSubscription />}
              />
              <Route exact path="/log-out" element={<Logout />} />
            </Routes>
          </HashRouter>
        ) : (
          <Loader visible />
        )}
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
