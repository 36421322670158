export const parseColumns = (data, texts) => {
  const parsedColumns = [
    {
      id: "id",
      width: 50,
      numeric: false,
      disablePadding: true,
      label: "Id",
    },
    {
      id: "nombre",
      label: texts.Dashboard.TableAttributes.nombre,
      width: 80,
      disablePadding: true,
      numeric: texts.Dashboard.TableAttributeTypes.nombre === "number",
    },
    {
      id: "apellidos",
      label: texts.Dashboard.TableAttributes.apellidos,
      width: 80,
      disablePadding: true,
      numeric: texts.Dashboard.TableAttributeTypes.apellidos === "number",
    },
    {
      id: "email",
      label: texts.Dashboard.TableAttributes.email,
      width: 200,
      disablePadding: true,
      numeric: texts.Dashboard.TableAttributeTypes.email === "number",
    },
    {
      id: "rol",
      label: texts.Dashboard.TableAttributes.rol,
      width: 100,
      disablePadding: true,
      numeric: texts.Dashboard.TableAttributeTypes.rol === "number",
    },
    {
      id: "plan",
      label: texts.Dashboard.TableAttributes.plan,
      width: 100,
      disablePadding: true,
      numeric: texts.Dashboard.TableAttributeTypes.plan === "number",
    },
  ];

  return parsedColumns;
};

export const parseRows = (data) => {
  const parsedRows = [];

  data.forEach((item) => {
    const { id, nombre, apellidos, email, rol, inmobiliaria } = item;
    let planA = null;
    if (inmobiliaria !== null) {
      const planL = inmobiliaria.plan;
      if (planL && planL !== null) {
        planA = planL.nombre;
      }
    }
    parsedRows.push({
      id: { key: "id", value: id, width: "70px", textAlign: "left" },
      nombre: { key: "nombre", value: nombre, width: "100px", textAlign: "left" },
      apellidos: { key: "apellidos", value: apellidos, width: "100px", textAlign: "left" },
      email: { key: "email", value: email, width: "250px", textAlign: "left" },
      rol: {
        key: "rol",
        value: rol !== null ? rol : "no tiene",
        width: "150px",
        textAlign: "left",
      },
      plan: {
        key: "plan",
        value: planA !== null ? planA : "no tiene",
        width: "150px",
        textAlign: "left",
      },
    });
  });
  return parsedRows;
};
