// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * @param {string} body
 * @param {string} css
 */
export const downloadPropertyAsPDF = async (body, css) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmobiliaria/download-pdf-generated`,
    { body, css },
    {
      headers: {
        ...getAuth,
        // @ts-ignore
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
      responseType: "blob",
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Takes a property object, sends it to the API, and returns the response
 * @param {object} property - The property object that contains the property's information.
 * @returns The data from the response.
 */
export const createProperty = async (property) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmueble`,
    { ...property },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 *
 * @param {number[]} selected
 * @returns
 */
export const importFromTokko = async (selected = []) => {
  const dataA = {};
  if (selected.length) {
    dataA.array_ids = selected;
    dataA.all = false;
  } else dataA.all = true;
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmueble/importar`,
    { ...dataA },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the property's ID and the ID of the property to delete
 * @param {string} propertyId - The property id of the property you want to delete.
 * @returns The data is being returned.
 */
export const deleteProperty = async (propertyId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/inmueble/${propertyId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the property's ID and property data to modify it
 * @param {string} propertyId - The property id of the property you want to delete.
 * @param {object} propertyData - Property data: propertyTypeId, stateId, neighborhoodId, cityId, provinceId, title, environments, bedrooms, bathrooms, garage, address, usdValue, pesosValue, totalSurface, deckSurface, description, latitude, longitude, postDate
 * @returns The data is being returned.
 */
export const modifyProperty = async (propertyId, propertyData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/inmueble/${propertyId}`,
    { ...propertyData },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
