/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable no-shadow */
/* eslint-disable react/function-component-definition */
import { useLayoutEffect, useEffect, useState, useRef } from "react";

// sito components
import SitoContainer from "sito-container";

// styles
import "./style.css";

// react-map-gl
// eslint-disable-next-line no-unused-vars
import "mapbox-gl/dist/mapbox-gl.css";

import mapboxgl from "!mapbox-gl";

// prop types
import PropTypes from "prop-types";

// @mui components
import { Box, Button } from "@mui/material";

// @mui icons
import MapIcon from "@mui/icons-material/Map";

// contexts
import { useLanguage } from "context/LanguageProvider";

// images
// import Crash from "assets/images/crash";
import config from "config";

const Map = (props) => {
  const {
    width,
    height,
    point,
    onMapClick,
    lng,
    lat,
    onChange,
    onChangeLng,
    onChangeLat,
    noButton,
    noGeocoder,
    noInputs,
    geocoderInput,
  } = props;
  const { languageState } = useLanguage();

  const [apiMap, setApiMap] = useState("");
  const [points, setPoints] = useState([]);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [localLng, setLocalLng] = useState(-58.619020034627965);
  const [localLat, setLocalLat] = useState(-34.713600884098724);

  const [zoom, setZoom] = useState(15);

  const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const [showMap, setShowMap] = useState(true);

  const localOnChangeLng = (e) => {
    const { value } = e.target;
    let onePoint = false;
    let newString = "";
    for (let i = 0; i < value.length; i += 1) {
      if (value[i] === "." && !onePoint) {
        onePoint = true;
        newString += value[i];
      } else if (value[i] === "-" && i === 0) newString += value[i];
      else if (numbers.indexOf(value[i]) > -1) newString += value[i];
    }
    if (onChange) onChange("lng", newString);
    else if (onChangeLng) onChangeLng(newString);
    setLocalLng(Number(newString));
  };

  const localOnChangeLat = (e) => {
    const { value } = e.target;
    let onePoint = false;
    let newString = "";
    for (let i = 0; i < value.length; i += 1) {
      if (value[i] === "." && !onePoint) {
        onePoint = true;
        newString += value[i];
      } else if (value[i] === "-" && i === 0) newString += value[i];
      else if (numbers.indexOf(value[i]) > -1) newString += value[i];
    }
    if (onChange) onChange("lat", newString);
    else if (onChangeLat) onChangeLat(newString);
    setLocalLat(Number(newString));
  };

  const init = async () => {
    try {
      setApiMap(config.mapBoxAPI);
      const result = [];
      const localPoints = [];
      result.forEach((item) => {
        const { id, location, headerImages } = item;
        const { name, description } = item.texts;
        const [lng, lat] = location.split(",");
        localPoints.push({
          type: "Feature",
          geometry: { type: "Point", coordinates: [Number(lat), Number(lng)] },
          properties: {
            id,
            name,
            headerImages,
            description,
            type: "places",
            phoneFormatted: "(202) 234-7336",
            phone: "2022347336",
            address: "1471 P St NW",
            city: "Washington DC",
            country: "United States",
            crossStreet: "at 15th St NW",
            postalCode: "20005",
            state: "D.C.",
          },
        });
      });
      setPoints({ type: "FeatureCollection", features: localPoints });
    } catch (e) {
      // console.log(e);
    }
  };

  useLayoutEffect(() => {
    init();
  }, []);

  const flyToPoint = (currentFeature) => {
    map.current.flyTo({
      center: currentFeature.geometry.coordinates,
      zoom: 20,
    });
  };

  const [sGeocoder, setSGeocoder] = useState(null);

  useEffect(() => {
    if (sGeocoder !== null) {
      sGeocoder.query(geocoderInput);
      sGeocoder.clear();
    }
  }, [geocoderInput]);

  useEffect(() => {
    if (localLng !== lng) setLocalLng(lng);
    if (localLat !== lat) setLocalLat(lat);
    if (lat && lng && map && map.current && map.current.getSource("single-point")) {
      map.current.getSource("single-point").setData({ coordinates: [lng, lat], type: "Point" });
      flyToPoint({ geometry: { coordinates: [lng, lat] } });
    }
  }, [lat, lng, map.current]);

  useEffect(() => {
    if (apiMap === "" || !points.type) return;
    mapboxgl.accessToken = apiMap;
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [localLng, localLat],
      zoom,
    });
    map.current.on("move", () => {
      setLocalLng(map.current.getCenter().lng);
      setLocalLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom().toFixed(2));
    });
    map.current.on("click", (event) => {
      /* Determine if a feature in the "locations" layer exists at that point. */
      const features = map.current.queryRenderedFeatures(event.point, {
        layers: ["point"],
      });

      /* If it does not exist, return */
      if (!features.length) return;

      const clickedPoint = features[0];

      /* Fly to the point */
      flyToPoint(clickedPoint);
    });
    let geocoder = sGeocoder;
    if (sGeocoder === null) {
      // eslint-disable-next-line no-undef
      geocoder = new MapboxGeocoder({
        // Initialize the geocoder
        countries: "ar",
        accessToken: config.mapBoxAPI, // Set the access token
        mapboxgl: map.current, // Set the mapbox-gl instance
        marker: false, // Do not use the default marker style
        placeholder: languageState.texts.Map.SearchAddressPlaceholder, // Placeholder text for the search bar
      });
      if (!noGeocoder) {
        // Add the geocoder to the map
        map.current.addControl(geocoder);
      }
      setSGeocoder(geocoder);
    } else map.current.addControl(sGeocoder);

    map.current.on("load", () => {
      /* Add the data to your map as a layer */
      map.current.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        (error, image) => {
          if (error) return error;
          map.current.addImage("my-point", image);
          map.current.addSource("single-point", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });

          map.current.addLayer({
            id: "point",
            source: "single-point",
            type: "circle",
            paint: {
              "circle-radius": 10,
              "circle-color": "#448ee4",
            },
          });

          if (point !== "" && point !== 0) {
            const [lat, lng] = point.split(",");
            map.current
              .getSource("single-point")
              .setData({ coordinates: [lng, lat], type: "Point" });
            flyToPoint({ geometry: { coordinates: [lng, lat] } });
          }

          if (!noGeocoder)
            geocoder.on("result", (event) => {
              map.current.getSource("single-point").setData(event.result.geometry);
            });

          map.current.addSource("places", {
            type: "geojson",
            data: points,
          });
          map.current.addLayer({
            id: "locations",
            type: "symbol",
            source: "places",
            layout: {
              "icon-image": "my-point",
              "icon-allow-overlap": false,
            },
          });
        }
      );

      /* addMarkers(); */

      // Listen for the `result` event from the Geocoder
      // `result` event is triggered when a user makes a selection
      //  Add a marker at the result's coordinates
    });
    map.current.on("click", (e) => {
      if (onMapClick) onMapClick(e.point, e.lngLat.wrap());
    });
  });

  return (
    <Box
      sx={{
        width,
        flex: 1,
        transition: "transform 500ms ease",
      }}
    >
      {!noInputs && (lng || lat) && (
        <SitoContainer ignoreDefault className="row">
          <SitoContainer
            ignoreDefault
            className="form-group col-md-12"
            sx={{ display: "flex", flexDirection: "row" }}
          >
            {lng && onChangeLng && (
              <SitoContainer ignoreDefault sx={{ flex: 1, width: "100%", marginRight: "10px" }}>
                <label htmlFor="lng">{languageState.texts.Map.Inputs.Longitude.label}</label>
                <input
                  placeholder={languageState.texts.Map.Inputs.Longitude.placeholder}
                  type="text"
                  name="lng"
                  id="lng"
                  value={localLng}
                  onChange={localOnChangeLng}
                />
              </SitoContainer>
            )}
            {lat && onChangeLat && (
              <SitoContainer ignoreDefault sx={{ flex: 1, width: "100%" }}>
                <label htmlFor="lng">{languageState.texts.Map.Inputs.Latitude.label}</label>
                <input
                  placeholder={languageState.texts.Map.Inputs.Latitude.placeholder}
                  type="text"
                  name="lat"
                  id="lat"
                  value={localLat}
                  onChange={localOnChangeLat}
                />
              </SitoContainer>
            )}
          </SitoContainer>
        </SitoContainer>
      )}
      {!noButton && (
        <Button
          sx={{ minWidth: 0, padding: "10px", borderRadius: "100%", marginBottom: "10px" }}
          variant={showMap ? "contained" : "outlined"}
          onClick={() => setShowMap(!showMap)}
        >
          <MapIcon />
        </Button>
      )}
      <Box
        sx={{
          width,
          height,
          position: !showMap ? "fixed" : "relative",
          opacity: !showMap ? 0 : 1,
          zIndex: !showMap ? -1 : 1,
        }}
      >
        <Box
          className="sidebar"
          sx={{
            backgroundColor: "rgba(35, 55, 75, 0.9)",
            color: "#fff",
            padding: "6px 12px",
            fontFamily: "monospace",
            zIndex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            margin: "12px",
            borderRadius: "4px",
          }}
        >
          Zoom: {zoom}
        </Box>

        <Box
          ref={mapContainer}
          className="map-container"
          sx={{
            width,
            height,
          }}
        />
      </Box>
    </Box>
  );
};

Map.defaultProps = {
  width: "100%",
  height: "500px",
  point: 0,
  lng: -58.619020034627965,
  lat: -34.713600884098724,
  onMapClick: undefined,
  onChange: undefined,
  onChangeLng: undefined,
  onChangeLat: undefined,
  noButton: false,
  noGeocoder: false,
  noInputs: false,
  geocoderInput: "",
};

Map.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  point: PropTypes.string,
  onMapClick: PropTypes.func,
  lng: PropTypes.number,
  lat: PropTypes.number,
  onChange: PropTypes.func,
  onChangeLng: PropTypes.func,
  onChangeLat: PropTypes.func,
  noButton: PropTypes.bool,
  noGeocoder: PropTypes.bool,
  noInputs: PropTypes.bool,
  geocoderInput: PropTypes.string,
};

export default Map;
