/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { Box, Divider, Typography, useTheme } from "@mui/material";

// own components
import Loader from "components/Loader/Loader";

// sito components
import SitoImage from "sito-image";
import SitoContainer from "sito-container";

// services
import { conversationList } from "services/chat/get";
import { propertyList } from "services/properties/get";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// local components
import NoChats from "./NoChats/NoChats";
import ChatItem from "./ChatItem/ChatItem";

// contexts
import { useChat } from "../Context/ChatProvider";

const Sidebar = (props) => {
  const theme = useTheme();

  const { chatState, setChatState } = useChat();

  const location = useLocation();
  const { selectedChat, selectChat } = props;

  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [loading, setLoading] = useState(true);

  const conversationReducer = (conversationState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { newList } = action;
        return [newList];
      }
      case "add": {
        const { newItem } = action;
        const exist = conversationState.findIndex(
          (item) => item.id === newItem.id
        );
        if (exist === -1) return [newItem, ...conversationState];
        else {
          conversationState[exist] = newItem;
          return [...conversationState];
        }
      }
      default:
        return [];
    }
  };

  const [localConversation, setLocalConversation] = useReducer(
    conversationReducer,
    []
  );

  const init = async (idU = "", idProperty = "") => {
    setLoading(true);
    try {
      let newChat = false;
      let newNombre = "";
      let newMensaje = "";
      let newUrlPhotoProfile = "";
      const response = await conversationList();

      console.log(response);
      if (response.status === 200) {
        const { data } = await response.data;
        if (!data.find((user) => String(user.user.id) === String(idU))) {
          const responseA = await propertyList(
            0,
            1,
            `filtros[id]=${idProperty}&opciones[datosInmobiliaria]=true`
          );
          console.log(responseA);
          if (responseA.status === 200) {
            const { data } = await responseA.data;
            if (data.length) {
              const [theProperty] = data;
              const { datosInmobiliaria } = theProperty;
              const { urlPhotoProfile, nombre, apellidos } = datosInmobiliaria;
              newNombre = nombre;
              newMensaje = "";
              newUrlPhotoProfile = urlPhotoProfile;
              newChat = true;
            }
          }
        }
        for (const item of data) {
          const { ultimoMensaje, user } = item;
          const mensaje = ultimoMensaje[0];
          const { id, nombre, urlPhotoProfile } = user;
          setLocalConversation({
            type: "add",
            newItem: {
              id,
              nombre,
              lastMessage: mensaje,
              urlPhotoProfile: urlPhotoProfile || "",
            },
          });
        }
        if (newChat) {
          setLocalConversation({
            type: "add",
            newItem: {
              id: idU,
              nombre: newNombre,
              lastMessage: newMensaje,
              urlPhotoProfile: newUrlPhotoProfile || "",
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  useEffect(() => {
    const { search } = location;
    const [paramName, paraValue] = search
      .substring(1)
      .split("&")[0]
      ?.split("=");
    const [paramName1, paraValue1] = search
      .substring(1)
      .split("&")[1]
      ?.split("=");
    if (
      paramName === "id" &&
      paraValue &&
      paramName1 === "property" &&
      paraValue1
    ) {
      init(paraValue, paraValue1);
    } else init();
  }, [location]);

  useEffect(() => {
    if (chatState.refetch) {
      const { search } = location;
      const [paramName, paraValue] = search
        .substring(1)
        .split("&")[0]
        ?.split("=");
      const [paramName1, paraValue1] = search
        .substring(1)
        .split("&")[1]
        ?.split("=");
      if (
        paramName === "id" &&
        paraValue &&
        paramName1 === "property" &&
        paraValue1
      ) {
        init(paraValue, paraValue1);
      } else init();
      setChatState({ type: "set", value: false });
    }
  }, [chatState]);

  return (
    <Box
      sx={{
        width: "300px",
        position: "relative",
        flexDirection: "column",
        height: "calc(100vh - 85px)",
        display: { md: "flex", xs: "none" },
        overflow: loading ? "hidden" : "auto",
        background: theme.palette.background.paper,
      }}
    >
      <Loader
        minimal
        visible={loading}
        sx={{
          background: `${theme.palette.background.paper} !important`,
          position: "absolute",
        }}
      />
      {/* <SitoContainer
        alignItems="center"
        sx={{
          padding: "20px",
          width: "100%",
          height: "100px",
        }}
      >
        <SitoImage
          alt="user-image"
          src={user.urlPhotoProfile}
          sx={{ width: "70px", height: "70px", borderRadius: "100%" }}
        />
        <SitoContainer flexDirection="column" sx={{ flex: 1, marginLeft: "20px" }}>
          <Typography>{user.nombre}</Typography>
          <Link to="/profile" className={css({ textDecoration: "underline" })}>
            <Typography variant="subtitle2">{languageState.texts.Chat.You}</Typography>
          </Link>
        </SitoContainer>
      </SitoContainer>
      <Divider /> */}
      <SitoContainer sx={{ position: "relative", padding: "15px" }}>
        <input
          required
          id="search"
          type="search"
          name="search"
          value={search}
          onChange={handleChange}
          placeholder={languageState.texts.Chat.Search}
        />
      </SitoContainer>
      {localConversation.length === 0 && <NoChats />}
      {localConversation.map((item, i) => (
        <Box key={item.id}>
          <ChatItem
            index={item.id}
            name={item.nombre}
            selected={selectedChat}
            selectChat={selectChat}
            src={item.urlPhotoProfile}
            message={item.lastMessage}
          />
        </Box>
      ))}
    </Box>
  );
};

Sidebar.propTypes = {
  user: PropTypes.shape({
    urlPhotoProfile: PropTypes.string,
    nombre: PropTypes.string,
    id: PropTypes.number,
    rol: PropTypes.string,
  }).isRequired,
  selectChat: PropTypes.func.isRequired,
  selectedChat: PropTypes.number.isRequired,
};

export default Sidebar;
