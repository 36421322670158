/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
import { Box } from "@mui/material";
import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

const Loader = (props) => {
  const { visible, minimal, sx, small } = props;

  return  (
    <Box
      id="preloader"
      ignoreDefault
      sx={{
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        opacity: visible ? 1 : 0,
        zIndex: visible ? 20 : "-1 !important",
        transition: "all 500ms ease",
        ...sx,
      }}
      style={{ position: minimal ? "absolute !important" : "fixed !important", ...sx }}
    >
      <SitoContainer
        className="row loader"
        ignoreDefault
        sx={{
          opacity: visible ? 1 : 0,
          zIndex: visible ? 20 : "-1 !important",
          transition: "all 500ms ease",
          display: "flex !important",
          alignItems: "center !important",
        }}
      >
        <SitoContainer
          ignoreDefault
          className="loader-icon"
          sx={{
            width: small ? "30px" : "80px",
            height: small ? "30px" : "80px",
            opacity: visible ? 1 : 0,
            zIndex: visible ? 20 : "-1 !important",
            transition: "all 500ms ease",
          }}
        />
      </SitoContainer>
    </Box>
  );
};

Loader.defaultProps = {
  minimal: false,
  small: false,
  sx: {},
};

Loader.propTypes = {
  visible: PropTypes.bool.isRequired,
  minimal: PropTypes.bool,
  small: PropTypes.bool,
  sx: PropTypes.object,
};

export default Loader;
