/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from "react";

import PropTypes from "prop-types";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { useTheme, Box, Modal } from "@mui/material";

// @mui/icons-material
import SendIcon from "@mui/icons-material/Send";

// sito components
import SitoContainer from "sito-container";

// components
import Loading from "components/Loader/Loader";

// services
import { getLinkers } from "services/email/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";
import { getMe } from "services/users/get";

const EmailDialog = (props) => {
  const theme = useTheme();

  const { open, handleClose, email, propertyData, onSubmit, toClient } = props;

  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const { languageState } = useLanguage();

  const [title, setTitle] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [messageContent, setMessageContent] = useState("");

  const toEmailMe = async (e) => {
    e.preventDefault();
    onSubmit(title, emailInput, messageContent);
  };

  const [ok, setOk] = useState(true);

  const validate = () => setOk(true);

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "email":
          return showNotification(
            "error",
            languageState.texts.Errors.EmailRequired
          );
        case "title":
          return showNotification(
            "error",
            languageState.texts.Errors.TitleRequired
          );
        default:
          return showNotification(
            "error",
            languageState.texts.Errors.MessageRequired
          );
      }
    }
  };

  const handleChange = (e) => {
    const { value, id } = e.target;
    switch (id) {
      case "email":
        return setEmailInput(value);
      case "title":
        return setTitle(value);
      default:
        return setMessageContent(value);
    }
  };

  const [loading, setLoading] = useState(true);

  const init = async () => {
    if (propertyData && propertyData.length) {
      const userData = await getMe();
      const { data } = await userData;
      if (data) {
        setEmailInput(propertyData[0].email);
        let parsedTitle = `${data.nombre} ${data.apellidos}`;
        let parsedMessage = "";

        if (propertyData.length === 1) {
          if (!toClient) {
            parsedTitle += languageState.texts.EmailDialog.AnnounceMessageTitle;
            parsedMessage =
              languageState.texts.EmailDialog.AnnounceMessageContent;
          } else parsedMessage = languageState.texts.EmailDialog.ClientMessage;
        } else {
          parsedMessage = toClient
            ? languageState.texts.EmailDialog.ClientMessages
            : languageState.texts.EmailDialog.AnnounceMessageContent;
          propertyData.forEach((item, i) => {
            parsedMessage += item.direccion.trim();
            if (i === propertyData.length - 2)
              parsedMessage += languageState.texts.Contact.inputs.subject.and;
            else if (i < propertyData.length - 2) parsedMessage += ", ";
            else parsedMessage += ".";
          });
        }

        try {
          /* const linkers = await getLinkers(
            propertyData.map((item) => `https://crabhaus.com/#/public-details?id=${item.id}`)
          );
          const linkersData = await linkers.data;
          linkersData.forEach((item) => {
            parsedMessage += `<br>${item}`;
          }); */
          parsedMessage += "\n";
          propertyData.forEach((item, i) => {
            parsedMessage += `https://crabhaus.com/#/public-details?id=${item.id}`;
            if (i < propertyData.length - 1) parsedMessage += "\n";
          });
          parsedMessage += languageState.texts.EmailDialog.ClientMessagesBye;
        } catch (err) {
          console.error(err);
        }

        setLoading(false);
        setTitle(parsedTitle);
        setMessageContent(parsedMessage);
      }
    }
  };

  useEffect(() => {
    init();
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        component="form"
        onSubmit={toEmailMe}
        sx={{
          p: 4,
          top: "50%",
          left: "50%",
          width: 500,
          boxShadow: 24,
          position: "absolute",
          bgcolor: "background.paper",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Loading visible={loading} minimal />
        <h5>{languageState.texts.EmailDialog.Title}</h5>
        <SitoContainer
          sx={{ marginBottom: "20px", display: {} }}
          flexDirection="column"
        >
          <label htmlFor="email">
            {languageState.texts.Contact.inputs.email.labelAlter}
          </label>
          <input
            id="email"
            type="email"
            name="email"
            value={emailInput}
            required
            onInput={validate}
            onInvalid={invalidate}
            onChange={handleChange}
            placeholder={languageState.texts.Contact.inputs.email.placeholder}
          />
        </SitoContainer>

        <SitoContainer sx={{ marginBottom: "20px" }} flexDirection="column">
          <label htmlFor="name">
            {languageState.texts.Contact.inputs.subject.label}
          </label>
          <input
            required
            id="title"
            type="text"
            name="title"
            value={title}
            onInput={validate}
            onInvalid={invalidate}
            onChange={handleChange}
            placeholder={languageState.texts.Contact.inputs.name.placeholder}
          />
        </SitoContainer>
        <SitoContainer flexDirection="column">
          <label htmlFor="name">
            {languageState.texts.Contact.inputs.message.labelAlter}
          </label>
          <textarea
            required
            id="message"
            name="message"
            onInput={validate}
            onInvalid={invalidate}
            value={messageContent}
            onChange={handleChange}
            className={`form-control ${css({ height: "200px !important" })}`}
            placeholder={languageState.texts.Contact.inputs.message.placeholder}
          />
        </SitoContainer>
        <SitoContainer
          justifyContent="flex-end"
          sx={{ marginTop: "20px", fullWidth: "100%" }}
        >
          <button
            type="button"
            onClick={handleClose}
            className={`butn ${css({
              background: "none",
              marginRight: "20px",
              color: "#000",
              border: `2px solid ${theme.palette.primary.main}`,
            })}`}
          >
            {languageState.texts.Buttons.Cancel}
          </button>
          <button type="submit" className="butn">
            {languageState.texts.Buttons.Send}
            <SendIcon sx={{ marginLeft: "20px" }} />
          </button>
        </SitoContainer>
      </Box>
    </Modal>
  );
};

EmailDialog.defaultProps = {
  toClient: false,
};

EmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  propertyData: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toClient: PropTypes.bool,
};

export default EmailDialog;
