// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { registerAuth, getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

// import md5 from "md5";

/**
 *
 * @param {string} name
 * @param {string} email
 * @param {string} message
 * @returns
 */
export const contact = async (name, email, message) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/inmobiliaria/send-mail`,
    { name, email, message },
    {
      headers: registerAuth,
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Takes a user object, sends it to the API, and returns the response
 * @param {object} user - The user object that contains the user's information.
 * @returns The data from the response.
 */
export const createAgent = async (user) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/user/create-agente`,
    { ...user },
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};
