/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

// @mui/material
import { useTheme, Box, Typography } from "@mui/material";

// sito components
import SitoImage from "sito-image";
import SitoContainer from "sito-container";

// images
import userPhoto from "img/content/myprofile-1.jpg";

// styles
import "./style.css";

const ChatItem = (props) => {
  const theme = useTheme();
  const { name, message, src, selected, selectChat, index } = props;
  return (
    <SitoContainer
      justifyContent="flex-start"
      extraProps={{ onClick: () => selectChat(index) }}
      sx={{
        padding: "15px",
        cursor: "pointer",

        "&:hover": { background: theme.palette.shimmer.selected },
        background: selected === index ? theme.palette.shimmer.selected : "",
      }}
    >
      <Box
        sx={{
          width: "65px",
          height: "65px",
          minWidth: "65px",
          minHeight: "65px",
          img: {
            objectFit: "cover",
          },
        }}
      >
        <SitoImage
          sx={{ borderRadius: "100%" }}
          alt={src}
          src={src === "" ? userPhoto : src}
        />
      </Box>
      <SitoContainer flexDirection="column" justifyContent="center">
        <Typography
          sx={{
            marginLeft: "20px",
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            marginLeft: "20px",
          }}
        >
          {message ? message.mensaje : ""}
        </Typography>
      </SitoContainer>
    </SitoContainer>
  );
};

ChatItem.defaultProps = {
  src: userPhoto,
  message: undefined,
};

ChatItem.propTypes = {
  src: PropTypes.string,
  message: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
  selectChat: PropTypes.func.isRequired,
};

export default ChatItem;
