/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

// @emotion/css
import { css } from "@emotion/css";

const SideHeader = (props) => {
  const { text } = props;

  return <li className={`dashboard-sidebar-header ${css({ padding: "0 !important" })}`}>{text}</li>;
};

SideHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SideHeader;
