/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

// @emotion/css
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// @mui/material
import { Box, IconButton } from "@mui/material";

// @mui/icons-material
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import {
  userLogged,
  createCookie,
  scrollTo,
  areValidatingEmail,
  logUser,
  removeValidatingCookie,
  isAdmin,
  isValidatingEmail,
} from "utils/functions";

// services
import { signIn } from "services/users/post";

// image
import img from "img/reals/header/page-title1.webp";

import config from "config";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit } = useForm();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [ok, setOk] = useState(1);

  const [remember, setRemember] = useState(false);

  const toggleRemember = () => setRemember(!remember);

  const localSignIn = async (d) => {
    setLoading(true);
    const { email, password } = d;
    const realUser = email;
    if (realUser)
      try {
        const response = await signIn({ email: realUser, password });
        if (response.status === 200) {
          logUser(remember, realUser);
          createCookie(
            config.jwtKey,
            response.data.expire,
            response.data.token
          );
          showNotification(
            "success",
            languageState.texts.Messages.LoginSuccessful
          );
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        if (response.error) {
          setLoading(false);
          const { error } = response;
          let message;
          if (error.indexOf("422") > -1)
            message = languageState.texts.Errors.Wrong;
          else if (error.indexOf("Error: Network Error") > -1)
            message = languageState.texts.Errors.NotConnected;
          else message = languageState.texts.Errors.SomeWrong;
          showNotification("error", message);
        }
      } catch (err) {
        console.log(err);
        let message = languageState.texts.Errors.SomeWrong;
        if (err.code === "ERR_NETWORK")
          message = languageState.texts.Errors.NotConnected;
        if (err.response.status === 422)
          message = languageState.texts.Errors.Wrong;
        showNotification("error", message);
        setLoading(false);
      }
  };

  useEffect(() => {
    const queryParams = location.search.substring(1);
    const [paramName, paramValue] = queryParams.split("=");
    if (paramName === "verified" && isValidatingEmail()) {
      showNotification("success", languageState.texts.Messages.EmailValidated);
      if (paramValue === "1" || paramValue === "true") {
        removeValidatingCookie();
        showNotification(
          "success",
          languageState.texts.Messages.EmailValidated
        );
      } else
        showNotification(
          "error",
          languageState.texts.Errors.ErrorAtValidateEmail
        );
      setLoading(false);
    }
    if (userLogged() && !areValidatingEmail()) {
      if (isAdmin()) navigate("/dashboard");
      else navigate("/profile");
    }
    scrollTo(0);
    setRouteState({
      type: "set",
      to: 2,
      page: languageState.texts.Links[2].label,
    });
  }, []);

  const validate = () => setOk(true);

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "email":
          if (!e.target.value.length)
            return showNotification(
              "error",
              languageState.texts.Errors.EmailRequired
            );
          return showNotification(
            "error",
            languageState.texts.Errors.InvalidEmail
          );
        case "password":
          return showNotification(
            "error",
            languageState.texts.Errors.NoEmptyPassword
          );
        default:
          return showNotification(
            "error",
            languageState.texts.Errors.TermsNdConditionRequired
          );
      }
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero background={img}>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[4].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul
                className={css({ "li::after": { display: "none !important" } })}
              >
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>{languageState.texts.Links[4].label}</li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-7 center-col">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-all xs-padding-25px-all border-radius-3"
              >
                <h3 className="text-center font-size30">
                  {languageState.texts.Links[4].label}
                </h3>
                <form
                  id="login-form"
                  method="post"
                  action="#!"
                  onSubmit={handleSubmit(localSignIn)}
                >
                  <SitoContainer ignoreDefault className="row">
                    <SitoContainer ignoreDefault className="col-md-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="email">
                          {languageState.texts.SignIn.itsInputs.email.label}
                        </label>
                        <input
                          placeholder={
                            languageState.texts.SignIn.itsInputs.email
                              .placeholder
                          }
                          type="email"
                          name="email"
                          id="email"
                          required
                          onInput={validate}
                          onInvalid={invalidate}
                          {...register("email")}
                        />
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-md-12">
                      <SitoContainer
                        ignoreDefault
                        className="form-group"
                        sx={{ position: "relative" }}
                      >
                        <Box>
                          <label htmlFor="password">
                            {
                              languageState.texts.SignIn.itsInputs.password
                                .label
                            }
                          </label>
                          <input
                            placeholder={
                              languageState.texts.SignIn.itsInputs.password
                                .placeholder
                            }
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            required
                            onInput={validate}
                            onInvalid={invalidate}
                            {...register("password")}
                          />
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translate(0px, -8%)",
                          }}
                        >
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Box>
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="remember" className="no-margin-bottom">
                          <input
                            id="remember"
                            onChange={toggleRemember}
                            type="checkbox"
                            className="min-height-auto"
                          />
                          {languageState.texts.SignIn.remember.label}
                        </label>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>

                  <SitoContainer
                    ignoreDefault
                    className="row align-items-center margin-30px-top"
                  >
                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.Buttons.SignIn}
                      </button>
                    </SitoContainer>
                    <SitoContainer
                      ignoreDefault
                      className="col-md-6 text-right xs-text-left xs-margin-20px-top"
                      sx={{ flexDirection: "column" }}
                    >
                      <SitoContainer justifyContent="flex-end">
                        <Link
                          to="/recovery"
                          className={css({ marginRight: "10px" })}
                        >
                          {languageState.texts.SignIn.recovery.message}
                        </Link>{" "}
                        {languageState.texts.SignIn.or}{" "}
                      </SitoContainer>
                      <Link
                        to="/register"
                        style={{ textDecoration: "underline" }}
                      >
                        {languageState.texts.SignIn.signUp.link}
                      </Link>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Login;
