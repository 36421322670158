// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth, registerAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Takes a multimedia object, sends it to the API, and returns the response
 * @param {object} multimedia - The multimedia object that contains the multimedia's information.
 * @param {number} inmuebleId
 * @returns The data from the response.
 */
export const createMultimedia = async (multimedia, inmuebleId) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/multimedia-inmueble`,
    { ...multimedia, inmueble_id: inmuebleId },
    {
      // @ts-ignore
      headers: { ...registerAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the multimedia 's ID and the ID of the multimedia to delete
 * @param {string} multimediaId - The multimedia id of the multimedia you want to delete.
 * @returns The data is being returned.
 */
export const deleteMultimedia = async (multimediaId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/multimedia-inmueble/${multimediaId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the multimedia's ID and multimedia data to modify it
 * @param {string} multimediaId - The multimedia id of the multimedia you want to delete.
 * @param {object} multimediaData - multimedia data: name, propertyId, type
 * @returns The data is being returned.
 */
export const modifyMultimedia = async (multimediaId, multimediaData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/multimedia-inmueble/${multimediaId}`,
    { ...multimediaData },
    {
      // @ts-ignore
      headers: { ...registerAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
