import React from "react";
import { numberArg } from "utils/parser";

const NumberInput = (props) => {
  const {
    placeholder,
    id,
    required,
    validate,
    invalidate,
    onChange,
    value,
    invalids,
  } = props;

  const handleNumber = (e) => {
    numberArg(e, onChange);
    // return onChange(e, e.target.value);
  };

  return (
    <input
      placeholder={placeholder}
      type="text"
      name={id}
      id={id}
      required={required}
      onInput={validate}
      /* onKeyDown={onKeyDown} */
      onInvalid={invalidate}
      onChange={handleNumber}
      value={value}
      className={invalids[id] ? "error" : ""}
    />
  );
};

export default NumberInput;
