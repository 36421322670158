/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui/icons-material
import { Favorite } from "@mui/icons-material";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";
import TabView from "components/MUI/TabView/TabView";

// utils
import { scrollTo } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// sections
import List from "./Sections/List/List";
import Insert from "./Sections/Insert/Insert";
import Matches from "./Sections/Matches/Matches";

const Main = () => {
  const location = useLocation();
  const { languageState } = useLanguage();
  const { setRouteState } = useRoute();

  const [tab, setTab] = useState(0);
  const [labelTabs, setLabelTabs] = useState(languageState.texts.Dashboard.Property.Tabs);

  const [toEdit, setToEdit] = useState(0);
  const [toViewMatches, setToViewMatches] = useState(0);

  const handleChange = (e, newTab) => setTab(newTab);

  useEffect(() => {
    if (tab === 0 || tab === 1) setLabelTabs(languageState.texts.Dashboard.Property.Tabs);
    setToEdit(0);
    setToViewMatches(0);
  }, [tab]);

  const navigateToInsert = () => setTab(2);

  const navigateToModify = useCallback(
    (id) => {
      setTab(2);
      setToEdit(id);
      setLabelTabs(languageState.texts.Dashboard.Property.TabsModify);
    },
    [setToEdit, toEdit]
  );

  const navigateToViewMatches = useCallback(
    (id) => {
      setTab(2);
      setToViewMatches(id);
      setLabelTabs(languageState.texts.Dashboard.Property.TabsModify);
    },
    [setToEdit, toEdit]
  );

  const tabs = [
    <Matches onEdit={navigateToViewMatches} />,
    <List navigateToInsert={navigateToInsert} onEdit={navigateToModify} />,
    <Insert toViewMatches={toViewMatches} toEdit={toEdit} />,
  ];

  useEffect(() => {
    setRouteState({
      type: "set",
      to: 7,
    });
    scrollTo();
  }, []);

  useEffect(() => {
    const { hash } = location;
    const index = Number(hash.substring(1));
    if (!Number.isNaN(index)) {
      const indexOf = languageState.texts.Dashboard.Property.Tabs[index];
      if (indexOf) setTab(index);
    }
  }, [location]);

  return (
    <main className="dashboard-content">
      <SitoContainer ignoreDefault className="dashboard-container-fluid dashboard-p-0">
        <h1 className="dashboard-h3 dashboard-mb-3">
          {languageState.texts.Dashboard.Property.Title.light}{" "}
          <strong>{languageState.texts.Dashboard.Property.Title.strong}</strong>
        </h1>
        <Loader visible={tabs.length === 0} />

        {tabs.length > 0 && (
          <SitoContainer ignoreDefault className="dashboard-row">
            <SitoContainer ignoreDefault className="dashboard-w-100 dashboard-d-flex">
              <SitoContainer ignoreDefault className="dashboard-w-100 dashboard-card">
                <TabView
                  value={tab}
                  icons={[<Favorite />]}
                  onChange={handleChange}
                  tabs={labelTabs}
                  content={tabs}
                />
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        )}
      </SitoContainer>
    </main>
  );
};

export default Main;
