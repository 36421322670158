/* eslint-disable react/function-component-definition */
import { Link } from "react-router-dom";

// @mui icons
import PersonIcon from "@mui/icons-material/Person";
import HouseIcon from "@mui/icons-material/House";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import HttpsIcon from "@mui/icons-material/Https";
import LanguageIcon from "@mui/icons-material/Language";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

const Sidebar = (props) => {
  const { currentLink, role } = props;
  const { languageState } = useLanguage();

  const icons = [
    <PersonIcon className="margin-10px-right" />,
    <PeopleIcon className="margin-10px-right" />,
    <LogoutIcon className="margin-10px-right" />,
    <HouseIcon className="margin-10px-right" />,
    <LanguageIcon className="margin-10px-right" />,
    <HttpsIcon className="margin-10px-right" />,
    <CreditCardIcon className="margin-10px-right" />,
  ];

  return (
    <SitoContainer ignoreDefault>
      <ul className="no-margin-bottom list-style13">
        {languageState.texts.Profile.Sidebar.Links.filter(
          (item) => !item.role || item.role === role
        ).map((item, i) => (
          <li key={item.label}>
            <Link to={item.link} className={currentLink === i ? "text-theme-color" : ""}>
              {icons[item.icon]}
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </SitoContainer>
  );
};

Sidebar.defaultProps = {
  role: 2,
};

Sidebar.propTypes = {
  currentLink: PropTypes.number.isRequired,
  role: PropTypes.number,
};

export default Sidebar;
