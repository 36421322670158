/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import { useEffect, useState, useReducer, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// @emotion/css
import { css } from "@emotion/css";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

// @mui/material
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Autocomplete,
  TextField,
  Tooltip,
  Button,
  IconButton,
} from "@mui/material";

// @mui/icons-material
import { LockOpen, Lock, Search, FilterAlt, FilterAltOff } from "@mui/icons-material";

// own components
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";
import SearchWrapper from "components/MUI/SearchWrapper/SearchWrapper";

// services
import { userList } from "services/users/get";
import { fertilizerList } from "services/fertilizers/get";
import { deleteUser, blockUser } from "services/users/post";

// functions
import { apiTokenCertified } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";
import { parseColumns, parseRows } from "../../utils";

const List = (props) => {
  const navigate = useNavigate();
  const { navigateToInsert, onEdit } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [showSearchWrapper, setShowSearchWrapper] = useState(false);
  const activateWrapper = () => setShowSearchWrapper(true);

  const { control, watch } = useForm();
  const [filtering, setFiltering] = useState(false);

  const values = watch(["user", "fullName", "email"]);

  const [user, setUser] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const [plans, setPlans] = useState([]);
  const [planId, setPlanId] = useState(0);

  const handlePlan = (e) => setPlanId(e.target.value);

  useEffect(() => {
    console.log(values);
    setUser(values[0]);
    setFullName(values[1]);
    setEmail(values[2]);
  }, [values]);

  const localListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = [];
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };

  const [localList, setLocalList] = useReducer(localListReducer, []);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const fetch = async (from = 0, filters = "") => {
    setLoading(true);
    const responsePlan = await fertilizerList(0, -1);
    if (responsePlan.status === 200) {
      const { data } = await responsePlan.data;
      if (data.length) setPlans(data.map((item) => ({ id: item.id, name: item.nombre })));
    } else if (responsePlan.error && !apiTokenCertified(responsePlan.error)) navigate("/log-out");
    else {
      setPlans(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    const response = await userList(from, 10, filters);
    if (response.status === 200) {
      const { data, total } = await response.data;
      if (data.length) {
        if (!columns.length) setColumns(parseColumns(data, languageState.texts));
        const parsedRows = parseRows(data, languageState.texts);
        if (!from) setLocalList({ type: "add", toAdd: parsedRows, max: total });
        else setLocalList({ type: "set", toAdd: parsedRows, from });
      }
    } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    else {
      setLocalList(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const [page, setPage] = useState(0);

  const onPagination = (newPage) => {
    if (localList[10 * newPage]) fetch(10 * newPage);
    setPage(newPage);
  };

  const retry = () => fetch();

  const editToDo = (selected) => {
    onEdit(selected[0].value);
  };

  const removeToDo = async (selected) => {
    setLoading(true);
    try {
      let ok = true;
      for (const item of selected) {
        const response = await deleteUser(item);
        if (response.status !== 200) {
          showNotification("error", languageState.texts.Errors.SomeWrong);
          ok = false;
          break;
        } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
      }
      if (ok) {
        showNotification("success", languageState.texts.Messages.CharacteristicDeletedSuccessful);
        setLocalList([]);
        retry();
      } else setLoading(false);
    } catch (error) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
      setLoading(false);
    }
  };

  const blockToDo = async (selected) => {
    setLoading(true);
    try {
      let ok = true;
      for (const item of selected) {
        const response = await blockUser(item);
        if (response.status !== 200) {
          showNotification("error", languageState.texts.Errors.SomeWrong);
          ok = false;
          break;
        } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
      }
      if (ok) {
        showNotification("success", languageState.texts.Messages.CharacteristicDeletedSuccessful);
        setLocalList([]);
        retry();
      } else setLoading(false);
    } catch (error) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("hola");
    fetch();
  }, []);

  const shownFiltersReducer = (filtersState, action) => {
    const { type } = action;
    switch (type) {
      case "added": {
        const newFilters = { ...filtersState };
        const { filterAdded } = action;
        newFilters[String(filterAdded.id)] = { ...filterAdded };
        return newFilters;
      }
      case "removed": {
        const newFilters = { ...filtersState };
        const { filterRemoved } = action;
        delete newFilters[String(filterRemoved)];
        return newFilters;
      }
      default:
        return { error: "error" };
    }
  };

  const [shownFilters] = useReducer(shownFiltersReducer, {
    ...languageState.texts.Users.Filters,
  });

  const executeFilter = useCallback(() => {
    let filters = "";
    //* number filters
    if (user && user.length)
      filters += `&filtros${encodeURIComponent("[")}user${encodeURIComponent("]")}=${user}`;
    if (fullName && fullName.length)
      filters += `&filtros${encodeURIComponent("[")}nombre_completo${encodeURIComponent(
        "]"
      )}=${fullName}`;
    if (email && email)
      filters += `&filtros${encodeURIComponent("[")}email${encodeURIComponent("]")}=${email}`;
    //* property type
    if (planId)
      filters += `&filtros${encodeURIComponent("[")}plan_id${encodeURIComponent("]")}=${planId}`;
    setLocalList({ type: "clean" });
    fetch(0, filters);
  }, [user, fullName, email, planId]);

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <SearchWrapper open={showSearchWrapper} handleClose={() => setShowSearchWrapper(false)} />
      <Loader visible={loading} minimal />
      {localList.length > 0 && (
        <ComplexTable
          onPageChange={onPagination}
          page={page}
          columns={columns}
          rows={localList}
          onEdit={editToDo}
          onBlock={blockToDo}
          onDelete={removeToDo}
          hasImage={false}
          filtering={filtering}
          filterHeight="280px"
          filters={
            <SitoContainer flexDirection="column" sx={{ height: "500px" }}>
              <SitoContainer alignItems="center" sx={{ marginTop: "13px" }}>
                <Button
                  variant="text"
                  color={Object.values(shownFilters).length ? "primary" : "secondary"}
                  onClick={executeFilter}
                  sx={{ marginTop: "-10px" }}
                  disabled={Object.values(shownFilters).length === 0}
                >
                  {languageState.texts.Buttons.ExecuteFilter}
                </Button>
                <IconButton
                  variant="text"
                  color={filtering ? "primary" : "inherit"}
                  onClick={() => setFiltering(!filtering)}
                  sx={{ marginTop: "-13px" }}
                >
                  {filtering ? <FilterAlt /> : <FilterAltOff />}
                </IconButton>
              </SitoContainer>
              <SitoContainer
                ignoreDefault
                className="form-group"
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                {filtering &&
                  Object.values(shownFilters).map((item) => (
                    <Box key={item.id}>
                      {item.type === "text" && (
                        <SitoContainer
                          alignItems="center"
                          sx={{ width: "450px", marginTop: "10px" }}
                        >
                          <label
                            htmlFor={item.name}
                            className={css({ margin: 0, marginRight: "20px", width: "125px" })}
                          >
                            {languageState.texts.Users.Filters[String(item.id)].label}
                          </label>
                          <Controller
                            control={control}
                            name={item.name}
                            render={({ field }) => (
                              <input
                                className={`form-control ${css({
                                  maxWidth: "275px",
                                  marginRight: "20px",
                                  height: "calc(1.5em + 0.75rem + 2px)",
                                })}`}
                                placeholder={item.placeholder}
                                type="text"
                                id={`${item.name}`}
                                value={field.value}
                                onChange={(event) => {
                                  field.onChange(event);
                                }}
                              />
                            )}
                          />
                        </SitoContainer>
                      )}
                      {item.type === "select" && (
                        <FormControl fullWidth sx={{ marginTop: "20px" }}>
                          <InputLabel id="plan">{languageState.texts.Search.Plan}</InputLabel>
                          <Select
                            labelId="plan"
                            id="plan1"
                            value={planId}
                            label={languageState.texts.Search.Plan}
                            onChange={handlePlan}
                          >
                            <MenuItem value={0}>{languageState.texts.Search.NoPlan}</MenuItem>
                            {plans !== -1 &&
                              plans.map((jtem) => (
                                <MenuItem value={jtem.id} key={jtem.id}>
                                  {jtem.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  ))}
              </SitoContainer>
            </SitoContainer>
          }
        />
      )}
      {!loading && !localList.length && localList !== -1 && <Empty onAction={navigateToInsert} />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

List.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default List;
