/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useEffect, useState, useReducer, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// @emotion/css
import { css } from "@emotion/css";

// @mui components
import {
  Box,
  Select,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
} from "@mui/material";

// @mui icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// own components
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";
import Sidebar from "views/Profile/Sections/Sidebar/Sidebar";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import {
  hasSelectedPlan,
  userLogged,
  scrollTo,
  apiTokenCertified,
} from "utils/functions";
import { parseColumns, parseRows } from "views/Dashboard/Subscription/utils";

// services
import { getMe } from "services/users/get";
import { fertilizerList } from "services/fertilizers/get";
import { subscriptionList } from "services/subscriptions/get";
import { userChangePhoto, userChangeFertilizer } from "services/users/post";

// images
import userPhoto from "img/content/myprofile-1.jpg";

const Payments = () => {
  const navigate = useNavigate();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const { register, setValue } = useForm();

  const [userRPhoto, setUserRPhoto] = useState(userPhoto);
  const [role, setRole] = useState(0);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const localReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = localListState;
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }

        return newLocalList;
      }
      default:
        return [];
    }
  };

  const [columns, setColumns] = useState([]);
  const [localList, setLocalList] = useReducer(localReducer, []);
  const [from, setFrom] = useState(0);

  const [plans, setPlans] = useState([]);
  const [oldPlan, setOldPlan] = useState(0);
  const [newPlan, setNewPlan] = useState(0);
  const handleChangePlan = (e) => setNewPlan(e.target.value);

  const [type, setType] = useState("");

  const fetch = async () => {
    setLoading(true);
    try {
      const responseFertilizer = await fertilizerList(0, -1);
      if (responseFertilizer.status === 200) {
        const { data } = await responseFertilizer.data;
        if (data.length) {
          setPlans(data.map(({ id, nombre }) => ({ id, nombre })));
        }
      }
      const response = await getMe();
      if (response.status === 200) {
        const meData = await response.data;
        setValue("user", meData.user);
        setType(meData.rol);
        const { abono_id } = meData.inmobiliaria;
        setNewPlan(abono_id);
        setOldPlan(abono_id);
        if (!hasSelectedPlan(abono_id)) window.location.href = "/";
        setRole(abono_id);
        setValue("name", meData.nombre);
        setValue("lastname", meData.apellidos);

        setUserData(meData);
        if (meData.e !== null) setUserRPhoto(meData.urlPhotoProfile);

        const responseA = await subscriptionList(
          from,
          10,
          `&filtros[abono_id]=${abono_id}&filtros[user_id]=${meData.id}`
        );
        if (responseA.status === 200) {
          const { data, total } = await responseA.data;
          if (!columns.length)
            setColumns(parseColumns(data, languageState.texts));
          if (data.length) {
            const parsedRows = parseRows(data, languageState.texts);
            if (!from && !localList.length)
              setLocalList({ type: "add", toAdd: parsedRows, max: total });
            else setLocalList({ type: "set", toAdd: parsedRows, from });
          }
        } else if (response.error && !apiTokenCertified(response.error))
          navigate("/log-out");
        else {
          setLocalList(-1);
          showNotification("error", languageState.texts.Errors.NotConnected);
        }
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
    setLoading(false);
  };

  const [page, setPage] = useState(0);

  const onPagination = (newPage) => {
    setFrom(10 + from);
    setPage(newPage);
    if (localList[10 * newPage]) fetch(10 * newPage, 11);
  };

  const retry = () => fetch();

  useEffect(() => {
    if (!userLogged()) navigate("/");
    setRouteState({
      type: "set",
      to: 6,
      page: languageState.texts.Links[6].label,
    });
    scrollTo();
    retry();
  }, []);

  const updateUserPhoto = async (photo) => {
    try {
      const response = await userChangePhoto(photo);
      if (response.status === 200)
        showNotification(
          "success",
          languageState.texts.Messages.PhotoSavedSuccessful
        );
      else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
      else showNotification("error", languageState.texts.Errors.SomeWrong);
    } catch (err) {
      console.log(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
  };

  const uploadPhoto = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    updateUserPhoto(file);
    const newReader = new FileReader();
    newReader.onload = async (event) => {
      const content = event.target.result;
      setUserRPhoto(content);
    };
    newReader.readAsDataURL(file);
  };

  useEffect(() => {
    const profilePhoto = document.getElementById("profile-photo");
    const profileFile = document.getElementById("input-profile-photo");
    if (profilePhoto !== null) profilePhoto.onclick = () => profileFile.click();
  }, []);

  const changePlan = useCallback(async () => {
    setLoading(true);
    try {
      if (newPlan !== oldPlan) {
        console.log(newPlan);
        const response = await userChangeFertilizer(newPlan);
        if (response.status === 200 || response.status === 204) {
          const { data } = response;
          const aTag = document.createElement("a");
          aTag.setAttribute("href", data);
          aTag.click();
        }
      }
    } catch (err) {
      console.log(err);
      showNotification("error", String(err));
    }
    setLoading(false);
  }, [newPlan, oldPlan]);

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[5].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul
                className={css({ "li::after": { display: "none !important" } })}
              >
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <ChevronRightIcon />
                <li>
                  <Link to="/profile">
                    {languageState.texts.Links[5].label}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>

      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer
              ignoreDefault
              className="col-lg-4 sm-margin-30px-bottom"
            >
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-tb padding-30px-lr border-radius-3"
              >
                <SitoContainer
                  ignoreDefault
                  className="text-center margin-30px-bottom"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { md: "229px", xs: "300px" },
                      height: { md: "229px", xs: "300px" },
                    }}
                  >
                    <SitoImage
                      id="profile-photo"
                      src={userRPhoto}
                      alt=""
                      sx={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>
                  <input
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    id="input-profile-photo"
                    {...register("input-profile-photo")}
                    onChange={uploadPhoto}
                  />
                  <h4 className="font-size19 no-margin-bottom">
                    {userData.nombre}
                  </h4>
                  <span className="font-size16">{userData.apellidos}</span>
                  <span className="font-size16">{`${type[0]?.toUpperCase()}${type
                    .substring(1)
                    .toLowerCase()}`}</span>
                </SitoContainer>

                <Sidebar currentLink={2} role={role} />
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-8">
              <SitoContainer
                ignoreDefault
                className="padding-20px-left sm-no-padding-left"
              >
                <SitoContainer
                  ignoreDefault
                  className="theme-shadow border-radius-3"
                >
                  <h4
                    className={`font-size24 ${css({
                      padding: "10px",
                      margin: 0,
                    })}`}
                  >
                    {languageState.texts.Profile.Sidebar.Links[2].label}
                  </h4>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ marginBottom: "10px" }}
                  >
                    <FormControl fullWidth sx={{ marginRight: "10px" }}>
                      <InputLabel>
                        {languageState.texts.Subscriptions.ChangePlan}
                      </InputLabel>
                      <Select
                        value={newPlan}
                        label={languageState.texts.Subscriptions.ChangePlan}
                        onChange={handleChangePlan}
                      >
                        {plans.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {console.log(newPlan === oldPlan)}
                    <Button
                      type="button"
                      disabled={newPlan === oldPlan}
                      className={`butn ${css({
                        height: "56px",
                        width: "132px",
                      })}`}
                      onClick={changePlan}
                      variant="contained"
                    >
                      {languageState.texts.Buttons.Save}
                    </Button>
                  </Box>
                  <ComplexTable
                    onPageChange={onPagination}
                    page={page}
                    columns={columns}
                    rows={localList}
                    noChecks
                    propOnClick={(e) => {
                      e.preventDefault();
                    }}
                    hasImage={false}
                  />
                  {!loading && !localList.length && localList !== -1 && (
                    <Empty />
                  )}
                  {!loading && localList === -1 && <Error onAction={retry} />}
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Payments;
