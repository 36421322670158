/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from "react";

import PropTypes from "prop-types";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { useTheme, Box, Modal } from "@mui/material";

// @mui/icons-material
import SendIcon from "@mui/icons-material/Send";

// services
import { getLinkers } from "services/email/post";

// sito components
import SitoContainer from "sito-container";

// components
import Loader from "components/Loader/Loader";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

const WhatsappDialog = (props) => {
  const theme = useTheme();
  const { open, handleClose, number, propertyData, toClient } = props;

  const [loading, setLoading] = useState(true);
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [messageContent, setMessageContent] = useState("");

  const toWhatMe = (e) => {
    e.preventDefault();
    const link = document.getElementById("to-wa.me");
    if (link) link.click();
  };

  const [ok, setOk] = useState(true);

  const validate = () => setOk(true);

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.MessageRequired,
          });
      }
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMessageContent(value);
  };

  const prepareChat = async () => {
    setLoading(true);
    if (propertyData.length) {
      let parsedMessage = "";
      if (propertyData.length === 1) {
        const [property] = propertyData;
        if (property.inmuebleCaracteristicas) {
          const parsedTitleCharacteristic = property.inmuebleCaracteristicas
            .length
            ? property.inmuebleCaracteristicas.find(
                (item) => item.caracteristica.id === 73
              ).valor
            : "";
          if (toClient)
            parsedMessage =
              languageState.texts.EmailDialog.ClientMessagesSingular;
          else if (number === "")
            parsedMessage =
              languageState.texts.EmailDialog.AnnounceMessageContentW;
          else
            parsedMessage = `${languageState.texts.EmailDialog.MessageContent}${parsedTitleCharacteristic}`;
        } else if (toClient)
          parsedMessage =
            languageState.texts.EmailDialog.ClientMessagesSingular;
        else if (number === "")
          parsedMessage =
            languageState.texts.EmailDialog.AnnounceMessageContentW;
        else
          parsedMessage = `${languageState.texts.EmailDialog.MessageContent}${
            property.address || property.direccion
          }`;
      } else if (toClient) {
        parsedMessage = languageState.texts.EmailDialog.ClientMessagesPlural;
        if (!toClient)
          propertyData.forEach((item, i) => {
            parsedMessage += item.direccion.trim();
            if (i === propertyData.length - 2)
              parsedMessage += languageState.texts.Contact.inputs.subject.and;
            else if (i < propertyData.length - 2) parsedMessage += ", ";
            else parsedMessage += ".";
          });
      } else if (number === "")
        parsedMessage = languageState.texts.EmailDialog.AnnounceMessageContentW;
      else parsedMessage = languageState.texts.EmailDialog.MessageContent;
      try {
        /* const linkers = await getLinkers(
          propertyData.map((item) => `https://crabhaus.com/#/public-details?id=${item.id}`)
        );
        const linkersData = await linkers.data;
        linkersData.forEach((item) => {
          parsedMessage += `\n${item}`;
        }); */
        parsedMessage += "\n";
        propertyData.forEach((item, i) => {
          parsedMessage += `https://crabhaus.com/#/public-details?id=${item.id}`;
          if (i < propertyData.length - 1) parsedMessage += "\n";
        });
        parsedMessage += languageState.texts.EmailDialog.ClientMessagesBye;
      } catch (err) {
        console.error(err);
      }
      setMessageContent(parsedMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    prepareChat();
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        component="form"
        onSubmit={toWhatMe}
        sx={{
          p: 4,
          top: "50%",
          width: { md: "600px", sm: "450px", xs: "90%" },
          left: "50%",
          boxShadow: 24,
          position: "absolute",
          bgcolor: "background.paper",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Loader minimal visible={loading} />

        <a
          href={`https://wa.me/${number.replace(
            /\s/g,
            ""
          )}?text=${encodeURIComponent(messageContent)}`}
          target="_blank"
          rel="noreferrer"
          id="to-wa.me"
        />
        <h5>{languageState.texts.WhatsappDialog.Title}</h5>
        <SitoContainer flexDirection="column">
          <label htmlFor="name">
            {languageState.texts.Contact.inputs.message.labelAlter}
          </label>
          <textarea
            id="message"
            name="message"
            className={`form-control ${css({ height: "200px !important" })}`}
            required
            value={messageContent}
            onChange={handleChange}
            onInput={validate}
            onInvalid={invalidate}
            placeholder={languageState.texts.Contact.inputs.message.placeholder}
          />
        </SitoContainer>
        <SitoContainer
          justifyContent="flex-end"
          sx={{ marginTop: "20px", fullWidth: "100%" }}
        >
          <button
            type="button"
            onClick={handleClose}
            className={`butn ${css({
              background: "none",
              marginRight: "20px",
              color: "#000",
              border: `2px solid ${theme.palette.primary.main}`,
            })}`}
          >
            {languageState.texts.Buttons.Cancel}
          </button>
          <button type="submit" className="butn">
            {languageState.texts.Buttons.Send}
            <SendIcon sx={{ marginLeft: "20px" }} />
          </button>
        </SitoContainer>
      </Box>
    </Modal>
  );
};

WhatsappDialog.defaultProps = {
  toClient: false,
};

WhatsappDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
  propertyData: PropTypes.object.isRequired,
  toClient: PropTypes.bool,
};

export default WhatsappDialog;
