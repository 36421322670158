/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */

// prop types
import PropTypes from "prop-types";

// @mui components
import { Tabs, Tab, Box } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ padding: "0 !important" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabViewIcon = (props) => {
  const { content, tabs, value, onChange, tabsPosition, sx } = props;

  return (
    <Box sx={{ width: "100%", ...sx }}>
      {tabsPosition === "up" && (
        <Box>
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={value}
            onChange={onChange}
            aria-label="basic tabs example"
          >
            {tabs.map((item, i) => (
              <Tab key={item} label={item} {...a11yProps(i)} />
            ))}
          </Tabs>
        </Box>
      )}
      {content.map((item, i) => (
        <TabPanel key={`tc${i}`} value={value} index={i} sx={{ height: "400px" }}>
          {item}
        </TabPanel>
      ))}
      {tabsPosition === "down" && (
        <Box sx={{ marginTop: "10px" }}>
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={value}
            onChange={onChange}
            aria-label="basic tabs example"
          >
            {tabs.map((item, i) => (
              <Tab key={i} icon={item} {...a11yProps(i)} label="" />
            ))}
          </Tabs>
        </Box>
      )}
    </Box>
  );
};

TabViewIcon.defaultProps = {
  onChange: undefined,
  value: 0,
  tabsPosition: "up",
  sx: {},
};

TabViewIcon.propTypes = {
  content: PropTypes.arrayOf(PropTypes.node).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  tabsPosition: PropTypes.string,
  sx: PropTypes.object,
};

export default TabViewIcon;
