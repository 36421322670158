/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

// @mui-icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// @mui components
import { Button } from "@mui/material";

// sito components
import SitoContainer from "sito-container";

import "./style.css";

const Carousel = (props) => {
  const { content, height, width, indicators, id, arrows /* hasBackground */ } = props;

  return (
    <SitoContainer
      ignoreDefault
      id={`${id}Controls`}
      className="carousel slide slider-fade"
      extraProps={{ "data-ride": "carousel" }}
      sx={{
        height,
        width,
      }}
    >
      {indicators && (
        <ol className="carousel-indicators">
          {content.map((item, i) => (
            <li
              key={i}
              data-target={`#${id}Indicators`}
              data-slide-to="0"
              className={i === 0 ? "active" : ""}
            ></li>
          ))}
        </ol>
      )}

      <SitoContainer ignoreDefault className="carousel-inner" sx={{ height: "100%" }}>
        {content.map((item, i) => (
          <SitoContainer
            key={i}
            ignoreDefault
            sx={{ height: "100%", width: "100%" }}
            className={`carousel-item ${i === 0 ? "active" : ""}`}
          >
            {item}
          </SitoContainer>
        ))}
      </SitoContainer>
      {arrows && (
        <>
          <a
            className="carousel-control-prev"
            href={`#${id}Controls`}
            role="button"
            data-slide="prev"
          >
            <Button
              sx={{
                borderRadius: "100%",
                minWidth: 0,
                width: 0,
                minHeight: 0,
                height: 0,
                padding: "10px",
              }}
              variant="contained"
            >
              <ChevronLeftIcon sx={{ color: "#222" }} />
            </Button>
          </a>

          <a
            className="carousel-control-next"
            href={`#${id}Controls`}
            role="button"
            data-slide="next"
          >
            <Button
              sx={{
                borderRadius: "100%",
                minWidth: 0,
                width: 0,
                minHeight: 0,
                height: 0,
                padding: "10px",
              }}
              variant="contained"
            >
              <ChevronRightIcon sx={{ color: "#222" }} />
            </Button>
          </a>
        </>
      )}
    </SitoContainer>
  );
};

Carousel.defaultProps = {
  height: "100vh",
  width: "100%",
  id: "",
  indicators: false,
  arrows: false,
  // hasBackground: true,
};

Carousel.propTypes = {
  content: PropTypes.arrayOf(PropTypes.node).isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  id: PropTypes.string,
  // hasBackground: PropTypes.bool,
  indicators: PropTypes.bool,
  arrows: PropTypes.bool,
};

export default Carousel;
