/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/function-component-definition */
import { useEffect, useState, useReducer, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// @emotion
import { css } from "@emotion/css";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// @mui/icons-material
import LockIcon from "@mui/icons-material/Lock";
import SearchIcon from "@mui/icons-material/Search";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// @mui components
import {
  Box,
  Tooltip,
  Autocomplete,
  Button,
  IconButton,
  TextField,
} from "@mui/material";

// services
import { propertyList } from "services/properties/get";
import { propertyTypeList } from "services/properties/types/get";
import { divisionList } from "services/geolocation/division/get";
import { stateList as stateGList } from "services/geolocation/state/get";
import { getMe } from "services/users/get";
import { sendEmailPDF } from "services/email/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// own components
import WhatsappDialog from "components/MUI/WhatsappDialog/WhatsappDialog";
import ShareDialog from "components/MUI/ShareDialog/ShareDialog";
import EmailDialog from "components/MUI/EmailDialog/EmailDialog";
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// utils
// import createPdf from "utils/pdf";
import { apiTokenCertified } from "utils/functions";
import { parseColumns, prepareRows } from "../../utils";

// props and styles
import { searchButtonSx } from "../Insert/styles";

const List = (props) => {
  const navigate = useNavigate();
  const { navigateToInsert, onEdit } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { control, watch } = useForm();

  const [filtering, setFiltering] = useState(false);

  const [valorUsdMin, setValorUsdMin] = useState();
  const [valorUsdMax, setValorUsdMax] = useState();
  const [valorPesosMin, setValorPesosMin] = useState();
  const [valorPesosMax, setValorPesosMax] = useState();
  const [environmentsMin, setValorEnvironmentsMin] = useState();
  const [environmentsMax, setValorEnvironmentsMax] = useState();

  const values = watch([
    "valorUsdMin",
    "valorUsdMax",
    "valorPesosMin",
    "valorPesosMax",
    "environmentsMin",
    "environmentsMax",
  ]);

  useEffect(() => {
    setValorUsdMin(values[0]);
    setValorUsdMax(values[1]);
    setValorPesosMin(values[2]);
    setValorPesosMax(values[3]);
    setValorEnvironmentsMin(values[4]);
    setValorEnvironmentsMax(values[5]);
  }, [values]);

  const localListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = [];
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };

  // dialogs
  const [whatsappDialog, setWhatsappDialog] = useState(false);
  const onCloseWhatsappDialog = () => setWhatsappDialog(false);

  const [emailDialog, setEmailDialog] = useState(false);
  const onCloseEmailDialog = () => setEmailDialog(false);

  const [toShareProperties, setToShareProperties] = useState([]);
  const [localList, setLocalList] = useReducer(localListReducer, []);

  const localDeactivatedReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = localListState;
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };
  const [localDeactivated, setLocalDeactivated] = useReducer(
    localDeactivatedReducer,
    []
  );
  const [seeBlocked, setSeeBlocked] = useState(false);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const fetchMultimedia = (multimedias) => {
    const images = [];
    if (multimedias !== null) {
      multimedias.forEach((item) => {
        if (item.tipo === "IMAGEN") images.push(item.url);
      });
      return images[0];
    }
    return undefined;
  };

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyTypeId, setPropertyTypeId] = useState();

  const [loadingPropertyTypes, setLoadingPropertyTypes] = useState(false);

  const fetchPropertyTypes = async () => {
    try {
      const propertyTypesPromise = await propertyTypeList(0, -1);
      if (propertyTypesPromise.status === 200) {
        const { data } = await propertyTypesPromise.data;
        const parsedTypes = [];
        data.forEach((item) =>
          parsedTypes.push({ id: item.id, name: item.nombre })
        );
        setPropertyTypes(parsedTypes);
        if (parsedTypes.length && !propertyTypeId)
          setPropertyTypeId(parsedTypes[0].id);
      } else if (
        propertyTypesPromise.error &&
        !apiTokenCertified(propertyTypesPromise.error)
      )
        navigate("/log-out");
      else {
        setLocalList(-1);
        showNotification("error", languageState.texts.Errors.NotConnected);
        // setLoadingProper(false);
        return;
      }
    } catch (err) {
      console.error(err);
      showNotification("error", String(err));
    }
    setLoadingPropertyTypes(false);
  };

  const fetch = async (from = 0, filters = "") => {
    setLoading(true);
    setLoadingPropertyTypes(true);
    fetchPropertyTypes();
    try {
      const response = await propertyList(
        from,
        10,
        `filtros[estado]=${
          seeBlocked ? "BORRADO" : "ACTIVO"
        }&opciones[multimedias]=1${filters}`
      );

      const userData = await getMe();
      let userId = -1;
      if (userData && userData.data) {
        const { inmobiliaria } = userData.data;
        if (inmobiliaria) userId = inmobiliaria.user_id;
      }
      fetchStates(1);
      if (response.status === 200 && userId > 1) {
        const { data, total } = await response.data;
        if (data.length) {
          console.log(data);
          if (!columns.length) setColumns(parseColumns(languageState.texts));
          const parsedRows = [];
          for (const item of data) {
            const { inmobiliaria_id } = item;
            const parsedItem = prepareRows(languageState.texts, item);
            const firstImage = fetchMultimedia(item.multimedias); // await fetchMultimedia(item.id);
            //* adding imagen
            if (!item.imagen)
              parsedItem.imagen = (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    div: {
                      width: "100px",
                      height: "60px",
                    },
                  }}
                >
                  <SitoImage
                    sx={{
                      width: "100px",
                      height: "60px",
                      objectFit: "cover",
                      borderRadius: "3px",
                    }}
                    src={firstImage}
                    alt="property"
                  />
                </Box>
              );
            if (userId === inmobiliaria_id) parsedRows.push(parsedItem);
          }
          if (!from) {
            if (!seeBlocked)
              setLocalList({ type: "add", toAdd: parsedRows, max: total });
            else
              setLocalDeactivated({
                type: "add",
                toAdd: parsedRows,
                max: total,
              });
          } else setLocalList({ type: "set", toAdd: parsedRows, from });
        }
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
      else {
        setLocalList(-1);
        showNotification("error", languageState.texts.Errors.NotConnected);
      }
    } catch (err) {
      console.error(err);
      showNotification("error", String(err));
    }

    setLoading(false);
  };

  const retry = () => fetch();

  const editToDo = (selected) => onEdit(selected[0]);

  useEffect(() => {
    if (seeBlocked) if (!localDeactivated.length) fetch();
  }, [seeBlocked]);

  const [page, setPage] = useState(0);

  const onPagination = (newPage) => {
    if (localList[10 * newPage]) fetch(10 * newPage);
    setPage(newPage);
  };

  const [blockedPage, setBlockedPage] = useState(0);

  const onPaginationBlocked = (newPage) => {
    if (localList[10 * newPage]) fetch(10 * newPage);
    setBlockedPage(newPage);
  };

  useEffect(() => {
    if (!localList.length) fetch();
  }, []);

  const shownFiltersReducer = (filtersState, action) => {
    const { type } = action;
    switch (type) {
      case "added": {
        const newFilters = { ...filtersState };
        const { filterAdded } = action;
        newFilters[String(filterAdded.id)] = { ...filterAdded };
        return newFilters;
      }
      case "removed": {
        const newFilters = { ...filtersState };
        const { filterRemoved } = action;
        delete newFilters[String(filterRemoved)];
        return newFilters;
      }
      default:
        return { error: "error" };
    }
  };

  const [shownFilters] = useReducer(shownFiltersReducer, {
    ...languageState.texts.Filters,
  });

  const noFilters = useCallback(
    () => !Object.values(shownFilters).length,
    [shownFilters]
  );

  //* states
  const stateReducer = (stateState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const newStateState = stateState;
        const { country, newStates } = action;
        newStateState[country] = newStates;
        return { ...newStateState };
      }
      case "addDivisions": {
        const newStateState = { ...stateState };
        const { newDivisions, state } = action;
        newStateState[1][state].division = {
          ...Object.values(newDivisions).map((item) => item.id),
        };
        return { ...newStateState };
      }
      default:
        return {};
    }
  };

  const [stateGs, setStateGs] = useReducer(stateReducer, {});
  const [selectedState, setSelectedState] = useState();
  const [selectedStateGId, setSelectedStateGId] = useState(0);

  const [loadingStates, setLoadingStates] = useState(false);

  const fetchStates = async (countryLId) => {
    setLoadingStates(true);
    const stateGPromise = await stateGList(
      0,
      -1,
      `filtros[pais_id]=${countryLId}`
    );
    const parsedStates = {};
    if (stateGPromise.status === 200) {
      const { data } = await stateGPromise.data;
      if (data.length) {
        data.forEach((item) => {
          const { name, pais_id } = item;
          const parsedItem = { id: item.id, name, pais_id };
          parsedItem.division = {};
          parsedStates[item.id] = parsedItem;
        });
        setStateGs({
          type: "set",
          country: countryLId,
          newStates: parsedStates,
        });
      }
    } else if (stateGPromise.error && !apiTokenCertified(stateGPromise.error))
      navigate("/log-out");
    else {
      showNotification("error", languageState.texts.Errors.LoadingStates);
      setSelectedStateGId(0);
    }
    setLoadingStates(false);
  };

  //* divisions
  const divisionReducer = (divisionState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const newDivisionState = divisionState;
        const { state, newDivisions } = action;
        newDivisionState[state] = newDivisions;
        return { ...newDivisionState };
      }
      case "addLocations": {
        const newDivisionState = { ...divisionState };
        const { newLocations, division } = action;
        if (!newDivisionState[division])
          newDivisionState[division] = { locations: {} };
        newDivisionState[division].locations = {
          ...Object.values(newLocations).map((item) => item.id),
        };
        return { ...newDivisionState };
      }
      default:
        return {};
    }
  };

  const [division, setDivision] = useState();
  const [selectedDivisionId, setSelectedDivisionId] = useState(0);
  const [divisionToSearch, setDivisionToSearch] = useState("");
  const [divisions, setDivisions] = useReducer(divisionReducer, {});
  const [loadingDivision, setLoadingDivisions] = useState(false);

  const fetchDivision = async (divisionName) => {
    setLoadingDivisions(true);
    const divisionPromise = await divisionList(
      0,
      -1,
      `filtros[estado_id]=${selectedStateGId}&filtros[name]=${divisionName}`
    );
    const parsedDivisions = {};
    if (divisionPromise.status === 200) {
      const { data } = await divisionPromise.data;
      if (data.length) {
        data.forEach((item) => {
          // if (item.name.toLowerCase() === divisionName.toLowerCase()) setDivisionId(item.id);
          const { name, estado_id } = item;
          const parsedItem = { id: item.id, name, estado_id };
          parsedItem.locations = {};
          parsedDivisions[item.name.toLowerCase()] = parsedItem;
        });
        setDivisions({
          type: "set",
          state: selectedStateGId,
          newDivisions: parsedDivisions,
        });
        setStateGs({
          type: "addDivisions",
          newDivisions: parsedDivisions,
          state: selectedStateGId,
        });
      } else setSelectedDivisionId(0);
    } else if (
      parsedDivisions.error &&
      !apiTokenCertified(parsedDivisions.error)
    )
      navigate("/log-out");
    else showNotification("error", languageState.texts.Errors.LoadingDivisions);
    setLoadingDivisions(false);
  };

  const handleSelected = async (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "selected-division": {
        setLoadingDivisions(true);
        await fetchDivision(value, true);
        break;
      }
      case "selected-state":
        return setSelectedStateGId(Number(value));
      default:
        return {};
    }
  };

  const toSearch = (e) => {
    const { id, value, wasSelected } = e.target;
    const lValue = value.toLowerCase();
    switch (id) {
      case "selected-division-input":
        if (!wasSelected)
          handleSelected({ target: { id: "selected-division", value } });
        break;
      default: {
        const filter = Object.values(stateGs[1]).filter((item) => {
          if (item.name.toLowerCase() === lValue) return item;
          return null;
        });
        if (filter.length)
          handleSelected({
            target: { id: "selected-state", value: filter[0].id },
          });
        else setSelectedStateGId(0);
        break;
      }
    }
  };

  const executeFilter = () => {
    //* titlte
    // eslint-disable-next-line no-unused-vars
    const titleString = {};
    //* usd
    const usdString = {};
    if (valorUsdMin || valorUsdMin === "0") usdString.valor_desde = valorUsdMin;
    if (valorUsdMax || valorUsdMax === "0") usdString.valor_hasta = valorUsdMax;
    if (usdString.valor_desde || usdString.valor_hasta) {
      // operator
      if (valorUsdMax && valorUsdMin) usdString.operador = "BETWEEN";
      else if (valorUsdMax && !valorUsdMin) usdString.operador = "<=";
      else if (valorUsdMin && !valorUsdMax) usdString.operador = ">=";
    }
    //* pesos
    const pesosString = {};
    if (valorPesosMin || valorPesosMin === "0")
      pesosString.valor_desde = valorPesosMin;
    if (valorPesosMax || valorPesosMax === "0")
      pesosString.valor_hasta = valorPesosMax;
    if (pesosString.valor_desde || pesosString.valor_hasta) {
      // operator
      if (valorPesosMax && valorPesosMin) pesosString.operador = "BETWEEN";
      else if (valorPesosMax && !valorPesosMin) pesosString.operador = "<=";
      else if (valorPesosMin && !valorPesosMax) pesosString.operador = ">=";
    }
    //* environments
    const environmentsString = {};
    if (environmentsMin || environmentsMin === "0")
      environmentsString.valor_desde = valorPesosMin;
    if (environmentsMax || environmentsMax === "0")
      environmentsString.valor_hasta = valorPesosMax;
    if (environmentsString.valor_desde || environmentsString.valor_hasta) {
      // operator
      if (environmentsMax && environmentsMin)
        environmentsString.operador = "BETWEEN";
      else if (environmentsMax && !environmentsMin)
        environmentsString.operador = "<=";
      else if (environmentsMin && !environmentsMax)
        environmentsString.operador = ">=";
    }
    let filters = "";
    //* number filters
    if (usdString.operador)
      filters += `&filtros${encodeURIComponent(
        "["
      )}valor_usd${encodeURIComponent("]")}=${encodeURIComponent(
        JSON.stringify(usdString)
      )}`;
    if (pesosString.operador)
      filters += `&filtros${encodeURIComponent(
        "["
      )}valor_pesos${encodeURIComponent("]")}=${encodeURIComponent(
        JSON.stringify(pesosString)
      )}`;
    if (environmentsString.operador)
      filters += `&filtros${encodeURIComponent(
        "["
      )}ambientes${encodeURIComponent("]")}=${encodeURIComponent(
        JSON.stringify(environmentsString)
      )}`;
    //* property type
    if (propertyTypeId)
      filters += `&filtros${encodeURIComponent(
        "["
      )}tipo_propiedad_id${encodeURIComponent("]")}=${propertyTypeId}`;
    //* state
    if (selectedStateGId)
      filters += `&filtros${encodeURIComponent(
        "["
      )}estado_id${encodeURIComponent("]")}=${selectedStateGId}`;
    if (selectedDivisionId)
      filters += `&filtros${encodeURIComponent(
        "["
      )}barrio_id${encodeURIComponent("]")}=${selectedDivisionId}`;
    setLocalList({ type: "clean" });
    fetch(0, filters);
  };

  const [showShareDialog, setShowShareDialog] = useState(false);

  const onCloseShareDialog = (clicked) => {
    switch (clicked) {
      case 1:
        setEmailDialog(true);
        break;
      case 2:
        setWhatsappDialog(true);
        break;

      default:
        break;
    }
    setShowShareDialog(false);
  };

  const onShare = (selected) => {
    const toShare = [];
    selected.forEach((item) => {
      const element = localList.find((jtem) => jtem.id === item);
      if (element) toShare.push(element);
    });
    setToShareProperties(toShare);
    setShowShareDialog(true);
  };

  const emailSend = async (subject, to, message) => {
    try {
      // const resultPDF = await createPdf([1, 3], languageState.texts);
      await sendEmailPDF(
        to,
        subject,
        message.replaceAll("\n", "<br>") /* resultPDF.body, resultPDF.css */
      );
      setEmailDialog(false);
    } catch (err) {
      console.error(err);
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
  };

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <ShareDialog open={showShareDialog} handleClose={onCloseShareDialog} />
      <WhatsappDialog
        number=""
        propertyData={toShareProperties}
        open={whatsappDialog}
        handleClose={onCloseWhatsappDialog}
      />
      <EmailDialog
        email=""
        propertyData={toShareProperties}
        open={emailDialog}
        handleClose={onCloseEmailDialog}
        onSubmit={emailSend}
      />
      <Loader visible={loading} minimal />
      {!loading && (
        <>
          {!seeBlocked ? (
            <>
              {((localList.length > 0 && !noFilters()) || noFilters) && (
                <ComplexTable
                  onPageChange={onPagination}
                  page={page}
                  columns={columns}
                  rows={localList}
                  onEdit={editToDo}
                  onShare={onShare}
                  canShow
                  filtering={filtering}
                  filterHeight="370px"
                  filters={
                    <SitoContainer
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      sx={{ height: "370px" }}
                    >
                      <SitoContainer
                        alignItems="center"
                        sx={{ marginTop: "13px" }}
                      >
                        <IconButton
                          variant="text"
                          color={filtering ? "primary" : "inherit"}
                          onClick={() => setFiltering(!filtering)}
                          sx={{ marginTop: "-13px" }}
                        >
                          {filtering ? <FilterAltIcon /> : <FilterAltOffIcon />}
                        </IconButton>
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="form-group"
                        sx={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        {!loadingPropertyTypes &&
                          filtering &&
                          Object.values(shownFilters).map((item) => (
                            <>
                              {item.type === "input" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{ width: "715px", marginTop: "5px" }}
                                >
                                  <label
                                    htmlFor={item.name}
                                    className={css({
                                      margin: 0,
                                      marginRight: "20px",
                                      width: "110px",
                                    })}
                                  >
                                    {
                                      languageState.texts.Filters[
                                        String(item.id)
                                      ].label
                                    }
                                  </label>
                                  <label
                                    htmlFor={`${item.name}Min`}
                                    className={css({
                                      margin: 0,
                                      marginRight: "20px",
                                    })}
                                  >
                                    {languageState.texts.Labels.Min}
                                  </label>
                                  <Controller
                                    control={control}
                                    name={`${item.name}Min`}
                                    render={({ field }) => (
                                      <input
                                        className={`form-control ${css({
                                          maxWidth: "125px",
                                          marginRight: "20px",
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                        })}`}
                                        placeholder={
                                          languageState.texts.Placeholders.Min
                                        }
                                        type="number"
                                        id={`${item.id}Min`}
                                        max={
                                          !values.filterEnvironmentsMax
                                            ? 99999
                                            : values.filterEnvironmentsMax
                                        }
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                  <label
                                    htmlFor={`${item.name}Max`}
                                    className={css({
                                      margin: 0,
                                      marginRight: "20px",
                                    })}
                                  >
                                    {languageState.texts.Labels.Max}
                                  </label>
                                  <Controller
                                    control={control}
                                    name={`${item.name}Max`}
                                    render={({ field }) => (
                                      <input
                                        className={`form-control ${css({
                                          maxWidth: "125px",
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                        })}`}
                                        placeholder={
                                          languageState.texts.Placeholders.Max
                                        }
                                        type="number"
                                        id={`${item.id}Max`}
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                </SitoContainer>
                              )}
                              {item.type === "text" && (
                                <>
                                  <SitoContainer
                                    alignItems="center"
                                    sx={{ width: "360px", marginTop: "5px" }}
                                  >
                                    <label
                                      htmlFor={item.name}
                                      className={css({
                                        margin: 0,
                                        marginRight: "20px",
                                      })}
                                    >
                                      {
                                        languageState.texts.Filters[
                                          String(item.id)
                                        ].label
                                      }
                                    </label>
                                    <Controller
                                      control={control}
                                      name={item.name}
                                      render={({ field }) => (
                                        <input
                                          className={`form-control ${css({
                                            maxWidth: "275px",
                                            marginRight: "20px",
                                            height:
                                              "calc(1.5em + 0.75rem + 2px)",
                                          })}`}
                                          placeholder={
                                            languageState.texts.Placeholders
                                              .Title
                                          }
                                          type="text"
                                          id={`${item.id}Title`}
                                          value={field.value}
                                          onChange={(event) => {
                                            field.onChange(event);
                                          }}
                                        />
                                      )}
                                    />
                                  </SitoContainer>
                                </>
                              )}
                              {item.type === "property-types" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{ width: "485px", marginTop: "5px" }}
                                >
                                  <label
                                    htmlFor="propertyType"
                                    className={css({
                                      marginRight: "20px",
                                      marginBottom: 0,
                                    })}
                                  >
                                    {
                                      languageState.texts.Dashboard.PropertyType
                                        .Select
                                    }
                                  </label>
                                  <select
                                    className={`form-control ${css({
                                      width: "200px",
                                      height: "calc(1.5em + 0.75rem + 2px)",
                                    })}`}
                                    id="propertyType"
                                    value={propertyTypeId}
                                    onChange={(e) => {
                                      setPropertyTypeId(e.target.value);
                                    }}
                                    disabled={!propertyTypes.length}
                                  >
                                    {propertyTypes.map((jtem) => (
                                      <option key={jtem.id} value={jtem.id}>
                                        {jtem.name}
                                      </option>
                                    ))}
                                  </select>
                                </SitoContainer>
                              )}
                              {item.type === "zones" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{
                                    marginTop: "5px",
                                    position: "relative",
                                    height: "calc(1.5em + 0.75rem + 2px)",
                                    width: "490px",
                                  }}
                                >
                                  <label
                                    htmlFor="state"
                                    className={css({
                                      marginRight: "20px",
                                      marginBottom: 0,
                                      width: "235px",
                                    })}
                                  >
                                    {
                                      languageState.texts.Dashboard.StateG
                                        .SelectAlter
                                    }
                                  </label>
                                  <Autocomplete
                                    options={
                                      Boolean(stateGs) && Boolean(stateGs[1])
                                        ? Object.values(stateGs[1])
                                        : []
                                    }
                                    getOptionLabel={(option) => option.name}
                                    value={selectedState}
                                    sx={{
                                      width: "100%",
                                      div: {
                                        height: "calc(1.5em + 0.75rem + 2px)",
                                      },
                                    }}
                                    onChange={(event, newValue) => {
                                      if (newValue !== null) {
                                        setSelectedState(newValue);
                                        setSelectedStateGId(newValue.id);
                                        toSearch({
                                          target: {
                                            id: "selected-state-input",
                                            value: newValue.name,
                                          },
                                        });
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {
                                              marginTop: "-8px",
                                            },
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        placeholder={
                                          languageState.texts.Filters[7].label
                                        }
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    autoHighlight
                                  />
                                  <Loader
                                    visible={loadingStates}
                                    minimal
                                    small
                                  />
                                </SitoContainer>
                              )}
                              {item.type === "divisions" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{
                                    marginTop: "5px",
                                    position: "relative",
                                    height: "calc(1.5em + 0.75rem + 2px)",
                                    width: "490px",
                                  }}
                                >
                                  <label
                                    htmlFor="division"
                                    className={css({
                                      marginRight: "20px",
                                      marginBottom: 0,
                                      width: "235px",
                                    })}
                                  >
                                    {
                                      languageState.texts.Dashboard.Division
                                        .SelectAlter
                                    }
                                  </label>
                                  <Autocomplete
                                    options={
                                      Boolean(divisions) &&
                                      Boolean(divisions[selectedStateGId])
                                        ? Object.values(
                                            divisions[selectedStateGId]
                                          )
                                        : []
                                    }
                                    disabled={!selectedStateGId}
                                    getOptionLabel={(option) => option.name}
                                    value={division}
                                    sx={{
                                      width: "100%",
                                      div: {
                                        height: "calc(1.5em + 0.75rem + 2px)",
                                      },
                                    }}
                                    onChange={(event, newValue) => {
                                      if (newValue !== null) {
                                        setDivision(newValue);
                                        setSelectedDivisionId(newValue.id);
                                        setDivisionToSearch(newValue.name);
                                        toSearch({
                                          target: {
                                            id: "selected-division-input",
                                            value: newValue.name,
                                            wasSelected: true,
                                          },
                                        });
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={
                                          languageState.texts.Filters[6].label
                                        }
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {
                                              marginTop: "-8px",
                                            },
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    autoHighlight
                                  />
                                  <Button
                                    sx={{
                                      ...searchButtonSx,
                                      marginTop: "-3px",
                                      marginRight: "-5px",
                                    }}
                                    disabled={!selectedStateGId}
                                    onClick={() =>
                                      toSearch({
                                        target: {
                                          id: "selected-division-input",
                                          value: divisionToSearch,
                                        },
                                      })
                                    }
                                  >
                                    <SearchIcon />
                                  </Button>
                                  <Loader
                                    visible={loadingDivision}
                                    minimal
                                    small
                                  />
                                </SitoContainer>
                              )}
                            </>
                          ))}
                      </SitoContainer>
                      <SitoContainer
                        sx={{ position: "absolute", right: "5px", top: "5px" }}
                      >
                        {!seeBlocked ? (
                          <Tooltip
                            title={
                              languageState.texts.Dashboard.Submit.SeeBlocked
                            }
                          >
                            <IconButton onClick={() => setSeeBlocked(true)}>
                              <LockIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              languageState.texts.Dashboard.Submit.SeeActivated
                            }
                          >
                            <IconButton onClick={() => setSeeBlocked(false)}>
                              <LockOpenIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </SitoContainer>
                      <Button
                        variant="text"
                        color={
                          Object.values(shownFilters).length
                            ? "primary"
                            : "secondary"
                        }
                        onClick={executeFilter}
                        sx={{ marginTop: "-10px" }}
                        disabled={Object.values(shownFilters).length === 0}
                      >
                        {languageState.texts.Buttons.ExecuteFilter}
                      </Button>
                    </SitoContainer>
                  }
                />
              )}
            </>
          ) : (
            <>
              {((localDeactivated.length > 0 && !noFilters()) || noFilters) && (
                <ComplexTable
                  onPageChange={onPaginationBlocked}
                  page={blockedPage}
                  columns={columns}
                  rows={localDeactivated}
                  onEdit={editToDo}
                  canShow
                  filtering
                  filters={
                    <SitoContainer
                      flexDirection="column"
                      sx={{ height: "300px" }}
                    >
                      <SitoContainer alignItems="center">
                        <Button
                          variant="text"
                          color={
                            Object.values(shownFilters).length
                              ? "primary"
                              : "secondary"
                          }
                          onClick={executeFilter}
                          sx={{ marginTop: "-10px" }}
                          disabled={Object.values(shownFilters).length === 0}
                        >
                          {languageState.texts.Buttons.ExecuteFilter}
                        </Button>
                        <IconButton
                          variant="text"
                          color={filtering ? "primary" : "inherit"}
                          onClick={() => setFiltering(!filtering)}
                          sx={{ marginTop: "-13px" }}
                        >
                          {filtering ? <FilterAltIcon /> : <FilterAltOffIcon />}
                        </IconButton>
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="form-group"
                        sx={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        {filtering &&
                          Object.values(shownFilters).map((item) => (
                            <>
                              {item.type === "input" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{ width: "715px", marginTop: "5px" }}
                                >
                                  <label
                                    htmlFor={item.name}
                                    className={css({
                                      margin: 0,
                                      marginRight: "20px",
                                      width: "110px",
                                    })}
                                  >
                                    {
                                      languageState.texts.Filters[
                                        String(item.id)
                                      ].label
                                    }
                                  </label>
                                  <label
                                    htmlFor={`${item.name}Min`}
                                    className={css({
                                      margin: 0,
                                      marginRight: "20px",
                                    })}
                                  >
                                    {languageState.texts.Labels.Min}
                                  </label>
                                  <Controller
                                    control={control}
                                    name={`${item.name}Min`}
                                    render={({ field }) => (
                                      <input
                                        className={`form-control ${css({
                                          maxWidth: "125px",
                                          marginRight: "20px",
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                        })}`}
                                        placeholder={
                                          languageState.texts.Placeholders.Min
                                        }
                                        type="number"
                                        id={`${item.id}Min`}
                                        max={
                                          !values.filterEnvironmentsMax
                                            ? 99999
                                            : values.filterEnvironmentsMax
                                        }
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                  <label
                                    htmlFor={`${item.name}Max`}
                                    className={css({
                                      margin: 0,
                                      marginRight: "20px",
                                    })}
                                  >
                                    {languageState.texts.Labels.Max}
                                  </label>
                                  <Controller
                                    control={control}
                                    name={`${item.name}Max`}
                                    render={({ field }) => (
                                      <input
                                        className={`form-control ${css({
                                          maxWidth: "125px",
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                        })}`}
                                        placeholder={
                                          languageState.texts.Placeholders.Max
                                        }
                                        type="number"
                                        id={`${item.id}Max`}
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                </SitoContainer>
                              )}
                              {item.type === "text" && (
                                <>
                                  <SitoContainer
                                    alignItems="center"
                                    sx={{ width: "360px", marginTop: "5px" }}
                                  >
                                    <label
                                      htmlFor={item.name}
                                      className={css({
                                        margin: 0,
                                        marginRight: "20px",
                                      })}
                                    >
                                      {
                                        languageState.texts.Filters[
                                          String(item.id)
                                        ].label
                                      }
                                    </label>
                                    <Controller
                                      control={control}
                                      name={item.name}
                                      render={({ field }) => (
                                        <input
                                          className={`form-control ${css({
                                            maxWidth: "275px",
                                            marginRight: "20px",
                                            height:
                                              "calc(1.5em + 0.75rem + 2px)",
                                          })}`}
                                          placeholder={
                                            languageState.texts.Placeholders
                                              .Title
                                          }
                                          type="text"
                                          id={`${item.id}Title`}
                                          value={field.value}
                                          onChange={(event) => {
                                            field.onChange(event);
                                          }}
                                        />
                                      )}
                                    />
                                  </SitoContainer>
                                </>
                              )}
                              {item.type === "property-types" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{ width: "485px", marginTop: "5px" }}
                                >
                                  <label
                                    htmlFor="propertyType"
                                    className={css({
                                      marginRight: "20px",
                                      marginBottom: 0,
                                    })}
                                  >
                                    {
                                      languageState.texts.Dashboard.PropertyType
                                        .Select
                                    }
                                  </label>
                                  <select
                                    className={`form-control ${css({
                                      width: "200px",
                                      height: "calc(1.5em + 0.75rem + 2px)",
                                    })}`}
                                    id="propertyType"
                                    value={propertyTypeId}
                                    onChange={(e) => {
                                      setPropertyTypeId(e.target.value);
                                    }}
                                    disabled={!propertyTypes.length}
                                  >
                                    {propertyTypes.map((jtem) => (
                                      <option key={jtem.id} value={jtem.id}>
                                        {jtem.name}
                                      </option>
                                    ))}
                                  </select>
                                </SitoContainer>
                              )}
                              {item.type === "zones" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{
                                    marginTop: "5px",
                                    position: "relative",
                                    height: "calc(1.5em + 0.75rem + 2px)",
                                    width: "490px",
                                  }}
                                >
                                  <label
                                    htmlFor="state"
                                    className={css({
                                      marginRight: "20px",
                                      marginBottom: 0,
                                      width: "235px",
                                    })}
                                  >
                                    {
                                      languageState.texts.Dashboard.StateG
                                        .SelectAlter
                                    }
                                  </label>
                                  <Autocomplete
                                    options={
                                      Boolean(stateGs) && Boolean(stateGs[1])
                                        ? Object.values(stateGs[1])
                                        : []
                                    }
                                    getOptionLabel={(option) => option.name}
                                    value={selectedState}
                                    sx={{
                                      width: "100%",
                                      div: {
                                        height: "calc(1.5em + 0.75rem + 2px)",
                                      },
                                    }}
                                    onChange={(event, newValue) => {
                                      if (newValue !== null) {
                                        setSelectedState(newValue);
                                        setSelectedStateGId(newValue.id);
                                        toSearch({
                                          target: {
                                            id: "selected-state-input",
                                            value: newValue.name,
                                          },
                                        });
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {
                                              marginTop: "-8px",
                                            },
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        placeholder={
                                          languageState.texts.Filters[7].label
                                        }
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    autoHighlight
                                  />
                                  <Loader
                                    visible={loadingStates}
                                    minimal
                                    small
                                  />
                                </SitoContainer>
                              )}
                              {item.type === "divisions" && (
                                <SitoContainer
                                  alignItems="center"
                                  sx={{
                                    marginTop: "5px",
                                    position: "relative",
                                    height: "calc(1.5em + 0.75rem + 2px)",
                                    width: "490px",
                                  }}
                                >
                                  <label
                                    htmlFor="division"
                                    className={css({
                                      marginRight: "20px",
                                      marginBottom: 0,
                                      width: "235px",
                                    })}
                                  >
                                    {
                                      languageState.texts.Dashboard.Division
                                        .SelectAlter
                                    }
                                  </label>
                                  <Autocomplete
                                    options={
                                      Boolean(divisions) &&
                                      Boolean(divisions[selectedStateGId])
                                        ? Object.values(
                                            divisions[selectedStateGId]
                                          )
                                        : []
                                    }
                                    disabled={!selectedStateGId}
                                    getOptionLabel={(option) => option.name}
                                    value={division}
                                    sx={{
                                      width: "100%",
                                      div: {
                                        height: "calc(1.5em + 0.75rem + 2px)",
                                      },
                                    }}
                                    onChange={(event, newValue) => {
                                      if (newValue !== null) {
                                        setDivision(newValue);
                                        setSelectedDivisionId(newValue.id);
                                        setDivisionToSearch(newValue.name);
                                        toSearch({
                                          target: {
                                            id: "selected-division-input",
                                            value: newValue.name,
                                            wasSelected: true,
                                          },
                                        });
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={
                                          languageState.texts.Filters[6].label
                                        }
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {
                                              marginTop: "-8px",
                                            },
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    autoHighlight
                                  />
                                  <Button
                                    sx={{
                                      ...searchButtonSx,
                                      marginTop: "-3px",
                                      marginRight: "-5px",
                                    }}
                                    disabled={!selectedStateGId}
                                    onClick={() =>
                                      toSearch({
                                        target: {
                                          id: "selected-division-input",
                                          value: divisionToSearch,
                                        },
                                      })
                                    }
                                  >
                                    <SearchIcon />
                                  </Button>
                                  <Loader
                                    visible={loadingDivision}
                                    minimal
                                    small
                                  />
                                </SitoContainer>
                              )}
                            </>
                          ))}
                      </SitoContainer>
                      <SitoContainer
                        sx={{ position: "absolute", right: "5px", top: "5px" }}
                      >
                        {!seeBlocked ? (
                          <Tooltip
                            title={
                              languageState.texts.Dashboard.Submit.SeeBlocked
                            }
                          >
                            <IconButton onClick={() => setSeeBlocked(true)}>
                              <LockIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              languageState.texts.Dashboard.Submit.SeeActivated
                            }
                          >
                            <IconButton onClick={() => setSeeBlocked(false)}>
                              <LockOpenIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </SitoContainer>
                    </SitoContainer>
                  }
                />
              )}
            </>
          )}
        </>
      )}
      {console.log(noFilters())}
      {!loading && !localList.length && localList !== -1 && noFilters() && (
        <Empty onAction={navigateToInsert} />
      )}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

List.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default List;
