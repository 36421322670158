// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Takes a property type object, sends it to the API, and returns the response
 * @param {object} propertyType - The property type object that contains the property type's information.
 * @returns The data from the response.
 */
export const createPropertyType = async (propertyType) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/tipo-propiedad`,
    { ...propertyType },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the property type's ID and the ID of the property type to delete
 * @param {string} propertyTypeId - The property id of the property you want to delete.
 * @returns The data is being returned.
 */
export const deletePropertyType = async (propertyTypeId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/tipo-propiedad/${propertyTypeId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the propertyType's ID and propertyType data to modify it
 * @param {string} propertyTypeId - The propertyType id of the propertyType you want to delete.
 * @param {object} propertyTypeData - PropertyType data: name
 * @returns The data is being returned.
 */
export const modifyPropertyType = async (propertyTypeId, propertyTypeData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/tipo-propiedad/${propertyTypeId}`,
    { ...propertyTypeData },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
