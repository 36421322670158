/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unreachable */
/* eslint-disable no-lonely-if */
/* eslint-disable no-throw-literal */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useCallback, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// @emotion
import { css } from "@emotion/css";

// @mui/icons-material
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import Favorite from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";

// @mui components
import {
  useTheme,
  Box,
  FormControlLabel,
  Chip,
  Button,
  IconButton,
  Checkbox,
  Autocomplete,
  TextField,
} from "@mui/material";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// own components
import WhatsappDialog from "components/MUI/WhatsappDialog/WhatsappDialog";
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";
import ShareDialog from "components/MUI/ShareDialog/ShareDialog";
import EmailDialog from "components/MUI/EmailDialog/EmailDialog";
import TabView from "components/MUI/TabView/TabView";
import Error from "components/MUI/Error/Error";
import Empty from "components/MUI/Empty/Empty";
import Loader from "components/Loader/Loader";
import Map from "components/Map/Map";
import NumberInput from "components/NumberInput/NumberInput";

// services
import { getMe } from "services/users/get";
// get
import { characteristicGroupList } from "services/characteristics/groups/get";
import { characteristicList } from "services/characteristics/get";
import { propertyTypeList } from "services/properties/types/get";
import { propertyList, activateProperty } from "services/properties/get";
import { searchList } from "services/searchs/get";
// post
import { createMultimedia, deleteMultimedia } from "services/multimedias/post";
import { sendEmail, sendEmailPDF } from "services/email/post";
import {
  createProperty,
  modifyProperty,
  deleteProperty,
} from "services/properties/post";
import {
  createSearch,
  createSearchDivision,
  modifySearch,
  deleteSearch,
  deleteSearchDivision,
  deleteSearchTypes,
  createSearchTypes,
} from "services/searchs/post";
import {
  createPropertyCharacteristic,
  modifyPropertyCharacteristic,
  deletePropertyCharacteristic,
} from "services/properties/characteristics/post";
// geolocation
import { countryList } from "services/geolocation/country/get";
import { locationList } from "services/geolocation/location/get";
import { divisionList } from "services/geolocation/division/get";
import { stateList as stateGList } from "services/geolocation/state/get";
// matches
import { matchPOST } from "services/match/post";
import { matchGET } from "services/match/get";

// functions
import {
  getComplexIndexOf,
  apiTokenCertified,
  scrollTo,
} from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

import createPdf from "utils/pdf";

// local components
import FilterMenu from "../../FilterMenu/FilterMenu";
import NoMatches from "./NoMatches/NoMatches";
import Tokko from "./Tokko/Tokko";

// utils
import { parseColumns, prepareRows } from "../../utils";

// props and styles
import {
  searchButtonSx,
  multimediaDeleteButtonSx,
  multimediaAddButtonSx,
  multimediaRowContainerSx,
  loadingMultimediaSx,
} from "./styles";
import { numberArg } from "utils/parser";
import { strToNumber } from "utils/parser";

const Insert = (props) => {
  const { toEdit, toViewMatches } = props;
  const navigate = useNavigate();

  const { languageState } = useLanguage();

  const [filtering, setFiltering] = useState(false);

  // dialogs
  const [whatsappDialog, setWhatsappDialog] = useState(false);
  const onCloseWhatsappDialog = () => setWhatsappDialog(false);

  const [emailDialog, setEmailDialog] = useState(false);
  const onCloseEmailDialog = () => setEmailDialog(false);

  const [toShareProperties, setToShareProperties] = useState([]);

  //* characteristics
  const [characteristicGroups, setCharacteristicGroups] = useState([]);
  const [characteristics, setCharacteristics] = useState([]);
  const [oldBasicCharacteristics, setOldBasicCharacteristics] = useState([]);
  const [oldCharacteristics, setOldCharacteristics] = useState([]);

  //* userForm
  const { control, reset, register, handleSubmit, setValue, getValues, watch } =
    useForm();

  const values = getValues();

  const [valorUsdMin, setValorUsdMin] = useState();
  const [valorUsdMax, setValorUsdMax] = useState();
  const [valorPesosMin, setValorPesosMin] = useState();
  const [valorPesosMax, setValorPesosMax] = useState();
  const [environmentsMin, setValorEnvironmentsMin] = useState();
  const [environmentsMax, setValorEnvironmentsMax] = useState();

  const [usdValue, setUsdValue] = useState(0);
  const [pesosValue, setPesosValue] = useState(0);

  const handleNumber = (e, newValue) => {
    const { target } = e;
    const { id } = target;
    if (id === "usdValue") setUsdValue(newValue);
    else if (id === "pesosValue") setPesosValue(newValue);
  };

  const onKeyDown = (e) => {
    const { code } = e;
    if (code === "ArrowDown") {
    } else if (code === "ArrowUp") {
    }
  };

  const filterValues = watch([
    "valorUsdMin",
    "valorUsdMax",
    "valorPesosMin",
    "valorPesosMax",
    "environmentsMin",
    "environmentsMax",
  ]);

  useEffect(() => {
    setValorUsdMin(filterValues[0]);
    setValorUsdMax(filterValues[1]);
    setValorPesosMin(filterValues[2]);
    setValorPesosMax(filterValues[3]);
    setValorEnvironmentsMin(filterValues[4]);
    setValorEnvironmentsMax(filterValues[5]);
  }, [filterValues]);

  const shownFiltersReducer = (filtersState, action) => {
    const { type } = action;
    switch (type) {
      case "added": {
        const newFilters = filtersState;
        const { filterAdded } = action;
        newFilters[String(filterAdded.id)] = { ...filterAdded };
        return newFilters;
      }
      case "removed": {
        const newFilters = filtersState;
        const { filterRemoved } = action;
        delete newFilters[String(filterRemoved.id)];
        return newFilters;
      }
      default:
        return { error: "error" };
    }
  };

  const [shownFilters] = useReducer(shownFiltersReducer, {
    ...languageState.texts.Filters,
  });

  const executeFilter = () => {
    //* usd
    const usdString = {};
    if (valorUsdMin || valorUsdMin === "0") usdString.valor_desde = valorUsdMin;
    if (valorUsdMax || valorUsdMax === "0") usdString.valor_hasta = valorUsdMax;
    if (usdString.valor_desde || usdString.valor_hasta) {
      // operator
      if (valorUsdMax && valorUsdMin) usdString.operador = "BETWEEN";
      else if (valorUsdMax && !valorUsdMin) usdString.operador = "<=";
      else if (valorUsdMin && !valorUsdMax) usdString.operador = ">=";
    }
    //* pesos
    const pesosString = {};
    if (valorPesosMin || valorPesosMin === "0")
      pesosString.valor_desde = valorPesosMin;
    if (valorPesosMax || valorPesosMax === "0")
      pesosString.valor_hasta = valorPesosMax;
    if (pesosString.valor_desde || pesosString.valor_hasta) {
      // operator
      if (valorPesosMax && valorPesosMin) pesosString.operador = "BETWEEN";
      else if (valorPesosMax && !valorPesosMin) pesosString.operador = "<=";
      else if (valorPesosMin && !valorPesosMax) pesosString.operador = ">=";
    }
    //* environments
    const environmentsString = {};
    if (environmentsMin || environmentsMin === "0")
      environmentsString.valor_desde = valorPesosMin;
    if (environmentsMax || environmentsMax === "0")
      environmentsString.valor_hasta = valorPesosMax;
    if (environmentsString.valor_desde || environmentsString.valor_hasta) {
      // operator
      if (environmentsMax && environmentsMin)
        environmentsString.operador = "BETWEEN";
      else if (environmentsMax && !environmentsMin)
        environmentsString.operador = "<=";
      else if (environmentsMin && !environmentsMax)
        environmentsString.operador = ">=";
    }
    let filters = "";
    //* number filters
    if (usdString.operador)
      filters += `&filtros${encodeURIComponent(
        "["
      )}valor_usd${encodeURIComponent("]")}=${encodeURIComponent(
        JSON.stringify(usdString)
      )}`;
    if (pesosString.operador)
      filters += `&filtros${encodeURIComponent(
        "["
      )}valor_pesos${encodeURIComponent("]")}=${encodeURIComponent(
        JSON.stringify(pesosString)
      )}`;
    if (environmentsString.operador)
      filters += `&filtros${encodeURIComponent(
        "["
      )}ambientes${encodeURIComponent("]")}=${encodeURIComponent(
        JSON.stringify(environmentsString)
      )}`;
    //* property type
    if (propertyTypeId && shownFilters["4"])
      filters += `&filtros${encodeURIComponent(
        "["
      )}tipo_propiedad_id${encodeURIComponent("]")}=${propertyTypeId}`;
    //* state
    if (selectedStateGId)
      filters += `&filtros${encodeURIComponent(
        "["
      )}estado_id${encodeURIComponent("]")}=${selectedStateGId}`;
    if (selectedDivisionId)
      filters += `&filtros${encodeURIComponent(
        "["
      )}barrio_id${encodeURIComponent("]")}=${selectedDivisionId}`;
    setPropertiesMatching({ type: "clean" });
    fetchMatches(0, filters);
  };

  const [tabsLabels, setTabsLabels] = useState(
    languageState.texts.Dashboard.Property.InsertTabs
  );

  const theme = useTheme();

  const [propertyToModify, setPropertyToModify] = useState(0);
  const [propertyStateV, setPropertyStateV] = useState(false);
  const [stateLoaded, setStateLoaded] = useState(false);

  //* Notification control
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  //* map control
  const [lng, setLng] = useState(-58.619020034627965);
  const [lat, setLat] = useState(-34.713600884098724);

  const onChangeMap = (which, value) => {
    if (which === "lng") return setLng(value);
    return setLat(value);
  };

  const lngLatSelected = (point, lngLat) => {
    setLng(lngLat.lng);
    setLat(lngLat.lat);
  };

  const [propertyTypeId, setPropertyTypeId] = useState(0);
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [selectedPropertyType, setSelectedPropertyType] = useState([
    { id: 0, nombre: languageState.texts.Labels.AllF },
  ]);

  //* countries
  const countryReducer = (countryState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { newCountryState } = action;
        return { ...newCountryState };
      }
      case "addStates": {
        const newCountryState = { ...countryState };
        const { newStates, country } = action;
        newCountryState[country].states = {
          ...Object.values(newStates).map((item) => item.id),
        };
        return { ...newCountryState };
      }
      default:
        return {};
    }
  };

  const [countryId, setCountryId] = useState(0);
  const [country, setCountry] = useState();
  const [countries, setCountries] = useReducer(countryReducer, {});
  const [, setCountryToSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [, setSelectedCountryToSearch] = useState("");
  const [, setShowCountries] = useState(false);

  const [invalidCountry, setInvalidCountry] = useState(false);

  //* states
  const stateReducer = (stateState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const newStateState = stateState;
        const { country, newStates } = action;
        newStateState[country] = newStates;
        return { ...newStateState };
      }
      case "addDivisions": {
        const newStateState = { ...stateState };
        const { newDivisions, state } = action;
        newStateState[countryId || selectedCountry][state].division = {
          ...Object.values(newDivisions).map((item) => item.id),
        };
        return { ...newStateState };
      }
      default:
        return {};
    }
  };

  const [state, setState] = useState();
  const [stateGId, setStateGId] = useState(0);
  // all states
  const [stateGs, setStateGs] = useReducer(stateReducer, {});
  const [, setStateToSearch] = useState("");

  const [selectedState, setSelectedState] = useState();
  const [selectedStateGId, setSelectedStateGId] = useState(0);
  const [, setSelectedStateToSearch] = useState("");

  const [, setShowStates] = useState(false);

  const [columns, setColumns] = useState([]);

  const localListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newLocalList = localListState;
        const { toAdd, max } = action;
        toAdd.forEach((item) => {
          newLocalList.push(item);
        });
        while (newLocalList.length < max) newLocalList.push({});
        return newLocalList;
      }
      case "set": {
        const newLocalList = localListState;
        const { toAdd, from } = action;
        let j = 0;
        for (let i = from; i < from + toAdd.length; i += 1) {
          newLocalList[i] = toAdd[j];
          j += 1;
        }
        return newLocalList;
      }
      default:
        return [];
    }
  };

  const [propertiesMatching, setPropertiesMatching] = useReducer(
    localListReducer,
    []
  );

  const onChat = (selected) => {
    const toChat = realMatches.find((item) => item.id === selected[0]);
    navigate(`/chat?id=${toChat.inmobiliaria_id}&property=${selected[0]}`);
  };

  const [, setInvalidState] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);

  const [realMatches, setRealMatches] = useState([]);

  const prepareRowsForTable = (data, from = 0, total = 0) => {
    const parsedRows = [];
    const toSave = [];
    data.forEach((item) => {
      const parsedItem = prepareRows(languageState.texts, item.inmuebleD);
      toSave.push(item.inmuebleD);
      const firstImage = fetchMultimediaJustPhotos(item.inmuebleD.multimedias);
      //* adding imagen
      if (!item.imagen)
        parsedItem.imagen = (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              div: {
                width: "100px",
                height: "60px",
              },
            }}
          >
            <SitoImage
              sx={{
                width: "100px",
                height: "60px",
                objectFit: "cover",
                borderRadius: "3px",
              }}
              src={firstImage}
              alt="property"
            />
          </Box>
        );
      if (
        !parsedRows.find((jtem) => {
          if (item.inmueble2_id === jtem.inmueble2_id) return jtem;
          return null;
        })
      )
        parsedRows.push(parsedItem);
    });
    // setRealProperties(realToSet);
    if (!from)
      setPropertiesMatching({ type: "add", toAdd: parsedRows, max: total });
    else setPropertiesMatching({ type: "set", toAdd: parsedRows, from });
    setRealMatches([...realMatches, ...toSave]);
  };

  //* divisions
  const divisionReducer = (divisionState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const newDivisionState = divisionState;
        const { state, newDivisions } = action;
        newDivisionState[state] = newDivisions;
        return { ...newDivisionState };
      }
      case "addLocations": {
        const newDivisionState = { ...divisionState };
        const { newLocations, division } = action;
        if (!newDivisionState[division])
          newDivisionState[division] = { locations: {} };
        newDivisionState[division].locations = {
          ...Object.values(newLocations).map((item) => item.id),
        };
        return { ...newDivisionState };
      }
      default:
        return {};
    }
  };

  const [division, setDivision] = useState();
  const [divisionId, setDivisionId] = useState(0);
  const [divisionToSearch, setDivisionToSearch] = useState("");
  const [divisions, setDivisions] = useReducer(divisionReducer, {});

  const [selectedDivision, setSelectedDivision] = useState();
  const [selectedDivisionId, setSelectedDivisionId] = useState(0);
  const [selectedDivisionToSearch, setSelectedDivisionToSearch] = useState("");

  const selectedDivisionsReducer = (selectedDivisionsState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { newDivisions } = action;
        return [...newDivisions];
      }
      case "remove": {
        const newDivisionsState = selectedDivisionsState;
        const { index } = action;
        newDivisionsState.splice(index, 1);
        return [...newDivisionsState];
      }
      case "add": {
        const newDivisionsState = selectedDivisionsState;
        const { newDivision } = action;
        const filter = newDivisionsState.filter(
          (item) => item.id === newDivision.id
        );
        if (!filter.length) newDivisionsState.push(newDivision);
        return [...newDivisionsState];
      }
      default:
        return [];
    }
  };
  const [selectedDivisions, setSelectedDivisions] = useReducer(
    selectedDivisionsReducer,
    []
  );

  const [, setInvalidDivision] = useState(false);
  const [loadingDivision, setLoadingDivisions] = useState(false);

  //* locations
  const locationReducer = (locationState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const newLocationState = locationState;
        const { division, newLocations } = action;
        newLocationState[division] = newLocations;
        return { ...newLocationState };
      }
      default:
        return {};
    }
  };

  const [location, setLocation] = useState();
  const [locationId, setLocationId] = useState(0);
  const [locations, setLocations] = useReducer(locationReducer, {});
  const [locationToSearch, setLocationToSearch] = useState("");

  const [, setShowLocations] = useState(false);

  const [loadingLocations, setLoadingLocations] = useState(false);
  const [, setInvalidLocation] = useState(false);

  const getCharacteristicNameById = (id, usedName = false) => {
    switch (id) {
      case 74: // 74
        return "environments";
      case 75: // 75
        return "bedrooms";
      case 76: // 76
        return "bathrooms";
      case 77: // 77
        return "garages";
      case 78: // 78
        setValueType(languageState.texts.Labels.USD);
        if (usedName) return "value";
        return "usdValue";
      case 79: // 79
        setValueType(languageState.texts.Labels.Pesos);
        if (usedName) return "value";
        return "pesosValue";
      case 80: // 80
        return "totalSurface";
      default: // 81
        return "deckSurface";
    }
  };

  const getCharacteristicIdByName = (name) => {
    switch (name) {
      case "environments": // environments
        return 74;
      case "bedrooms": // bedrooms
        return 75;
      case "bathrooms": // bathrooms
        return 76;
      case "garages": // garages
        return 77;
      case "value": // ask for value type
        if (valueType === languageState.texts.Labels.USD) return 78;
        return 79;
      case "usdValue": // usd_value
        return 78;
      case "pesosValue": // pesos_value
        return 79;
      case "totalSurface": // totalSurface
        return 80;
      default: // deckSurface
        return 81;
    }
  };

  const prepareCharacteristic = (theProperty, localData) => {
    const newOldCharacteristics = [];
    const newOldBasicCharacteristics = [];
    theProperty.inmuebleCaracteristicas.forEach((item) => {
      const idCharacteristic = item.caracteristica.id;
      if (item.caracteristica.grupo_caracteristica_id !== 1) {
        // reading non basic characteristics
        for (const jtem of localData)
          if (jtem.id === idCharacteristic) {
            newOldCharacteristics.push({ id: jtem.id, value: item.valor });
            setValue(`characteristic-${jtem.id}`, true);
          }
      } else {
        // reading basic characteristics
        switch (idCharacteristic) {
          case 73: // title
            setValue("title", item.valor);
            newOldBasicCharacteristics.push("titulo");
            break;
          case 74: // environments
            setValue("environments", item.valor);
            newOldBasicCharacteristics.push("ambientes");
            break;
          case 75: // bedrooms
            setValue("bedrooms", item.valor);
            newOldBasicCharacteristics.push("dormitorios");
            break;
          case 76: // bathrooms
            setValue("bathrooms", item.valor);
            newOldBasicCharacteristics.push("banos");
            break;
          case 77: // garages
            setValue("garages", item.valor);
            newOldBasicCharacteristics.push("cocheras");
            break;
          case 78: {
            // usd_value
            const parsed = numberArg(item.valor);

            setUsdValue(parsed);
            setValue("usdValue", item.valor);
            newOldBasicCharacteristics.push("valor_usd");
            break;
          }
          case 79: {
            // pesos_value
            const parsed = numberArg(item.valor);
            setPesosValue(parsed);
            setValue("pesosValue", item.valor);
            newOldBasicCharacteristics.push("valor_pesos");
            break;
          }
          case 80: // totalSurface
            setValue("totalSurface", item.valor);
            newOldBasicCharacteristics.push("superficie_total");
            break;
          case 81: // deckSurface
            setValue("deckSurface", item.valor);
            newOldBasicCharacteristics.push("superficie_cubierta");
            break;
          case 82: // description
            setValue("description", item.valor);
            newOldBasicCharacteristics.push("descripcion");
            break;
          default:
            break;
        }
      }
    });
    setOldCharacteristics(newOldCharacteristics);
    setOldBasicCharacteristics(newOldBasicCharacteristics);
    showNotification(
      "success",
      languageState.texts.Messages.PropertyLoadedSuccessful
    );

    setLoading(false);
  };

  const videoReducer = (videoState, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { value, content } = action;
        return {
          value,
          content,
        };
      }
      case "remove":
      default:
        return {
          value: "",
          content: "",
        };
    }
  };

  const [videoIds, setVideoIds] = useReducer(videoReducer, {});
  const [loadingVideo, setLoadingVideo] = useState(false);

  useEffect(() => {
    if (loadingVideo)
      setTimeout(() => {
        setLoadingVideo(false);
      }, 500);
  }, [loadingVideo]);

  const video360Reducer = (video360State, action) => {
    const { type } = action;
    switch (type) {
      case "set": {
        const { value, content } = action;
        return {
          value,
          content,
        };
      }
      case "remove":
      default:
        return {
          value: "",
          content: "",
        };
    }
  };

  const [video360Ids, setVideo360Ids] = useReducer(video360Reducer, {});
  const [loadingVideo360, setLoadingVideo360] = useState(false);

  useEffect(() => {
    if (loadingVideo360)
      setTimeout(() => {
        setLoadingVideo360(false);
      }, 500);
  }, [loadingVideo360]);

  const imageReducer = (imageState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newImageState = { ...imageState };
        const { value, content } = action;
        newImageState[value] = { value, content };
        return { ...newImageState };
      }
      case "remove": {
        const newImageState = { ...imageState };
        const { value } = action;
        delete newImageState[value];
        return { ...newImageState };
      }
      case "clean":
      default:
        return {};
    }
  };

  const [imageIds, setImageIds] = useReducer(imageReducer, {});
  const [loadingPhotos, setLoadingPhotos] = useState(false);

  useEffect(() => {
    if (loadingPhotos)
      setTimeout(() => {
        setLoadingPhotos(false);
      }, 500);
  }, [loadingPhotos]);

  const flatReducer = (flatState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        const newFlatState = { ...flatState };
        const { value, content } = action;
        newFlatState[value] = { value, content };
        return { ...newFlatState };
      }
      case "remove": {
        const newFlatState = { ...flatState };
        const { value } = action;
        delete newFlatState[value];
        return { ...newFlatState };
      }
      case "clean":
      default:
        return {};
    }
  };

  const [flatIds, setFlatIds] = useReducer(flatReducer, {});
  const [loadingFlats, setLoadingFlats] = useState(false);

  useEffect(() => {
    if (loadingFlats)
      setTimeout(() => {
        setLoadingFlats(false);
      }, 500);
  }, [loadingFlats]);

  const cleanMultimedia = () => {
    setImageIds({ type: "clean" });
    setFlatIds({ type: "clean" });
    setVideoIds({ type: "remove" });
    setVideo360Ids({ type: "remove" });
    setLoadingPhotos(true);
    setLoadingFlats(true);
    setLoadingVideo(true);
    setLoadingVideo360(true);
  };

  const [oldMultimedia, setOldMultimedia] = useState([]);

  const removeVideo = async () => {
    setLoadingVideo(true);
    const indexA = getComplexIndexOf(
      ["nombre", "tipo"],
      [videoIds.value.substring(8), "VIDEO"],
      oldMultimedia
    );
    if (indexA !== -1) {
      const response = await deleteMultimedia(oldMultimedia[indexA].id);
      if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    }
    setValue("video", "");
    setVideoIds({ type: "delete" });
  };

  const removeVideo360 = async () => {
    setLoadingVideo360(true);
    const indexA = getComplexIndexOf(
      ["nombre", "tipo"],
      [video360Ids.value.substring(11), "RECORRIDO360"],
      oldMultimedia
    );
    if (indexA !== -1) {
      const response = await deleteMultimedia(oldMultimedia[indexA].id);
      if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    }
    setValue("video360", "");
    setVideo360Ids({ type: "remove" });
  };

  const removePhoto = async (value) => {
    setLoadingPhotos(true);
    let index = -1;
    const newImage = imageIds;
    const indexA = getComplexIndexOf(
      ["nombre", "tipo"],
      [value.substring(9), "IMAGEN"],
      oldMultimedia
    );
    if (indexA !== -1) {
      const response = await deleteMultimedia(oldMultimedia[indexA].id);
      if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    }
    Object.values(newImage).filter((item, i) => {
      if (item.value === `photos[!]${value}`) index = i;
      return null;
    });
    const newArrayOfFiles = getValues("photos");
    if (newArrayOfFiles) {
      newArrayOfFiles.splice(index, 1);
      setValue("photos", newArrayOfFiles);
    }
    setImageIds({ type: "remove", value });
  };

  const removeFlat = async (value) => {
    setLoadingFlats(true);
    let index = -1;
    const indexA = getComplexIndexOf(
      ["nombre", "tipo"],
      [value.substring(8), "PLANO"],
      oldMultimedia
    );
    if (indexA !== -1) {
      const response = await deleteMultimedia(oldMultimedia[indexA].id);
      if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    }
    const newFlat = flatIds;
    Object.values(newFlat).filter((item, i) => {
      if (item.value === `flats[!]${value}`) index = i;
      return null;
    });
    const newArrayOfFiles = getValues("flats");
    if (newArrayOfFiles) {
      newArrayOfFiles.splice(index, 1);
      setValue("flats", newArrayOfFiles);
    }
    setFlatIds({ type: "remove", value });
  };

  // exclusives / non exclusives
  const [loadingSearches, setLoadingSearches] = useState(false);

  const [oldSearches, setOldSearches] = useState([]);
  const [oldDivisions, setOldDivisions] = useState([]);
  const [oldTypes, setOldTypes] = useState([]);

  const [page, setPage] = useState(0);

  const onPagination = (newPage) => {
    if (propertiesMatching[10 * newPage]) fetchMatches(10 * newPage);
    setPage(newPage);
  };

  const fetchMatches = useCallback(
    async (from = 0, filters = "") => {
      setLoadingMatch(true);
      try {
        const result = await matchPOST(propertyToModify || toViewMatches);
        if (result.error && !apiTokenCertified(result.error))
          navigate("/log-out");
        if (result.status === 201) {
          const matchesPromise = await matchGET(
            propertyToModify || toViewMatches,
            from,
            10,
            true,
            true,
            filters
          );
          if (matchesPromise.status === 200) {
            const { data, total } = matchesPromise.data;
            if (data.length) {
              if (!from) setColumns(parseColumns(languageState.texts));
              prepareRowsForTable(data, false, from, total);
            } else setPropertiesMatching(-1);
          } else if (
            matchesPromise.error &&
            !apiTokenCertified(matchesPromise.error)
          )
            navigate("/log-out");
        } else if (result.error && !apiTokenCertified(result.error))
          navigate("/log-out");
      } catch (err) {
        console.error(err);
        const { message } = err;
        showNotification("error", message || String(err));
      }
      setLoadingMatch(false);
    },
    [propertyToModify, toViewMatches]
  );

  const fetchSearches = async (propertyId) => {
    try {
      const searchesPromise = await searchList(propertyId, 0, -1);
      console.log(searchesPromise, "searches");
      if (searchesPromise.error && !apiTokenCertified(searchesPromise.error))
        navigate("/log-out");
      let divisionIds = [];
      let typesIds = [];
      if (searchesPromise.status === 200) {
        const { data } = await searchesPromise.data;
        const localOldSearches = [];
        data.forEach((item, i) => {
          if (item.buscaLocalidads) divisionIds = item.buscaLocalidads;
          localOldSearches.push(item);
          setValue(
            `${getCharacteristicNameById(item.caracteristica_id, true)}-min`,
            item.valor_desde
          );
          setValue(
            `${getCharacteristicNameById(item.caracteristica_id, true)}-max`,
            item.valor_hasta
          );
          if (item.buscaTipoPropiedad) {
            const arrayOfTypes = [];
            item.buscaTipoPropiedad.forEach((jtem) =>
              arrayOfTypes.push({ id: jtem.tipo_propiedad_id })
            );
            setSelectedPropertyType(arrayOfTypes);
            setOldTypes(arrayOfTypes);
            setPropertyToFill(true);
          }
        });
        setOldSearches(localOldSearches);
      } else
        showNotification("error", languageState.texts.Errors.LoadingSearches);
      let filters = "";
      divisionIds.forEach((item, i) => {
        if (i) filters += "&";
        filters += `filtros[id][${i + 1}]=${item.localidad_id}`;
      });
      if (filters.length) {
        const divisionPromise = await divisionList(0, -1, filters);
        if (divisionPromise.status === 200) {
          const { data } = await divisionPromise.data;
          if (data.length) {
            const statePromise = await stateGList(
              0,
              1,
              `filtros[i]=${data[0].estado_id}`
            );
            if (statePromise.status === 200) {
              const stateData = await statePromise.data.data;
              if (stateData.length) setSelectedStateToSearch(stateData[0].name);
            } else if (
              statePromise.error &&
              !apiTokenCertified(statePromise.error)
            )
              navigate("/log-out");
            else setSelectedStateToSearch(languageState.texts.Labels.All);
            setOldDivisions(
              data.map((item) => ({ id: item.id, name: item.name }))
            );
            setSelectedDivisions({
              type: "set",
              newDivisions: data.map((item) => ({
                id: item.id,
                name: item.name,
              })),
            });
          }
        } else if (
          divisionPromise.error &&
          !apiTokenCertified(divisionPromise.error)
        )
          navigate("/log-out");
      } else setSelectedStateToSearch(languageState.texts.Labels.All);
    } catch (err) {
      const { message } = err;
      showNotification("error", message || String(err));
    }
    setLoadingSearches(false);
  };

  const fetchMultimediaJustPhotos = (multimedias) => {
    const images = [];
    if (multimedias !== null) {
      multimedias.forEach((item) => {
        if (item.tipo === "IMAGEN") images.push(item.url);
      });
      return images[0];
    }
    return undefined;
  };

  const fetchMultimedia = (multimedia) => {
    if (multimedia !== null) {
      multimedia.forEach((item) => {
        if (item.tipo === "IMAGEN")
          setImageIds({
            type: "add",
            value: `photos[!]${item.nombre}`,
            content: item.url,
          });
        else if (item.tipo === "PLANO")
          setFlatIds({
            type: "add",
            value: `flats[!]${item.nombre}`,
            content: item.url,
          });
        else if (item.tipo === "VIDEO")
          setVideoIds({
            type: "set",
            value: `video[!]${item.nombre}`,
            content: item.url,
          });
        else if (item.tipo === "RECORRIDO360")
          setVideo360Ids({
            type: "set",
            value: `video[!]${item.nombre}`,
            content: item.url,
          });
      });
      setLoadingPhotos(true);
      setLoadingFlats(true);
      setLoadingVideo(true);
      setLoadingVideo360(true);
    }
    setOldMultimedia(multimedia || []);
  };

  const [loadingMatch, setLoadingMatch] = useState(false);

  const [loading, setLoading] = useState(true);

  const fetchStates = async (countryLId) => {
    setLoadingStates(true);
    const stateGPromise = await stateGList(
      0,
      -1,
      `filtros[pais_id]=${countryLId}`
    );
    const parsedStates = {};
    if (stateGPromise.status === 200) {
      const { data } = await stateGPromise.data;
      if (data.length) {
        data.forEach((item) => {
          const { name, pais_id } = item;
          const parsedItem = { id: item.id, name, pais_id };
          parsedItem.division = {};
          parsedStates[item.id] = parsedItem;
        });
        setStateGs({
          type: "set",
          country: countryLId,
          newStates: parsedStates,
        });
        setCountries({
          type: "addStates",
          newStates: parsedStates,
          country: countryLId,
        });
      }
    } else if (stateGPromise.error && !apiTokenCertified(stateGPromise.error))
      navigate("/log-out");
    else {
      showNotification("error", languageState.texts.Errors.LoadingStates);
      setInvalidState(true);
      setStateGId(0);
    }
    setLoadingStates(false);
  };

  const fetchDivision = async (divisionName, stateA, selected = false) => {
    const divisionPromise = await divisionList(
      0,
      -1,
      `filtros[estado_id]=${
        selected ? selectedStateGId : stateGId
      }&filtros[name]=${divisionName}`
    );
    const parsedDivisions = {};
    setInvalidDivision(false);
    if (divisionPromise.status === 200) {
      const { data } = await divisionPromise.data;
      if (data.length) {
        data.forEach((item) => {
          // if (item.name.toLowerCase() === divisionName.toLowerCase()) setDivisionId(item.id);
          const { name, estado_id } = item;
          const parsedItem = { id: item.id, name, estado_id };
          parsedItem.locations = {};
          parsedDivisions[item.name.toLowerCase()] = parsedItem;
        });
        if (selected) {
          setDivisions({
            type: "set",
            state: stateA,
            newDivisions: parsedDivisions,
          });
          setStateGs({
            type: "addDivisions",
            newDivisions: parsedDivisions,
            state: stateA,
          });
        } else {
          setDivisions({
            type: "set",
            state: stateA,
            newDivisions: parsedDivisions,
          });
          setStateGs({
            type: "addDivisions",
            newDivisions: parsedDivisions,
            state: stateA,
          });
        }
      } else {
        setInvalidDivision(true);
        if (selected) setSelectedDivisionId(0);
        else setDivisionId(0);
      }
    } else if (
      parsedDivisions.error &&
      !apiTokenCertified(parsedDivisions.error)
    )
      navigate("/log-out");
    else {
      showNotification("error", languageState.texts.Errors.LoadingDivisions);
      setInvalidDivision(true);
      setDivisionId(0);
    }
    setLoadingDivisions(false);
  };

  const fetchLocations = async (locationName, selected = false) => {
    const locationPromise = await locationList(
      0,
      -1,
      `filtros[division_id]=${
        selected ? selectedDivisionId : divisionId
      }&filtros[name]=${locationName}`
    );
    setInvalidLocation(false);
    const parsedLocations = {};
    if (locationPromise.status === 200) {
      const { data } = await locationPromise.data;
      if (data.length) {
        data.forEach((item) => {
          // if (item.name.toLowerCase() === locationName.toLowerCase()) setLocationId(item.id);
          const { name, division_id } = item;
          const parsedItem = { id: item.id, name, division_id };
          parsedLocations[item.name.toLowerCase()] = parsedItem;
        });
        if (selected) {
          setLocations({
            type: "set",
            division: selectedDivisionId,
            newLocations: parsedLocations,
          });
          setDivisions({
            type: "addLocations",
            newLocations: parsedLocations,
            division: selectedDivisionId,
          });
        } else {
          setLocations({
            type: "set",
            division: divisionId,
            newLocations: parsedLocations,
          });
          setDivisions({
            type: "addLocations",
            newLocations: parsedLocations,
            division: divisionId,
          });
        }
      } else {
        setInvalidLocation(true);
        if (!selected) setLocationId(0);
      }
    } else if (
      parsedLocations.error &&
      !apiTokenCertified(parsedLocations.error)
    )
      navigate("/log-out");
    else {
      showNotification("error", languageState.texts.Errors.LoadingLocations);
      setInvalidLocation(true);
      if (!selected) setLocationId(0);
    }
    setLoadingLocations(false);
  };

  const handleSelected = async (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "propertyTypeToSearch":
        return setSelectedPropertyType(Number(value));
      case "propertyType":
        return setPropertyTypeId(Number(value));
      case "location": {
        setLoadingLocations(true);
        await fetchLocations(value);
        break;
      }
      case "selected-location": {
        setLoadingLocations(true);
        await fetchLocations(value, true);
        break;
      }
      case "division": {
        setLoadingDivisions(true);
        await fetchDivision(value, stateGId);
        break;
      }
      case "selected-division": {
        setLoadingDivisions(true);
        await fetchDivision(value, selectedStateGId, true);
        break;
      }
      case "state":
        /* if (
          stateGs[countryId] &&
          stateGs[countryId][value] &&
          stateGs[countryId][value].division &&
          !Object.values(stateGs[countryId][value].division).length
        ) {
          setLoadingDivisions(true);
          await fetchDivision("", value, false);
        } */
        return setStateGId(Number(value));
      case "selected-state":
        /* if (
          stateGs[selectedCountryId] &&
          stateGs[selectedCountryId][value] &&
          stateGs[selectedCountryId][value].division &&
          !Object.values(stateGs[selectedCountryId][value].division).length
        ) {
          setLoadingDivisions(true);
          await fetchDivision("", value, true);
        } */
        return setSelectedStateGId(Number(value));
      case "country":
        if (countries[value].states.length) setStateGs(countries[value].states);
        else await fetchStates(value);
        return setCountryId(Number(value));
      default:
        return {};
    }
  };

  //* Tab control
  const [tab, setTab] = useState(0);

  const handleChange = (e, newTab) => setTab(newTab);

  const [matchTab, setMatchTab] = useState(0);
  const onChangeMatchTab = (e, newTab) => setMatchTab(newTab);

  useEffect(() => {
    if (matchTab === 1) if (!propertiesMatching.length) fetchMatches();
  }, [matchTab]);

  const [bigTab, setBigTab] = useState(0);
  const onChangeBigTab = (e, newTab) => setBigTab(newTab);

  useEffect(() => {
    if (bigTab === 1 && !stateGs[selectedCountry]) fetchStates(1);
  }, [bigTab]);

  useEffect(() => {
    if (tab > 3)
      handleSelected({ target: { id: "country", value: selectedCountry } });
  }, [tab]);

  const toSearch = (e) => {
    const { id, value, wasSelected } = e.target;
    const lValue = value.toLowerCase();
    switch (id) {
      case "location-input":
        if (!wasSelected) handleSelected({ target: { id: "location", value } });
        break;
      case "selected-location-input":
        if (!wasSelected)
          handleSelected({ target: { id: "selected-location", value } });
        break;
      case "division-input":
        if (!wasSelected) handleSelected({ target: { id: "division", value } });
        break;
      case "selected-division-input":
        if (!wasSelected)
          handleSelected({ target: { id: "selected-division", value } });
        break;
      case "state-input": {
        setInvalidState(false);
        const filter = Object.values(stateGs[countryId]).filter((item) => {
          if (item.name.toLowerCase() === lValue) return item;
          return null;
        });
        if (filter.length)
          handleSelected({ target: { id: "state", value: filter[0].id } });
        else {
          setStateGId(0);
          setInvalidState(true);
        }
        break;
      }
      case "selected-state-input": {
        setInvalidState(false);
        const filter = Object.values(stateGs[selectedCountryId]).filter(
          (item) => {
            if (item.name.toLowerCase() === lValue) return item;
            return null;
          }
        );
        if (filter.length)
          handleSelected({
            target: { id: "selected-state", value: filter[0].id },
          });
        else {
          setSelectedStateGId(0);
          setInvalidState(true);
        }
        break;
      }
      default: {
        // country
        setInvalidCountry(false);
        const filter = Object.values(countries).filter((item) => {
          if (
            item.name.toLowerCase() === lValue ||
            item.iso_code.toLowerCase() === lValue ||
            `${item.iso_code.toLowerCase()}-${item.name.toLowerCase()}` ===
              lValue
          )
            return item;
          return null;
        });
        if (filter.length)
          handleSelected({ target: { id: "country", value: filter[0].id } });
        else {
          setCountryId(0);
          setInvalidCountry(true);
        }
        break;
      }
    }
  };

  const [loadingPropertyTypes, setLoadingPropertyTypes] = useState(false);

  const fetchPropertyTypes = async () => {
    try {
      //* property types
      const propertyTypePromise = await propertyTypeList(0, -1);
      if (propertyTypePromise.status === 200) {
        const { data } = await propertyTypePromise.data;
        if (data.length) {
          setPropertyTypeId(data[0].id);
          setPropertyTypes(data);
        }
      } else if (
        propertyTypePromise.error &&
        !apiTokenCertified(propertyTypePromise.error)
      )
        navigate("/log-out");
    } catch (err) {
      console.error(err);
      const { message } = err;
      showNotification("error", message || String(err));
    }
    setLoadingPropertyTypes(false);
  };

  const [propertyToFill, setPropertyToFill] = useState(false);

  const doPropertyToFill = useCallback(() => {
    if (
      propertyTypes.length &&
      selectedPropertyType.length &&
      selectedPropertyType[0].id !== 0
    ) {
      const newTypes = [];
      selectedPropertyType.forEach((item) => {
        const find = propertyTypes.find((jtem) => jtem.id === item.id);
        if (find) newTypes.push(find);
      });
      setSelectedPropertyType(newTypes);
    }
  }, [propertyTypes, selectedPropertyType]);

  useEffect(() => {
    if (propertyToFill) {
      setPropertyToFill(false);
      doPropertyToFill();
    }
  }, [propertyToFill]);

  const [loadingCharacteristics, setLoadingCharacteristics] = useState(false);

  const fetchCharacteristics = async (theProperty) => {
    try {
      //* characteristic groups
      const characteristicGroupPromise = await characteristicGroupList(0, -1);
      if (characteristicGroupPromise.status === 200) {
        const { data } = await characteristicGroupPromise.data;
        if (data.length)
          setCharacteristicGroups(
            data.filter((item) => {
              if (item.id !== 1) return item;
              return null;
            })
          );
      } else if (
        characteristicGroupPromise.error &&
        !apiTokenCertified(characteristicGroupPromise.error)
      )
        navigate("/log-out");
      //* characteristics
      const characteristicPromise = await characteristicList(0, -1);
      let localData = [];
      if (characteristicPromise.status === 200) {
        const { data } = await characteristicPromise.data;
        if (data.length) {
          setCharacteristics(data);
          localData = data;
        }
      } else if (
        characteristicPromise.error &&
        !apiTokenCertified(characteristicPromise.error)
      )
        navigate("/log-out");

      if (theProperty)
        // reading characteristics
        prepareCharacteristic(theProperty, localData);
      else setLoading(false);
    } catch (err) {
      console.error(err);
      const { message } = err;
      showNotification("error", message || String(err));
      setLoading(false);
    }
    setLoadingCharacteristics(false);
  };

  const [loadingGeolocation, setLoadingGeolocation] = useState(false);

  const fetchGeolocation = async (theProperty) => {
    try {
      //* countries
      const countryPromise = await countryList(0, -1);
      const parsedCountries = {};
      if (countryPromise.status === 200) {
        const { data } = await countryPromise.data;
        data.forEach((item, i) => {
          // if (i === 0) setSelectedCountry(item.id);
          const { id, name, iso_code } = item;
          const parsedItem = { id, name, iso_code };
          parsedItem.states = {};
          parsedCountries[parsedItem.id] = parsedItem;
        });
        setCountries({ type: "set", newCountryState: parsedCountries });
      } else if (
        countryPromise.error &&
        !apiTokenCertified(countryPromise.error)
      )
        navigate("/log-out");
      else
        showNotification("error", languageState.texts.Errors.LoadingCountries);
      if (theProperty) {
        // location
        const locationPromise = await locationList(
          0,
          1,
          `filtros[id]=${theProperty.localidad_id}`
        );
        if (locationPromise.status === 200) {
          const [locationData] = await locationPromise.data.data;
          setLocationId(locationData.id);
          setLocationToSearch(locationData.name);
          // division
          const divisionPromise = await divisionList(
            0,
            1,
            `filtros[id]=${locationData.division_id}`
          );
          if (divisionPromise.status === 200) {
            const [divisionData] = await divisionPromise.data.data;
            setDivisionId(divisionData.id);
            setDivisionToSearch(divisionData.name);
            // state
            const statePromise = await stateGList(
              0,
              1,
              `filtros[id]=${divisionData.estado_id}`
            );
            if (statePromise.status === 200) {
              const [stateData] = await statePromise.data.data;
              setStateGId(stateData.id);
              setStateToSearch(stateData.name);
              setCountryId(stateData.pais_id);
              fetchStates(1);
              // using reducers
              setCountryToSearch(parsedCountries[stateData.pais_id].name);
              // states
              const newStates = {};
              newStates[stateData.id] = {
                id: stateData.id,
                pais_id: stateData.pais_id,
                name: stateData.name,
              };
              setStateGs({
                type: "set",
                country: stateData.pais_id,
                newStates,
              });
              // divisions
              const newDivisions = {};
              newDivisions[divisionData.id] = {
                id: divisionData.id,
                name: divisionData.name,
                estado_id: divisionData.estado_id,
              };
              setDivisions({
                type: "set",
                state: stateData.id,
                newDivisions,
              });
              // locations
              const newLocations = {};
              newLocations[locationData.id] = {
                id: locationData.id,
                name: locationData.name,
                division_id: locationData.division_id,
              };
              setLocations({
                type: "set",
                division: divisionData.name.toLowerCase(),
                newLocations,
              });
              if (toViewMatches) {
                setBigTab(1);
                setMatchTab(1);
              }
            } else if (
              statePromise.error &&
              !apiTokenCertified(statePromise.error)
            )
              navigate("/log-out");
            else {
              showNotification(
                "error",
                languageState.texts.Errors.LoadingStates
              );
              setInvalidState(true);
              setStateGId(0);
            }
          } else if (
            divisionPromise.error &&
            !apiTokenCertified(divisionPromise.error)
          )
            navigate("/log-out");
          else {
            showNotification(
              "error",
              languageState.texts.Errors.LoadingDivisions
            );
            setInvalidDivision(true);
            setDivisionId(0);
          }
        } else if (
          locationPromise.error &&
          !apiTokenCertified(locationPromise.error)
        )
          navigate("/log-out");
        else {
          showNotification(
            "error",
            languageState.texts.Errors.LoadingLocations
          );
          setInvalidLocation(true);
          setLocationId(0);
        }
      }
    } catch (err) {
      console.error(err);
      const { message } = err;
      showNotification("error", message || String(err));
    }
    setLoadingGeolocation(false);
  };

  const fetch = async (toModify) => {
    setLoading(true);
    try {
      setLoadingPropertyTypes(true);
      fetchPropertyTypes();
      let theProperty = undefined;
      //* property
      if (toModify || toViewMatches) {
        setTabsLabels(languageState.texts.Dashboard.Property.InsertTabs);
        const response = await propertyList(
          0,
          1,
          `filtros[id]=${toModify}&opciones[multimedias]=1`
        );
        if (response.status === 200) {
          const { data } = await response.data;
          setOldCharacteristics(data.inmuebleCaracteristicas);
          if (data.length) {
            theProperty = data[0];
            console.log(theProperty);
            if (theProperty.estado === "ACTIVO") {
              setPropertyStateV(true);
              setStateLoaded(true);
            } else {
              setPropertyStateV(false);
              setStateLoaded(true);
            }
            // property type - tipo_propiedad_id
            if (theProperty.tipo_propiedad_id)
              setPropertyTypeId(theProperty.tipo_propiedad_id);
            // address - direccion
            if (theProperty.direccion)
              setValue("address", theProperty.direccion);
            // latitude - latitud
            if (theProperty.latitud) setLat(Number(theProperty.latitud));
            // longitude - longitud
            if (theProperty.longitud) setLng(Number(theProperty.longitud));
            // to search
            await fetchSearches(theProperty.id);
            // multimedia
            fetchMultimedia(theProperty.multimedias);
          }
        } else if (response.error && !apiTokenCertified(response.error))
          navigate("/log-out");
      }
      setLoadingGeolocation(true);
      fetchGeolocation(theProperty);
      setLoadingCharacteristics(true);
      fetchCharacteristics(theProperty);
    } catch (err) {
      setPropertyTypeId(-1);
      setCountryId(-1);
      setLocationId(-1);
      setDivisionId(-1);
      setStateGId(-1);
      const { message } = err;
      showNotification("error", message || String(err));
      setLoading(false);
    }
  };

  const retry = (toModify = 0) => fetch(toModify);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const onUploadFile = useCallback(
    (event) => {
      const { files, id } = event.target;

      switch (id) {
        case "flats":
          setLoadingFlats(true);
          break;
        case "video":
          setLoadingVideo(true);
          break;
        case "video360":
          setLoadingVideo360(true);
          break;
        default:
          setLoadingPhotos(true);
          break;
      }
      const filesAsArray = [];
      let bigFiles = false;
      for (let i = 0; i < files.length; i += 1) {
        console.log(files[i].size < 1e7);
        if (files[i].size < 1e7) filesAsArray.push(files.item(i));
        else bigFiles = true;
      }
      const file = files[0];
      if (getValues(id) && getValues(id).length > 0)
        setValue(id, [...getValues(id), ...filesAsArray]);
      else setValue(id, filesAsArray);
      if (!file) return;
      for (const item of filesAsArray) {
        const newReader = new FileReader();
        newReader.onload = async (e) => {
          const content = e.target.result;
          // const div = document.createElement("div");
          if (content.indexOf("data:image") > -1) {
            if (id === "photos")
              setImageIds({
                type: "add",
                value: `${id}[!]${item.name}`,
                content,
              });
            else
              setFlatIds({
                type: "add",
                value: `${id}[!]${item.name}`,
                content,
              });
          } else if (content.indexOf("data:video") > -1) {
            if (id === "video")
              setVideoIds({
                type: "set",
                value: `${id}[!]${item.name}`,
                content,
              });
            else
              setVideo360Ids({
                type: "set",
                value: `${id}[!]${item.name}`,
                content,
              });
          }
        };
        newReader.readAsDataURL(item);
      }
      if (bigFiles)
        showNotification("warning", languageState.texts.Warnings.bigFiles);
    },
    [
      setValue,
      imageIds,
      flatIds,
      videoIds,
      video360Ids,
      setImageIds,
      setFlatIds,
      setVideoIds,
      setVideo360Ids,
      loadingPhotos,
      loadingFlats,
      loadingVideo,
      loadingVideo360,
    ]
  );

  const labelForFile = css({
    marginBottom: "0 !important",
  });

  const thumbnail = {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "3px",
  };

  // value control
  const [valueType, setValueType] = useState(languageState.texts.Labels.USD);

  const lookNumericValues = useCallback(
    (toInsert) => {
      const exclusivesValues = [];
      const nExclusivesValues = [];
      const numericValues = {};
      const keys = Object.keys(getValues());
      keys.forEach((item) => {
        //* looking for numeric values
        if (item.indexOf("filter") === -1) {
          // min
          if (
            item.indexOf("-min") > 0 &&
            (getValues(item) || getValues(item) === 0)
          ) {
            const realName = item.split("-")[0];
            if (!numericValues[realName])
              numericValues[realName] = { id: realName };
            numericValues[realName].min = getValues(item);
          }
          // max
          if (
            item.indexOf("-max") > 0 &&
            (getValues(item) || getValues(item) === 0)
          ) {
            const realName = item.split("-")[0];
            if (!numericValues[realName])
              numericValues[realName] = { id: realName };
            numericValues[realName].max = getValues(item);
          }
          //* looking for checkboxes
          if (item.indexOf("ex") === 0 && getValues(item))
            exclusivesValues.push({
              id: item.split("-")[2],
              value: getValues(item),
            });
          if (item.indexOf("nex") === 0 && getValues(item))
            nExclusivesValues.push({
              id: item.split("-")[2],
              value: getValues(item),
            });
        }
      });
      // parsing to backend
      Object.values(numericValues).forEach((item) => {
        if (item.min !== undefined) {
          const obj = {
            caracteristica_id: getCharacteristicIdByName(item.id),
            valor_desde: String(item.min) || "0",
          };
          if (item.max && item.min) obj.operador = "BETWEEN";
          else if (item.max && !item.min) obj.operador = "<=";
          else if (item.min && !item.max) obj.operador = ">=";
          if (item.max || item.max === 0) obj.valor_hasta = String(item.max);
          toInsert.push(obj);
        }
      });
    },
    [getValues]
  );

  const localCreateSearchDivisions = async () => {
    // new divisions
    const responseA = await createSearchDivision({
      inmueble_id: propertyToModify,
      localidad_ids: [...selectedDivisions.map((item) => item.id)],
    });
    console.log("response save divisions", responseA);
    if (responseA.error && !apiTokenCertified(responseA.error))
      navigate("/log-out");
    if (responseA.status !== 200 && responseA.status !== 204)
      showNotification("error", languageState.texts.Errors.ModifyingSearches);
    if (responseA.status === 200 || responseA.status === 204) {
      setTab(0);
      showNotification(
        "success",
        languageState.texts.Messages.SearchSavedSuccessful
      );
    }
  };

  const localCreateSearchTypes = async (tipo_propiedad_ids) => {
    // new divisions
    const responseA = await createSearchTypes({
      inmueble_id: propertyToModify,
      tipo_propiedad_ids,
    });
    console.log("response save types", responseA);
    if (responseA.error && !apiTokenCertified(responseA.error))
      navigate("/log-out");
    if (responseA.status !== 200 && responseA.status !== 204)
      showNotification("error", languageState.texts.Errors.ModifyingSearches);
    if (responseA.status === 200 || responseA.status === 204) {
      setTab(0);
      showNotification(
        "success",
        languageState.texts.Messages.SearchSavedSuccessful
      );
    }
  };

  const localCreateSearches = async () => {
    try {
      const toSaveData = {
        inmueble_id: propertyToModify,
        caracteristica_array: [],
        localidad_ids: [],
        tipo_propiedad_ids: [],
      };
      const toInsert = [];
      lookNumericValues(toInsert);
      if (toInsert.length || toSaveData.tipo_propiedad_ids) {
        let realInsert = [];
        const toModify = [];
        const toDeleteData = {};
        let toDelete = [];
        // realInsert = [...selectedDivisions.map((item) => item.id)];
        // looking for new inserts
        /* toInsert.forEach((item) => {
                    const filter = oldSearches.filter(
                        (jtem) =>
                            Number(jtem.caracteristica_id) ===
                            Number(item.caracteristica_id)
                    );
                    // if (!filter.length) realInsert.push(item);
                    // else toModify.push(item);
                }); */
        // looking for delete properties
        oldSearches.forEach((item) => {
          const filter = toInsert.filter(
            (jtem) =>
              Number(jtem.caracteristica_id) === Number(item.caracteristica_id)
          );
          if (filter.length) toDelete.push(item.caracteristica_id);
        });
        console.log(toInsert, toDelete, toModify, realInsert);
        // return;
        toSaveData.caracteristica_array = [...toInsert];
        const toModifyData = {
          inmueble_id: propertyToModify,
          caracteristica_array: toModify,
        };
        let response;
        toDeleteData.caracteristica_ids = [...toDelete];
        // if there are properties to remove
        if (toDelete.length) {
          response = await deleteSearch({
            inmueble_id: propertyToModify,
            caracteristica_ids: toDeleteData.caracteristica_ids,
          });
          console.log("response - delete", response);
          if (response.error && !apiTokenCertified(response.error))
            navigate("/log-out");
          if (response.status !== 200 && response.status !== 204)
            showNotification(
              "error",
              languageState.texts.Errors.ModifyingSearches
            );
        }
        // taking all divisions
        toSaveData.localidad_ids = [
          ...selectedDivisions.map((item) => item.id),
        ];
        // looking for delete old divisions
        toDelete = [];
        oldDivisions.forEach((item) => {
          const filter = selectedDivisions.filter(
            (jtem) => Number(jtem.id) === Number(item.id)
          );
          if (!filter.length) toDelete.push(item.id);
        });
        toDeleteData.localidad_ids = [...toDelete];
        // if there are divisions to delete
        if (toDeleteData.localidad_ids.length) {
          response = await deleteSearchDivision({
            inmueble_id: propertyToModify,
            localidad_ids: toDeleteData.localidad_ids,
          });
          console.log("response to delete divisions", response);
          if (response.error && !apiTokenCertified(response.error))
            navigate("/log-out");
          if (response.status !== 200 && response.status !== 204)
            showNotification(
              "error",
              languageState.texts.Errors.ModifyingSearches
            );
        }
        // taking all property types
        if (selectedPropertyType)
          toSaveData.tipo_propiedad_ids = selectedPropertyType.map((item) =>
            Number(item.id)
          );
        // looking for delete old types
        toDelete = [];
        oldDivisions.forEach((item) => {
          const filter = selectedPropertyType?.filter(
            (jtem) => Number(jtem.id) === Number(item.id)
          );
          if (!filter.length) toDelete.push(item.id);
        });
        toDeleteData.tipo_propiedad_ids = [...toDelete];

        // if there are types to delete
        if (toDeleteData.tipo_propiedad_ids.length) {
          response = await deleteSearchTypes({
            inmueble_id: propertyToModify,
            tipo_propiedad_ids: toDeleteData.tipo_propiedad_ids,
          });
          console.log("response to delete types", response);
          if (response.error && !apiTokenCertified(response.error))
            navigate("/log-out");
          if (response.status !== 200 && response.status !== 204)
            showNotification(
              "error",
              languageState.texts.Errors.ModifyingSearches
            );
        }
        // if there are properties to keep
        if (toModify.length) {
          console.log("body", toModifyData);
          response = await modifySearch(toModifyData);
          console.log("response modify", response);
          if (response.error && !apiTokenCertified(response.error))
            navigate("/log-out");
          if (!response || response.status !== 200)
            showNotification(
              "error",
              languageState.texts.Errors.ModifyingSearches
            );
        }
        // if there are new properties
        console.log(
          toSaveData.caracteristica_array,
          oldSearches,
          toDeleteData.caracteristica_ids
        );
        console.log(
          toSaveData.localidad_ids,
          oldDivisions,
          toDeleteData.localidad_ids
        );
        console.log(
          toSaveData.tipo_propiedad_ids,
          oldTypes,
          toDeleteData.tipo_propiedad_ids
        );
        // return;
        if (
          toSaveData.caracteristica_array.length ||
          toSaveData.localidad_ids.length ||
          toSaveData.tipo_propiedad_ids.length
        ) {
          const response = await createSearch(toSaveData);
          console.log("response save", response);
          if (response.status === 200 || response.status === 204) {
            showNotification(
              "success",
              languageState.texts.Messages.SearchSavedSuccessful
            );
          } else if (response.error && !apiTokenCertified(response.error))
            navigate("/log-out");
          else showNotification("error", languageState.texts.Errors.SomeWrong);
        }
        /* if (toSaveData.localidad_ids.length) {
                        await localCreateSearchDivisions()
                    }
                    if (toSaveData.tipo_propiedad_ids.length) {
                        await localCreateSearchTypes(toSaveData.tipo_propiedad_ids)
                    } */
      } else {
        document.getElementById("environments-min")?.focus();
        showNotification(
          "error",
          languageState.texts.Errors.OneCharacteristicAtLeast
        );
      }
      fetchSearches(propertyToModify);
    } catch (err) {
      console.log("hola");
      console.error(err);
      const { message } = err;
      if (message) showNotification("error", message);
      if (err.response && err.response.data && err.response.data.data) {
        const { inmueble_id } = err.response.data.data;
        if (inmueble_id) showNotification("error", inmueble_id[0]);
        else showNotification("error", String(err));
      } else showNotification("error", String(err));
      setLoadingSearches(false);
    }
  };

  const localCreateMultimedia = async (inmuebleId) => {
    setLoading(true);
    try {
      const { video, video360, photos, flats } = getValues();
      const data = {};
      data.inmueble_id = inmuebleId;
      if (video) data.video = video[0] || "";
      if (video360) data.video360 = video360[0] || "";
      if (photos)
        photos.forEach((item, i) => {
          data[`imagenes[${i}]`] = item;
        });
      if (flats)
        flats.forEach((item, i) => {
          data[`planos[${i}]`] = item;
        });
      console.log(data);
      const response = await createMultimedia(data, inmuebleId);
      console.log(response);
      if (response.status === 200) {
        // showNotification("success", languageState.texts.Messages.MultimediaCreatedSuccessful);
        return true;
      }

      if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
      return false;
    } catch (err) {
      const { status, message } = err;
      console.error(err);
      if (status === 422) {
        console.error(err);
        showNotification(
          "error",
          `${languageState.texts.Errors.MultimediaError}${message}`
        );
        return `${languageState.texts.Errors.MultimediaError}${message}`;
      }
      if (err.message.indexOf("422") > -1) {
        console.error(err);
        const error = err.response.data.data;
        let message = languageState.texts.Errors.SomeWrong;
        const newInvalids = invalids;
        try {
          error.forEach((item) => {
            const enName =
              languageState.texts.Dashboard.TableAttributesEN[item.field];
            newInvalids[enName] = true;
            message = languageState.texts.Errors.NameTaken;
          });
        } catch (err) {
          console.error(err);
        }

        setInvalids(newInvalids);
        showNotification("error", message);
      } else {
        console.error(err);
        const { message } = err;
        showNotification("error", message || String(err));
      }
    }
    setLoading(false);
    return false;
  };

  const createBasicInformation = (data, usdValue, pesosValue) => {
    const dataAsArray = Object.values(data);
    const basicCharacteristics = characteristics.filter((item) => {
      if (item.grupo_caracteristica_id === 1) return item;
      return null;
    });
    let toReturn = [];
    const toInsert = [];
    basicCharacteristics.forEach((item) => {
      switch (item.id) {
        case 73: // title
          if (dataAsArray[0] !== "") {
            if (oldBasicCharacteristics.indexOf("titulo") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: dataAsArray[0],
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: dataAsArray[0],
              });
          }
          break;
        case 74: // environments
          if (dataAsArray[1] !== "") {
            if (oldBasicCharacteristics.indexOf("ambientes") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: dataAsArray[1],
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: dataAsArray[1],
              });
          }
          break;
        case 75: // bedrooms
          if (dataAsArray[2] !== "") {
            if (oldBasicCharacteristics.indexOf("dormitorios") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: dataAsArray[2],
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: dataAsArray[2],
              });
          }
          break;
        case 76: // bathrooms
          if (dataAsArray[3] !== "") {
            if (oldBasicCharacteristics.indexOf("banos") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: dataAsArray[3],
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: dataAsArray[3],
              });
          }
          break;
        case 77: // garages
          if (dataAsArray[4] !== "") {
            if (oldBasicCharacteristics.indexOf("cocheras") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: dataAsArray[4],
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: dataAsArray[4],
              });
          }
          break;
        case 78: // usd_value
          if (oldBasicCharacteristics.indexOf("valor_usd") === -1)
            toInsert.push({
              caracteristica_id: item.id,
              valor: strToNumber(String(usdValue)),
            });
          else
            toReturn.push({
              caracteristica_id: item.id,
              valor: strToNumber(String(usdValue)),
            });
          break;
        case 79: // pesos_value
          if (oldBasicCharacteristics.indexOf("valor_pesos") === -1)
            toInsert.push({
              caracteristica_id: item.id,
              valor: strToNumber(String(pesosValue)),
            });
          else
            toReturn.push({
              caracteristica_id: item.id,
              valor: strToNumber(String(pesosValue)),
            });
          break;
        case 80: // totalSurface
          if (dataAsArray[5] !== "") {
            if (oldBasicCharacteristics.indexOf("superficie_total") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: String(dataAsArray[5]),
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: String(dataAsArray[5]),
              });
          }
          break;
        case 81: // deckSurface
          if (dataAsArray[6] !== "") {
            if (oldBasicCharacteristics.indexOf("superficie_cubierta") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: String(dataAsArray[6]),
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: String(dataAsArray[6]),
              });
          }
          break;
        case 82: // description
          if (dataAsArray[8] !== "") {
            if (oldBasicCharacteristics.indexOf("descripcion") === -1)
              toInsert.push({
                caracteristica_id: item.id,
                valor: dataAsArray[8],
              });
            else
              toReturn.push({
                caracteristica_id: item.id,
                valor: dataAsArray[8],
              });
          }
          break;
        default:
          break;
      }
    });
    if (!propertyToModify) toReturn = toInsert;

    return { toReturn, toInsert };
  };

  const localCreateProperty = useCallback(
    async (d) => {
      setLoading(true);
      if (!locationId) {
        setTab(2);
        if (document.getElementById("neighborhood"))
          document.getElementById("neighborhood").focus();
        showNotification("error", languageState.texts.Errors.InvalidLocation);
      } else if (usdValue && usdValue.length === 0) {
        setTab(0);
        if (document.getElementById("usdValue"))
          document.getElementById("usdValue").focus();
        showNotification("error", languageState.texts.Errors.USDValueNotEmpty);
      } else if (pesosValue && pesosValue.length === 0) {
        setTab(0);
        if (document.getElementById("pesosValue"))
          document.getElementById("pesosValue").focus();
        showNotification(
          "error",
          languageState.texts.Errors.PesosValueNotEmpty
        );
      } else
        try {
          const basicInformation = createBasicInformation(
            d,
            usdValue,
            pesosValue
          );
          const characteristicValues = [];
          Object.keys(d).forEach((item) => {
            if (item.indexOf("characteristic") === 0 && d[item])
              characteristicValues.push({
                caracteristica_id: Number(item.split("-")[1]),
                valor: "1",
              });
          });
          const data = {
            tipo_propiedad_id: propertyTypeId,
            // inmobiliaria_id: stateId,
            latitud: lat,
            longitud: lng,
            localidad_id: locationId,
            direccion: getValues("address"),
          };
          if (!propertyToModify)
            data.caracteristicas_array = [
              ...basicInformation.toReturn,
              ...characteristicValues,
            ];
          console.info("creating property");
          const response = propertyToModify
            ? await modifyProperty(propertyToModify, data)
            : await createProperty(data);
          if (response.status === 200) {
            const { id } = response.data;
            console.info("creating multimedia");
            const resultOfMultimedia = await localCreateMultimedia(
              id || propertyToModify
            );
            console.info("multimedia created", resultOfMultimedia);
            let responseFromCharacteristics = false;
            const toDelete = [];
            // searching to delete
            oldCharacteristics.forEach((item) => {
              const filter = characteristicValues.filter((jtem) => {
                if (item.id === jtem.caracteristica_id) return jtem;
                return null;
              });
              if (!filter.length) toDelete.push(item.id);
              else
                basicInformation.toReturn.push({
                  caracteristica_id: item.id,
                  valor: item.value,
                });
            });
            // searching to add
            characteristicValues.forEach((item) => {
              const filter = oldCharacteristics.filter((jtem) => {
                if (item.caracteristica_id === jtem.id) return jtem;
                return null;
              });
              if (!filter.length)
                basicInformation.toInsert.push({
                  caracteristica_id: item.caracteristica_id,
                  valor: "1",
                });
            });
            try {
              // deleting non used characteristics
              if (propertyToModify && toDelete.length)
                responseFromCharacteristics =
                  await deletePropertyCharacteristic(
                    propertyToModify,
                    toDelete
                  );
              if (
                propertyToModify &&
                toDelete.length &&
                responseFromCharacteristics.status !== 200 &&
                responseFromCharacteristics.status !== 204
              )
                throw { err: responseFromCharacteristics };
            } catch (err) {
              console.error(err);
            }
            try {
              // inserting new characteristics
              if (propertyToModify && basicInformation.toInsert.length)
                responseFromCharacteristics =
                  await createPropertyCharacteristic(
                    propertyToModify,
                    basicInformation.toInsert
                  );
              if (
                propertyToModify &&
                basicInformation.toInsert.length &&
                responseFromCharacteristics.status !== 200
              )
                throw { err: responseFromCharacteristics };
            } catch (err) {
              console.error(err);
              const { message } = err;
              showNotification("error", message || String(err));
            }
            try {
              // modifying new characteristics
              if (propertyToModify && basicInformation.toReturn.length)
                responseFromCharacteristics =
                  await modifyPropertyCharacteristic(
                    propertyToModify,
                    basicInformation.toReturn
                  );
              if (
                propertyToModify &&
                basicInformation.toReturn.length &&
                responseFromCharacteristics.status !== 200
              )
                throw { err: responseFromCharacteristics };
            } catch (err) {
              console.error(err);
              const { message } = err;
              showNotification("error", message || String(err));
            }
            reset({
              title: "",
              environments: 0,
              bedrooms: 0,
              bathrooms: 0,
              garages: 0,
              address: "",
              usdValue: 0,
              pesosValue: 0,
              totalSurface: 0,
              deckSurface: 0,
              description: "",
              video: "",
              video360: "",
              photos: "",
              flats: "",
            });
            setUsdValue(0);
            setPesosValue(0);
            cleanMultimedia();
            if (!resultOfMultimedia.length) {
              showNotification(
                "success",
                languageState.texts.Messages.PropertyCreatedSuccessful
              );
            } else {
              showNotification("warning", resultOfMultimedia);
            }
            setTab(0);
            if (propertyToModify) retry();
            else {
              setPropertyToModify(id);
              setBigTab(1);
            }
          } else if (response.error && !apiTokenCertified(response.error))
            navigate("/log-out");
          else showNotification("error", languageState.texts.Errors.SomeWrong);
        } catch (err) {
          console.error(err);
          if (err.status && err.status === 403)
            showNotification("error", languageState.texts.Errors.UpgradePlan);
          if (err.message && err.message.indexOf("422") > -1) {
            const errorData = err.response.data.data;
            let message = languageState.texts.Errors.SomeWrong;
            const newInvalids = invalids;
            errorData.forEach((item) => {
              const enName =
                languageState.texts.Dashboard.TableAttributesEN[item.field];
              newInvalids[enName] = true;
              message = item.message;
            });
            setInvalids(newInvalids);
            setTab(0);
            showNotification("error", message);
          } else {
            console.error(err);
            const { message } = err;
            showNotification("error", message || String(err));
          }
        }
      setLoading(false);
    },
    [usdValue, pesosValue, locationId, propertyToModify]
  );

  const validate = () => setOk(true);

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return showNotification(
            "error",
            languageState.texts.Errors.NameRequired
          );
      }
    }
  };

  useEffect(() => {
    if (toEdit) setPropertyToModify(toEdit);
    if (!propertyTypes.length) retry(toEdit);
  }, [toEdit]);

  const toViewMatchesSection = async () => {
    if (toViewMatches) {
      setPropertyToModify(toViewMatches);
      await retry(toViewMatches);
    }
  };

  useEffect(() => {
    toViewMatchesSection();
  }, [toViewMatches]);

  const [tokko, setTokko] = useState("");

  const [toSearchProp, setToSearchProp] = useState(0);

  const fetchTokko = async () => {
    const response = await getMe();
    if (response.status === 200) {
      const localTokko = response.data.tokko_api_key;
      setTokko(localTokko);
    } else if (response.error && !apiTokenCertified(response.error))
      navigate("/log-out");
    else showNotification("error", languageState.texts.Errors.NotConnected);
  };

  const [seeingTokko, setSeeingTokko] = useState(false);

  useEffect(() => {
    if (toSearchProp) {
      setBigTab(1);
      setSeeingTokko(false);
      setPropertyToModify(toSearchProp);
    }
  }, [toSearchProp]);

  useEffect(() => {
    fetchTokko();
  }, []);

  const removeToDo = async (selected) => {
    setLoading(true);
    try {
      console.log("to desactivar", selected);
      const response = await deleteProperty(selected[0]);
      if (response.status !== 200) {
        showNotification("error", languageState.texts.Errors.SomeWrong);
        setPropertyStateV(!propertyStateV);
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
      else
        showNotification(
          "success",
          languageState.texts.Messages.PropertyDeactivatedSuccessful
        );
      setLoading(false);
    } catch (error) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
      setLoading(false);
    }
  };

  const activateToDo = async (selected) => {
    setLoading(true);
    try {
      console.log("to activar", selected);
      const response = await activateProperty(selected[0]);
      if (response.status !== 200) {
        showNotification("error", languageState.texts.Errors.SomeWrong);
        setPropertyStateV(!propertyStateV);
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
      else
        showNotification(
          "success",
          languageState.texts.Messages.PropertyActivatedSuccessful
        );
      setLoading(false);
    } catch (error) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
      setLoading(false);
    }
  };

  const [showShareDialog, setShowShareDialog] = useState(false);

  const onCloseShareDialog = (clicked) => {
    switch (clicked) {
      case 1:
        setEmailDialog(true);
        break;

      case 2:
        setWhatsappDialog(true);
        break;

      default:
        break;
    }
    setShowShareDialog(false);
  };

  const onShare = (selected) => {
    const toShare = [];
    selected.forEach((item) => {
      const element = realMatches.find((jtem) => jtem.id === item);
      if (element) toShare.push(element);
    });
    setToShareProperties(toShare);
    setShowShareDialog(true);
  };

  const togglePropertyState = useCallback(
    (newState) => {
      console.log(propertyToModify, stateLoaded);
      if (propertyToModify) {
        if (stateLoaded) {
          if (!newState) removeToDo([propertyToModify]);
          else activateToDo([propertyToModify]);
        } else setStateLoaded(true);
      }
      setPropertyStateV(newState);
    },
    [propertyToModify, stateLoaded]
  );

  const emailSend = async (subject, to, message) => {
    try {
      /* const resultPDF = await createPdf(
        toShareProperties.map((item) => item.id),
        languageState.texts
      ); */
      await sendEmailPDF(
        to,
        subject,
        message.replaceAll("\n", "<br>") /* resultPDF.body, resultPDF.css */
      );
      setEmailDialog(false);
    } catch (err) {
      console.error(err);
      const { message } = err;
      showNotification("error", message || String(err));
    }
  };

  const [geocoderInput, setGeocoderInput] = useState("");

  useEffect(() => {
    const elements = document.querySelector(".mapboxgl-ctrl-geocoder--input");
    if (countries[countryId]) {
      const countryName = countries[countryId].name;
      if (elements !== null) {
        // elements.value = countryName;
        setGeocoderInput(countryName);
        setTimeout(() => {
          elements.blur();
        }, 200);
      }
    }
  }, [countries, countryId]);

  useEffect(() => {
    const elements = document.querySelector(".mapboxgl-ctrl-geocoder--input");
    if (state) {
      const stateName = state.name;
      if (elements !== null) {
        // elements.value = stateName;
        setGeocoderInput(stateName);
        setTimeout(() => {
          elements.blur();
        }, 200);
      }
    }
  }, [state]);

  useEffect(() => {
    const elements = document.querySelector(".mapboxgl-ctrl-geocoder--input");
    if (division) {
      const divisionName = division.name;
      if (elements !== null) {
        // elements.value = stateName;
        setGeocoderInput(divisionName);
        setTimeout(() => {
          elements.blur();
        }, 200);
      }
    }
  }, [divisionId]);

  const [inputDivision, setInputDivision] = useState("");
  const handleSelectedDivision = (e, value) => {
    setInputDivision(value);
  };

  const [timeOut, setTimeOut] = useState(null);
  const [ask, setAsk] = useState(false);

  useEffect(() => {
    if (inputDivision.length) {
      clearTimeout(timeOut);
      setTimeOut(
        setTimeout(() => {
          setAsk(true);
        }, 1000)
      );
    }
  }, [inputDivision]);

  useEffect(() => {
    if (ask) {
      toSearch({
        target: {
          id: "selected-division-input",
          value: inputDivision,
        },
      });
      setAsk(false);
    }
  }, [ask, inputDivision]);

  return (
    <SitoContainer sx={{ position: "relative", minHeight: "300px" }}>
      <ShareDialog open={showShareDialog} handleClose={onCloseShareDialog} />
      <WhatsappDialog
        number=""
        toClient
        propertyData={toShareProperties}
        open={whatsappDialog}
        handleClose={onCloseWhatsappDialog}
      />
      <EmailDialog
        email=""
        toClient
        propertyData={toShareProperties}
        open={emailDialog}
        handleClose={onCloseEmailDialog}
        onSubmit={emailSend}
      />
      <Loader visible={loading} sx={{ zIndex: loading ? 99 : -1 }} />

      <form
        id="register-form"
        className={css({ width: "100%" })}
        onSubmit={handleSubmit(localCreateProperty)}
      >
        <SitoContainer
          alignItems="center"
          justifyContent="flex-start"
          sx={{ marginTop: "20px" }}
        >
          {!seeingTokko ? (
            <h3 className="dashboard-h3 dashboard-mb-3">
              {toEdit || toViewMatches || propertyToModify
                ? `${
                    languageState.texts.Dashboard.Property.Modify.Title
                  } ${getValues("title")}`
                : languageState.texts.Dashboard.Property.Insert.Title}
            </h3>
          ) : (
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.Property.TokkoTitle}
            </h3>
          )}
        </SitoContainer>
        <SitoContainer>
          <Button
            color={!seeingTokko ? "primary" : "inherit"}
            onClick={() => setSeeingTokko(false)}
            type="button"
          >
            {languageState.texts.Buttons.CreateProperty}
          </Button>
          {!propertyToModify && (
            <Button
              color={seeingTokko ? "primary" : "inherit"}
              onClick={() => setSeeingTokko(true)}
              type="button"
            >
              {languageState.texts.Buttons.ImportTokko}
            </Button>
          )}
          {propertyToModify > 0 && (
            <Button
              variant={!propertyStateV ? "contained" : "outlined"}
              onClick={() => togglePropertyState(!propertyStateV)}
              type="button"
            >
              {!propertyStateV
                ? languageState.texts.Buttons.Activate
                : languageState.texts.Buttons.Deactivate}
            </Button>
          )}
        </SitoContainer>

        {!propertyToModify && seeingTokko && (
          <Tokko
            onSuccess={(id) => {
              setPropertyToModify(id);
              setToSearchProp(id);
            }}
          />
        )}
        {!seeingTokko && (
          <TabView
            value={bigTab}
            onChange={onChangeBigTab}
            tabs={languageState.texts.Dashboard.Property.BigTabs}
            content={[
              <>
                <TabView
                  sx={{ marginTop: "20px" }}
                  value={tab}
                  onChange={handleChange}
                  tabs={tabsLabels}
                  disabled={[5]}
                  content={[
                    <>
                      {/* property type */}
                      <SitoContainer
                        ignoreDefault
                        className="row"
                        sx={{ marginTop: "20px" }}
                      >
                        <SitoContainer ignoreDefault className="col-md-12">
                          {loadingPropertyTypes ? (
                            <Loader
                              visible
                              sx={{
                                position: "relative !important",
                                height: "60px",
                                width: "100",
                              }}
                              small
                            />
                          ) : (
                            <SitoContainer
                              ignoreDefault
                              className="form-group"
                              sx={{ marginRight: "10px" }}
                            >
                              <label htmlFor="propetyType">
                                {
                                  languageState.texts.Dashboard.PropertyType
                                    .Select
                                }
                              </label>

                              <select
                                className="form-control"
                                id="propertyType"
                                value={propertyTypeId}
                                onChange={handleSelected}
                                disabled={!propertyTypes.length}
                              >
                                {propertyTypes.map((jtem) => (
                                  <option key={jtem.id} value={jtem.id}>
                                    {jtem.nombre}
                                  </option>
                                ))}
                              </select>
                            </SitoContainer>
                          )}
                        </SitoContainer>
                      </SitoContainer>
                      <SitoContainer ignoreDefault className="row">
                        {languageState.texts.Dashboard.Property.Inputs.map(
                          (item, i) => (
                            <SitoContainer
                              key={i}
                              ignoreDefault
                              className="col-md-12"
                              sx={{ display: "flex " }}
                            >
                              {item.map((jtem) => (
                                <SitoContainer
                                  key={jtem.id}
                                  ignoreDefault
                                  className="form-group"
                                  sx={{
                                    width: "100%",
                                    marginRight: item.length ? "10px" : "",
                                  }}
                                >
                                  <label htmlFor={jtem.id}>{`${jtem.label} ${
                                    jtem.required ? "*" : ""
                                  }`}</label>
                                  {jtem.id === "usdValue" ? (
                                    <NumberInput
                                      onChange={handleNumber}
                                      value={usdValue}
                                      {...{
                                        ...jtem,
                                        validate,
                                        invalidate,
                                        invalids,
                                      }}
                                    />
                                  ) : null}
                                  {jtem.id === "pesosValue" ? (
                                    <NumberInput
                                      onChange={handleNumber}
                                      value={pesosValue}
                                      {...{
                                        ...jtem,
                                        validate,
                                        invalidate,
                                        invalids,
                                      }}
                                    />
                                  ) : null}
                                  {jtem.type === "textarea" ? (
                                    <textarea
                                      placeholder={jtem.placeholder}
                                      name={jtem.id}
                                      id={jtem.id}
                                      required={jtem.required}
                                      onInput={validate}
                                      onInvalid={invalidate}
                                      {...register(jtem.id)}
                                      className={`${css({
                                        height: "100px !important",
                                      })} ${invalids[jtem.id] ? "error" : ""}`}
                                    />
                                  ) : (
                                    <>
                                      {jtem.id !== "usdValue" &&
                                      jtem.id !== "pesosValue" ? (
                                        <input
                                          placeholder={jtem.placeholder}
                                          type={jtem.type}
                                          name={jtem.id}
                                          lang="ro"
                                          id={jtem.id}
                                          required={jtem.required}
                                          onInput={validate}
                                          onInvalid={invalidate}
                                          defaultValue={
                                            jtem.type === "number" ? 0 : ""
                                          }
                                          {...register(jtem.id)}
                                          className={
                                            invalids[jtem.id] ? "error" : ""
                                          }
                                        />
                                      ) : null}
                                    </>
                                  )}
                                </SitoContainer>
                              ))}
                            </SitoContainer>
                          )
                        )}
                        <SitoContainer
                          ignoreDefault
                          className="row align-items-center margin-30px-top"
                        >
                          <SitoContainer ignoreDefault className="col-md-6">
                            <button
                              type="button"
                              className="butn"
                              onClick={() => {
                                setTab(1);
                                scrollTo(0);
                              }}
                            >
                              {languageState.texts.Buttons.Next}
                            </button>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                    </>,
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {loadingCharacteristics ? (
                        <Loader
                          visible
                          sx={{
                            position: "absolute !important",
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <>
                          {" "}
                          {characteristicGroups
                            .filter((item) => {
                              const charFilter = characteristics.filter(
                                (jtem) => {
                                  if (jtem.grupo_caracteristica_id === item.id)
                                    return jtem;
                                  return null;
                                }
                              );
                              if (charFilter.length) return item;
                              return null;
                            })
                            .map((item) => (
                              <SitoContainer
                                flexDirection="column"
                                key={item.id}
                              >
                                <h5 className={css({ marginTop: "20px" })}>
                                  {item.nombre}
                                </h5>
                                <SitoContainer
                                  justifyContent="flex-start"
                                  sx={{
                                    flexWrap: "wrap",
                                    background: theme.palette.background.paper,
                                    padding: "10px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  {characteristics
                                    .filter((jtem) => {
                                      if (
                                        jtem.grupo_caracteristica_id === item.id
                                      )
                                        return jtem;
                                      return null;
                                    })
                                    .map((ktem) => (
                                      <Controller
                                        key={ktem.id}
                                        control={control}
                                        name={`characteristic-${ktem.id}`}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            sx={{
                                              flex: 1,
                                              minWidth: "200px",
                                              maxWidth: "200px",
                                            }}
                                            control={
                                              <Checkbox
                                                id={`characteristic-${ktem.id}`}
                                                name={`characteristic-${ktem.id}`}
                                                checked={field.value}
                                                onChange={(event, checked) => {
                                                  field.onChange(
                                                    (event, checked)
                                                  );
                                                }}
                                              />
                                            }
                                            label={ktem.nombre}
                                          />
                                        )}
                                      />
                                    ))}
                                </SitoContainer>
                              </SitoContainer>
                            ))}
                        </>
                      )}

                      <SitoContainer
                        ignoreDefault
                        className="row align-items-center margin-30px-top"
                      >
                        <SitoContainer ignoreDefault className="col-md-6">
                          <button
                            type="button"
                            className="butn"
                            onClick={() => setTab(2)}
                          >
                            {languageState.texts.Buttons.Next}
                          </button>
                        </SitoContainer>
                      </SitoContainer>
                    </Box>,
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      {loadingGeolocation ? (
                        <Loader
                          visible
                          sx={{
                            position: "absolute !important",
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <>
                          <SitoContainer>
                            {/* country */}
                            <SitoContainer
                              ignoreDefault
                              className="row"
                              sx={{
                                marginTop: "20px",
                                flex: 1,
                                width: "100%",
                                marginRight: "20px",
                              }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="col-md-12"
                                sx={{ paddingRight: "4px" }}
                              >
                                <SitoContainer
                                  ignoreDefault
                                  className="form-group"
                                >
                                  <label htmlFor="users">
                                    {
                                      languageState.texts.Dashboard.Country
                                        .Select
                                    }
                                  </label>
                                  <SitoContainer
                                    sx={{
                                      position: "relative",
                                      height: "calc(1.5em + 0.75rem + 2px)",
                                    }}
                                  >
                                    <Autocomplete
                                      options={Object.values(countries)}
                                      getOptionLabel={(option) => option.name}
                                      value={country}
                                      sx={{
                                        width: "100%",
                                      }}
                                      onChange={(event, newValue) => {
                                        setCountry(newValue);
                                        setCountryId(newValue.id);
                                        setCountryToSearch(newValue.name);
                                        setShowCountries(false);
                                        toSearch({
                                          target: {
                                            id: "country-input",
                                            value: newValue.name,
                                          },
                                        });
                                      }}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{
                                            border:
                                              "1px solid #ced4da !important",
                                            borderRadius: "3px",
                                            div: {
                                              input: {},
                                              fieldset: {
                                                border: "none !important",
                                              },
                                            },
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                          }}
                                        />
                                      )}
                                      autoHighlight
                                    />
                                    <Loader
                                      visible={!Object.values(countries).length}
                                      minimal
                                      small
                                    />
                                  </SitoContainer>
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                            {/* state */}
                            <SitoContainer
                              ignoreDefault
                              className="row"
                              sx={{
                                marginTop: "20px",
                                flex: 1,
                                width: "100%",
                              }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="col-md-12"
                                sx={{ paddingLeft: "2px" }}
                              >
                                <SitoContainer
                                  ignoreDefault
                                  className="form-group"
                                >
                                  <label htmlFor="users">
                                    {
                                      languageState.texts.Dashboard.StateG
                                        .SelectAlter
                                    }
                                  </label>
                                  <SitoContainer
                                    sx={{
                                      position: "relative",
                                      height: "calc(1.5em + 0.75rem + 2px)",
                                    }}
                                  >
                                    <Autocomplete
                                      options={
                                        Boolean(stateGs) &&
                                        Boolean(stateGs[countryId])
                                          ? Object.values(stateGs[countryId])
                                          : []
                                      }
                                      disabled={!countryId}
                                      getOptionLabel={(option) => option.name}
                                      value={state}
                                      sx={{
                                        width: "100%",
                                        div: {
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                          label: { marginTop: "-2px" },
                                        },
                                      }}
                                      onChange={(event, newValue) => {
                                        setState(newValue);
                                        setStateGId(newValue.id);
                                        setStateToSearch(newValue.name);
                                        setShowStates(false);
                                        toSearch({
                                          target: {
                                            id: "state-input",
                                            value: newValue.name,
                                          },
                                        });
                                      }}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{
                                            border:
                                              "1px solid #ced4da !important",
                                            borderRadius: "3px",
                                            div: {
                                              input: {
                                                marginTop: "-8px",
                                              },
                                              fieldset: {
                                                border: "none !important",
                                              },
                                            },
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                          }}
                                        />
                                      )}
                                      autoHighlight
                                    />
                                    <Loader
                                      sx={{ position: "absolute !important" }}
                                      visible={loadingStates}
                                      minimal
                                      small
                                    />
                                  </SitoContainer>
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                          <SitoContainer>
                            {/* divisions */}
                            <SitoContainer
                              ignoreDefault
                              className="row"
                              sx={{
                                marginTop: "20px",
                                flex: 1,
                                width: "100%",
                                marginRight: "20px",
                              }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="col-md-12"
                                sx={{ paddingRight: "4px" }}
                              >
                                <SitoContainer
                                  ignoreDefault
                                  className="form-group"
                                >
                                  <label htmlFor="users">
                                    {
                                      languageState.texts.Dashboard.Division
                                        .SelectAlter
                                    }
                                  </label>
                                  <SitoContainer
                                    sx={{
                                      position: "relative",
                                      height: "calc(1.5em + 0.75rem + 2px)",
                                    }}
                                  >
                                    <Autocomplete
                                      options={
                                        Boolean(divisions) &&
                                        Boolean(divisions[stateGId])
                                          ? Object.values(divisions[stateGId])
                                          : []
                                      }
                                      disabled={!stateGId}
                                      getOptionLabel={(option) => option.name}
                                      value={division}
                                      sx={{
                                        width: "100%",
                                        div: {
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                          label: { marginTop: "-2px" },
                                        },
                                      }}
                                      noOptionsText={
                                        languageState.texts.Dashboard.Division
                                          .NoOptionsText
                                      }
                                      onChange={(event, newValue) => {
                                        setDivision(newValue);
                                        setDivisionId(newValue.id);
                                        setDivisionToSearch(newValue.name);
                                        toSearch({
                                          target: {
                                            id: "division-input",
                                            value: newValue.name,
                                            wasSelected: true,
                                          },
                                        });
                                      }}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{
                                            border:
                                              "1px solid #ced4da !important",
                                            borderRadius: "3px",
                                            div: {
                                              input: {
                                                marginTop: "-8px",
                                              },
                                              fieldset: {
                                                border: "none !important",
                                              },
                                            },
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                          }}
                                          onChange={() =>
                                            toSearch({
                                              target: {
                                                id: "division-input",
                                                value: divisionToSearch,
                                              },
                                            })
                                          }
                                        />
                                      )}
                                      autoHighlight
                                    />
                                    <Loader
                                      sx={{
                                        position: "absolute !important",
                                        width: "40px !important",
                                        height: "30px !important",
                                        top: "4px  !important",
                                        right: "28px !important",
                                        left: "inherit !important",
                                        div: {
                                          width: "25px",
                                          height: "25px",
                                        },
                                      }}
                                      visible={loadingDivision}
                                      minimal
                                      small
                                    />
                                  </SitoContainer>
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                            {/* locations */}
                            <SitoContainer
                              ignoreDefault
                              className="row"
                              sx={{
                                marginTop: "20px",
                                flex: 1,
                                width: "100%",
                              }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="col-md-12"
                                sx={{ paddingLeft: "2px" }}
                              >
                                <SitoContainer
                                  ignoreDefault
                                  className="form-group"
                                >
                                  <label htmlFor="users">
                                    {
                                      languageState.texts.Dashboard.Location
                                        .Select
                                    }
                                  </label>
                                  <SitoContainer
                                    sx={{
                                      position: "relative",
                                    }}
                                  >
                                    <Autocomplete
                                      options={
                                        Boolean(locations) &&
                                        Boolean(locations[divisionId])
                                          ? Object.values(locations[divisionId])
                                          : []
                                      }
                                      disabled={!divisionId}
                                      getOptionLabel={(option) => option.name}
                                      value={location}
                                      sx={{
                                        width: "100%",
                                        div: {
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                          label: { marginTop: "-2px" },
                                        },
                                      }}
                                      noOptionsText={
                                        languageState.texts.Dashboard.Division
                                          .NoOptionsText
                                      }
                                      onChange={(event, newValue) => {
                                        setLocation(newValue);
                                        setLocationId(newValue.id);
                                        setLocationToSearch(newValue.name);
                                        setShowLocations(false);
                                        toSearch({
                                          target: {
                                            id: "location-input",
                                            value: newValue.name,
                                            wasSelected: true,
                                          },
                                        });
                                      }}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{
                                            border:
                                              "1px solid #ced4da !important",
                                            borderRadius: "3px",
                                            div: {
                                              input: {
                                                marginTop: "-8px",
                                              },
                                              fieldset: {
                                                border: "none !important",
                                              },
                                            },
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                          }}
                                          onChange={() =>
                                            toSearch({
                                              target: {
                                                id: "location-input",
                                                value: locationToSearch,
                                              },
                                            })
                                          }
                                        />
                                      )}
                                      autoHighlight
                                    />
                                    <Loader
                                      sx={{
                                        position: "absolute !important",
                                        width: "40px !important",
                                        height: "30px !important",
                                        top: "4px  !important",
                                        right: "28px !important",
                                        left: "inherit !important",
                                        div: {
                                          width: "25px",
                                          height: "25px",
                                        },
                                      }}
                                      visible={loadingLocations}
                                      minimal
                                      small
                                    />
                                  </SitoContainer>
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                          <Map
                            onMapClick={lngLatSelected}
                            lat={lat}
                            lng={lng}
                            point={lat && lng ? `${lat},${lng}` : ""}
                            onChange={onChangeMap}
                            onChangeLat={(newValue) => setLat(newValue)}
                            onChangeLng={(newValue) => setLng(newValue)}
                            geocoderInput={geocoderInput}
                            noInputs
                          />
                        </>
                      )}

                      <SitoContainer
                        ignoreDefault
                        className="row align-items-center margin-30px-top"
                      >
                        <SitoContainer ignoreDefault className="col-md-6">
                          <button
                            type="button"
                            className="butn"
                            onClick={() => setTab(3)}
                          >
                            {languageState.texts.Buttons.Next}
                          </button>
                        </SitoContainer>
                      </SitoContainer>
                    </Box>,
                    <>
                      <SitoContainer
                        ignoreDefault
                        className="row"
                        sx={{ marginTop: "20px" }}
                      >
                        <SitoContainer
                          ignoreDefault
                          className="col-md-12"
                          sx={{ display: "flex" }}
                        >
                          {/* Photos */}
                          <SitoContainer
                            flexDirection="column"
                            sx={{ width: "100%" }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="form-group"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <label className={labelForFile} htmlFor="photos">
                                {
                                  languageState.texts.Dashboard.Multimedia
                                    .Inputs[2].label
                                }
                              </label>
                              {!loadingPhotos && (
                                <input
                                  type="file"
                                  multiple
                                  name="photos"
                                  accept=".png,.jpeg,.jpg"
                                  id="photos"
                                  onChange={onUploadFile}
                                />
                              )}
                            </SitoContainer>
                            <SitoContainer
                              id="input-photos"
                              ignoreDefault
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: "10px",
                              }}
                            >
                              {loadingPhotos ? (
                                <SitoContainer
                                  sx={{
                                    ...loadingMultimediaSx,
                                  }}
                                >
                                  <Loader visible minimal />
                                </SitoContainer>
                              ) : (
                                <>
                                  {Object.values(imageIds).map((item) => (
                                    <SitoContainer
                                      key={item.value}
                                      sx={{
                                        ...multimediaRowContainerSx,
                                      }}
                                    >
                                      <SitoImage
                                        sx={thumbnail}
                                        key={item.value}
                                        src={item.content}
                                        alt={item.value}
                                      />
                                      <Button
                                        variant="contained"
                                        sx={{
                                          ...multimediaDeleteButtonSx,
                                        }}
                                        color="error"
                                        id={`button[!]${item.value}`}
                                        onClick={() => removePhoto(item.value)}
                                      >
                                        <CloseIcon
                                          id={`icon[!]${item.value}`}
                                          sx={{ fontSize: "20px" }}
                                        />
                                      </Button>
                                    </SitoContainer>
                                  ))}
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      ...multimediaAddButtonSx,
                                    }}
                                    onClick={() =>
                                      document.getElementById("photos").click()
                                    }
                                  >
                                    <AddAPhotoIcon />
                                  </Button>
                                </>
                              )}
                            </SitoContainer>
                          </SitoContainer>
                          {/* Flats */}
                          <SitoContainer
                            flexDirection="column"
                            sx={{ width: "100%" }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="form-group"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <label className={labelForFile} htmlFor="flats">
                                {
                                  languageState.texts.Dashboard.Multimedia
                                    .Inputs[3].label
                                }
                              </label>
                              {!loadingFlats && (
                                <input
                                  type="file"
                                  multiple
                                  name="flats"
                                  accept=".png,.jpeg,.jpg"
                                  id="flats"
                                  onChange={onUploadFile}
                                />
                              )}
                            </SitoContainer>
                            <SitoContainer
                              id="input-flats"
                              ignoreDefault
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: "10px",
                              }}
                            >
                              {loadingFlats ? (
                                <SitoContainer
                                  sx={{
                                    ...loadingMultimediaSx,
                                  }}
                                >
                                  <Loader visible minimal />
                                </SitoContainer>
                              ) : (
                                <>
                                  {Object.values(flatIds).map((item) => (
                                    <SitoContainer
                                      key={item.value}
                                      sx={{
                                        ...multimediaRowContainerSx,
                                      }}
                                    >
                                      <SitoImage
                                        sx={thumbnail}
                                        key={item.value}
                                        src={item.content}
                                        alt={item.value}
                                      />
                                      <Button
                                        variant="contained"
                                        sx={{
                                          ...multimediaDeleteButtonSx,
                                        }}
                                        color="error"
                                        id={`button[!]${item.value}`}
                                        onClick={() => removeFlat(item.value)}
                                      >
                                        <CloseIcon
                                          id={`icon[!]${item.value}`}
                                          sx={{ fontSize: "20px" }}
                                        />
                                      </Button>
                                    </SitoContainer>
                                  ))}
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      ...multimediaAddButtonSx,
                                    }}
                                    onClick={() =>
                                      document.getElementById("flats").click()
                                    }
                                  >
                                    <AddAPhotoIcon />
                                  </Button>
                                </>
                              )}
                            </SitoContainer>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="row"
                        sx={{ marginTop: "20px" }}
                      >
                        <SitoContainer
                          ignoreDefault
                          className="col-md-12"
                          sx={{ display: "flex" }}
                        >
                          <SitoContainer
                            flexDirection="column"
                            sx={{ width: "100%" }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="form-group"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <label className={labelForFile} htmlFor="videos">
                                {
                                  languageState.texts.Dashboard.Multimedia
                                    .Inputs[0].label
                                }
                              </label>
                              {!loadingVideo && (
                                <input
                                  type="file"
                                  multiple
                                  name="video"
                                  accept=".mp4, .mpeg, .webm"
                                  id="video"
                                  onChange={onUploadFile}
                                />
                              )}
                            </SitoContainer>
                            <SitoContainer
                              id="input-video"
                              ignoreDefault
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: "10px",
                              }}
                            >
                              {loadingVideo ? (
                                <SitoContainer
                                  sx={{
                                    width: "300px",
                                    height: "300px",
                                    position: "relative",
                                  }}
                                >
                                  <Loader visible minimal />
                                </SitoContainer>
                              ) : (
                                <>
                                  {videoIds.value && (
                                    <SitoContainer
                                      sx={{
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        position: "relative",
                                        width: "100%",
                                        height: "300px",
                                      }}
                                    >
                                      {console.log(videoIds.content)}
                                      <video width="100%" height="300" controls>
                                        <source src={videoIds.content} />
                                      </video>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          ...multimediaDeleteButtonSx,
                                        }}
                                        color="error"
                                        id={`button[!]${videoIds.value}`}
                                        onClick={removeVideo}
                                      >
                                        <CloseIcon
                                          id={`icon[!]${videoIds.value}`}
                                          sx={{ fontSize: "20px" }}
                                        />
                                      </Button>
                                    </SitoContainer>
                                  )}
                                  {!videoIds.value && (
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        ...multimediaAddButtonSx,
                                      }}
                                      onClick={() =>
                                        document.getElementById("video").click()
                                      }
                                    >
                                      <VideoCallIcon />
                                    </Button>
                                  )}
                                </>
                              )}
                            </SitoContainer>
                          </SitoContainer>
                          <SitoContainer
                            flexDirection="column"
                            sx={{ width: "100%" }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="form-group"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <label
                                className={labelForFile}
                                htmlFor="videos360"
                              >
                                {
                                  languageState.texts.Dashboard.Multimedia
                                    .Inputs[1].label
                                }
                              </label>
                              {!loadingVideo360 && (
                                <input
                                  type="file"
                                  multiple
                                  name="video360"
                                  accept=".mp4, .mpeg, .webm"
                                  id="video360"
                                  onChange={onUploadFile}
                                />
                              )}
                            </SitoContainer>
                            <SitoContainer
                              id="input-video360s"
                              ignoreDefault
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: "10px",
                              }}
                            >
                              {loadingVideo360 ? (
                                <SitoContainer
                                  sx={{
                                    width: "300px",
                                    height: "300px",
                                    position: "relative",
                                  }}
                                >
                                  <Loader visible minimal />
                                </SitoContainer>
                              ) : (
                                <>
                                  {video360Ids.value && (
                                    <SitoContainer
                                      sx={{
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        position: "relative",
                                        width: "100%",
                                        height: "300px",
                                      }}
                                      key={video360Ids.value}
                                    >
                                      <video width="100%" height="300" controls>
                                        <source src={video360Ids.content} />
                                      </video>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          ...multimediaDeleteButtonSx,
                                        }}
                                        color="error"
                                        id={`button[!]${video360Ids.value}`}
                                        onClick={removeVideo360}
                                      >
                                        <CloseIcon
                                          id={`icon[!]${video360Ids.value}`}
                                          sx={{ fontSize: "20px" }}
                                        />
                                      </Button>
                                    </SitoContainer>
                                  )}
                                  {!video360Ids.value && (
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        ...multimediaAddButtonSx,
                                      }}
                                      onClick={() =>
                                        document
                                          .getElementById("video360")
                                          .click()
                                      }
                                    >
                                      <VideoCallIcon />
                                    </Button>
                                  )}
                                </>
                              )}
                            </SitoContainer>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="row align-items-center margin-30px-top"
                      >
                        <SitoContainer ignoreDefault className="col-md-6">
                          <button type="submit" className="butn">
                            {languageState.texts.Buttons.Save}
                          </button>
                        </SitoContainer>
                      </SitoContainer>
                    </>,
                  ]}
                />
              </>,
              <>
                {propertyToModify ? (
                  <TabView
                    value={matchTab}
                    onChange={onChangeMatchTab}
                    icons={["", <Favorite />]}
                    tabs={languageState.texts.Dashboard.Property.GoingToMatch}
                    content={[
                      <>
                        <SitoContainer>
                          {/* property type */}
                          <SitoContainer
                            ignoreDefault
                            className="row"
                            sx={{
                              marginTop: "20px",
                              flex: 1,
                              width: "100%",
                              marginRight: "8px",
                            }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="col-md-12"
                              sx={{ paddingRight: "4px" }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="form-group"
                              >
                                <label htmlFor="propertyTypeToSearch">
                                  {
                                    languageState.texts.Dashboard.PropertyType
                                      .Select
                                  }
                                </label>
                                <SitoContainer
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <Autocomplete
                                    disabled={!propertyTypes.length}
                                    id="property-types"
                                    multiple
                                    options={[
                                      {
                                        id: 0,
                                        nombre: languageState.texts.Labels.AllF,
                                      },
                                      ...propertyTypes,
                                    ]}
                                    getOptionLabel={(option) => option.nombre}
                                    value={selectedPropertyType}
                                    sx={{
                                      width: "100%",
                                    }}
                                    onChange={(event, newValue) => {
                                      setSelectedPropertyType(newValue);
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.nombre}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {
                                              marginTop: "-8px",
                                            },
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    autoHighlight
                                  />
                                  {/*  <select
                                    className={`form-control ${css({
                                      position: "absolute",
                                      marginRight: "20px",
                                      height:
                                        "calc(1.5em + 0.75rem + 2px) !important",
                                    })} ${invalidCountry ? "error" : ""}`}
                                    id="propertyTypeToSearch"
                                    value={selectedPropertyType}
                                    onChange={handleSelected}
                                    disabled={!propertyTypes.length}
                                  >
                                    <option value={0}>
                                      {languageState.texts.Labels.AllF}
                                    </option>
                                    {propertyTypes.map((jtem) => (
                                      <option key={jtem.id} value={jtem.id}>
                                        {jtem.nombre}
                                      </option>
                                    ))}
                                  </select> */}
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                          {/* country */}
                          <SitoContainer
                            ignoreDefault
                            className="row"
                            sx={{
                              marginTop: "20px",
                              flex: 1,
                              width: "100%",
                              marginRight: "20px",
                            }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="col-md-12"
                              sx={{ paddingRight: "4px" }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="form-group"
                              >
                                <label htmlFor="country-state">
                                  {languageState.texts.Dashboard.Country.Select}
                                </label>
                                <SitoContainer
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <Autocomplete
                                    options={Object.values(countries)}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedCountry || { name: "" }}
                                    sx={{
                                      width: "100%",
                                    }}
                                    onChange={(event, newValue) => {
                                      setSelectedCountry(newValue);
                                      setSelectedCountryId(newValue.id);
                                      setSelectedCountryToSearch(newValue.name);
                                      setSelectedState(null);
                                      setShowCountries(false);
                                      toSearch({
                                        target: {
                                          id: "country-input",
                                          value: newValue.name,
                                        },
                                      });
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {},
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    autoHighlight
                                  />
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                          {/* state */}
                          <SitoContainer
                            ignoreDefault
                            className="row"
                            sx={{ marginTop: "20px", flex: 1, width: "100%" }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="col-md-12"
                              sx={{ paddingLeft: "2px" }}
                            >
                              <SitoContainer
                                ignoreDefault
                                className="form-group"
                              >
                                <label htmlFor="selected-state-input">
                                  {
                                    languageState.texts.Dashboard.StateG
                                      .SelectAlter
                                  }
                                </label>
                                <SitoContainer
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <Autocomplete
                                    options={
                                      Boolean(stateGs) &&
                                      Boolean(
                                        stateGs[selectedCountryId] &&
                                          Boolean(selectedCountry)
                                      )
                                        ? Object.values(stateGs[countryId])
                                        : []
                                    }
                                    disabled={!selectedCountry}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedState || { name: "" }}
                                    sx={{
                                      width: "100%",
                                    }}
                                    onChange={(event, newValue) => {
                                      setSelectedState(newValue);
                                      setSelectedStateGId(newValue.id);
                                      setSelectedStateToSearch(newValue.name);
                                      setShowStates(false);
                                      setSelectedDivision(null);
                                      toSearch({
                                        target: {
                                          id: "selected-state-input",
                                          value: newValue.name,
                                        },
                                      });
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {},
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    autoHighlight
                                  />
                                  <Loader
                                    sx={{ position: "absolute !important" }}
                                    visible={loadingStates}
                                    minimal
                                    small
                                  />
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                        </SitoContainer>
                        <SitoContainer>
                          {/* divisions */}
                          <SitoContainer
                            ignoreDefault
                            className="row"
                            flexDirection="column"
                            sx={{ marginTop: "20px", flex: 1, width: "100%" }}
                          >
                            <SitoContainer ignoreDefault className="col-md-12">
                              <SitoContainer
                                ignoreDefault
                                className="form-group"
                              >
                                <label>
                                  {
                                    languageState.texts.Dashboard.Division
                                      .SelectAlter
                                  }
                                </label>
                                <SitoContainer
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <Autocomplete
                                    options={
                                      Boolean(divisions) &&
                                      Boolean(divisions[selectedStateGId])
                                        ? Object.values(
                                            divisions[selectedStateGId]
                                          )
                                        : []
                                    }
                                    /* inputValue={inputDivision} */
                                    onInputChange={handleSelectedDivision}
                                    disabled={!selectedStateGId}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedDivision}
                                    sx={{
                                      width: "100%",
                                    }}
                                    onChange={(event, newValue) => {
                                      if (newValue !== null) {
                                        setSelectedDivision(newValue);
                                        setSelectedDivisionToSearch("");
                                        setSelectedDivisions({
                                          type: "add",
                                          newDivision: newValue,
                                        });
                                        toSearch({
                                          target: {
                                            id: "selected-division-input",
                                            value: newValue.name,
                                            wasSelected: true,
                                          },
                                        });
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        {option.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          border:
                                            "1px solid #ced4da !important",
                                          borderRadius: "3px",
                                          div: {
                                            input: {
                                              paddingLeft: "25px !important",
                                            },
                                            fieldset: {
                                              border: "none !important",
                                            },
                                          },
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                        placeholder={
                                          languageState.texts.Dashboard.Division
                                            .PlaceholderAlter
                                        }
                                      />
                                    )}
                                    autoHighlight
                                  />
                                  <Button
                                    sx={{
                                      ...searchButtonSx,
                                      left: 0,
                                      top: "50%",
                                      transform: "translate(0px, -50%)",
                                    }}
                                    disabled={
                                      !selectedStateGId &&
                                      selectedDivisionToSearch.length === 0
                                    }
                                    onClick={() =>
                                      toSearch({
                                        target: {
                                          id: "selected-division-input",
                                          value: selectedDivisionToSearch,
                                        },
                                      })
                                    }
                                  >
                                    <SearchIcon />
                                  </Button>
                                  <Loader
                                    sx={{
                                      position: "absolute !important",
                                      width: "25px!important",
                                      height: "30px!important",
                                      top: "4px!important",
                                      left: "7px!important",
                                      right: "inherit !important",
                                      div: {
                                        width: "25px",
                                        height: "25px",
                                      },
                                    }}
                                    visible={loadingDivision}
                                    minimal
                                    small
                                  />
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                            {/* selected divisions */}
                            {selectedDivisions.map((item, i) => (
                              <SitoContainer
                                alignItems="center"
                                sx={{
                                  position: "relative",
                                  minWidth: "100px",
                                  border: "2px solid gray",
                                  borderRadius: "40px",
                                  padding: "10px",
                                  marginTop: "20px",
                                  marginLeft: "15px",
                                }}
                                key={item.id}
                              >
                                <p
                                  className={css({
                                    margin: 0,
                                    marginRight: "20px",
                                  })}
                                >
                                  {item.name}
                                </p>
                                <Button
                                  variant="contained"
                                  sx={{
                                    borderRadius: "100%",
                                    minWidth: 0,
                                    padding: "2px",
                                  }}
                                  color="error"
                                  onClick={() =>
                                    setSelectedDivisions({
                                      type: "remove",
                                      index: i,
                                    })
                                  }
                                >
                                  <CloseIcon sx={{ fontSize: "20px" }} />
                                </Button>
                              </SitoContainer>
                            ))}
                          </SitoContainer>
                        </SitoContainer>
                        <SitoContainer
                          ignoreDefault
                          className="row"
                          sx={{ marginTop: "20px" }}
                        >
                          <SitoContainer
                            ignoreDefault
                            className="col-md-12"
                            sx={{ display: "flex " }}
                          >
                            <SitoContainer
                              ignoreDefault
                              className="form-group"
                              sx={{
                                flex: 1,
                                width: "100%",
                              }}
                            >
                              <SitoContainer
                                alignItems="center"
                                sx={{ marginBottom: "10px" }}
                              >
                                <label htmlFor="environments">
                                  {
                                    languageState.texts.Dashboard.Property
                                      .SpecialInputs.Environments.label
                                  }
                                </label>
                              </SitoContainer>
                              <SitoContainer alignItems="center">
                                <SitoContainer
                                  flexDirection="column"
                                  fullWidth
                                  sx={{ marginRight: "10px" }}
                                >
                                  <label htmlFor="environments-min">
                                    {languageState.texts.Labels.Min}
                                  </label>
                                  <Controller
                                    control={control}
                                    name="environments-min"
                                    render={({ field }) => (
                                      <input
                                        className="form-control"
                                        placeholder={
                                          languageState.texts.Placeholders.Min
                                        }
                                        type="number"
                                        id="environments-min"
                                        max={
                                          !values[`environments-max`]
                                            ? 99999
                                            : values[`environments-max`]
                                        }
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                </SitoContainer>
                                <SitoContainer
                                  flexDirection="column"
                                  fullWidth
                                  sx={{ marginRight: "30px" }}
                                >
                                  <label htmlFor="environments-max">
                                    {languageState.texts.Labels.Max}
                                  </label>
                                  <Controller
                                    control={control}
                                    name="environments-max"
                                    render={({ field }) => (
                                      <input
                                        className="form-control"
                                        placeholder={
                                          languageState.texts.Placeholders.Max
                                        }
                                        type="number"
                                        id="environments-max"
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                            <SitoContainer
                              ignoreDefault
                              className="form-group"
                              sx={{
                                flex: 1,
                                width: "100%",
                              }}
                            >
                              <SitoContainer alignItems="center">
                                <label
                                  htmlFor="value"
                                  className={css({ marginRight: "20px" })}
                                >
                                  {languageState.texts.Labels.Value}
                                </label>
                                <Chip
                                  label={languageState.texts.Labels.USD}
                                  variant={
                                    valueType === languageState.texts.Labels.USD
                                      ? "contained"
                                      : "outlined"
                                  }
                                  color={
                                    valueType === languageState.texts.Labels.USD
                                      ? "primary"
                                      : "default"
                                  }
                                  disabled={
                                    valueType === languageState.texts.Labels.USD
                                  }
                                  onClick={() =>
                                    setValueType(languageState.texts.Labels.USD)
                                  }
                                  sx={{
                                    marginRight: "20px",
                                    marginBottom: "10px",
                                  }}
                                />

                                <Chip
                                  sx={{ marginBottom: "10px" }}
                                  label={languageState.texts.Labels.Pesos}
                                  variant={
                                    valueType ===
                                    languageState.texts.Labels.Pesos
                                      ? "contained"
                                      : "outlined"
                                  }
                                  color={
                                    valueType ===
                                    languageState.texts.Labels.Pesos
                                      ? "primary"
                                      : "default"
                                  }
                                  disabled={
                                    valueType ===
                                    languageState.texts.Labels.Pesos
                                  }
                                  onClick={() =>
                                    setValueType(
                                      languageState.texts.Labels.Pesos
                                    )
                                  }
                                />
                              </SitoContainer>
                              <SitoContainer alignItems="center">
                                <SitoContainer
                                  flexDirection="column"
                                  fullWidth
                                  sx={{ marginRight: "10px" }}
                                >
                                  <label htmlFor="value-min">
                                    {languageState.texts.Labels.Min}
                                  </label>
                                  <Controller
                                    control={control}
                                    name="value-min"
                                    render={({ field }) => (
                                      <input
                                        className="form-control"
                                        placeholder={
                                          languageState.texts.Placeholders.Min
                                        }
                                        type="number"
                                        id="value-min"
                                        max={
                                          !values["value-max"]
                                            ? 99999
                                            : values["value-max"]
                                        }
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                </SitoContainer>
                                <SitoContainer flexDirection="column" fullWidth>
                                  <label htmlFor="value-max">
                                    {languageState.texts.Labels.Max}
                                  </label>
                                  <Controller
                                    control={control}
                                    name="value-max"
                                    render={({ field }) => (
                                      <input
                                        className="form-control"
                                        placeholder={
                                          languageState.texts.Placeholders.Max
                                        }
                                        type="number"
                                        id="value-max"
                                        min={0}
                                        value={field.value}
                                        onChange={(event) => {
                                          field.onChange(event);
                                        }}
                                      />
                                    )}
                                  />
                                </SitoContainer>
                              </SitoContainer>
                            </SitoContainer>
                          </SitoContainer>
                        </SitoContainer>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ position: "relative", marginTop: "20px" }}
                        >
                          <Button
                            variant="contained"
                            disabled={loadingSearches}
                            onClick={() => {
                              setLoadingSearches(true);
                              localCreateSearches();
                            }}
                          >
                            {languageState.texts.Buttons.SaveSearch}
                          </Button>
                          <Loader
                            visible={loadingSearches}
                            minimal
                            small
                            sx={{
                              height: "40px !important",
                              position: "absolute !important",
                              top: 0,
                              left: 0,
                            }}
                          />
                        </Box>
                      </>,
                      <>
                        {oldSearches.length > 0 ? (
                          <>
                            <Loader
                              visible={loadingMatch}
                              minimal
                              sx={{ width: "100%", height: "100%" }}
                            />
                            <>
                              {propertiesMatching === -1 ? (
                                <Empty />
                              ) : (
                                <ComplexTable
                                  onPageChange={onPagination}
                                  page={page}
                                  onShare={onShare}
                                  columns={columns}
                                  rows={propertiesMatching}
                                  onChat={onChat}
                                  filtering={filtering}
                                  filterHeight="200px"
                                  filters={
                                    <SitoContainer
                                      flexDirection="column"
                                      sx={{ height: "300px" }}
                                    >
                                      <SitoContainer
                                        alignItems="center"
                                        sx={{ marginTop: "13px" }}
                                      >
                                        <Button
                                          variant="text"
                                          color={
                                            Object.values(shownFilters).length
                                              ? "primary"
                                              : "secondary"
                                          }
                                          onClick={executeFilter}
                                          sx={{ marginTop: "-10px" }}
                                          disabled={
                                            Object.values(shownFilters)
                                              .length === 0
                                          }
                                        >
                                          {
                                            languageState.texts.Buttons
                                              .ExecuteFilter
                                          }
                                        </Button>
                                        <IconButton
                                          variant="text"
                                          color={
                                            filtering ? "primary" : "inherit"
                                          }
                                          onClick={() =>
                                            setFiltering(!filtering)
                                          }
                                          sx={{ marginTop: "-13px" }}
                                        >
                                          {filtering ? (
                                            <FilterAltIcon />
                                          ) : (
                                            <FilterAltOffIcon />
                                          )}
                                        </IconButton>
                                      </SitoContainer>
                                      <SitoContainer
                                        ignoreDefault
                                        className="form-group"
                                        sx={{
                                          display: "flex",
                                          flex: 1,
                                          flexDirection: "column",
                                        }}
                                      >
                                        {filtering &&
                                          Object.values(shownFilters).map(
                                            (item) => (
                                              <>
                                                {item.type === "input" && (
                                                  <SitoContainer
                                                    alignItems="center"
                                                    sx={{
                                                      width: "715px",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor={item.name}
                                                      className={css({
                                                        margin: 0,
                                                        marginRight: "20px",
                                                        width: "110px",
                                                      })}
                                                    >
                                                      {
                                                        languageState.texts
                                                          .Filters[
                                                          String(item.id)
                                                        ].label
                                                      }
                                                    </label>
                                                    <label
                                                      htmlFor={`${item.name}Min`}
                                                      className={css({
                                                        margin: 0,
                                                        marginRight: "20px",
                                                      })}
                                                    >
                                                      {
                                                        languageState.texts
                                                          .Labels.Min
                                                      }
                                                    </label>
                                                    <Controller
                                                      control={control}
                                                      name={`${item.name}Min`}
                                                      render={({ field }) => (
                                                        <input
                                                          className={`form-control ${css(
                                                            {
                                                              maxWidth: "125px",
                                                              marginRight:
                                                                "20px",
                                                              height:
                                                                "calc(1.5em + 0.75rem + 2px)",
                                                            }
                                                          )}`}
                                                          placeholder={
                                                            languageState.texts
                                                              .Placeholders.Min
                                                          }
                                                          type="number"
                                                          id={`${item.id}Min`}
                                                          max={
                                                            !values.filterEnvironmentsMax
                                                              ? 99999
                                                              : values.filterEnvironmentsMax
                                                          }
                                                          min={0}
                                                          value={field.value}
                                                          onChange={(event) => {
                                                            field.onChange(
                                                              event
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                    <label
                                                      htmlFor={`${item.name}Max`}
                                                      className={css({
                                                        margin: 0,
                                                        marginRight: "20px",
                                                      })}
                                                    >
                                                      {
                                                        languageState.texts
                                                          .Labels.Max
                                                      }
                                                    </label>
                                                    <Controller
                                                      control={control}
                                                      name={`${item.name}Max`}
                                                      render={({ field }) => (
                                                        <input
                                                          className={`form-control ${css(
                                                            {
                                                              maxWidth: "125px",
                                                              height:
                                                                "calc(1.5em + 0.75rem + 2px)",
                                                            }
                                                          )}`}
                                                          placeholder={
                                                            languageState.texts
                                                              .Placeholders.Max
                                                          }
                                                          type="number"
                                                          id={`${item.id}Max`}
                                                          min={0}
                                                          value={field.value}
                                                          onChange={(event) => {
                                                            field.onChange(
                                                              event
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                  </SitoContainer>
                                                )}
                                                {item.type === "text" && (
                                                  <>
                                                    <SitoContainer
                                                      alignItems="center"
                                                      sx={{
                                                        width: "360px",
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <label
                                                        htmlFor={item.name}
                                                        className={css({
                                                          margin: 0,
                                                          marginRight: "20px",
                                                        })}
                                                      >
                                                        {
                                                          languageState.texts
                                                            .Filters[
                                                            String(item.id)
                                                          ].label
                                                        }
                                                      </label>
                                                      <Controller
                                                        control={control}
                                                        name={item.name}
                                                        render={({ field }) => (
                                                          <input
                                                            className={`form-control ${css(
                                                              {
                                                                maxWidth:
                                                                  "275px",
                                                                marginRight:
                                                                  "20px",
                                                                height:
                                                                  "calc(1.5em + 0.75rem + 2px)",
                                                              }
                                                            )}`}
                                                            placeholder={
                                                              languageState
                                                                .texts
                                                                .Placeholders
                                                                .Title
                                                            }
                                                            type="text"
                                                            id={`${item.id}Title`}
                                                            value={field.value}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              field.onChange(
                                                                event
                                                              );
                                                            }}
                                                          />
                                                        )}
                                                      />
                                                    </SitoContainer>
                                                  </>
                                                )}
                                                {item.type ===
                                                  "property-types" && (
                                                  <SitoContainer
                                                    alignItems="center"
                                                    sx={{
                                                      width: "485px",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="propertyType"
                                                      className={css({
                                                        marginRight: "20px",
                                                        marginBottom: 0,
                                                      })}
                                                    >
                                                      {
                                                        languageState.texts
                                                          .Dashboard
                                                          .PropertyType.Select
                                                      }
                                                    </label>
                                                    <select
                                                      className={`form-control ${css(
                                                        {
                                                          width: "200px",
                                                          height:
                                                            "calc(1.5em + 0.75rem + 2px)",
                                                        }
                                                      )}`}
                                                      id="propertyType"
                                                      value={propertyTypeId}
                                                      onChange={(e) => {
                                                        setPropertyTypeId(
                                                          e.target.value
                                                        );
                                                      }}
                                                      disabled={
                                                        !propertyTypes.length
                                                      }
                                                    >
                                                      {propertyTypes.map(
                                                        (jtem) => (
                                                          <option
                                                            key={jtem.id}
                                                            value={jtem.id}
                                                          >
                                                            {jtem.name}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </SitoContainer>
                                                )}
                                                {item.type === "zones" && (
                                                  <SitoContainer
                                                    alignItems="center"
                                                    sx={{
                                                      marginTop: "5px",
                                                      position: "relative",
                                                      height:
                                                        "calc(1.5em + 0.75rem + 2px)",
                                                      width: "490px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="state"
                                                      className={css({
                                                        marginRight: "20px",
                                                        marginBottom: 0,
                                                        width: "235px",
                                                      })}
                                                    >
                                                      {
                                                        languageState.texts
                                                          .Dashboard.StateG
                                                          .SelectAlter
                                                      }
                                                    </label>
                                                    <Autocomplete
                                                      options={
                                                        Boolean(stateGs) &&
                                                        Boolean(stateGs[1])
                                                          ? Object.values(
                                                              stateGs[1]
                                                            )
                                                          : []
                                                      }
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.name}
                                                      value={selectedState}
                                                      sx={{
                                                        width: "100%",
                                                        div: {
                                                          height:
                                                            "calc(1.5em + 0.75rem + 2px)",
                                                        },
                                                      }}
                                                      onChange={(
                                                        event,
                                                        newValue
                                                      ) => {
                                                        if (newValue !== null) {
                                                          setSelectedState(
                                                            newValue
                                                          );
                                                          setSelectedStateGId(
                                                            newValue.id
                                                          );
                                                          toSearch({
                                                            target: {
                                                              id: "selected-state-input",
                                                              value:
                                                                newValue.name,
                                                            },
                                                          });
                                                        }
                                                      }}
                                                      renderOption={(
                                                        props,
                                                        option
                                                      ) => (
                                                        <Box
                                                          component="li"
                                                          {...props}
                                                        >
                                                          {option.name}
                                                        </Box>
                                                      )}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          sx={{
                                                            border:
                                                              "1px solid #ced4da !important",
                                                            borderRadius: "3px",
                                                            div: {
                                                              input: {
                                                                marginTop:
                                                                  "-8px",
                                                              },
                                                              fieldset: {
                                                                border:
                                                                  "none !important",
                                                              },
                                                            },
                                                          }}
                                                          placeholder={
                                                            languageState.texts
                                                              .Filters[7].label
                                                          }
                                                          inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete:
                                                              "new-password", // disable autocomplete and autofill
                                                          }}
                                                        />
                                                      )}
                                                      autoHighlight
                                                    />
                                                    <Loader
                                                      visible={loadingStates}
                                                      minimal
                                                      small
                                                    />
                                                  </SitoContainer>
                                                )}
                                                {item.type === "divisions" && (
                                                  <SitoContainer
                                                    alignItems="center"
                                                    sx={{
                                                      marginTop: "5px",
                                                      position: "relative",
                                                      height:
                                                        "calc(1.5em + 0.75rem + 2px)",
                                                      width: "490px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="division"
                                                      className={css({
                                                        marginRight: "20px",
                                                        marginBottom: 0,
                                                        width: "235px",
                                                      })}
                                                    >
                                                      {
                                                        languageState.texts
                                                          .Dashboard.Division
                                                          .SelectAlter
                                                      }
                                                    </label>
                                                    <Autocomplete
                                                      options={
                                                        Boolean(divisions) &&
                                                        Boolean(
                                                          divisions[
                                                            selectedStateGId
                                                          ]
                                                        )
                                                          ? Object.values(
                                                              divisions[
                                                                selectedStateGId
                                                              ]
                                                            )
                                                          : []
                                                      }
                                                      disabled={
                                                        !selectedStateGId
                                                      }
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.name}
                                                      value={division}
                                                      sx={{
                                                        width: "100%",
                                                        div: {
                                                          height:
                                                            "calc(1.5em + 0.75rem + 2px)",
                                                        },
                                                      }}
                                                      onChange={(
                                                        event,
                                                        newValue
                                                      ) => {
                                                        if (newValue !== null) {
                                                          setDivision(newValue);
                                                          setSelectedDivisionId(
                                                            newValue.id
                                                          );
                                                          setDivisionToSearch(
                                                            newValue.name
                                                          );
                                                          toSearch({
                                                            target: {
                                                              id: "selected-division-input",
                                                              value:
                                                                newValue.name,
                                                              wasSelected: true,
                                                            },
                                                          });
                                                        }
                                                      }}
                                                      renderOption={(
                                                        props,
                                                        option
                                                      ) => (
                                                        <Box
                                                          component="li"
                                                          {...props}
                                                        >
                                                          {option.name}
                                                        </Box>
                                                      )}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          placeholder={
                                                            languageState.texts
                                                              .Filters[6].label
                                                          }
                                                          sx={{
                                                            border:
                                                              "1px solid #ced4da !important",
                                                            borderRadius: "3px",
                                                            div: {
                                                              input: {
                                                                marginTop:
                                                                  "-8px",
                                                              },
                                                              fieldset: {
                                                                border:
                                                                  "none !important",
                                                              },
                                                            },
                                                          }}
                                                          inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete:
                                                              "new-password", // disable autocomplete and autofill
                                                          }}
                                                        />
                                                      )}
                                                      autoHighlight
                                                    />
                                                    <Button
                                                      sx={{
                                                        ...searchButtonSx,
                                                        marginTop: "-3px",
                                                        marginRight: "-5px",
                                                      }}
                                                      disabled={
                                                        !selectedStateGId
                                                      }
                                                      onClick={() =>
                                                        toSearch({
                                                          target: {
                                                            id: "selected-division-input",
                                                            value:
                                                              divisionToSearch,
                                                          },
                                                        })
                                                      }
                                                    >
                                                      <SearchIcon />
                                                    </Button>
                                                    <Loader
                                                      visible={loadingDivision}
                                                      minimal
                                                      small
                                                    />
                                                  </SitoContainer>
                                                )}
                                              </>
                                            )
                                          )}
                                      </SitoContainer>
                                    </SitoContainer>
                                  }
                                  canShow
                                />
                              )}
                            </>
                            {!loadingMatch &&
                              propertiesMatching.length === 0 &&
                              !toViewMatches && <NoMatches />}
                            {/* fetch only matches */}
                            {!loadingMatch && propertiesMatching === -1 && (
                              <Error onAction={fetchMatches(0, -1)} />
                            )}
                          </>
                        ) : (
                          <SitoContainer
                            flexDirection="column"
                            alignItems="center"
                            sx={{ marginTop: "20px" }}
                          >
                            <h3 className="dashboard-h3 dashboard-mb-3">
                              {
                                languageState.texts.Dashboard.Property.Match
                                  .MissingTitle
                              }
                            </h3>
                            <Button
                              variant="contained"
                              onClick={() => setMatchTab(0)}
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <ChevronLeftIcon />{" "}
                              {
                                languageState.texts.Buttons
                                  .CharacteristicsToSearch
                              }
                            </Button>
                          </SitoContainer>
                        )}
                      </>,
                    ]}
                  />
                ) : (
                  <SitoContainer
                    flexDirection="column"
                    alignItems="center"
                    sx={{ marginTop: "20px" }}
                  >
                    <h3 className="dashboard-h3 dashboard-mb-3">
                      {
                        languageState.texts.Dashboard.Property.Match
                          .MissingProperty
                      }
                    </h3>
                    <Button
                      variant="contained"
                      onClick={() => setBigTab(0)}
                      sx={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <ChevronLeftIcon />{" "}
                      {languageState.texts.Buttons.CreateProperty}
                    </Button>
                  </SitoContainer>
                )}
              </>,
            ]}
          />
        )}
      </form>
    </SitoContainer>
  );
};

Insert.defaultProps = {
  toEdit: undefined,
  toViewMatches: undefined,
  toSearchProp: false,
};

Insert.propTypes = {
  toEdit: PropTypes.number,
  toViewMatches: PropTypes.number,
  toSearchProp: PropTypes.bool,
};

export default Insert;
