/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/function-component-definition */
import { Link } from "react-router-dom";

// @emotion/css
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";
// components
import LanguageChanger from "components/LanguageChanger/LanguageChanger";

// @mui icons
import EmailIcon from "@mui/icons-material/Email";
// import PlaceIcon from "@mui/icons-material/Place";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

// utils
import { scrollTo, userLogged, isAdmin } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// image
import logoFooter from "img//reals/logos/logo-footer-small.png";

const Footer = () => {
  const { languageState } = useLanguage();
  const { routeState } = useRoute();

  const icons = [<TwitterIcon />, <FacebookIcon />, <InstagramIcon />];

  const goto = () => {
    if (routeState.route === 0) scrollTo();
  };

  const flexIcons = css({
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  });

  return (
    <footer className="footer-style3 bg-white border-top">
      <SitoContainer ignoreDefault className="container">
        <SitoContainer ignoreDefault className="footer-5">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer
              ignoreDefault
              className="col-lg-3 col-sm-7 col-xs-6 sm-margin-30px-bottom"
            >
              <span className="footer-logo margin-25px-bottom display-inline-block">
                <Link onClick={goto} to="/">
                  <SitoImage
                    src={logoFooter}
                    alt="logo"
                    sx={{ width: "auto", height: "auto" }}
                  />
                </Link>
              </span>
              <ul className="no-margin-bottom">
                {/* <li>
                  <a
                    href="https://www.google.com/maps/place/Avenida+del+Libertador+6810/@-34.5493345,-58.4566867,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb42f77c3f561:0xbbc79ee63b7a27a1!8m2!3d-34.5493345!4d-58.454498"
                    className={`font-size14 ${flexIcons}`}
                  >
                    <PlaceIcon
                      sx={{ fontSize: "medium" }}
                      className="margin-10px-right text-theme-color"
                    />
                    {languageState.texts.Footer.Contact.Address}
                  </a>
  </li> */}
                <li>
                  <a
                    href={`mailto:${languageState.texts.Footer.Contact.Email}`}
                    className={`font-size14 ${flexIcons}`}
                  >
                    <EmailIcon
                      sx={{ fontSize: "medium" }}
                      className="margin-10px-right text-theme-color"
                    />
                    {languageState.texts.Footer.Contact.Email}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={languageState.texts.Contact.Details.PhoneLink}
                    className={`font-size14 ${flexIcons}`}
                  >
                    <WhatsAppIcon
                      sx={{ fontSize: "medium" }}
                      className="margin-10px-right text-theme-color"
                    />
                    {languageState.texts.Footer.Contact.Phone}
                  </a>
                </li>
              </ul>
              <LanguageChanger />
            </SitoContainer>

            <SitoContainer
              ignoreDefault
              className="col-lg-3 col-sm-7 col-xs-6 mobile-margin-30px-bottom"
            >
              <SitoContainer
                ignoreDefault
                className="padding-40px-left sm-no-padding-left"
              >
                <h4>{languageState.texts.Footer.QuickLinks}</h4>
                <ul className="no-margin-bottom no-padding">
                  {languageState.texts.Links.filter((item) => {
                    if (item.logged === 0) return item;
                    if (item.logged > 0 && userLogged())
                      if ((item.admin && isAdmin()) || !item.admin) return item;
                    if (item.logged < 0 && !userLogged()) return item;
                    return null;
                  }).map((item) => (
                    <li key={item.label}>
                      <Link to={item.to} className="font-size14">
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-3 col-sm-5 col-xs-6">
              <h4>Suscribite a nuestro newsletters</h4>
              <SitoContainer
                ignoreDefault
                className="email-box margin-20px-bottom"
              >
                <SitoContainer ignoreDefault className="input">
                  <form method="post" action="#!">
                    <input type="email" placeholder="Email" />
                    <button type="submit" className="butn">
                      {languageState.texts.Buttons.Subscribe}
                    </button>
                  </form>
                </SitoContainer>
              </SitoContainer>

              <SitoContainer ignoreDefault className="footer-icon">
                <p>{languageState.texts.Footer.SocialMedia.Title}</p>
                <ul className="no-margin-bottom">
                  {languageState.texts.Footer.SocialMedia.Links.map((item) => (
                    <li key={item.name}>
                      <a target="_blank" rel="noreferrer" href={item.link}>
                        {icons[item.icon]}
                      </a>
                    </li>
                  ))}
                </ul>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
      <SitoContainer ignoreDefault className="footer-style3-bottom">
        <SitoContainer ignoreDefault className="container">
          <p className="font-size14">&copy; 2022 Crabhaus</p>
        </SitoContainer>
      </SitoContainer>
    </footer>
  );
};

export default Footer;
