// @ts-check

import axios from "axios";
import config from "config";
// @ts-ignore
import { getAuth } from "auth/auth";

// cookies
// @ts-ignore
import { getCookie } from "utils/functions";

/**
 * Takes a characteristic group object, sends it to the API, and returns the response
 * @param {object} characteristicGroup - The characteristicGroup object that contains the characteristic group's information.
 * @returns The data from the response.
 */
export const createCharacteristicGroup = async (characteristicGroup) => {
  const response = await axios.post(
    // @ts-ignore
    `${config.apiUrl}/grupo-caracteristica`,
    { ...characteristicGroup },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the characteristic group's ID and the ID of the characteristic group to delete
 * @param {string} characteristicGroupId - The characteristicGroup id of the characteristic group you want to delete.
 * @returns The data is being returned.
 */
export const deleteCharacteristicGroup = async (characteristicGroupId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/grupo-caracteristica/${characteristicGroupId}`,
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};

/**
 * Sends a POST request to the API with the characteristic group's ID and characteristic group data to modify it
 * @param {string} characteristicGroupId - characteristicGroup id of the characteristic group you want to delete.
 * @param {object} characteristicGroupData - CharacteristicGroup data: name
 * @returns The data is being returned.
 */
export const modifyCharacteristicGroup = async (characteristicGroupId, characteristicGroupData) => {
  const response = await axios.put(
    // @ts-ignore
    `${config.apiUrl}/grupo-caracteristica/${characteristicGroupId}`,
    { ...characteristicGroupData },
    {
      // @ts-ignore
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    }
  );
  const data = await response.data;
  return data;
};
