/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// services
import { createFertilizer, modifyFertilizer } from "services/fertilizers/post";
import { fertilizerList } from "services/fertilizers/get";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { apiTokenCertified } from "utils/functions";

const Insert = (props) => {
  const { toEdit } = props;
  const [fertilizerToModify, setFertilizerToModify] = useState(0);

  const navigate = useNavigate();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { reset, register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const localCreateFertilizer = async (d) => {
    setLoading(true);
    try {
      const { name, value, propertyCount } = d;
      let response;
      const data = {
        nombre: name,
        valor: value,
        cantidad_propiedades: propertyCount,
      };
      if (fertilizerToModify) response = await createFertilizer(data);
      else response = await modifyFertilizer(fertilizerToModify, data);
      if (response.status === 200) {
        showNotification("success", languageState.texts.Messages.FertilizerCreatedSuccessful);
        reset({ name: "", value: "", cantidad_propiedades: "" });
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      if (err.message.indexOf("422") > -1) {
        const error = err.response.data.data;
        let message = languageState.texts.Errors.SomeWrong;
        const newInvalids = invalids;
        error.forEach((item) => {
          const enName = languageState.texts.Dashboard.TableAttributesEN[item.field];
          newInvalids[enName] = true;
          message = languageState.texts.Errors.NameTaken;
        });
        setInvalids(newInvalids);
        showNotification("error", message);
      }
    }
    setLoading(false);
  };

  const validate = () => setOk(true);

  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return showNotification("error", languageState.texts.Errors.NameRequired);
      }
    }
  };

  const fetch = async (toModify) => {
    setLoading(true);
    try {
      const response = await fertilizerList(0, 1, `filtros[id]=${toModify}`);
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          const [theFertilizer] = data;
          reset({
            name: theFertilizer.nombre,
            value: theFertilizer.valor,
            propertyCount: theFertilizer.cantidad_propiedades,
          });
        }
      } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    } catch (err) {
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const retry = (toModify = 0) => fetch(toModify);

  useEffect(() => {
    if (toEdit) {
      setFertilizerToModify(toEdit);
      retry(toEdit);
    }
  }, [toEdit]);

  return (
    <SitoContainer sx={{ position: "relative", minHeight: "300px" }}>
      <Loader visible={loading} minimal />
      {!loading && (
        <form
          id="register-form"
          method="post"
          action="#!"
          className={css({ width: "100%", padding: "20px" })}
          onSubmit={handleSubmit(localCreateFertilizer)}
        >
          <SitoContainer alignItems="center" justifyContent="space-between">
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.Fertilizer.Insert.Title}
            </h3>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            {languageState.texts.SignIn.inputs
              .filter((item) => {
                if (item.models && item.models.indexOf("fertilizer") > -1) return item;
                return null;
              })
              .map((item) => (
                <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                  <SitoContainer ignoreDefault className="form-group">
                    <label htmlFor={item.id}>{`${item.label} ${item.required ? "*" : ""}`}</label>
                    <input
                      placeholder={item.fertilizerPlaceholder}
                      type={item.type}
                      name={item.id}
                      id={item.id}
                      maxLength={item.maxLength}
                      required={item.required}
                      onInput={validate}
                      onInvalid={invalidate}
                      {...register(item.id)}
                      className={invalids[item.id] ? "error" : ""}
                    />
                  </SitoContainer>
                </SitoContainer>
              ))}
            {languageState.texts.Dashboard.Fertilizer.Inputs.map((item) => (
              <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                <SitoContainer ignoreDefault className="form-group">
                  <label htmlFor={item.id}>{`${item.label} ${item.required ? "*" : ""}`}</label>
                  <input
                    placeholder={item.placeholder}
                    type={item.type}
                    name={item.id}
                    id={item.id}
                    maxLength={item.maxLength}
                    required={item.required}
                    onInput={validate}
                    onInvalid={invalidate}
                    {...register(item.id)}
                    className={invalids[item.id] ? "error" : ""}
                  />
                </SitoContainer>
              </SitoContainer>
            ))}
          </SitoContainer>
          <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
            <SitoContainer ignoreDefault className="col-md-6">
              <button type="submit" className="butn">
                {languageState.texts.Buttons.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
    </SitoContainer>
  );
};

Insert.defaultProps = {
  toEdit: undefined,
};

Insert.propTypes = {
  toEdit: PropTypes.number,
};

export default Insert;
