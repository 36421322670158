/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useRef, useReducer, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// @emotion/css
import { css } from "@emotion/css";

// framer-motion
import { useInView } from "framer-motion";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// @mui components
import {
  Box,
  Button,
  IconButton,
  TextField,
  Autocomplete,
} from "@mui/material";

// @mui icons
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// services
import { getMe } from "services/users/get";
import { tokkoList } from "services/properties/get";
import { propertyTypeList } from "services/properties/types/get";

// own components
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";

import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";
import { importFromTokko } from "services/properties/post";
import { userChangeTokkoAPI } from "services/users/post";

// utils
import { apiTokenCertified } from "utils/functions";
import { parseColumns } from "../../../utils";

// images
import "./style.css";

const Tokko = (props) => {
  const navigate = useNavigate();

  const { languageState } = useLanguage();
  const { onSuccess } = props;

  const { control, watch } = useForm();

  const [filtering, setFiltering] = useState(false);

  const [code, setCode] = useState();
  const [priceMin, setPriceMin] = useState();
  const [priceMax, setPriceMax] = useState();
  const [roomAmountMin, setRoomAmountMin] = useState();
  const [roomAmountMax, setRoomAmountMax] = useState();
  const [suiteAmountMin, setSuiteAmountMin] = useState();
  const [suiteAmountMax, setSuiteAmountMax] = useState();

  const values = watch([
    "code",
    "priceAmountMin",
    "priceAmountMax",
    "roomAmountMin",
    "roomAmountMax",
    "suiteAmountMin",
    "suiteAmountMax",
  ]);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyTypeIds, setPropertyTypeIds] = useState();

  useEffect(() => {
    setCode(values[0]);
    setPriceMin(values[1]);
    setPriceMax(values[2]);
    setRoomAmountMin(values[3]);
    setRoomAmountMax(values[4]);
    setSuiteAmountMin(values[5]);
    setSuiteAmountMax(values[6]);
  }, [values]);

  const shownFiltersReducer = (filtersState, action) => {
    const { type } = action;
    switch (type) {
      case "added": {
        const newFilters = { ...filtersState };
        const { filterAdded } = action;
        newFilters[String(filterAdded.id)] = { ...filterAdded };
        return newFilters;
      }
      case "removed": {
        const newFilters = { ...filtersState };
        const { filterRemoved } = action;
        delete newFilters[String(filterRemoved)];
        return newFilters;
      }
      default:
        return { error: "error" };
    }
  };

  const [shownFilters] = useReducer(shownFiltersReducer, {
    ...languageState.texts.FiltersTokko,
  });

  const noFilters = () => !Object.values(shownFilters).length;

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const { setNotificationState } = useNotification();

  const localListReducer = (localListState, action) => {
    const { type } = action;
    switch (type) {
      case "add": {
        try {
          const newLocalList = [...localListState];
          const { toAdd } = action;

          toAdd.forEach((item) => {
            newLocalList.push(item);
          });
          console.log(toAdd.length, newLocalList.length);
          return newLocalList;
        } catch (err) {
          console.error(err);
          const { toAdd } = action;
          return toAdd;
        }
      }
      case "set": {
        const { toAdd } = action;
        return toAdd;
      }
      case "error": {
        return -1;
      }
      default:
        return [];
    }
  };
  const [localList, setLocalList] = useReducer(localListReducer, []);
  const [columns, setColumns] = useState([]);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [loading, setLoading] = useState(true);

  const [tokkoAPI, setTokkoAPI] = useState("");

  const [loadingPropertyTypes, setLoadingPropertyTypes] = useState(false);

  const fetchPropertyTypes = async () => {
    try {
      const propertyTypesPromise = await propertyTypeList(0, -1);
      if (propertyTypesPromise.status === 200) {
        const { data } = await propertyTypesPromise.data;
        const parsedTypes = [];
        data.forEach((item) =>
          parsedTypes.push({ id: item.id, name: item.nombre })
        );
        setPropertyTypes(parsedTypes);
        if (parsedTypes.length && !propertyTypeIds)
          setPropertyTypes(
            parsedTypes.map((item) => ({ id: item.id, name: item.name }))
          );
      } else if (
        propertyTypesPromise.error &&
        !apiTokenCertified(propertyTypesPromise.error)
      )
        navigate("/log-out");
    } catch (err) {
      console.error(err);
      const { message } = err;
      showNotification("error", message || String(err));
    }

    setLoadingPropertyTypes(false);
  };

  const fetch = useCallback(
    async (from = 0, limit = 10, filters = "") => {
      console.log("fetching");
      try {
        if (from === 0) setLoading(true);
        const APIresponse = await getMe();
        let localTokkoAPI = "";
        if (APIresponse.status >= 200 && APIresponse.status <= 210) {
          const meData = await APIresponse.data;
          localTokkoAPI = meData.tokko_api_key;
          if (localTokkoAPI === null) localTokkoAPI = "";
          setTokkoAPI(meData.tokko_api_key);
        } else if (APIresponse.error && !apiTokenCertified(APIresponse.error))
          navigate("/log-out");
        fetchPropertyTypes();
        if (localTokkoAPI.length) {
          const response = await tokkoList(from, 10, filters);
          console.log("response", response);
          if (response.status === 200) {
            const { data, total } = await response.data;
            if (total > 10) setHasMore(from < total);
            else setHasMore(false);
            if (data.length) {
              let parsedColumns = [];
              const parsedRows = [];
              data.forEach((item) => {
                const { id } = item;
                const parsedItem = {
                  id,
                };
                parsedItem.titulo = item.publication_title;
                parsedItem.direccion = item.address;

                //* Doing price
                let currency =
                  languageState.texts.Dashboard.TableAttributes.pesos;
                let { price } = item.operations[0].prices[0];
                if (
                  item.operations &&
                  item.operations[0] &&
                  item.operations[0].prices &&
                  item.operations[0].prices[0]
                ) {
                  if (item.operations[0].prices[0].currency === "USD")
                    currency =
                      languageState.texts.Dashboard.TableAttributes.usd;
                  price = item.operations[0].prices[0].price;
                }
                parsedItem.valor = {
                  primary: languageState.texts.Dashboard.TableAttributes.venta,
                  secondary: `${currency} ${price}`,
                  real: price,
                };
                console.log(item.suite_amount);
                // bedrooms
                if (!item.suite_amount)
                  parsedItem.dormitorios = {
                    primary: `0 ${languageState.texts.Dashboard.TableAttributes.dormitorios}`,
                    real: 0,
                  };
                else
                  parsedItem.dormitorios = {
                    primary: `${item.suite_amount} ${languageState.texts.Dashboard.TableAttributes.dormitorios}`,
                    real: item.suite_amount,
                  };
                // bathrooms
                if (!item.bathroom_amount)
                  parsedItem.dormitorios.secondary = `0 ${languageState.texts.Dashboard.TableAttributes.banos}`;
                else
                  parsedItem.dormitorios.secondary = `${item.bathroom_amount} ${languageState.texts.Dashboard.TableAttributes.banos}`;
                // surfaces covered
                if (!item.roofed_surface)
                  parsedItem.superficie_cubierta = {
                    primary: `0 m² ${languageState.texts.Dashboard.TableAttributes.cub}`,
                    real: 0,
                  };
                else
                  parsedItem.superficie_cubierta = {
                    primary: `${item.roofed_surface} m² ${languageState.texts.Dashboard.TableAttributes.cub}`,
                    real: Number(item.roofed_surface),
                  };
                // total surface
                if (!item.total_surface) {
                  parsedItem.superficie_cubierta.secondary = `0 m² ${languageState.texts.Dashboard.TableAttributes.tot}`;
                  /* parsedItem.superficie_total = `0 m² ${languageState.texts.Dashboard.TableAttributes.tot}`; */
                } else {
                  parsedItem.superficie_cubierta.secondary = `${item.total_surface} m² ${languageState.texts.Dashboard.TableAttributes.tot}`;
                  /* parsedItem.superficie_total = `${item.total_surface} m² ${languageState.texts.Dashboard.TableAttributes.tot}`; */
                }
                delete parsedItem.superficie_total;
                //* adding imagen
                if (!item.imagen)
                  parsedItem.imagen = (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        div: {
                          width: "100px",
                          height: "60px",
                        },
                      }}
                    >
                      <SitoImage
                        sx={{
                          width: "100px",
                          height: "60px",
                          objectFit: "cover",
                          borderRadius: "3px",
                        }}
                        src={
                          item.photos && item.photos[0]
                            ? item.photos[0].image
                            : ""
                        }
                        alt="property"
                      />
                    </Box>
                  );
                parsedRows.push(parsedItem);
              });
              if (!columns.length) {
                parsedColumns = parseColumns(languageState.texts);
                setColumns(parsedColumns);
              }
              console.log("localList", localList);
              if (!filters.length || localList.length)
                setLocalList({ type: "add", toAdd: parsedRows });
              else setLocalList({ type: "set", toAdd: parsedRows });
            }
          } else if (response.error && !apiTokenCertified(response.error))
            navigate("/log-out");
          else {
            setLocalList({ type: "error" });
            showNotification("error", languageState.texts.Errors.SomeWrong);
            setHasMore(false);
          }
        } else setHasMore(false);
      } catch (err) {
        console.error(err);
        setLocalList({ type: "error" });
        showNotification("error", languageState.texts.Errors.SomeWrong);
        setHasMore(false);
      }
      setLoading(false);
    },
    [localList, setLocalList]
  );

  const retry = () => fetch();

  const ref = useRef(null);
  const isInView = useInView(ref);

  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (!fetching) {
      fetch(page * 10);
      // setFetching(true);
    }
  }, [page, fetching]);

  const reFetch = useCallback(async () => {
    try {
      const response = await userChangeTokkoAPI(tokkoAPI);
      if (response.status === 200) {
        fetch(page * 10);
      } else if (response.error && !apiTokenCertified(response.error))
        navigate("/log-out");
    } catch (err) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
    }
  }, [page, tokkoAPI]);

  const sendAll = async () => {
    setLoading(true);
    try {
      const response = await importFromTokko();
      if (response.status === 200)
        showNotification(
          "success",
          languageState.texts.Messages.ImportingSuccessful
        );
      else {
        const { error } = response;
        console.log(error);
        let message;
        if (error.indexOf("422") > -1)
          message = languageState.texts.Errors.SomeWrong;
        if (error.indexOf("423") > -1)
          message = languageState.texts.Errors.TokkoUpgradePlan;
        else if (error.indexOf("Error: Network Error") > -1)
          message = languageState.texts.Errors.NotConnected;
        else message = languageState.texts.Errors.SomeWrong;
        showNotification("error", message);
      }
      // onSuccess(selected, "tokko");
    } catch (err) {
      console.log(err);
      let message = languageState.texts.Errors.SomeWrong;
      if (err.code === "ERR_NETWORK")
        message = languageState.texts.Errors.NotConnected;
      if (err.response.status === 422)
        message = languageState.texts.Errors.SomeWrong;
      if (err.response.status === 403)
        message = languageState.texts.Errors.TokkoUpgradePlan;
      showNotification("error", message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isInView) setPage(page + 1);
  }, [isInView]);

  const selectToDo = async (selected) => {
    setLoading(true);
    try {
      const response = await importFromTokko(selected);
      if (response.status === 200)
        showNotification(
          "success",
          languageState.texts.Messages.ImportingSuccessful
        );
      else {
        console.log(response);
        const { error } = response;
        console.log(error);
        let message;
        if (error.indexOf("422") > -1)
          message = languageState.texts.Errors.SomeWrong;
        if (error.indexOf("423") > -1)
          message = languageState.texts.Errors.TokkoUpgradePlan;
        else if (error.indexOf("Error: Network Error") > -1)
          message = languageState.texts.Errors.NotConnected;
        else message = languageState.texts.Errors.SomeWrong;
        showNotification("error", message);
      }
      // onSuccess(selected, "tokko");
    } catch (err) {
      console.log(err);
      let message = languageState.texts.Errors.SomeWrong;
      if (err.code === "ERR_NETWORK")
        message = languageState.texts.Errors.NotConnected;
      if (err.response.status === 422)
        message = languageState.texts.Errors.SomeWrong;
      if (err.response.status === 403)
        message = languageState.texts.Errors.TokkoUpgradePlan;
      showNotification("error", message);
    }
    setLoading(false);
  };

  const executeFilter = () => {
    //* code
    const codeReference = [];
    if (code && code !== "")
      codeReference.push(["reference_code", "contains", code]);
    //* suiteAmount
    const suiteAmountArray = []; // ["suite_amount", "operator", "number"]
    if (suiteAmountMin !== "" && suiteAmountMin !== undefined)
      suiteAmountArray.push(["suite_amount", ">", suiteAmountMin]);
    if (suiteAmountMax !== "" && suiteAmountMax !== undefined)
      suiteAmountArray.push(["suite_amount", "<", suiteAmountMax]);
    //* roomAmount
    const roomAmountArray = []; // ["room_amount", "operator", "number"]
    if (roomAmountMin !== "" && roomAmountMin !== undefined)
      roomAmountArray.push(["room_amount", ">", roomAmountMin]);
    if (roomAmountMax !== "" && roomAmountMax !== undefined)
      roomAmountArray.push(["room_amount", "<", roomAmountMax]);
    let filters = "";
    //* number filters
    if (priceMin !== undefined) filters += `&price_from=${priceMin}`;
    if (priceMax !== undefined) filters += `&price_to=${priceMax}`;
    let parsed = "[";
    codeReference.forEach((item, i) => {
      const [attribute, operator, value] = item;
      parsed += "[";
      parsed += `"${attribute}","${operator}","${value}"`;
      if (i !== suiteAmountArray.length - 1) parsed += "]";
      else parsed += "]";
    });
    suiteAmountArray.forEach((item, i) => {
      const [attribute, operator, value] = item;
      parsed += "[";
      parsed += `"${attribute}","${operator}","${value}"`;
      parsed += "]";
    });
    roomAmountArray.forEach((item, i) => {
      const [attribute, operator, value] = item;
      parsed += "[";
      parsed += `"${attribute}","${operator}","${value}"`;
      if (i !== roomAmountArray.length - 1) parsed += "]";
      else parsed += "]";
    });
    parsed += "]";
    filters += `&filtros=${parsed}`;
    console.log(propertyTypeIds);
    //* property type
    if (propertyTypeIds)
      filters += `&property_types=[${propertyTypeIds.map((item) => item.id)}]`;
    setLocalList({ type: "clean" });
    console.log(filters);
    fetch(0, 10, filters);
  };

  return (
    <SitoContainer
      justifyContent="center"
      flexDirection="column"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <SitoContainer flexDirection="column" sx={{ marginBottom: "20px" }}>
        <SitoContainer alignItems="center">
          <SitoContainer
            alignItems="center"
            sx={{
              width: "100%",
              marginTop: "5px",
              flexWrap: "wrap",
              gap: "10px 10px",
            }}
          >
            <label
              htmlFor="tokko"
              className={css({ margin: 0, marginRight: "20px" })}
            >
              {languageState.texts.Dashboard.Property.Input.label}
            </label>
            <input
              className={`form-control ${css({
                maxWidth: "370px",
                marginRight: "20px",
                height: "calc(1.5em + 0.75rem + 2px)",
              })}`}
              placeholder={
                languageState.texts.Dashboard.Property.Input.placeholder
              }
              type="text"
              id="tokkoAPI"
              value={tokkoAPI}
              onChange={(event) => {
                setTokkoAPI(event.target.value);
              }}
            />
            <Button
              onClick={reFetch}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              {languageState.texts.Buttons.Save}
            </Button>
            <Button
              onClick={sendAll}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              {languageState.texts.Buttons.ImportAll}
            </Button>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
      {!loading && localList !== -1 ? (
        <ComplexTable
          columns={columns}
          rows={localList}
          onSelect={selectToDo}
          filtering={filtering}
          filterHeight="340px"
          filters={
            <SitoContainer
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{
                height: filtering ? "340px" : "0",
                transition: "all 500ms ease",
              }}
            >
              <SitoContainer alignItems="center" sx={{ marginTop: "15px" }}>
                <IconButton
                  variant="text"
                  color={filtering ? "primary" : "inherit"}
                  onClick={() => setFiltering(!filtering)}
                  sx={{ marginTop: "-13px" }}
                >
                  {filtering ? <FilterAltIcon /> : <FilterAltOffIcon />}
                </IconButton>
              </SitoContainer>
              <SitoContainer
                ignoreDefault
                className="form-group"
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  transition: "all 500ms ease",
                  height: filtering ? "300px" : "0",
                  overflow: "hidden",
                }}
              >
                {Object.values(shownFilters).map((item) => (
                  <SitoContainer
                    key={item.name}
                    alignItems="center"
                    sx={{
                      width: "715px",
                      marginTop: "5px",
                    }}
                  >
                    <label
                      htmlFor={item.name}
                      className={css({
                        margin: 0,
                        marginRight: "20px",
                        width: "140px",
                      })}
                    >
                      {languageState.texts.FiltersTokko[String(item.id)].label}
                    </label>
                    {item.type === "normal" ? (
                      <Controller
                        control={control}
                        name={`${item.name}`}
                        render={({ field }) => (
                          <input
                            className={`form-control ${css({
                              maxWidth: "198px",
                              height: "calc(1.5em + 0.75rem + 2px)",
                            })}`}
                            placeholder={languageState.texts.Placeholders.Code}
                            type="text"
                            id={`${item.name}`}
                            value={field.value}
                            onChange={(event) => {
                              field.onChange(event);
                            }}
                          />
                        )}
                      />
                    ) : null}
                    {item.type === "input" ? (
                      <>
                        <label
                          htmlFor={`${item.name}AmountMin`}
                          className={css({ margin: 0, marginRight: "20px" })}
                        >
                          {languageState.texts.Labels.Min}
                        </label>
                        <Controller
                          control={control}
                          name={`${item.name}AmountMin`}
                          render={({ field }) => (
                            <input
                              className={`form-control ${css({
                                maxWidth: "125px",
                                marginRight: "20px",
                                height: "calc(1.5em + 0.75rem + 2px)",
                              })}`}
                              placeholder={languageState.texts.Placeholders.Min}
                              type="number"
                              id={`${item.name}AmountMin`}
                              max={
                                !values.filterEnvironmentsMax
                                  ? 99999
                                  : values.filterEnvironmentsMax
                              }
                              min={0}
                              value={field.value}
                              onChange={(event) => {
                                field.onChange(event);
                              }}
                            />
                          )}
                        />
                        <label
                          htmlFor={`${item.name}AmountMax`}
                          className={css({ margin: 0, marginRight: "20px" })}
                        >
                          {languageState.texts.Labels.Max}
                        </label>
                        <Controller
                          control={control}
                          name={`${item.name}AmountMax`}
                          render={({ field }) => (
                            <input
                              className={`form-control ${css({
                                maxWidth: "125px",
                                height: "calc(1.5em + 0.75rem + 2px)",
                              })}`}
                              placeholder={languageState.texts.Placeholders.Max}
                              type="number"
                              id={`${item.id}AmountMax`}
                              min={0}
                              value={field.value}
                              onChange={(event) => {
                                field.onChange(event);
                              }}
                            />
                          )}
                        />
                      </>
                    ) : null}
                    {item.type === "property-types" && (
                      <SitoContainer
                        alignItems="center"
                        sx={{ width: "485px", marginTop: "5px" }}
                      >
                        <Autocomplete
                          multiple
                          defaultValue={[]}
                          filterSelectedOptions
                          options={propertyTypes}
                          value={propertyTypeIds || []}
                          ChipProps={{ color: "primary", size: "small" }}
                          getOptionLabel={(option) => option.name}
                          sx={{
                            width: "100%",
                            div: {
                              label: { marginTop: "-2px" },
                            },
                          }}
                          id="propertyType"
                          onChange={(event, newValue) =>
                            setPropertyTypeIds(newValue)
                          }
                          disabled={!propertyTypes.length}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                border: "1px solid #ced4da !important",
                                borderRadius: "3px",
                                div: {
                                  fieldset: {
                                    border: "none !important",
                                  },
                                },
                              }}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                          autoHighlight
                        />
                      </SitoContainer>
                    )}
                  </SitoContainer>
                ))}
              </SitoContainer>
              <Button
                variant="text"
                color={
                  Object.values(shownFilters).length ? "primary" : "secondary"
                }
                onClick={executeFilter}
                sx={{ marginTop: "-10px" }}
                disabled={Object.values(shownFilters).length === 0}
              >
                {languageState.texts.Buttons.ExecuteFilter}
              </Button>
            </SitoContainer>
          }
        />
      ) : null}

      {hasMore ? (
        <div ref={ref}>
          <Loader
            visible={hasMore}
            sx={{
              height: "64px !important",
              position: "inherit !important",
              marginTop: "20px !important",
            }}
          />
        </div>
      ) : null}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

Tokko.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default Tokko;
