/* eslint-disable camelcase */
export const parseColumns = (texts) => {
  const parsedColumns = [];
  parsedColumns.push({
    id: "id",
    width: 20,
    numeric: true,
    disablePadding: false,
    label: "Id",
  });
  parsedColumns.push({
    id: "nombre",
    width: 200,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.nombre,
  });
  parsedColumns.push({
    id: "rol",
    width: 60,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.rol,
  });
  parsedColumns.push({
    id: "valor",
    width: 50,
    numeric: true,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.valor,
  });
  parsedColumns.push({
    id: "cantidad_propiedades",
    width: 150,
    numeric: true,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.cantidad_propiedades,
  });
  return parsedColumns;
};

export const parseRows = (data) => {
  const parsedRows = [];
  data.forEach((item) => {
    const { id, cantidad_propiedades, rol, nombre, valor } = item;
    parsedRows.push({
      id: { key: "id", value: id, width: "60px", textAlign: "center" },
      nombre: {
        key: "nombre",
        value: nombre,
        width: "250px",
        textAlign: "left",
      },
      rol: {
        key: "rol",
        value: rol,
        width: "140px",
        textAlign: "left",
      },
      valor: {
        key: "valor",
        value: valor,
        width: "70px",
        textAlign: "center",
      },
      cantidad_propiedades: {
        key: "cantidad_propiedades",
        value: cantidad_propiedades,
        width: "150px",
        textAlign: "center",
      },
    });
  });
  return parsedRows;
};
