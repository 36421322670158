/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";

// @mui icons
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import { css } from "@emotion/css";

// @mui
import { Badge, IconButton } from "@mui/material";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";
import { useRoute } from "context/RouterProvider";
import { primary, aliceblue } from "context/ThemeProvider";

// images
import avatar from "img/avatars/avatar.jpg";

// contexts
import useOnclickOutside from "react-cool-onclickoutside";

// services
import { getMe } from "services/users/get";
// import { getNotification } from "services/get";

// functions
// import { getCookie } from "utils/functions";

// import config from "config";

const Navbar = (props) => {
  const { changeShow } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();
  const { routeState } = useRoute();
  const [currentLink, setCurrentLink] = useState(0);

  const [, setLoading] = useState(true);

  const [notifications /* , setNotifications */] = useState([]);
  const [messages /* , setMessages */] = useState([]);
  const [userData, setUserData] = useState({ nombre: "User name" });

  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessages, setShowMessages] = useState(false);

  const messageCountText = () => {
    if (messages.length === 0) return `${languageState.texts.Dashboard.Navbar.Messages.NoMessages}`;
    if (messages.length === 1)
      return `1 ${languageState.texts.Dashboard.Navbar.Messages.OneMessage}`;
    return `${messages.length} ${languageState.texts.Dashboard.Navbar.Messages.Messages}`;
  };

  const ref = useOnclickOutside(() => {
    setShowNotifications(false);
    setShowMessages(false);
  });

  useEffect(() => {
    if (routeState.route !== undefined) setCurrentLink(routeState.route);
  }, [routeState]);

  const init = async () => {
    try {
      const response = await getMe();
      const meData = await response.data;
      // console.log(meData);
      setUserData(meData);

      /* const notification = await getNotification();
      setNotifications([].length);
      setMessages([
        {
          avatar: avatar5,
          index: 0,
          name: "Vanessa Tucker",
          message: "Nam pretium turpis et arcu. Duis arcu tortor.",
          minutes: "15m ago",
        },
      ]);

      if (notification.error) {
        const { error } = notification;
        let message;
        if (error.indexOf("422") > -1) message = languageState.texts.Errors.Wrong;
        else if (error.indexOf("Error: Network Error") > -1)
          message = languageState.texts.Errors.NotConnected;
        else message = languageState.texts.Errors.SomeWrong;
        setNotificationState({
          type: "set",
          ntype: "error",
          message,
        });
      } */
    } catch (err) {
      // console.log(err);
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.SomeWrong,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {}, [currentLink]);

  // style
  const avatarStyle = {
    height: "auto",
    maxWidth: "100%",
    borderRadius: "50%",
    paddingRight: "10px",
  };

  return (
    <nav className="dashboard-navbar dashboard-navbar-expand dashboard-navbar-light dashboard-navbar-bg">
      <a className="dashboard-sidebar-toggle js-sidebar-toggle" onClick={changeShow}>
        <i className="dashboard-hamburger dashboard-align-self-center"></i>
      </a>

      <SitoContainer ignoreDefault className="dashboard-navbar-collapse dashboard-collapse">
        <ul
          className={`dashboard-navbar-nav dashboard-navbar-align ${css({ alignItems: "center" })}`}
        >
          <li className="dashboard-nav-item dashboard-dropdown">
            <a
              className={`dashboard-nav-icon dashboard-dropdown-toggle ${css({ padding: 0 })}`}
              href="#"
              id="alertsDropdown"
              data-bs-toggle="dropdown"
            >
              <SitoContainer ignoreDefault className="dashboard-position-relative">
                <IconButton onClick={() => setShowNotifications(true)}>
                  <Badge
                    badgeContent={notifications.length}
                    sx={{ span: { background: primary, color: aliceblue } }}
                  >
                    <NotificationsNoneIcon
                      className="dashboard-align-middle"
                      sx={{
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  </Badge>
                </IconButton>
              </SitoContainer>
            </a>
            <SitoContainer
              className="dashboard-dropdown-menu dashboard-dropdown-menu-lg dashboard-dropdown-menu-end dashboard-py-0"
              sx={{ display: showNotifications ? "initial !important" : "none", right: "5px" }}
              aria-labelledby="alertsDropdown"
              ref={ref}
            >
              <SitoContainer ignoreDefault className="dashboard-dropdown-menu-header">
                4 New Notifications
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-list-group">
                <a href="#" className="dashboard-list-group-item">
                  <SitoContainer
                    ignoreDefault
                    className="dashboard-row dashboard-g-0 dashboard-align-items-center"
                  >
                    <SitoContainer ignoreDefault className="dashboard-col-2">
                      <i className="dashboard-text-danger" data-feather="alert-circle"></i>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="dashboard-col-10">
                      <SitoContainer ignoreDefault className="dashboard-text-dark">
                        Update completed
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="dashboard-text-muted dashboard-small dashboard-mt-1"
                      >
                        Restart server 12 to complete the update.
                      </SitoContainer>
                      <div className="dashboard-text-muted dashboard-small dashboard-mt-1">
                        30m ago
                      </div>
                    </SitoContainer>
                  </SitoContainer>
                </a>
                <a href="#" className="dashboard-list-group-item">
                  <SitoContainer
                    ignoreDefault
                    className="dashboard-row g-0 dashboard-align-items-center"
                  >
                    <SitoContainer ignoreDefault className="dashboard-col-2">
                      <i className="dashboard-text-warning" data-feather="bell"></i>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="dashboard-col-10">
                      <SitoContainer ignoreDefault className="dashboard-text-dark">
                        Lorem ipsum
                      </SitoContainer>
                      <SitoContainer ignoreDefault className="dashboard-text-muted small mt-1">
                        Aliquam ex eros, imperdiet vulputate hendrerit et.
                      </SitoContainer>
                      <SitoContainer ignoreDefault className="dashboard-text-muted small mt-1">
                        2h ago
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                </a>
                <a href="#" className="dashboard-list-group-item">
                  <SitoContainer
                    ignoreDefault
                    className="dashboard-row dashboard-g-0 dashboard-align-items-center"
                  >
                    <SitoContainer ignoreDefault className="dashboard-col-2">
                      <i className="dashboard-text-primary" data-feather="home"></i>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="dashboard-col-10">
                      <SitoContainer ignoreDefault className="dashboard-text-dark">
                        Login from 192.186.1.8
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="dashboard-text-muted dashboard-small dashboard-mt-1"
                      >
                        5h ago
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                </a>
                <a href="#" className="dashboard-list-group-item">
                  <SitoContainer
                    ignoreDefault
                    className="dashboard-row g-0 dashboard-align-items-center"
                  >
                    <SitoContainer ignoreDefault className="dashboard-col-2">
                      <i className="dashboard-text-success" data-feather="user-plus"></i>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="dashboard-col-10">
                      <SitoContainer ignoreDefault className="dashboard-text-dark">
                        New connection
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="dashboard-text-muted dashboard-small dashboard-mt-1"
                      >
                        Christina accepted your request.
                      </SitoContainer>
                      <SitoContainer
                        ignoreDefault
                        className="dashboard-text-muted dashboard-small dashboard-mt-1"
                      >
                        14h ago
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                </a>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-dropdown-menu-footer">
                <a href="#" className="dashboard-text-muted">
                  Show all notifications
                </a>
              </SitoContainer>
            </SitoContainer>
          </li>
          <li className="dashboard-nav-item dashboard-dropdown">
            <a
              className={`dashboard-nav-icon dashboard-dropdown-toggle ${css({ paddingLeft: 0 })}`}
              href="#"
              id="messagesDropdown"
              data-bs-toggle="dropdown"
            >
              <SitoContainer ignoreDefault className="dashboard-position-relative">
                <IconButton onClick={() => setShowMessages(true)}>
                  <Badge
                    badgeContent={messages.length}
                    sx={{ span: { background: primary, color: aliceblue } }}
                  >
                    <ChatBubbleOutlineIcon
                      sx={{
                        width: "22px !important",
                        height: "22px !important",
                      }}
                      className="dashboard-align-middle"
                    />
                  </Badge>
                </IconButton>
              </SitoContainer>
            </a>
            <SitoContainer
              className="dashboard-dropdown-menu dashboard-dropdown-menu-lg dashboard-dropdown-menu-end dashboard-py-0"
              sx={{ display: showMessages ? "initial !important" : "none", right: "5px" }}
              aria-labelledby="messagesDropdown"
              ref={ref}
            >
              <SitoContainer ignoreDefault className="dashboard-dropdown-menu-header">
                <SitoContainer ignoreDefault className="dashboard-position-relative">
                  {messageCountText()}
                </SitoContainer>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-list-group">
                {messages.map((item) => (
                  <a key={item.index} href="#" className="dashboard-list-group-item">
                    <SitoContainer
                      ignoreDefault
                      className="dashboard-row dashboard-g-0 dashboard-align-items-center"
                    >
                      <SitoContainer ignoreDefault className="dashboard-col-2">
                        <SitoImage src={item.avatar} sx={avatarStyle} alt={item.name} />
                      </SitoContainer>
                      <SitoContainer ignoreDefault className="dashboard-col-10 dashboard-ps-2">
                        <SitoContainer ignoreDefault className="dashboard-text-dark">
                          {item.name}
                        </SitoContainer>
                        <SitoContainer
                          ignoreDefault
                          className="dashboard-text-muted dashboard-small vmt-1"
                        >
                          {item.message}
                        </SitoContainer>
                        <SitoContainer
                          ignoreDefault
                          className="dashboard-text-muted dashboard-small dashboard-mt-1"
                        >
                          {item.minutes}
                        </SitoContainer>
                      </SitoContainer>
                    </SitoContainer>
                  </a>
                ))}
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-dropdown-menu-footer">
                <a href="#" className="dashboard-text-muted">
                  {languageState.texts.Dashboard.Navbar.ShowAll}
                </a>
              </SitoContainer>
            </SitoContainer>
          </li>
          <li className="dashboard-nav-item dashboard-dropdown">
            <a
              className="dashboard-nav-icon dashboard-dropdown-toggle dashboard-d-inline-block dashboard-d-sm-none"
              href="#"
              data-bs-toggle="dropdown"
            >
              <i className="dashboard-align-middle" data-feather="settings"></i>
            </a>

            <a
              className={`dashboard-nav-link dashboard-dropdown-toggle dashboard-d-none dashboard-d-sm-inline-block ${css(
                { display: "flex", alignItems: "center" }
              )}`}
              href="#"
              data-bs-toggle="dropdown"
            >
              <SitoImage
                src={avatar}
                sx={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "0.2rem",
                  marginRight: "0.25rem",
                }}
                alt="Charles Hall"
              />
              <span className="dashboard-text-dark">{userData ? userData.nombre : ""}</span>
            </a>
            <SitoContainer
              ignoreDefault
              className="dashboard-dropdown-menu dashboard-dropdown-menu-end"
            >
              {languageState.texts.Dashboard.Navbar.Links.map((item) => (
                <Link key={item.label} className="dashboard-dropdown-item" to={item.link}>
                  <i className="dashboard-align-middle me-1" data-feather={item.icon}></i>
                  {item.label}
                </Link>
              ))}
            </SitoContainer>
          </li>
        </ul>
      </SitoContainer>
    </nav>
  );
};

Navbar.propTypes = {
  changeShow: PropTypes.func.isRequired,
};

export default Navbar;
