/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getAuth } from "auth/auth";
import config from "config";

// cookies
import { getCookie } from "utils/functions";

/**
 *
 * @param {number} id
 * @param {number} filter
 * @returns
 */
export const propertyService = async (id = 0, filter = 0) => {
  let response;
  console.log(getCookie(config.jwtKey));
  console.log(`${config.apiUrl}/inmueble/${id}?${filter ? `${filter}` : ""}`);
  try {
    response = await axios.get(
      `${config.apiUrl}/inmueble/${id}?${filter ? `${filter}` : ""}`,
      {
        headers: { ...getAuth },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    console.log(err);
    return { error: String(err) };
  }
};

/**
 *
 * @param {number} from
 * @param {number} limit
 * @param {number} filter
 * @returns
 */
export const propertyList = async (from = 0, limit = 10, filter = 0) => {
  let response;
  console.log("aqui!", getCookie(config.jwtKey));
  console.log(
    decodeURIComponent(
      `${config.apiUrl}/inmueble?offset=${from}&limit=${limit}${
        filter ? `&${filter}` : ""
      }`
    )
  );
  try {
    response = await axios.get(
      `${config.apiUrl}/inmueble?offset=${from}&limit=${limit}${
        filter ? `&${filter}` : ""
      }`,
      {
        headers: {
          ...getAuth,
          Authorization: `Bearer ${getCookie(config.jwtKey)}`,
        },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    console.log(err);
    return { error: String(err) };
  }
};

/**
 * Sends a POST request to the API with the property's ID and the ID of the property to activate
 * @param {string} propertyId - The property id of the property you want to delete.
 * @returns The data is being returned.
 */
export const activateProperty = async (propertyId) => {
  const response = await axios.delete(
    // @ts-ignore
    `${config.apiUrl}/inmueble/${propertyId}/activar`,
    {
      // @ts-ignore
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.jwtKey)}`,
      },
    }
  );
  const data = await response.data;
  return data;
};

/**
 *
 * @param {number} from
 * @param {number} limit
 * @param {string} filter
 * @returns
 */
export const tokkoList = async (from = 0, limit = 10, filter = "") => {
  let response;
  try {
    response = await axios.get(
      `${config.apiUrl}/inmueble/property-search?offset=${from}&limit=${limit}${
        filter ? `&${filter}` : ""
      }`,
      {
        headers: {
          ...getAuth,
          Authorization: `Bearer ${getCookie(config.jwtKey)}`,
        },
      }
    );
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};
