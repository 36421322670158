/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */

// import io from "socket.io";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// sito components
import SitoContainer from "sito-container";

// services
import { getMe } from "services/users/get";
import { conversationList } from "services/chat/get";

// contexts
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// components
import Loader from "components/Loader/Loader";

// local components
import Sidebar from "./Sidebar/Sidebar";
import Content from "./Content/Content";

const Chat = () => {
  const location = useLocation();
  const { setRouteState } = useRoute();

  const [propertyChat, setPropertyChat] = useState(-1);
  const [selectedChat, setSelectedChat] = useState(-1);

  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const init = async (id = "") => {
    try {
      /* const response = await conversationList(0, 1);
      if (response.status === 200) {
        const { data } = await response.data;
        if (data.length) {
          if (!data.find((user) => String(user.user.id) === id))
          
          const { id } = data[0].user;
          setSelectedChat(id);
        }
      } */
      setSelectedChat(id);
    } catch (err) {
      console.error("error", err);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
  };

  const selectChat = (index) => {
    console.log("clicked", index);
    setSelectedChat(index);
  };

  const [user, setUser] = useState({
    urlPhotoProfile: "",
    nombre: "",
    id: 0,
  });

  const fetchProperData = async () => {
    try {
      const response = await getMe();
      if (response.status === 200) {
        const { data } = response;
        if (data) {
          const { nombre, urlPhotoProfile, id, rol } = data;
          console.log("emit", data);
          setUser({
            id,
            nombre,
            urlPhotoProfile: urlPhotoProfile || "",
            rol,
          });
        } else showNotification("error", languageState.texts.Errors.SomeWrong);
      }
    } catch (err) {
      console.error(err);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
  };

  useEffect(() => {
    setRouteState({
      type: "set",
      to: 9,
    });
    if (!user.id) {
      fetchProperData();

      const queryParams = location.search.substring(1);
      const [idParam, chatParam] = queryParams.split("&");
      let theId;
      if (idParam) {
        const [param, value] = idParam.split("=");
        if (param === "id" && value) {
          setSelectedChat(Number(value));
          theId = value;
        }
      }
      if (chatParam) {
        const [param, value] = chatParam.split("=");
        if (param === "property" && value) setPropertyChat(Number(value));
      }
      init(theId);
    }
  }, [user, location]);

  return (
    <SitoContainer>
      <Sidebar
        user={user}
        selectChat={selectChat}
        selectedChat={selectedChat}
      />
      {user.nombre ? (
        <Content
          ownUser={user}
          selectedChat={selectedChat}
          propertyChat={propertyChat}
        />
      ) : (
        <Loader visible />
      )}
    </SitoContainer>
  );
};

export default Chat;
