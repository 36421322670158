/* eslint-disable react/function-component-definition */
import { useEffect } from "react";
import CountUp from "react-countup";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

// layouts
import Section from "layouts/Section/Section";

const Counter = () => {
  const { languageState } = useLanguage();

  useEffect(() => {}, []);
  return (
    <Section>
      <SitoContainer ignoreDefault className="container">
        <SitoContainer ignoreDefault className="row align-items-center">
          <SitoContainer ignoreDefault className="col-md-6 xs-margin-40px-bottom">
            <h4 className="font-size38 sm-font-size30 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
              {languageState.texts.Home.Counter.Title}
            </h4>
          </SitoContainer>

          <SitoContainer ignoreDefault className="col-md-6">
            <SitoContainer ignoreDefault className="row">
              <SitoContainer ignoreDefault className="col-6 text-center">
                <h4 className="font-size100 sm-font-size80 xs-font-size50 margin-5px-bottom text-theme-color">
                  <CountUp enableScrollSpy className="countup" end={587} duration={1} />
                  <sup>+</sup>
                </h4>
                <p className="no-margin-bottom text-extra-dark-gray font-weight-500">
                  {languageState.texts.Home.Counter.Sales}
                </p>
              </SitoContainer>

              <SitoContainer ignoreDefault className="col-6 text-center">
                <h4 className="font-size100 sm-font-size80 xs-font-size50 margin-5px-bottom text-theme-color countup">
                  <CountUp enableScrollSpy className="countup" end={3432} duration={1} />
                </h4>
                <p className="no-margin-bottom text-extra-dark-gray font-weight-500">
                  {languageState.texts.Home.Counter.Users}
                </p>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

export default Counter;
