/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-constructed-context-values */
import * as React from "react";

// texts
import texts from "lang/texts.json";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

const ChatContext = React.createContext();

const chatReducer = (chatState, action) => {
  switch (action.type) {
    case "set":
      return {
        refetch: action.value,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const ChatProvider = ({ children }) => {
  const [chatState, setChatState] = React.useReducer(chatReducer, {
    lang: "es",
    texts: texts.es,
  });

  const value = { chatState, setChatState };
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// hooks
const useChat = () => {
  const context = React.useContext(ChatContext);
  if (context === undefined)
    throw new Error("chatContext must be used within a Provider");
  return context;
};

export { ChatProvider, useChat };
