/* eslint-disable react/function-component-definition */

// @mui icons
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

const Empty = () => {
  const { languageState } = useLanguage();
  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}
      flexDirection="column"
    >
      <ReceiptLongIcon color="secondary" sx={{ fontSize: "4rem" }} />
      <h4 className="dashboard-h3 dashboard-mb-3">
        {languageState.texts.Dashboard.Message.NoMatches}
      </h4>
    </SitoContainer>
  );
};

export default Empty;
