/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

// framer-motion
import { motion } from "framer-motion";

// contexts
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";

import { Box } from "@mui/material";

// sito components
import SitoContainer from "sito-container";

// own components
import Carousel from "components/Carousel/Carousel";

// images
import bg1 from "img/reals/Carousel/Casas CRAB.png";
/* import bg2 from "img/reals/Carousel/Locales CRAB.jpg";
import bg3 from "img/reals/Carousel/Lotes-CRAB.jpg";
import bg4 from "img/reals/Carousel/Oficinas 2 CRAB.jpg"; */

// utils
import { scrollTo, userLogged } from "utils/functions";

// sections
import AboutSection from "./Sections/About/About";
import ServicesSection from "./Sections/Services/Services";
/* 
import LatestSection from "./Sections/Latest/Latest";
import TestimonialsSection from "./Sections/Testimonials/Testimonials";
import ExtraSection from "./Sections/Extra/Extra"; 
*/
import FeaturesSection from "./Sections/Features/Features";
import UserCategoriesSection from "./Sections/UserCategories/UserCategories";
import CounterSection from "./Sections/Counter/Counter";

const Home = () => {
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();

  useEffect(() => {
    setRouteState({ type: "set", to: 0 });
    scrollTo();
  }, []);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const ulItem = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const [banner, setBanner] = useState([]);

  const images = [bg1 /* , bg2, bg3, bg4 */];

  useEffect(() => {
    const newBanner = [];
    languageState.texts.Home.Phrases.forEach((item, i) => {
      if (i === 0)
        newBanner.push(
          <SitoContainer
            ignoreDefault
            className="item bg-img cover-background"
            extraProps={{ "data-overlay-dark": "6" }}
            background={`url("${images[i]}")`}
            sx={{ height: "100%" }}
          >
            <SitoContainer
              ignoreDefault
              className="container h-100"
              sx={{ height: "100%" }}
            >
              <SitoContainer
                ignoreDefault
                className="display-table h-100"
                sx={{ height: "100%" }}
              >
                <SitoContainer
                  ignoreDefault
                  className="display-table-cell vertical-align-middle caption"
                  sx={{ height: "100%" }}
                >
                  <Box
                    className="overflow-hidden mobile-width-auto"
                    sx={{
                      height: "100%",
                      padding: { md: "0 8rem", xs: "0 40px" },
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <motion.ul
                      variants={container}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                    >
                      <motion.div variants={ulItem} viewport={{ once: true }}>
                        <h2 className="margin-60px-bottom text-white">
                          {item}
                        </h2>
                      </motion.div>
                      <motion.div variants={ulItem} viewport={{ once: true }}>
                        <a href="#/login" className="butn theme">
                          <span className="alt-font">
                            {userLogged()
                              ? languageState.texts.Buttons.GoToProfile
                              : languageState.texts.Buttons.SignIn}
                          </span>
                        </a>
                      </motion.div>
                    </motion.ul>
                  </Box>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        );
    });
    setBanner(newBanner);
  }, []);

  return (
    <>
      <Carousel content={banner} id="banners" />
      <AboutSection />
      <ServicesSection />
      <CounterSection />
      <UserCategoriesSection />
      <FeaturesSection />
      {/* <LatestSection />
      <TestimonialsSection />
      <ExtraSection /> */}
    </>
  );
};

export default Home;
