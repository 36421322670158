/* eslint-disable camelcase */
export const parseColumns = (texts) => {
  const parsedColumns = [];
  parsedColumns.push({
    id: "id",
    width: 20,
    numeric: true,
    disablePadding: true,
    label: "Id",
  });
  parsedColumns.push({
    id: "nombre",
    width: 200,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.nombre,
  });
  parsedColumns.push({
    id: "group_caracteristica_id",
    width: 200,
    numeric: false,
    disablePadding: true,
    label: texts.Dashboard.TableAttributes.group_caracteristica_id,
  });

  return parsedColumns;
};

export const parseRows = (data, groups) => {
  const parsedRows = [];

  data.forEach((item) => {
    const { id, nombre, grupo_caracteristica_id } = item;
    parsedRows.push({
      id: { key: "id", value: id, width: "60px", textAlign: "center" },
      nombre: {
        key: "nombre",
        value: nombre,
        width: "250px",
        textAlign: "left",
      },
      group_caracteristica_id: {
        key: "group_caracteristica_id",
        value: groups.filter((jtem) => jtem.id === grupo_caracteristica_id)[0].nombre,
        width: "250px",
        textAlign: "left",
      },
    });
  });
  return parsedRows;
};
