/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/function-component-definition */
// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// layouts
import Section from "layouts/Section/Section";

// image
import features01 from "img/reals/features/01.webp";
import features02 from "img/reals/features/02.webp";
import features03 from "img/reals/features/03.webp";
import features04 from "img/reals/features/04.webp";
import features05 from "img/reals/features/05.webp";
import features06 from "img/reals/features/06.webp";

// contexts
import { useLanguage } from "context/LanguageProvider";

const Features = () => {
  const { languageState } = useLanguage();
  return (
    <Section>
      <SitoContainer ignoreDefault className="container">
        <SitoContainer
          ignoreDefault
          className="margin-50px-bottom xs-margin-40px-bottom text-center"
        >
          <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
            {languageState.texts.Home.LatestMatches.Title}
          </h4>
        </SitoContainer>
        <SitoContainer ignoreDefault className="row">
          <SitoContainer ignoreDefault className="col-lg-4 col-md-12 sm-margin-25px-bottom">
            <SitoContainer ignoreDefault className="margin-25px-bottom">
              <SitoContainer
                ignoreDefault
                className="featured-1 position-relative overflow-hidden border-radius-4"
              >
                <SitoContainer ignoreDefault className="featured-img">
                  <SitoImage
                    src={features01}
                    alt=""
                    sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                  />
                </SitoContainer>
                <SitoContainer ignoreDefault className="featured-text text-center">
                  <h4 className="margin-5px-bottom">
                    <a href="#" className="font-size28 text-white font-weight-500">
                      {languageState.texts.Home.Properties.Types.Apartments}
                    </a>
                  </h4>
                  <span className="font-size18 text-white"> </span>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault>
              <SitoContainer
                ignoreDefault
                className="featured-1 position-relative overflow-hidden border-radius-4"
              >
                <SitoContainer ignoreDefault className="featured-img">
                  <SitoImage
                    src={features02}
                    alt=""
                    sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                  />
                </SitoContainer>
                <SitoContainer ignoreDefault className="featured-text text-center">
                  <h4 className="margin-5px-bottom">
                    <a href="#" className="font-size28 text-white font-weight-500">
                      {languageState.texts.Home.Properties.Types.Houses}
                    </a>
                  </h4>
                  <span className="font-size18 text-white"> </span>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>

          <SitoContainer ignoreDefault className="col-lg-4 col-md-12 sm-margin-25px-bottom">
            <SitoContainer ignoreDefault className="margin-25px-bottom">
              <SitoContainer
                ignoreDefault
                className="featured-1 position-relative overflow-hidden border-radius-4"
              >
                <SitoContainer ignoreDefault className="featured-img">
                  <SitoImage
                    src={features03}
                    sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                  />
                </SitoContainer>
                <SitoContainer ignoreDefault className="featured-text text-center">
                  <h4 className="margin-5px-bottom">
                    <a href="#" className="font-size28 text-white font-weight-500">
                      {languageState.texts.Home.Properties.Types.Lots}
                    </a>
                  </h4>
                  <span className="font-size18 text-white"> </span>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
            <SitoContainer ignoreDefault>
              <SitoContainer
                ignoreDefault
                className="featured-1 position-relative overflow-hidden border-radius-4"
              >
                <SitoContainer ignoreDefault className="featured-img">
                  <SitoImage
                    src={features06}
                    sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                  />
                </SitoContainer>
                <SitoContainer ignoreDefault className="featured-text text-center">
                  <h4 className="margin-5px-bottom">
                    <a href="#" className="font-size28 text-white font-weight-500">
                      {languageState.texts.Home.Properties.Types.Vehicles}
                    </a>
                  </h4>
                  <span className="font-size18 text-white"> </span>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>

          <SitoContainer ignoreDefault className="col-lg-4 col-md-12">
            <SitoContainer ignoreDefault className="margin-25px-bottom">
              <SitoContainer
                ignoreDefault
                className="featured-1 position-relative overflow-hidden border-radius-4"
              >
                <SitoContainer ignoreDefault className="featured-img">
                  <SitoImage
                    src={features04}
                    sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                  />
                </SitoContainer>
                <SitoContainer ignoreDefault className="featured-text text-center">
                  <h4 className="margin-5px-bottom">
                    <a href="#" className="font-size28 text-white font-weight-500">
                      {languageState.texts.Home.Properties.Types.Locations}
                    </a>
                  </h4>
                  <span className="font-size18 text-white"> </span>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
            <SitoContainer ignoreDefault>
              <SitoContainer
                ignoreDefault
                className="featured-1 position-relative overflow-hidden border-radius-4"
              >
                <SitoContainer ignoreDefault className="featured-img">
                  <SitoImage
                    src={features05}
                    sx={{ borderRadius: "4px", width: "100%", height: "100%" }}
                  />
                </SitoContainer>
                <SitoContainer ignoreDefault className="featured-text text-center">
                  <h4 className="margin-5px-bottom">
                    <a href="#" className="font-size28 text-white font-weight-500">
                      {languageState.texts.Home.Properties.Types.Offices}
                    </a>
                  </h4>
                  <span className="font-size18 text-white"> </span>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

export default Features;
