/* eslint-disable react/function-component-definition */

// @emotion/css
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

import { useLanguage } from "context/LanguageProvider";

const Success = () => {
  const { languageState } = useLanguage();

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ width: "100%", minHeight: "60vh" }}
    >
      <h1 className="dashboard-h3 dashboard-mb-3">
        {languageState.texts.Subscription.Success.Title}
      </h1>
      <a href="/" className={css({ textDecoration: "none" })}>
        <button type="submit" className="butn">
          {languageState.texts.Buttons.GoToProfile}
        </button>
      </a>
    </SitoContainer>
  );
};

export default Success;
