import React from "react";

import { css } from "@emotion/css";

// @mui/material
import { Box, InputLabel, MenuItem, FormControl, Select } from "@mui/material/";

// contexts
import { useLanguage } from "context/LanguageProvider";

const languages = [
  {
    label: "en",
    code: "us",
  },
  { code: "es", label: "es" },
  { code: "pt", label: "pt" },
];

export default function LanguageChanger() {
  const { languageState, setLanguageState } = useLanguage();

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    setLanguageState({ type: "set", lang: value });
  };

  return (
    <Box>
      <FormControl sx={{ width: "100px", marginTop: "20px" }}>
        <InputLabel>{languageState.texts.Languages.label}</InputLabel>
        <Select
          value={languageState.lang}
          label={languageState.texts.Languages.label}
          onChange={handleChange}
          sx={{ display: "flex", gap: 1 }}
        >
          {languages.map((item) => (
            <MenuItem key={item.label} value={item.label}>
              <img
                loading="lazy"
                width="20"
                className={css({ marginRight: "10px" })}
                src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

LanguageChanger.propTypes = {};
