/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

// @mui icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";

// @mui components
import { Button, Link } from "@mui/material";

// own components
import ComplexTable from "components/MUI/ComplexTable/ComplexTable";
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { multimediaList } from "services/multimedias/get";
import { deleteMultimedia } from "services/multimedias/post";
// import { propertyList } from "services/properties/get";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { apiTokenCertified } from "utils/functions";

const List = (props) => {
  const navigate = useNavigate();
  const { navigateToInsert } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [localList, setLocalList] = useState([]);

  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const fetch = async (from = 0) => {
    setLoading(true);
    const response = await multimediaList(from);
    if (response.status === 200) {
      const { data, total } = await response.data;
      if (data.length) {
        const parsedColumns = [];
        if (!from)
          Object.keys(data[0]).forEach((item) => {
            if (item === "id")
              parsedColumns.push({
                id: item,
                width: 90,
                numeric: false,
                disablePadding: true,
                label: item,
              });
            else
              parsedColumns.push({
                id: item,
                label: languageState.texts.Dashboard.TableAttributes[item],
                width: 200,
                disablePadding: false,
                numeric: languageState.texts.Dashboard.TableAttributeTypes[item] === "number",
              });
          });
        const parsedRows = [];
        data.forEach((item) => {
          const parsedItem = { ...item };
          if (parsedItem.id)
            parsedItem.id = {
              id: parsedItem.id,
              component: (
                <SitoContainer alignItems="center">
                  <Button
                    variant="contained"
                    sx={{ minWidth: 0, padding: "5px", borderRadius: "100%" }}
                    onClick={async () => {
                      setLoading(true);
                      await deleteMultimedia(item.id);
                      fetch();
                    }}
                  >
                    <CloseIcon />
                  </Button>
                  {parsedItem.id}
                </SitoContainer>
              ),
            };
          if (parsedItem.url)
            parsedItem.url = (
              <Link href={item.url} target="_blank" rel="noreferrer">
                {item.url}
                <OpenInNewIcon />
              </Link>
            );
          parsedRows.push(parsedItem);
        });
        if (data.length < total && !from)
          for (let i = 10; i < total; i += 1) {
            const emptyElement = {};
            Object.keys(data[0]).forEach((item) => {
              emptyElement[item] = "";
            });
            parsedRows.push(emptyElement);
          }
        if (from)
          for (let i = 0; i < 10; i += 1)
            if (localList[i + from]) localList[i + from] = parsedRows[i];
        if (!from) {
          setColumns(parsedColumns);
          setLocalList(parsedRows);
        }
      }
    } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
    else {
      setLocalList(-1);
      showNotification("error", languageState.texts.Errors.NotConnected);
    }
    setLoading(false);
  };

  const [page, setPage] = useState(0);

  const onPagination = (newPage) => {
    if (localList[10 * newPage]) fetch(10 * newPage);
    setPage(newPage);
  };

  const retry = () => fetch();

  const removeToDo = async (selected) => {
    setLoading(true);
    try {
      let ok = true;
      for (const item of selected) {
        const response = await deleteMultimedia(item);
        if (response.status !== 200) {
          showNotification("error", languageState.texts.Errors.SomeWrong);
          ok = false;
          break;
        } else if (response.error && !apiTokenCertified(response.error)) navigate("/log-out");
      }
      if (ok) {
        showNotification("success", languageState.texts.Messages.CharacteristicDeletedSuccessful);
        setLocalList([]);
        retry();
      } else setLoading(false);
    } catch (error) {
      showNotification("error", languageState.texts.Errors.SomeWrong);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {localList.length > 0 && (
        <ComplexTable
          onPageChange={onPagination}
          page={page}
          columns={columns}
          rows={localList}
          onDelete={removeToDo}
        />
      )}
      {!loading && !localList.length && localList !== -1 && <Empty onAction={navigateToInsert} />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

List.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
};

export default List;
